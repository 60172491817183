import { Box, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: '100%',
    padding: theme.spacing(3),
  },
  investors: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  investor: {
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${colors.grey[50]}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  empty: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
}));

interface Props {
  step: Fluit.process.Step;
}

const DealStep: React.FC<Props> = ({ step }) => {
  const { state } = React.useContext(AppContext);
  const { investors, assignments } = state;
  const assigned = _.filter(assignments, { step_id: step.id });
  const classes = useStyles();
  return (
    <Paper className={clsx(classes.paper, (!assigned || assigned.length < 1) && classes.empty)}>
      <Box>
        <Typography variant="h6">
          {step.name} <Typography variant="caption">({assigned ? assigned.length : 0})</Typography>
        </Typography>
      </Box>
      {assigned && assigned.length > 0 ? (
        <Box className={classes.investors}>
          {_.map(_.orderBy(assigned, ['assigned_at'], ['asc']), item => {
            let investor: Fluit.investors.Investor | undefined = _.find(
              investors,
              investor => investor.id === item.investor_id
            );
            return investor ? (
              <Box key={item.id} className={classes.investor}>
                {investor.name}
              </Box>
            ) : null;
          })}
        </Box>
      ) : null}
    </Paper>
  );
};

export default DealStep;
