import _ from 'lodash';
import React from 'react';
import OnboardFunding from 'src/components/Forms/Onboarding/Funding';
import OnboardJoinOrganisation from 'src/components/Forms/Onboarding/Join';
import OnboardNewUser from 'src/components/Forms/Onboarding/NewUser';
import OnboardOrganisation from 'src/components/Forms/Onboarding/Organisation';
import OnboardOrganisationUser from 'src/components/Forms/Onboarding/OrganisationUser';
import { AppContext } from 'src/contexts/AppContext';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import OnboardReview from './Review';

const OnboardDisplayHandler: React.FC = () => {
  /* Context */
  const { state } = React.useContext(AppContext);
  const { invites } = state;
  const { onboard } = React.useContext(OnboardingContext);
  const { step } = onboard;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (_.some(invites)) {
    return <OnboardJoinOrganisation />;
  }

  if (state.organisation.id !== '') {
    return <OnboardOrganisationUser />;
  }

  if (!_.some(invites) && state.organisation.id === '') {
    if (step === 0) return <OnboardNewUser />;
    if (step === 1) return <OnboardOrganisation />;
    if (step === 2) return <OnboardFunding />;
    if (step === 3) return <OnboardReview />;
  }

  return <></>;
};

export default OnboardDisplayHandler;
