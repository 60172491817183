import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const DealsReducer = (state: Fluit.deals.Deal[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'DEAL_CREATE':
      const DEAL_CREATE = _.orderBy(_.concat(_.values(state), action.payload), ['name', 'type'], ['asc', 'asc']);
      return DEAL_CREATE;
    case 'DEALS_LIST':
      const DEAL_LIST = _.orderBy(_.values(action.payload), ['name', 'type'], ['asc', 'asc']);
      return DEAL_LIST;
    case 'DEAL_UPDATE':
      const DEAL_UPDATE = _.orderBy(
        _.concat(
          _.filter(state, function(deal) {
            return deal.id !== action.payload.id;
          }),
          action.payload
        ),
        ['name', 'type'],
        ['asc', 'asc']
      );
      return DEAL_UPDATE;
    case 'DEAL_DELETE':
      const DEAL_DELETE = _.orderBy(
        _.filter(state, deal => deal.id !== action.payload.id),
        ['name', 'type'],
        ['asc', 'asc']
      );
      return DEAL_DELETE;
    case 'DEAL_PROCESS_UPDATE':
      return {
        ...state,
        process: {
          ...process,
          steps: action.payload,
        },
      };
    case 'DEALS_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
