import { Box, Chip, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import colors from 'src/themes/colors';
import { Email, Fax, Mobile, Telephone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    fill: theme.palette.grey[200],
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  header: {
    background: colors.gradients.vertical.grey,
    padding: theme.spacing(3, 3, 2),
  },
  content: {
    '&:empty': {
      display: 'none',
    },
    padding: theme.spacing(0, 3, 3),
    '& > div': {
      margin: theme.spacing(1, 0),
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  attribute: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

interface Props {
  data: Fluit.investors.Contact;
  showInvestor?: boolean;
}
const ContactDisplay: React.FC<Props> = ({ data, showInvestor }) => {
  const { first_name, last_name, telephone, position, fax, mobile, email, investor_id, decision_maker } = data;
  const { state } = React.useContext(AppContext);
  const investor = _.find(state.investors, { id: investor_id });
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.header}>
        {decision_maker ? (
          <Box mb={1}>
            <Chip size="small" label="Decision Maker" color="primary" />
          </Box>
        ) : null}
        {showInvestor && investor ? (
          <Typography variant="caption">
            <em>{investor.name}</em>
          </Typography>
        ) : null}
        <Typography variant="h4">
          {first_name} {last_name}
        </Typography>
        <Typography variant="body2">{position}</Typography>
      </Box>
      <Box className={classes.content}>
        {telephone ? (
          <Box>
            <Typography variant="body2">
              <Link color="secondary" href={`tel:${telephone}`} className={classes.attribute}>
                <Telephone className={classes.svg} /> {telephone}
              </Link>
            </Typography>
          </Box>
        ) : null}
        {mobile ? (
          <Box>
            <Typography variant="body2">
              <Link color="secondary" href={`tel:${mobile}`} className={classes.attribute}>
                <Mobile className={classes.svg} /> {mobile}
              </Link>
            </Typography>
          </Box>
        ) : null}
        {fax ? (
          <Box>
            <Typography variant="body2" className={classes.attribute}>
              <Fax className={classes.svg} /> {fax}
            </Typography>
          </Box>
        ) : null}
        {email ? (
          <Box>
            <Typography variant="body2">
              <Link color="secondary" href={`mailto:${email}`} target="_blank" className={classes.attribute}>
                <Email className={classes.svg} /> {email}
              </Link>
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ContactDisplay;
