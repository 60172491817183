import Fluit from 'src/types/Fluit';
import { ProductUsers, ProductDeals } from './products';

interface init {
  assignment: Fluit.assignments.Assignment;
  address: Fluit.address.Address;
  application: Fluit.context.application.State;
  billing: Fluit.billing.Billing;
  checkout: Fluit.checkout.Checkout;
  contact: Fluit.investors.Contact;
  country: Fluit.address.Country;
  currency: Fluit.Currency;
  deal: Fluit.deals.Deal;
  investor: Fluit.investors.Investor;
  member: Fluit.organisations.Member;
  note: Fluit.notes.Note;
  organisation: Fluit.organisations.Organisation;
  user: Fluit.users.User;
  process: Fluit.process.Process;
  tours: Fluit.context.tours.State;
}

const init_country: Fluit.address.Country = {
  name: 'United Kingdom',
  iso: 'gb',
  currency: 'GBP',
};

const init_currency: Fluit.Currency = {
  name: 'United Kingdom Pound',
  code: 'GBP',
  symbol: '£',
  alpha_2: 'gb',
};

const init_address: Fluit.address.Address = {
  line_1: '',
  line_2: '',
  town: '',
  city: '',
  postcode: '',
  country: {
    name: 'United Kingdom',
    iso: 'gb',
    currency: 'GBP',
  },
};

const init_organisation: Fluit.organisations.Organisation = {
  /* Oranisation Details */
  id: '',
  name: '',
  telephone: '',
  email: '',
  website: '',
  type: '',
  industry: '',
  year_founded: 2020,
  employees: 1,
  location: init_country,

  /* Financial Details */
  currency: init_currency,
  current_round: 'Seed',
  total_funding: 0,

  /* Limit Data */
  limits: {
    deals: {
      count: 0,
      limit: 1,
    },
    investors: {
      count: 0,
      limit: 1,
    },
    members: {
      count: 0,
      limit: 1,
    },
    activities: {
      count: 0,
      limit: 1,
    },
    limit_activities: true,
  },
};

const init_user: Fluit.users.User = {
  id: '',
  email: '',
  email_verified: false,
  is_onboarded: null,
  first_name: '',
  telephone: '',
  position: '',
  last_name: '',
  invites: null,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  settings: {
    default_organisation: '',
  },
};

const init_deal: Fluit.deals.Deal = {
  org_id: '',
  id: '',
  name: '',
  type: 'Fundraise',
  termsheets: [],
  status: 'Inactive',
  details: {
    round: 'None',
    currency: init_currency,
    amount: 0,
    equity: 0,
  },
  created_at: '',
  updated_at: '',
};

const init_investor: Fluit.investors.Investor = {
  id: '',
  org_id: '',
  name: '',
  address: init_address,
  telephone: '',
  fax: '',
  email: '',
  website: '',
  deals: null,
  regions: undefined,
  types: undefined,
  stages: undefined,
  location: undefined,
  investment_max: undefined,
  investment_min: undefined,
  sectors: undefined,
  legal: {
    legal_name: '',
    registration_no: '',
    address: init_address,
    telephone: '',
    fax: '',
    email: '',
    website: '',
    notes: '',
  },
  signatory: {
    first_name: '',
    last_name: '',
    address: init_address,
    telephone: '',
    fax: '',
    email: '',
    notes: '',
    witness: false,
  },
};

const init_checkout: Fluit.checkout.Checkout = {
  products: {
    users: ProductUsers,
    deals: ProductDeals,
  },
  interval: 'month',
  quantity: {
    users: 1,
    deals: 1,
  },
  totals: {
    gross: 0,
    vat: 0,
    total: 0,
    savings: 0,
  },
  customer: {
    id: '',
    name: '',
    phone: '',
    email: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      country: '',
      state: '',
      postal_code: '',
    },
    tax_id_data: [
      {
        type: '',
        value: '',
      },
    ],
    metadata: {
      org_id: '',
      tax_country: '',
    },
  },
  step: 0,
  logic: [true, false, false],
  error: undefined,
  loading: false,
  result: {
    subscription: {
      current_period_start: 1598728301,
      current_period_end: 1598728301,
      latest_invoice: {
        subtotal: 0,
        total: 0,
      },
    },
    items: [
      {
        quantity: 0,
      },
      {
        quantity: 0,
      },
    ],
  },
};

const init_note: Fluit.notes.Note = {
  id: '',
  org_id: '',
  title: '',
  contents: '[]',
  parent_type: 'organisation',
  parent_id: '',
  child_id: '',
  created_by: '',
  creator_name: '',
  updated_by: '',
  updator_name: '',
};

const init_billing: Fluit.billing.Billing = {
  stripe_customer_id: '',
  invoices: [],
  payment_methods: [],
  customer: null,
  latest_invoice: null,
};

const init_member: Fluit.organisations.Member = {
  id: '',
  org_id: '',
  email: '',
  email_verified: false,
  is_onboarded: null,
  first_name: '',
  telephone: '',
  position: '',
  last_name: '',
  invites: null,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  settings: {
    default_organisation: '',
  },
  system: {
    disabled: false,
  },
  role: 'member',
};

const init_contact: Fluit.investors.Contact = {
  id: '',
  org_id: '',
  investor_id: '',
  first_name: '',
  last_name: '',
  position: '',
  telephone: '',
  mobile: '',
  fax: '',
  email: '',
  decision_maker: false,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  created_by: '',
  updated_by: '',
  system: {
    status: 'ACTIVE',
  },
};

const init_ui: Fluit.props.UI = {
  modal: {
    open: false,
    title: '',
    component: '',
  },
  notes: {},
};

const init_apis: Fluit.services.Initalisation = {
  activity_system: false,
  activity_user: false,
  activity_user_called: false,
  activity_system_called: false,
  admin: false,
  assignments: false,
  buying: false,
  contacts: false,
  deals: false,
  initalized: false,
  investors: false,
  loading: false,
  organisation: false,
  processes: false,
  registering: false,
  reload: false,
  subscriptions: false,
  switching: false,
  tasks: false,
  termsheets: false,
  user: false,
  users: false,
  payment_failed: false,
};

const init_tours: Fluit.context.tours.State = {
  firstLoad: true,
  navigation: false,
  deal: false,
  investor: false,
  investor_search: false,
  tourTarget: 'none',
};

const init_application: Fluit.context.application.State = {
  assignments: [],
  billing: init_billing,
  contacts: [],
  deals: [],
  init: init_apis,
  investors: [],
  invited_members: [],
  invites: [],
  members: [],
  notes: [],
  organisation: init_organisation,
  organisations: [],
  processes: [],
  subscriptions: [],
  tasks: [],
  termsheets: [],
  ui: init_ui,
  user: init_user,
};

const init_process: Fluit.process.Process = {
  id: '',
  deal_id: '',
  org_id: '',
  steps: [
    {
      id: '',
      name: '',
      editable: false,
    },
  ],
};

const init_assignment: Fluit.assignments.Assignment = {
  id: '',
  step_id: '',
  deal_id: '',
  process_id: '',
  investor_id: '',
  org_id: '',
  contacts: [],
};

// * MAIN EXPORT OBJECT
export const initalise: init = {
  assignment: init_assignment,
  address: init_address,
  application: init_application,
  billing: init_billing,
  checkout: init_checkout,
  contact: init_contact,
  country: init_country,
  currency: init_currency,
  deal: init_deal,
  investor: init_investor,
  member: init_member,
  note: init_note,
  organisation: init_organisation,
  process: init_process,
  user: init_user,
  tours: init_tours,
};
