import { Button, Grid, LinearProgress, makeStyles, Paper, TextField, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { initalise } from 'src/constants/init';
import { AlgoliaContext } from 'src/contexts/AlgoliaContext';
import { AppContext } from 'src/contexts/AppContext';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { DealContext } from 'src/contexts/DealContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { UserFirebase } from 'src/services/users';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const ProfileDeleteForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { setSearch } = React.useContext(AlgoliaContext);
  const { setCheckout } = React.useContext(CheckoutContext);
  const { setDeal } = React.useContext(DealContext);
  const { setInvestor } = React.useContext(InvestorContext);
  const { setOnboard } = React.useContext(OnboardingContext);
  const { setProcess } = React.useContext(ProcessContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, user } = state;
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState('');
  const classes = useStyles();
  const fbAuth = React.useContext(FirebaseContext);
  const [error, setError] = React.useState<Fluit.firestore.Error | undefined>(undefined);

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const logout = React.useCallback(async () => {
    await fbAuth.signOut();
    setSearch({
      query: '',
      selected: [],
    });
    setCheckout(initalise.checkout);
    setDeal(initalise.deal);
    setInvestor(initalise.investor);
    setOnboard({
      user: initalise.user,
      organisation: initalise.organisation,
      step: 0,
      currency_step: false,
    });
    setProcess(initalise.process);
    dispatch({ type: 'ACTIVITIES_FLUSH' });
    dispatch({ type: 'API_FLUSH_INIT' });
    dispatch({ type: 'BILLING_FLUSH' });
    dispatch({ type: 'CONTACTS_FLUSH' });
    dispatch({ type: 'DEALS_FLUSH' });
    dispatch({ type: 'INVESTORS_FLUSH' });
    dispatch({ type: 'INVITE_FLUSH' });
    dispatch({ type: 'INVITED_FLUSH' });
    dispatch({ type: 'MEMBERS_FLUSH' });
    dispatch({ type: 'NOTES_FLUSH' });
    dispatch({ type: 'PROCESSES_FLUSH' });
    dispatch({ type: 'ORGANISATION_FLUSH' });
    dispatch({ type: 'ORGANISATIONS_FLUSH' });
    dispatch({ type: 'SUBSCRIPTIONS_FLUSH' });
    dispatch({ type: 'TASKS_FLUSH' });
    dispatch({ type: 'TERMSHEETS_FLUSH' });
    dispatch({ type: 'UI_FLUSH' });
    dispatch({ type: 'USER_FLUSH' });
    dispatch({ type: 'API_LOADING', payload: false });
  }, [fbAuth, dispatch, setSearch, setCheckout, setDeal, setInvestor, setOnboard, setProcess]);

  const deleteAccount = React.useCallback(async () => {
    try {
      setLoading(true);
      dispatch({ type: 'API_LOADING', payload: true });
      await UserFirebase.delete();
      setToast({
        message: 'Account deleted',
        type: 'success',
      });
      closeModal();
      logout();
    } catch (error) {
      dispatch({ type: 'API_LOADING', payload: false });
      setLoading(false);
      setError(error);
      setToast({
        message: `${error.name} | ${error.message}`,
        type: 'error',
      });
    }
  }, [setLoading, setToast, closeModal, dispatch, logout]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">Permanently delete your account from Fluit?</Typography>
        <ul>
          <li>
            You will <b>delete</b> {organisation.name} from Fluit
          </li>
          <li>
            You will <b>delete</b> your account from Fluit
          </li>
          <li>
            You will <b>lose access to</b> {organisation.name}
          </li>
          <li>
            You will <b>lose access to</b> all deals associated to {organisation.name}
          </li>
          <li>
            You will <b>lose access to</b> investors associated to {organisation.name}
          </li>
          <li>
            You will <b>lose access to</b> all notes, tasks, and activity logs
          </li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          To permantly delete your account type {user.email} in the field below
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          name="user_email"
          onChange={event => {
            setConfirm(event.target.value);
          }}
          error={confirm === user.email ? true : false}
          helperText={confirm === user.email ? `Pressing delete will permantly delete your account` : ''}
          disabled={loading ? true : false}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          style={{ marginRight: '16px' }}
          onClick={() => deleteAccount()}
          disabled={loading || confirm !== user.email ? true : false}
        >
          Delete
        </Button>
        <Button variant="text" size="large" onClick={() => closeModal()}>
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
      {error && (
        <Grid item xs={12}>
          <Paper>
            <Alert severity="error">
              {error.name} {error.message}{' '}
            </Alert>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default ProfileDeleteForm;
