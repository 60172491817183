import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import * as React from 'react';
import TasksList from 'src/components/Tasks/List';
import { AppContext } from 'src/contexts/AppContext';

const Tasks: React.FC = () => {
  const { dispatch } = React.useContext(AppContext);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const TaskCreate = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `New Task`,
        component: 'TASK_CREATE',
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection={mobile ? 'column' : 'row'} justifyContent="space-between">
          <Typography variant="h1">Tasks</Typography>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="space-between">
            <Button style={{ marginLeft: 16 }} variant="outlined" color="secondary" onClick={() => TaskCreate()}>
              New {!mobile && 'Task'}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TasksList />
      </Grid>
    </Grid>
  );
};

export default Tasks;
