import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as FluitGrey } from 'src/assets/logo/fluit-greyscale.svg';
import { ReactComponent as FluitLogo } from 'src/assets/logo/fluit-purple-solid.svg';
import { initalise } from 'src/constants/init';
import { desktopLinks } from 'src/constants/navigation';
import { AlgoliaContext } from 'src/contexts/AlgoliaContext';
import { AppContext } from 'src/contexts/AppContext';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { DealContext } from 'src/contexts/DealContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { Close, Menu as MenuIcon } from 'src/themes/icons';
import shadows from 'src/themes/shadows';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    boxShadow: shadows.black[2],
    padding: theme.spacing(1, 0),
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    '& button, & > a': {
      marginLeft: theme.spacing(1),
    },
  },
  svg: {
    width: '20px',
    height: '20px',
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  svgClose: {
    width: '16px',
    height: '16px',
    fill: theme.palette.grey[200],
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  drawer: {
    width: '90vw',
    padding: theme.spacing(3, 0),
  },
  drawerHeader: {
    height: '72px',
    padding: theme.spacing(0, 3),
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    '& > div': {
      padding: theme.spacing(1, 3),
    },
  },
  link: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    color: 'inherit',
    alignItems: 'center',
    justifyContent: 'center',
    '& $linkText': {
      fontWeight: 400,
    },
    '& svg': {
      width: '20px',
      height: '20px',
      marginRight: theme.spacing(2),
    },
  },
  active: {
    background: colors.purple[50],
    '& $linkText': {
      fontWeight: 600,
    },
    color: theme.palette.primary.main,
    '& svg, & svg g, & svg path': {
      fill: theme.palette.primary.main,
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
    border: `1px solid ${colors.grey[300]}`,
    color: colors.grey[300],
    fontSize: '9px',
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    fontWeight: 600,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  linkText: {},
}));

const HeaderSite: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { setSearch } = React.useContext(AlgoliaContext);
  const { setCheckout } = React.useContext(CheckoutContext);
  const { setDeal } = React.useContext(DealContext);
  const { setInvestor } = React.useContext(InvestorContext);
  const { setOnboard } = React.useContext(OnboardingContext);
  const { setProcess } = React.useContext(ProcessContext);
  const { user } = state;
  const [open, setOpen] = React.useState(false);
  const fbAuth = React.useContext(FirebaseContext);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('md'));
  const history = useHistory();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const logout = async () => {
    await fbAuth.signOut();
    setSearch({
      query: '',
      selected: [],
    });
    setCheckout(initalise.checkout);
    setDeal(initalise.deal);
    setInvestor(initalise.investor);
    setOnboard({
      user: initalise.user,
      organisation: initalise.organisation,
      step: 0,
      currency_step: false,
    });
    setProcess(initalise.process);
    dispatch({ type: 'ACTIVITIES_FLUSH' });
    dispatch({ type: 'API_FLUSH_INIT' });
    dispatch({ type: 'BILLING_FLUSH' });
    dispatch({ type: 'CONTACTS_FLUSH' });
    dispatch({ type: 'DEALS_FLUSH' });
    dispatch({ type: 'INVESTORS_FLUSH' });
    dispatch({ type: 'INVITE_FLUSH' });
    dispatch({ type: 'INVITED_FLUSH' });
    dispatch({ type: 'MEMBERS_FLUSH' });
    dispatch({ type: 'NOTES_FLUSH' });
    dispatch({ type: 'PROCESSES_FLUSH' });
    dispatch({ type: 'ORGANISATION_FLUSH' });
    dispatch({ type: 'ORGANISATIONS_FLUSH' });
    dispatch({ type: 'SUBSCRIPTIONS_FLUSH' });
    dispatch({ type: 'TASKS_FLUSH' });
    dispatch({ type: 'TERMSHEETS_FLUSH' });
    dispatch({ type: 'UI_FLUSH' });
    dispatch({ type: 'USER_FLUSH' });
    toggleDrawer(false);
  };

  const handleLink = (route: string) => {
    setOpen(false);
    history.replace(route);
  };

  const openLink = (url: string) => {
    window.location.href = url;
  };

  if (mobile) {
    return (
      <AppBar position="fixed" className={classes.appBar} color="inherit" elevation={0}>
        <Container maxWidth="md">
          <Toolbar disableGutters={true} className={classes.toolBar}>
            <NavLink to={!state.init.user ? ROUTES.DEALS : ROUTES.REGISTER}>
              <FluitLogo />
            </NavLink>
            <Box className={classes.links}>
              {!state.init.user ? (
                <Box>
                  <Button component={Link} href="https://fluit.co/">
                    Home
                  </Button>
                  <Button component={Link} href="https://fluit.co/#features">
                    Features
                  </Button>
                  <Button component={Link} href="https://fluit.co/#platform">
                    Platform
                  </Button>
                  <Button component={Link} href="https://fluit.co/#pricing">
                    Pricing
                  </Button>
                  <Button component={Link} href="https://fluit.co/mission.html">
                    Company
                  </Button>
                </Box>
              ) : null}

              {!state.init.user ? (
                <Button color="primary" variant="outlined" component={NavLink} to={ROUTES.REGISTER}>
                  Register
                </Button>
              ) : null}

              {!state.init.user ? (
                <Button color="primary" variant="contained" component={NavLink} to={ROUTES.LOGIN}>
                  Login
                </Button>
              ) : null}

              {state.init.user && user.is_onboarded ? (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    component={NavLink}
                    to={ROUTES.DEALS}
                    style={{ marginRight: 16 }}
                  >
                    Dashboard
                  </Button>
                  <Button color="secondary" variant="outlined" onClick={logout}>
                    Logout
                  </Button>
                </>
              ) : null}

              {state.init.user && !user.is_onboarded ? (
                <Button color="secondary" variant="outlined" onClick={logout}>
                  Logout
                </Button>
              ) : null}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }

  return (
    <AppBar position="fixed" className={classes.appBar} color="inherit" elevation={0}>
      <Container maxWidth="md">
        <Toolbar disableGutters={true} className={classes.toolBar}>
          <NavLink to={!state.init.user ? ROUTES.DEALS : ROUTES.REGISTER}>
            <FluitLogo />
          </NavLink>
          <Box className={classes.links}>
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon className={classes.svg} />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Box className={classes.drawerHeader}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <FluitGrey />
            <IconButton onClick={toggleDrawer(false)}>
              <Close className={classes.svgClose} />
            </IconButton>
          </Box>
        </Box>

        {!state.init.user ? (
          <List component="nav" aria-label="main navigation" className={classes.list}>
            <ListItem button divider onClick={() => openLink('https://www.fluit.co/')}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button divider onClick={() => openLink('https://www.fluit.co/#features')}>
              <ListItemText primary="Features" />
            </ListItem>
            <ListItem button onClick={() => openLink('https://www.fluit.co/#platform')}>
              <ListItemText primary="Platform" />
            </ListItem>
            <ListItem button onClick={() => openLink('https://www.fluit.co/#pricing')}>
              <ListItemText primary="Pricing" />
            </ListItem>
            <ListItem button onClick={() => openLink('https://www.fluit.co/mission.html')}>
              <ListItemText primary="Company" />
            </ListItem>
          </List>
        ) : (
          <List component="nav" aria-label="main navigation" className={classes.list}>
            {_.map(desktopLinks, link => {
              return (
                <ListItem button divider onClick={() => handleLink(link.route)} key={link.title}>
                  <NavLink
                    to={link.route}
                    className={classes.link}
                    activeClassName={classes.active}
                    exact={link.route === '/' ? true : false}
                  >
                    {link.icon}
                    <ListItemText disableTypography primary={link.title} className={classes.linkText} />
                  </NavLink>
                </ListItem>
              );
            })}
            <ListItem button divider onClick={() => handleLink(ROUTES.PROFILE)}>
              <NavLink to={ROUTES.PROFILE} className={classes.link} activeClassName={classes.active}>
                <Box className={classes.avatar}>
                  {user.first_name.charAt(0)}
                  {user.last_name.charAt(0)}
                </Box>
                <ListItemText disableTypography primary="Profile" className={classes.linkText} />
              </NavLink>
            </ListItem>
          </List>
        )}

        {!state.init.user ? (
          <Box px={3} mt={2}>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              component={NavLink}
              to={ROUTES.REGISTER}
              onClick={toggleDrawer(false)}
            >
              Register
            </Button>
          </Box>
        ) : null}

        {!state.init.user ? (
          <Box px={3} mt={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              component={NavLink}
              to={ROUTES.LOGIN}
              onClick={toggleDrawer(false)}
            >
              Login
            </Button>
          </Box>
        ) : (
          <Box px={3} mt={2}>
            <Button color="primary" variant="contained" fullWidth onClick={logout}>
              Logout
            </Button>
          </Box>
        )}
      </SwipeableDrawer>
    </AppBar>
  );
};

export default HeaderSite;
