import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import InvestorDetailsDisplay from 'src/components/Displays/InvestorDetails';
import InvestorSwitch from 'src/components/Inputs/Autocomplete/InvestorSwitch';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import InvestorTabActivity from 'src/components/Tabs/Investors/Activity';
import InvestorTabContacts from 'src/components/Tabs/Investors/Contacts';
import InvestorTabDeals from 'src/components/Tabs/Investors/Deals';
import InvestorTabDetails from 'src/components/Tabs/Investors/Details';
import InvestorTabLegal from 'src/components/Tabs/Investors/Legal';
import InvestorTabs from 'src/components/Tabs/Investors/Navigation';
import InvestorTabNotes from 'src/components/Tabs/Investors/Notes';
import InvestorTabTasks from 'src/components/Tabs/Investors/Tasks';
import InvestorTabTermsheets from 'src/components/Tabs/Investors/Termsheets';
import TabPanel from 'src/components/Tabs/TabPanel';
import AppTour from 'src/components/Tour/Tour';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { InvestorFirebase } from 'src/services/investors';
import Fluit from 'src/types/Fluit';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Investor: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const firebase = React.useContext(FirebaseContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { investors, organisation } = state;
  const { investor, setInvestor } = React.useContext(InvestorContext);
  let { investor_id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  let query = useQuery();
  let is_search = useRouteMatch('/investors/search');

  const handleTabs = (event: React.ChangeEvent<{}>, newTab: string) => {
    history.push(`${ROUTES.INVESTORS}/${investor_id}?tab=${newTab}`);
  };

  const tab = query.get('tab') ? query.get('tab') : 'contacts';

  React.useEffect(() => {
    if (investor.id !== investor_id && !is_search && !loading) {
      history.push(ROUTES.INVESTORS);
    }
  }, [history, investor_id, investors, is_search, loading, investor]);

  const handleInvestor = React.useCallback(
    (investor: Fluit.investors.Investor) => {
      setLoading(true);
      setInvestor(investor);
      setLoading(false);
    },
    [setLoading, setInvestor]
  );

  const listInvestors = React.useCallback(async () => {
    try {
      const investors = await InvestorFirebase.list(organisation.id);
      dispatch({ type: 'INVESTORS_LIST', payload: investors });
    } catch (error) {
      const err: Fluit.firestore.Error = error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [organisation.id, dispatch, setToast]);

  React.useEffect(() => {
    listInvestors();
  }, [listInvestors]);

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('organisations')
      .doc(organisation.id)
      .collection('investors')
      .where('id', '==', investor_id)
      .where('system.disabled', '==', false)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          const investor = change.doc.data() as Fluit.investors.Investor;
          if (change.type === 'added') {
            const investor_exists = _.find(investors, { id: investor.id });
            if (!investor_exists) {
              dispatch({ type: 'INVESTOR_CREATE', payload: investor });
              dispatch({ type: 'COUNT_UP_INVESTOR' });
            } else {
              dispatch({ type: 'INVESTOR_UPDATE', payload: investor });
            }
            handleInvestor(investor);
          }
          if (change.type === 'modified') {
            handleInvestor(investor);
            dispatch({ type: 'INVESTOR_UPDATE', payload: investor });
          }
          if (change.type === 'removed') {
            history.push(ROUTES.INVESTORS);
            dispatch({ type: 'INVESTOR_DELETE', payload: investor });
          }
        });
        setLoading(false);
      });

    return () => {
      unsubscribe();
    };
  }, [firebase, dispatch, organisation.id, handleInvestor, investor_id, history]); // eslint-disable-line react-hooks/exhaustive-deps

  const pannels = (
    <>
      <TabPanel value={tab} id="contacts">
        <InvestorTabContacts />
      </TabPanel>
      <TabPanel value={tab} id="deals">
        <InvestorTabDeals />
      </TabPanel>
      <TabPanel value={tab} id="termsheets">
        <InvestorTabTermsheets />
      </TabPanel>
      <TabPanel value={tab} id="legal">
        <InvestorTabLegal />
      </TabPanel>
      <TabPanel value={tab} id="details">
        <InvestorTabDetails />
      </TabPanel>
      <TabPanel value={tab} id="tasks">
        <InvestorTabTasks />
      </TabPanel>
      <TabPanel value={tab} id="notes">
        <InvestorTabNotes />
      </TabPanel>
      <TabPanel value={tab} id="activity">
        <InvestorTabActivity />
      </TabPanel>
    </>
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4} lg={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {loading ? <GenericLoader height="64px" /> : <InvestorSwitch setLoading={setLoading} />}
          </Grid>

          {loading ? (
            <Grid item xs={12}>
              <GenericLoader height="112px" />
            </Grid>
          ) : (
            <InvestorTabs handleTabs={handleTabs} tab={tab} />
          )}

          {!mobile ? (
            <Grid item xs={12}>
              {loading ? <GenericLoader height="120px" /> : <InvestorDetailsDisplay />}
            </Grid>
          ) : null}

          <AppTour />
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        {loading ? <GenericLoader height="100%" variant="none" /> : pannels}
      </Grid>
    </Grid>
  );
};

export default Investor;
