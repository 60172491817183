import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const ContactsReducer = (state: Fluit.investors.Contact[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'CONTACT_CREATE':
      const CONTACT_CREATE = _.orderBy(
        _.concat(_.values(state), action.payload),
        ['last_name', 'first_name'],
        ['asc', 'asc']
      );
      return CONTACT_CREATE;
    case 'CONTACT_UPDATE':
      const CONTACT_UPDATE = _.orderBy(
        _.concat(_.values(_.filter(state, contact => contact.id !== action.payload.id)), action.payload),
        ['last_name', 'first_name'],
        ['asc', 'asc']
      );
      return CONTACT_UPDATE;
    case 'CONTACT_DELETE':
      const CONTACT_DELETE = _.orderBy(
        _.values(_.filter(state, contact => contact.id !== action.payload.id)),
        ['last_name', 'first_name'],
        ['asc', 'asc']
      );
      return CONTACT_DELETE;
    case 'CONTACTS_LIST':
      const CONTACTS_LIST = _.orderBy(action.payload, ['last_name', 'first_name'], ['asc', 'asc']);
      return CONTACTS_LIST;
    /* FLUSH */
    case 'CONTACTS_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
