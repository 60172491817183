import { makeStyles, Theme, Typography } from '@material-ui/core';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import React from 'react';
import colors from 'src/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  right: {
    '&::before': {
      flex: 0,
      padding: 0,
      display: 'none',
    },
  },
  content: {
    paddingRight: 0,
    paddingTop: 3,
  },
  date: {
    fontSize: 16,
    fontWeight: 600,
    display: 'block',
    color: colors.grey[100],
  },
  dot: {
    backgroundColor: colors.grey[100],
  },
  root: {
    minHeight: 'auto',
  },
}));

interface Props {
  message: string;
}

const ActivityEnd: React.FC<Props> = ({ message }) => {
  const classes = useStyles();

  return (
    <TimelineItem
      classes={{
        missingOppositeContent: classes.right,
        root: classes.root,
      }}
    >
      <TimelineSeparator>
        <TimelineDot className={classes.dot} />
      </TimelineSeparator>

      <TimelineContent className={classes.content}>
        <Typography variant="overline" className={classes.date}>
          {message}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ActivityEnd;
