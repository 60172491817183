import { Box, CircularProgress, Divider, TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderGroupParams, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { InvestorFirebase } from 'src/services/investors';
import Fluit from 'src/types/Fluit';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  touched: boolean | undefined;
  error: string | undefined;
  label: string;
  value?: string;
}

const AutocompleteInvestorsByLetter: React.FC<Props> = ({ setFieldValue, name, id, touched, error, label, value }) => {
  const { state, dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const { investors, organisation } = state;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Fluit.investors.Investor[]>([]);
  const [fetched, setFetched] = React.useState(false);
  const loading = open && options.length === 0 && !fetched;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (_.some(investors)) {
      setOptions(_.values(investors));
      return undefined;
    }

    if (fetched) {
      return undefined;
    }

    (async () => {
      try {
        const result = await InvestorFirebase.list(organisation.id);
        const updatedInvestors = _.map(result, investor =>
          _.assign(investor, {
            firstLetter: /[0-9]/.test(investor.name[0].toUpperCase()) ? '0-9' : investor.name[0].toUpperCase(),
          })
        );
        dispatch({ type: 'INVESTORS_LIST', payload: updatedInvestors });
        if (active) {
          setOptions(updatedInvestors);
        }
        setFetched(true);
      } catch (error) {
        const err = error as Fluit.firestore.Error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [investors, organisation, loading, dispatch, setToast, fetched]);

  let val: Fluit.investors.Investor | null = null;

  if (value) {
    const deal = _.find(investors, { id: value });
    val = deal ? deal : null;
  }

  return (
    <Field
      name={name}
      id={id ? id : name}
      component={Autocomplete}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      groupBy={(option: Fluit.investors.Investor) => option.firstLetter}
      getOptionLabel={(option: Fluit.investors.Investor) => option.name}
      autoSelect={true}
      value={val}
      onChange={(event: object, value: Fluit.investors.Investor) => {
        setFieldValue(name, value ? value.id : '');
      }}
      noOptionsText="No investors available"
      renderGroup={(option: AutocompleteRenderGroupParams) => (
        <Box key={option.key} mb={1}>
          <Box px={2} py={1}>
            <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
              {option.group}
            </Typography>
          </Box>
          {option.children}
          <Divider light={true} />
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteInvestorsByLetter;
