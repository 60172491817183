import { Box } from '@material-ui/core';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  id: string;
  value: string | null;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, id, ...other } = props;

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== id}
      id={`nav-tabpanel-${id}`}
      aria-labelledby={`nav-tab-${id}`}
      {...other}
    >
      {value === id && <Box>{children}</Box>}
    </Box>
  );
};

export default TabPanel;
