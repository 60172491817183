import { makeStyles, Theme, createStyles } from '@material-ui/core';

const FluitStyles = {
  AutoComplete: {
    country: makeStyles((theme: Theme) =>
      createStyles({
        option: {
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        },
        flag: { flex: 0, marginRight: theme.spacing(0.5) },
        label: { flex: 1 },
        alpha_2: { flex: 0, color: theme.palette.text.hint },
        symbol: { flex: 0, marginRight: theme.spacing(0.5), color: theme.palette.text.hint },
      })
    ),
  },
  Stepper: {
    stepper: makeStyles((theme: Theme) =>
      createStyles({
        stepper: {
          background: 'none',
          width: 'fit-content',
        },
      })
    ),
  },
};

export default FluitStyles;
