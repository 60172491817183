import { Grid } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import React from 'react';
import CheckoutSummary from 'src/components/Checkout/Summary';
import BillingDetails from 'src/components/Displays/BillingDetails';
import { AppContext } from 'src/contexts/AppContext';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { BillingFirebase } from 'src/services/billing';
import { billing_creditCardStyles } from 'src/styles/billing';
import { styles_checkoutPayment } from 'src/styles/CheckoutStyles';
import Fluit from 'src/types/Fluit';
import Stripe from 'stripe';

const CheckoutPay: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { checkout, setCheckout } = React.useContext(CheckoutContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;
  const { limits } = organisation;
  const { interval, products, quantity } = checkout;
  const [customer, setCustomer] = React.useState<Fluit.checkout.Customer>(checkout.customer);

  const stripe = useStripe();
  const elements = useElements();
  const classes = styles_checkoutPayment();

  // * Set Product Items & Quantity
  const handleItems = React.useCallback(() => {
    if (interval === 'month') {
      return [
        {
          // * Users
          price: products.users.standard.month.price_id,
          quantity: quantity.users,
        },
        {
          // * Deals
          price: products.deals.fundraise.month.price_id,
          quantity: quantity.deals,
        },
      ];
    }
    return [
      {
        // * Users
        price: products.users.standard.year.price_id,
        quantity: quantity.users,
      },
      {
        // * Deals
        price: products.deals.fundraise.year.price_id,
        quantity: quantity.deals,
      },
    ];
  }, [interval, products, quantity]);

  // * Handle & Display Errors
  const handleError = React.useCallback(
    (error: Stripe.IStripeError) => {
      setCheckout({
        ...checkout,
        error: error,
        loading: false,
      });
      dispatch({ type: 'API_LOADING', payload: false });
    },
    [setCheckout, checkout, dispatch]
  );

  const handlePurchaseSuccess = React.useCallback(
    (result: any) => {
      dispatch({ type: 'BUYING_SET', payload: true });
      dispatch({ type: 'SUBSCRIPTION_UPDATE', payload: result.subscription });

      const updated_Organisation = {
        ...organisation,
        limits: {
          deals: {
            count: limits.deals.count,
            limit: checkout.quantity.deals,
          },
          investors: {
            count: limits.investors.count,
            limit: 1000,
          },
          members: {
            count: limits.members.count,
            limit: checkout.quantity.users,
          },
          activities: {
            count: limits.activities.count,
            limit: 1000,
          },
          limit_activities: false,
        },
      };
      dispatch({ type: 'ORGANISATION_SET', payload: updated_Organisation });
      dispatch({ type: 'ORGANISATIONS_SET', payload: updated_Organisation });
      setCheckout({
        ...checkout,
        result: result,
        step: 3,
      });
    },
    [checkout, setCheckout, dispatch, organisation, limits]
  );

  // * On Subscription Complete
  const onSubscriptionComplete = React.useCallback(
    (result: any) => {
      setCheckout({
        ...checkout,
        loading: false,
      });
      dispatch({ type: 'API_LOADING', payload: false });
      setToast({
        type: 'success',
        message: `Successfully purchased`,
      });
      localStorage.removeItem('latestInvoiceId');
      localStorage.removeItem('latestInvoicePaymentIntentStatus');

      /* Set Success UI */
      handlePurchaseSuccess(result);
    },
    [checkout, setCheckout, setToast, dispatch, handlePurchaseSuccess]
  );

  const handleCustomer = React.useCallback(async () => {
    try {
      let result: Stripe.customers.ICustomer;
      if (customer.id === '') {
        result = await BillingFirebase.createCustomer(customer);
      } else {
        result = await BillingFirebase.updateCustomer(customer);
      }
      return { ...customer, id: result.id };
    } catch (error) {
      handleError(error);
    }
  }, [customer, handleError]);

  // * Handle CustomerAction
  const handleCustomerAction = React.useCallback(
    async ({ subscription, invoice, items, paymentMethodId, isRetry }: Fluit.checkout.pay.CustomerAction) => {
      if (subscription && subscription.status === 'active') {
        return { subscription, items, paymentMethodId };
      }

      let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

      let payment_status = paymentIntent.status === 'requires_action' && stripe;
      let retry_status = isRetry === true && paymentIntent.status === 'requires_payment_method' && stripe;

      if (!(payment_status || retry_status)) {
        return { subscription, items, paymentMethodId };
      }

      try {
        if (stripe) {
          const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          });

          if (result.error) {
            throw result;
          }

          let sub = subscription;
          if (!subscription) {
            sub = await BillingFirebase.getSubscription(state.organisation.id, invoice.subscription.id);
          }

          if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
            const successResult = {
              subscription: sub,
              invoice: invoice,
              paymentMethodId: paymentMethodId,
              items: items,
            };
            onSubscriptionComplete(successResult);
          }
        }
      } catch (result) {
        handleError(result.error);
      }
    },
    [handleError, stripe, onSubscriptionComplete, state.organisation.id]
  );

  const handleRequiresPaymentMethod = React.useCallback(
    ({ subscription, paymentMethodId, items }: Fluit.checkout.pay.RequiresPaymentMethod) => {
      if (subscription.status === 'active') {
        return { subscription, items, paymentMethodId };
        // return undefined;
      } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
        const err: Stripe.IStripeError = {
          message: 'Your card was declined',
          type: 'card_error',
        };
        throw err;
      } else {
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
        return { subscription, items, paymentMethodId };
      }
    },
    []
  );

  const retryInvoice = React.useCallback(
    async (customerId: string, paymentMethodId: string, items: object, invoiceId: string) => {
      try {
        setCheckout({
          ...checkout,
          loading: false,
        });
        const invoice = await BillingFirebase.retryInvoice(customerId, paymentMethodId, invoiceId);

        handleCustomerAction({
          subscription: null,
          invoice: invoice,
          items: items,
          paymentMethodId: paymentMethodId,
          isRetry: true,
        });
      } catch (error) {
        handleError(error);
      }
    },
    [setCheckout, checkout, handleCustomerAction, handleError]
  );

  const createSubscription = React.useCallback(
    async (customerId: string, paymentMethodId: string, items: object) => {
      const tax_rates = customer.address.country === 'gb' ? ['txr_1H3kGTJjXuaGRK8L1wzjENxc'] : [];
      let billing_cycle_date = 0;
      try {
        setCheckout({
          ...checkout,
          loading: false,
        });
        const subscription = await BillingFirebase.createPaymentSubscription(
          customerId,
          paymentMethodId,
          state.organisation.id,
          items,
          billing_cycle_date,
          tax_rates
        );
        const result = {
          subscription: subscription,
          paymentMethodId: paymentMethodId,
          items: items,
        };

        handleCustomerAction({
          subscription: subscription,
          invoice: null,
          items: items,
          paymentMethodId: paymentMethodId,
          isRetry: false,
        });

        handleRequiresPaymentMethod({
          subscription: subscription,
          items: items,
          paymentMethodId: paymentMethodId,
        });

        if (subscription.status === 'active') {
          onSubscriptionComplete(result);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [
      checkout,
      handleError,
      setCheckout,
      state.organisation,
      customer.address.country,
      handleCustomerAction,
      handleRequiresPaymentMethod,
      onSubscriptionComplete,
    ]
  );

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent) => {
      /* Set Loading */
      dispatch({ type: 'API_LOADING', payload: true });

      /* Prevent Default Action of Form */
      event.preventDefault();

      /* Exit if stripe isn't loaded */
      if (!stripe || !elements) {
        return undefined;
      }

      /* Set Items for Submission */
      const items = handleItems();

      /* Handle Stripe Elements */
      const cardElement = elements.getElement(CardElement);

      const latestInvoicePaymentIntentStatus = localStorage.getItem('latestInvoicePaymentIntentStatus');

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement as StripeCardElement,
      });

      /* Handle Error */
      if (error) {
        handleError(error);
        return;
      }

      /* Handle Customer Create || Update */
      const stripeCustomer: Fluit.checkout.Customer | undefined = await handleCustomer();

      if (stripeCustomer) {
        /* Make Payment & Subscription */

        setCustomer(stripeCustomer);
        dispatch({ type: 'BILLING_LIST', payload: { stripe_customer_id: stripeCustomer.id } });

        if (
          (latestInvoicePaymentIntentStatus === 'requires_payment_method' ||
            latestInvoicePaymentIntentStatus === 'requires_action') &&
          stripeCustomer.id !== '' &&
          paymentMethod
        ) {
          const invoiceId = localStorage.getItem('latestInvoiceId');
          retryInvoice(stripeCustomer.id, paymentMethod.id, items, String(invoiceId));
        } else if (paymentMethod) {
          createSubscription(stripeCustomer.id, paymentMethod.id, items);
        }
      }
    },
    [dispatch, elements, stripe, handleCustomer, handleError, handleItems, createSubscription, retryInvoice]
  );

  return (
    <Grid item xs={12}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7} lg={8}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {customer && <BillingDetails customer={customer} />}
              </Grid>
              <Grid item xs={12}>
                <CardElement
                  id="card-details"
                  options={{
                    style: billing_creditCardStyles,
                    hidePostalCode: true,
                    classes: {
                      base: classes.base,
                      complete: classes.complete,
                      empty: classes.empty,
                      focus: classes.focused,
                      invalid: classes.invalid,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <CheckoutSummary />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default CheckoutPay;
