import React from 'react';
import { initalise } from 'src/constants/init';
import Fluit from 'src/types/Fluit';

export const InvestorContext = React.createContext<Fluit.context.investor.Context>(
  {} as Fluit.context.investor.Context
);

export const InvestorProvider = ({ children }: Fluit.props.Children) => {
  const [investor, setInvestor] = React.useState<Fluit.context.investor.State>(initalise.investor);
  return <InvestorContext.Provider value={{ investor, setInvestor }}>{children}</InvestorContext.Provider>;
};
