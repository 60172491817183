import React from 'react';
import { initalise } from 'src/constants/init';
import Fluit from 'src/types/Fluit';

export const DealContext = React.createContext<Fluit.context.deal.Context>({} as Fluit.context.deal.Context);

export const DealProvider = ({ children }: Fluit.props.Children) => {
  const [deal, setDeal] = React.useState<Fluit.context.deal.State>(initalise.deal);
  return <DealContext.Provider value={{ deal, setDeal }}>{children}</DealContext.Provider>;
};
