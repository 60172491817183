import Fluit from 'src/types/Fluit';

export const data_countries: Fluit.address.Country[] = [
  {
    name: 'Afghanistan',
    iso: 'af',
    currency: 'USD',
  },
  {
    name: 'Albania',
    iso: 'al',
    currency: 'EUR',
  },
  {
    name: 'Algeria',
    iso: 'dz',
    currency: 'DZD',
  },
  {
    name: 'Andorra',
    iso: 'ad',
    currency: 'EUR',
  },
  {
    name: 'Angola',
    iso: 'ao',
    currency: 'AOA',
  },
  {
    name: 'Antigua and Barbuda',
    iso: 'ag',
    currency: 'XCD',
  },
  {
    name: 'Argentina',
    iso: 'ar',
    currency: 'ARS',
  },
  {
    name: 'Armenia',
    iso: 'am',
    currency: 'AMD',
  },
  {
    name: 'Australia',
    iso: 'au',
    currency: 'AUD',
  },
  {
    name: 'Austria',
    iso: 'at',
    currency: 'EUR',
  },
  {
    name: 'Azerbaijan',
    iso: 'az',
    currency: 'AZM',
  },
  {
    name: 'Bahrain',
    iso: 'bh',
    currency: 'BHD',
  },
  {
    name: 'Bangladesh',
    iso: 'bd',
    currency: 'BDT',
  },
  {
    name: 'Barbados',
    iso: 'bb',
    currency: 'BBD',
  },
  {
    name: 'Belarus',
    iso: 'by',
    currency: 'BYR',
  },
  {
    name: 'Belgium',
    iso: 'be',
    currency: 'EUR',
  },
  {
    name: 'Belize',
    iso: 'bz',
    currency: 'BZD',
  },
  {
    name: 'Benin',
    iso: 'bj',
    currency: 'XOF',
  },
  {
    name: 'Bhutan',
    iso: 'bt',
    currency: 'BTN',
  },
  {
    name: 'Bolivia',
    iso: 'bo',
    currency: 'BOB',
  },
  {
    name: 'Bosnia and Herzegovina',
    iso: 'ba',
    currency: 'BAM',
  },
  {
    name: 'Botswana',
    iso: 'bw',
    currency: 'BWP',
  },
  {
    name: 'Brazil',
    iso: 'br',
    currency: 'BRL',
  },
  {
    name: 'Brunei',
    iso: 'bn',
    currency: 'BND',
  },
  {
    name: 'Bulgaria',
    iso: 'bg',
    currency: 'EUR',
  },
  {
    name: 'Burkina Faso',
    iso: 'bf',
    currency: 'XOF',
  },
  {
    name: 'Burundi',
    iso: 'bi',
    currency: 'BIF',
  },
  {
    name: 'Cambodia',
    iso: 'kh',
    currency: 'KHR',
  },
  {
    name: 'Cameroon',
    iso: 'cm',
    currency: 'XAF',
  },
  {
    name: 'Canada',
    iso: 'ca',
    currency: 'CAD',
  },
  {
    name: 'Cape Verde',
    iso: 'cv',
    currency: 'CVE',
  },
  {
    name: 'Chad',
    iso: 'td',
    currency: 'XAF',
  },
  {
    name: 'Chile',
    iso: 'cl',
    currency: 'CLP',
  },
  {
    name: 'China',
    iso: 'cn',
    currency: 'RMB',
  },
  {
    name: 'Colombia',
    iso: 'co',
    currency: 'COP',
  },
  {
    name: 'Comoros',
    iso: 'km',
    currency: 'USD',
  },
  {
    name: 'Congo',
    iso: 'cd',
    currency: 'CDF',
  },
  {
    name: 'Costa Rica',
    iso: 'cr',
    currency: 'CRC',
  },
  {
    name: "Cote d'Ivoire",
    iso: 'ci',
    currency: 'XOF',
  },
  {
    name: 'Croatia',
    iso: 'hr',
    currency: 'EUR',
  },
  {
    name: 'Cuba',
    iso: 'cu',
    currency: 'CUP',
  },
  {
    name: 'Cyprus',
    iso: 'cy',
    currency: 'EUR',
  },
  {
    name: 'Czech Republic',
    iso: 'cz',
    currency: 'CZK',
  },
  {
    name: 'Denmark',
    iso: 'dk',
    currency: 'DKK',
  },
  {
    name: 'Djibouti',
    iso: 'dj',
    currency: 'DJF',
  },
  {
    name: 'Dominica',
    iso: 'dm',
    currency: 'XCD',
  },
  {
    name: 'Dominican Republic',
    iso: 'do',
    currency: 'DOP',
  },
  {
    name: 'East Timor',
    iso: 'tl',
    currency: 'USD',
  },
  {
    name: 'Ecuador',
    iso: 'ec',
    currency: 'USD',
  },
  {
    name: 'Egypt',
    iso: 'eg',
    currency: 'EGP',
  },
  {
    name: 'El Salvador',
    iso: 'sv',
    currency: 'USD',
  },
  {
    name: 'Equatorial Guinea',
    iso: 'gq',
    currency: 'XAF',
  },
  {
    name: 'Eritrea',
    iso: 'er',
    currency: 'ERN',
  },
  {
    name: 'Estonia',
    iso: 'ee',
    currency: 'EEK',
  },
  {
    name: 'Ethiopia',
    iso: 'et',
    currency: 'ETB',
  },
  {
    name: 'Fiji',
    iso: 'fj',
    currency: 'FJD',
  },
  {
    name: 'Finland',
    iso: 'fi',
    currency: 'EUR',
  },
  {
    name: 'France',
    iso: 'fr',
    currency: 'EUR',
  },
  {
    name: 'Gabon',
    iso: 'ga',
    currency: 'XAF',
  },
  {
    name: 'Gambia',
    iso: 'gm',
    currency: 'GMD',
  },
  {
    name: 'Georgia',
    iso: 'ge',
    currency: 'GEL',
  },
  {
    name: 'Germany',
    iso: 'de',
    currency: 'EUR',
  },
  {
    name: 'Ghana',
    iso: 'gh',
    currency: 'GHC',
  },
  {
    name: 'Greece',
    iso: 'gr',
    currency: 'EUR',
  },
  {
    name: 'Greenland',
    iso: 'gl',
    currency: 'DKK',
  },
  {
    name: 'Grenada',
    iso: 'gd',
    currency: 'XCD',
  },
  {
    name: 'Guatemala',
    iso: 'gt',
    currency: 'GTQ',
  },
  {
    name: 'Guinea',
    iso: 'gn',
    currency: 'GNF',
  },
  {
    name: 'Guinea-Bissau',
    iso: 'gw',
    currency: 'XOF',
  },
  {
    name: 'Guyana',
    iso: 'gy',
    currency: 'GYD',
  },
  {
    name: 'Haiti',
    iso: 'ht',
    currency: 'HTG',
  },
  {
    name: 'Honduras',
    iso: 'hn',
    currency: 'HNL',
  },
  {
    name: 'Hungary',
    iso: 'hu',
    currency: 'HUF',
  },
  {
    name: 'Iceland',
    iso: 'is',
    currency: 'ISK',
  },
  {
    name: 'India',
    iso: 'in',
    currency: 'INR',
  },
  {
    name: 'Indonesia',
    iso: 'id',
    currency: 'IDR',
  },
  {
    name: 'Iran',
    iso: 'ir',
    currency: 'IRR',
  },
  {
    name: 'Iraq',
    iso: 'iq',
    currency: 'NID',
  },
  {
    name: 'Ireland',
    iso: 'ie',
    currency: 'EUR',
  },
  {
    name: 'Israel',
    iso: 'il',
    currency: 'ILS',
  },
  {
    name: 'Italy',
    iso: 'it',
    currency: 'EUR',
  },
  {
    name: 'Jamaica',
    iso: 'jm',
    currency: 'JMD',
  },
  {
    name: 'Japan',
    iso: 'jp',
    currency: 'JPY',
  },
  {
    name: 'Jordan',
    iso: 'jo',
    currency: 'JOD',
  },
  {
    name: 'Kazakhstan',
    iso: 'kz',
    currency: 'KZT',
  },
  {
    name: 'Kenya',
    iso: 'ke',
    currency: 'KES',
  },
  {
    name: 'Kiribati',
    iso: 'ki',
    currency: 'AUD',
  },
  {
    name: 'Kuwait',
    iso: 'kw',
    currency: 'KWD',
  },
  {
    name: 'Kyrgyzstan',
    iso: 'kg',
    currency: 'KGS',
  },
  {
    name: 'Laos',
    iso: 'la',
    currency: 'LAK',
  },
  {
    name: 'Latvia',
    iso: 'lv',
    currency: 'LVL',
  },
  {
    name: 'Lebanon',
    iso: 'lb',
    currency: 'LBP',
  },
  {
    name: 'Lesotho',
    iso: 'ls',
    currency: 'LSL',
  },
  {
    name: 'Liberia',
    iso: 'lr',
    currency: 'LRD',
  },
  {
    name: 'Libya',
    iso: 'ly',
    currency: 'LYD',
  },
  {
    name: 'Liechtenstein',
    iso: 'li',
    currency: 'CHF',
  },
  {
    name: 'Lithuania',
    iso: 'lt',
    currency: 'LTL',
  },
  {
    name: 'Luxembourg',
    iso: 'lu',
    currency: 'EUR',
  },
  {
    name: 'Macedonia',
    iso: 'mk',
    currency: 'EUR',
  },
  {
    name: 'Madagascar',
    iso: 'mg',
    currency: 'MGA',
  },
  {
    name: 'Malawi',
    iso: 'mw',
    currency: 'MWK',
  },
  {
    name: 'Malaysia',
    iso: 'my',
    currency: 'MYR',
  },
  {
    name: 'Maldives',
    iso: 'mv',
    currency: 'MVR',
  },
  {
    name: 'Mali',
    iso: 'ml',
    currency: 'XOF',
  },
  {
    name: 'Malta',
    iso: 'mt',
    currency: 'EUR',
  },
  {
    name: 'Marshall Islands',
    iso: 'mh',
    currency: 'USD',
  },
  {
    name: 'Mauritania',
    iso: 'mr',
    currency: 'MRO',
  },
  {
    name: 'Mauritius',
    iso: 'mu',
    currency: 'MUR',
  },
  {
    name: 'Mexico',
    iso: 'mx',
    currency: 'MXN',
  },
  {
    name: 'Moldova',
    iso: 'md',
    currency: 'MDL',
  },
  {
    name: 'Monaco',
    iso: 'mc',
    currency: 'EUR',
  },
  {
    name: 'Mongolia',
    iso: 'mn',
    currency: 'MNT',
  },
  {
    name: 'Montenegro',
    iso: 'me',
    currency: 'EUR',
  },
  {
    name: 'Morocco',
    iso: 'ma',
    currency: 'MAD',
  },
  {
    name: 'Mozambique',
    iso: 'mz',
    currency: 'MZM',
  },
  {
    name: 'Myanmar',
    iso: 'mm',
    currency: 'MMK',
  },
  {
    name: 'Namibia',
    iso: 'na',
    currency: 'NAD',
  },
  {
    name: 'Nauru',
    iso: 'nr',
    currency: 'AUD',
  },
  {
    name: 'Nepal',
    iso: 'np',
    currency: 'NPR',
  },
  {
    name: 'Netherlands',
    iso: 'nl',
    currency: 'EUR',
  },
  {
    name: 'New Zealand',
    iso: 'nz',
    currency: 'NZD',
  },
  {
    name: 'Nicaragua',
    iso: 'ni',
    currency: 'NIO',
  },
  {
    name: 'Niger',
    iso: 'ne',
    currency: 'XOF',
  },
  {
    name: 'Nigeria',
    iso: 'ng',
    currency: 'NGN',
  },
  {
    name: 'North Korea',
    iso: 'kp',
    currency: 'KPW',
  },
  {
    name: 'Norway',
    iso: 'no',
    currency: 'NOK',
  },
  {
    name: 'Oman',
    iso: 'om',
    currency: 'OMR',
  },
  {
    name: 'Pakistan',
    iso: 'pk',
    currency: 'PKR',
  },
  {
    name: 'Palau',
    iso: 'pw',
    currency: 'USD',
  },
  {
    name: 'Panama',
    iso: 'pa',
    currency: 'PAB',
  },
  {
    name: 'Papua New Guinea',
    iso: 'pg',
    currency: 'PGK',
  },
  {
    name: 'Paraguay',
    iso: 'py',
    currency: 'PYG',
  },
  {
    name: 'Peru',
    iso: 'pe',
    currency: 'PEN',
  },
  {
    name: 'Philippines',
    iso: 'ph',
    currency: 'PHP',
  },
  {
    name: 'Poland',
    iso: 'pl',
    currency: 'PLN',
  },
  {
    name: 'Portugal',
    iso: 'pt',
    currency: 'EUR',
  },
  {
    name: 'Qatar',
    iso: 'qa',
    currency: 'QAR',
  },
  {
    name: 'Romania',
    iso: 'ro',
    currency: 'ROL',
  },
  {
    name: 'Russia',
    iso: 'ru',
    currency: 'RUB',
  },
  {
    name: 'Rwanda',
    iso: 'rw',
    currency: 'RWF',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso: 'kn',
    currency: 'XCD',
  },
  {
    name: 'Saint Lucia',
    iso: 'lc',
    currency: 'XCD',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso: 'vc',
    currency: 'XCD',
  },
  {
    name: 'Samoa',
    iso: 'ws',
    currency: 'WST',
  },
  {
    name: 'San Marino',
    iso: 'sm',
    currency: 'EUR',
  },
  {
    name: 'Sao Tome and Principe',
    iso: 'st',
    currency: 'STD',
  },
  {
    name: 'Saudi Arabia',
    iso: 'sa',
    currency: 'SAR',
  },
  {
    name: 'Senegal',
    iso: 'sn',
    currency: 'XOF',
  },
  {
    name: 'Serbia',
    iso: 'rs',
    currency: 'EUR',
  },
  {
    name: 'Seychelles',
    iso: 'sc',
    currency: 'SCR',
  },
  {
    name: 'Sierra Leone',
    iso: 'sl',
    currency: 'SLL',
  },
  {
    name: 'Singapore',
    iso: 'sg',
    currency: 'SGD',
  },
  {
    name: 'Slovakia',
    iso: 'sk',
    currency: 'EUR',
  },
  {
    name: 'Slovenia',
    iso: 'si',
    currency: 'EUR',
  },
  {
    name: 'Solomon Islands',
    iso: 'sb',
    currency: 'SBD',
  },
  {
    name: 'Somalia',
    iso: 'so',
    currency: 'SOS',
  },
  {
    name: 'South Africa',
    iso: 'za',
    currency: 'ZAR',
  },
  {
    name: 'South Korea',
    iso: 'kr',
    currency: 'KRW',
  },
  {
    name: 'South Sudan',
    iso: 'ss',
    currency: 'ANG',
  },
  {
    name: 'Spain',
    iso: 'es',
    currency: 'EUR',
  },
  {
    name: 'Sri Lanka',
    iso: 'lk',
    currency: 'LKR',
  },
  {
    name: 'Sudan',
    iso: 'sd',
    currency: 'SDG',
  },
  {
    name: 'Suriname',
    iso: 'sr',
    currency: 'SRG',
  },
  {
    name: 'Swaziland',
    iso: 'sz',
    currency: 'SZL',
  },
  {
    name: 'Sweden',
    iso: 'se',
    currency: 'SEK',
  },
  {
    name: 'Switzerland',
    iso: 'ch',
    currency: 'CHF',
  },
  {
    name: 'Syria',
    iso: 'sy',
    currency: 'SYP',
  },
  {
    name: 'Taiwan',
    iso: 'tw',
    currency: 'TWD',
  },
  {
    name: 'Tajikistan',
    iso: 'tj',
    currency: 'TJS',
  },
  {
    name: 'Tanzania',
    iso: 'tz',
    currency: 'TZS',
  },
  {
    name: 'Thailand',
    iso: 'th',
    currency: 'THB',
  },
  {
    name: 'The Bahamas',
    iso: 'bs',
    currency: 'BSD',
  },
  {
    name: 'The Central African Republic',
    iso: 'cf',
    currency: 'XAF',
  },
  {
    name: 'The Federated States of Micronesia',
    iso: 'fm',
    currency: 'USD',
  },
  {
    name: 'United Arab Emirates',
    iso: 'ae',
    currency: 'AED',
  },
  {
    name: 'United States',
    iso: 'us',
    currency: 'USD',
  },
  {
    name: 'Togo',
    iso: 'tg',
    currency: 'XOF',
  },
  {
    name: 'Tonga',
    iso: 'to',
    currency: 'TOP',
  },
  {
    name: 'Trinidad and Tobago',
    iso: 'tt',
    currency: 'TTD',
  },
  {
    name: 'Tunisia',
    iso: 'tn',
    currency: 'TND',
  },
  {
    name: 'Turkey',
    iso: 'tr',
    currency: 'USD',
  },
  {
    name: 'Turkmenistan',
    iso: 'tm',
    currency: 'TMM',
  },
  {
    name: 'Tuvalu',
    iso: 'tv',
    currency: 'AUD',
  },
  {
    name: 'Uganda',
    iso: 'ug',
    currency: 'UGX',
  },
  {
    name: 'Ukraine',
    iso: 'ua',
    currency: 'UAH',
  },
  {
    name: 'United Kingdom',
    iso: 'gb',
    currency: 'GBP',
  },
  {
    name: 'Uruguay',
    iso: 'uy',
    currency: 'UYU',
  },
  {
    name: 'Uzbekistan',
    iso: 'uz',
    currency: 'UZS',
  },
  {
    name: 'Vanuatu',
    iso: 'vu',
    currency: 'VUV',
  },
  {
    name: 'Vatican City',
    iso: 'va',
    currency: 'EUR',
  },
  {
    name: 'Venezuela',
    iso: 've',
    currency: 'VEB',
  },
  {
    name: 'Vietnam',
    iso: 'vn',
    currency: 'VND',
  },
  {
    name: 'Yemen',
    iso: 'ye',
    currency: 'YER',
  },
  {
    name: 'Zambia',
    iso: 'zm',
    currency: 'ZMK',
  },
  {
    name: 'Zimbabwe',
    iso: 'zw',
    currency: 'ZWD',
  },
];
