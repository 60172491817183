import _ from 'lodash';
import Fluit from 'src/types/Fluit';
import { initalise } from 'src/constants/init';

export const ProcessReducer = (state: Fluit.process.Process[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'PROCESS_SET':
      const PROCESS_SET = () => {
        const exists = _.find(state, { id: action.payload.id });
        if (exists) {
          const updated = _.filter(state, process => process.id !== action.payload.id);
          return _.concat(updated, action.payload);
        }
        return _.concat(state, action.payload);
      };

      return PROCESS_SET();

    case 'PROCESSES_LIST':
      return action.payload;
    case 'PROCESS_UPDATE':
      return state;
    case 'PROCESSES_FLUSH':
      return initalise.application.processes;
    default:
      return { ...state };
  }
};
