import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { format, parseISO } from 'date-fns';

export const firebaseToDate = (
  date: firebase.firestore.Timestamp | firebase.firestore.FieldValue | undefined | null | string
) => {
  if (date) {
    const d = date as firebase.firestore.Timestamp;
    if (d.seconds < 0) {
      return;
    }
    const nd = new firebase.firestore.Timestamp(d.seconds, d.nanoseconds);
    const iso = nd.toDate().toISOString();
    return format(parseISO(iso), 'iii, do MMM yyyy');
  }
  return '';
};
