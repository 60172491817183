import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LoginAsset } from 'src/assets/images/sign_in.svg';
import LoginForm from 'src/components/Auth/Login';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles(() => ({
  svg: {
    width: '100%',
    height: 'auto',
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={6}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
          <Box mb={4}>
            <Typography variant="h1">Login</Typography>
          </Box>
          <Box mb={4}>
            <LoginForm />
          </Box>
          <Typography variant="subtitle1">
            Don't have an account?{' '}
            <Link component={NavLink} to={ROUTES.REGISTER}>
              Register here
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%" alignItems="flex-end">
          <LoginAsset className={classes.svg} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
