import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as Yup from 'yup';

const ProfileEmailForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const fbAuth = React.useContext(FirebaseContext);
  const { user } = state;
  return (
    <Formik
      initialValues={{
        email: user.email,
        confirmEmail: '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        fbAuth
          .auth()
          .currentUser?.updateEmail(values.email)
          .then(() => {
            actions.setSubmitting(false);
            setToast({
              message: `Email updated from ${user.email} to ${values.email}`,
              type: 'success',
            });
            dispatch({
              type: 'USER_SET',
              payload: {
                ...user,
                email: values.email,
              },
            });
            dispatch({
              type: 'MEMBER_UPDATE',
              payload: {
                ...user,
                email: values.email,
              },
            });
          })
          .catch(function(error) {
            actions.setSubmitting(false);
            setToast({
              message: error.message,
              type: 'error',
            });
          });
        actions.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('An email address is required to update')
          .notOneOf([user.email], 'This is your current email address'),
        confirmEmail: Yup.string()
          .required('Confirm your email to update')
          .oneOf([Yup.ref('email'), null], "Email addresses don't match")
          .notOneOf([user.email], 'This is your current email address'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  autoComplete="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="confirmEmail"
                  label="Confirm Email"
                  autoComplete=""
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update Email
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfileEmailForm;
