import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import colors from 'src/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `2px dotted ${colors.grey[100]}`,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& svg': {
      width: '80px',
      height: '80px',
      marginBottom: theme.spacing(1),
    },
    '& p': {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },
    '&$noAction': {
      '& p': {
        marginBottom: 0,
      },
    },
  },
  noAction: {},
}));

interface Props {
  icon: React.ReactNode;
  text: string;
  label?: string;
  action?: Function;
}

const NoData: React.FC<Props> = ({ icon, text, label, action }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, !action && classes.noAction)}>
      <Box>{icon}</Box>
      <Typography variant="body1">{text}</Typography>
      {action && (
        <Button variant="contained" color="primary" size="large" onClick={() => action()}>
          {label}
        </Button>
      )}
    </Box>
  );
};

export default NoData;
