import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import TermsheetCard from 'src/components/Cards/Termsheet';
import NoData from 'src/components/Empty';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { TermsheetFirebase } from 'src/services/termsheets';
import { Document, InvestorsNone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

interface Props {
  investorCount: number;
}

const DealTermsheets: React.FC<Props> = ({ investorCount }) => {
  const { state, dispatch } = React.useContext(AppContext);
  const { deal } = React.useContext(DealContext);
  const { setToast } = React.useContext(ToastContext);
  const { termsheets } = state;
  const [dealTermsheets, setDealTermsheets] = React.useState<Fluit.deals.Termsheet[]>([]);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const addTermsheet = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Add a Termsheet`,
        component: 'DEAL_TERMSHEET_CREATE',
      },
    });
  };

  React.useEffect(() => {
    (async () => {
      try {
        const result = await TermsheetFirebase.get(state.organisation.id, deal.id);
        dispatch({ type: 'TERMSHEETS_SET', payload: result });
      } catch (error) {
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();
  }, [state.organisation.id, deal.id, setToast, dispatch]);

  React.useEffect(() => {
    const deal_termsheets = _.filter(termsheets, { deal_id: deal.id });
    setDealTermsheets(_.orderBy(deal_termsheets, ['created_at.seconds'], ['asc']));
  }, [termsheets, setDealTermsheets, deal.id]);

  const assignInvestor = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Add Investor to Deal`,
        component: 'DEAL_ASSIGN_INVESTORS',
      },
    });
  };

  const addInvestors = () => {
    history.push(ROUTES.INVESTOR_SEARCH);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Termsheets</Typography>
            {_.some(dealTermsheets) ? (
              <Button color="secondary" variant="outlined" onClick={() => addTermsheet()}>
                {!mobile ? 'Add Termsheet' : 'Add'}
              </Button>
            ) : null}
          </Box>
        </Box>
      </Grid>

      {investorCount > 0 ? (
        _.some(dealTermsheets) ? (
          _.map(dealTermsheets, termsheet => (
            <Grid item xs={12} key={termsheet.id}>
              <TermsheetCard termsheet={termsheet} deal={deal} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <NoData
              text={`No termsheets received for this deal`}
              icon={<Document />}
              label="Add Termsheet"
              action={addTermsheet}
            />
          </Grid>
        )
      ) : _.some(state.investors) ? (
        <Grid item xs={12}>
          <NoData
            text={`Assign investors to this deal before you add termsheets.`}
            icon={<InvestorsNone />}
            label="Add Investors"
            action={assignInvestor}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <NoData
            text={`Add investors to your organisation before you add termsheets.`}
            icon={<InvestorsNone />}
            label="Add Investors"
            action={addInvestors}
          />
        </Grid>
      )}

      {/* {_.some(dealTermsheets) ? (
        _.map(dealTermsheets, termsheet => (
          <Grid item xs={12} key={termsheet.id}>
            <TermsheetCard termsheet={termsheet} deal={deal} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <NoData
            text={`No termsheets received for this deal`}
            icon={<Document />}
            label="Add Termsheet"
            action={addTermsheet}
          />
        </Grid>
      )} */}
    </Grid>
  );
};

export default DealTermsheets;
