import { initalise } from 'src/constants/init';
import Fluit from 'src/types/Fluit';

export const ToursReducer = (state: Fluit.context.tours.State, action: Fluit.reducer.actions.TourActions) => {
  // console.log(`%c•• [TOUR]: ${action.type} ••`, 'color: #FF79C6');
  // console.log(action.payload);
  switch (action.type) {
    case 'TOURS_SET_TARGET':
      return {
        ...state,
        tourTarget: action.payload,
      };

    case 'TOURS_SET_FIRSTLOAD':
      return {
        ...state,
        firstLoad: action.payload,
      };

    case 'TOURS_SET_NAVIGATION':
      return {
        ...state,
        navigation: action.payload,
      };

    case 'TOURS_SET_DEAL':
      return {
        ...state,
        deal: action.payload,
      };

    case 'TOURS_SET_INVESTOR':
      return {
        ...state,
        investor: action.payload,
      };

    case 'TOURS_SET_INVESTOR_SEARCH':
      return {
        ...state,
        investor_search: action.payload,
      };

    /* FLUSH */
    case 'TOURS_FLUSH':
      return initalise.tours;

    case 'TOURS_FIRSTLOAD_FLUSH':
      return {
        ...initalise.tours,
        firstLoad: true,
      };

    default:
      return state;
  }
};
