import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';

export const NotesFirebase = {
  create: async (note: Fluit.notes.Note) => {
    try {
      const resp = await API({
        endpoint: `organisations/${note.org_id}/notes`,
        method: 'POST',
        payload: note,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (note: Fluit.notes.Note) => {
    try {
      const resp = await API({
        endpoint: `organisations/${note.org_id}/notes/${note.id}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (note: Fluit.notes.Note) => {
    delete note.created_at;
    delete note.updated_at;
    try {
      const resp = await API({
        endpoint: `organisations/${note.org_id}/notes/${note.id}`,
        method: 'PATCH',
        payload: note,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (orgId: string): Promise<Fluit.notes.Note[]> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/notes`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
