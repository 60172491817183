import { Box, Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import AutocompleteCountries from 'src/components/Inputs/Autocomplete/Countries';
import AutocompleteIndustries from 'src/components/Inputs/Autocomplete/Industries';
import FormikTextField from 'src/components/Inputs/Textfields/FormikTextField';
import { telephoneRegex, urlRegex } from 'src/constants/regex';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import { trimString } from 'src/helpers/trim';
import * as Yup from 'yup';

const OnboardOrganisation: React.FC = () => {
  /* Context */
  const { onboard, setOnboard } = React.useContext(OnboardingContext);

  const prevPage = () => {
    setOnboard({
      ...onboard,
      step: onboard.step - 1,
    });
  };

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h1">Organisation Details</Typography>
      </Box>
      <Formik
        initialValues={{
          type: 'startup',
          name: onboard.organisation.name ? onboard.organisation.name : '',
          telephone: onboard.organisation.telephone ? onboard.organisation.telephone : '',
          email: onboard.organisation.email ? onboard.organisation.email : '',
          website: onboard.organisation.website ? onboard.organisation.website : '',
          industry: onboard.organisation.industry ? onboard.organisation.industry : '',
          year_founded: onboard.organisation.year_founded ? onboard.organisation.year_founded : 2020,
          employees: onboard.organisation.employees ? onboard.organisation.employees : 1,
          location: {
            name: onboard.organisation.location.name ? onboard.organisation.location.name : '',
            iso: onboard.organisation.location.iso ? onboard.organisation.location.iso : '',
            currency: onboard.organisation.location.currency ? onboard.organisation.location.currency : '',
          },
        }}
        onSubmit={(values, actions) => {
          setOnboard({
            ...onboard,
            organisation: {
              ...onboard.organisation,
              type: values.type,
              name: trimString(values.name),
              telephone: trimString(values.telephone),
              email: trimString(values.email),
              website: trimString(values.website),
              industry: values.industry,
              year_founded: values.year_founded,
              employees: values.employees,
              location: values.location,
            },
            step: onboard.step + 1,
          });
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Organisation name is required'),
          telephone: Yup.string()
            .required('Organisation telephone number is required')
            .matches(telephoneRegex, 'Not a valid telephone number'),
          email: Yup.string()
            .email(`This is not a valid email address`)
            .required('Organisation primary email is required'),
          website: Yup.string().matches(urlRegex, 'Not a valid website address'),
          location: Yup.object().shape({
            name: Yup.string().required(`Select your company location from the list`),
            currency: Yup.string(),
            iso: Yup.string(),
          }),
          industry: Yup.string().required('Select your industry type from the list'),
          employees: Yup.number()
            .required('Total Number of Employees is required')
            .min(1, 'Must be more than 1'),
          year_founded: Yup.number()
            .required('Total Number of Employees is required')
            .min(1500, 'Must be more than 1500')
            .max(new Date().getFullYear(), `Can't be after this year`),
        })}
      >
        {props => {
          const { isSubmitting, handleSubmit, touched, errors, setFieldValue, values } = props;
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="name"
                    label="Organisation Name"
                    autoComplete="company name"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={7}>
                  <Field
                    fullWidth={true}
                    component={FormikTextField}
                    type="number"
                    name="year_founded"
                    label="Year Founded"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <Field
                    fullWidth={true}
                    component={FormikTextField}
                    type="number"
                    name="employees"
                    label="Number of Employees"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteCountries
                    setFieldValue={setFieldValue}
                    name="location"
                    label="Location"
                    error={errors.location?.iso}
                    touched={touched.location?.iso}
                    value={values.location}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteIndustries
                    setFieldValue={setFieldValue}
                    name="industry"
                    label="Industry"
                    error={errors.industry}
                    touched={touched.industry}
                    value={values.industry}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth={true}
                    component={FormikTextField}
                    name="telephone"
                    label="Telephone number"
                    autoComplete="telephone"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth={true}
                    component={FormikTextField}
                    name="email"
                    label="Email"
                    autoComplete="email"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth={true}
                    component={FormikTextField}
                    name="website"
                    label="Website"
                    autoComplete="website"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    style={{ marginRight: '16px' }}
                  >
                    Next
                  </Button>
                  <Button color="default" size="large" onClick={() => prevPage()}>
                    Back
                  </Button>
                </Grid>
                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress variant="query" />
                  </Grid>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OnboardOrganisation;
