import {
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Popper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { Field, Formik } from 'formik';
import * as React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { passwordRegex } from 'src/constants/regex';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ToastContext } from 'src/contexts/ToastContext';
import colors from 'src/themes/colors';
import { Check } from 'src/themes/icons';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  passed: {
    color: colors.green[700],
    '& $icon': {
      background: colors.green[700],
    },
  },
  unpassed: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1, 0),
    alignItems: 'center',
  },
  icon: {
    width: '20px',
    height: '20px',
    borderRadius: theme.spacing(20),
    background: colors.grey[50],
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svg: {
    width: '12px',
    height: '12px',
    fill: colors.common.white,
    '& g, & path': {
      fill: colors.common.white,
    },
  },
}));

const ProfilePasswordForm: React.FC = () => {
  const classes = useStyles();
  const { setToast } = React.useContext(ToastContext);
  const fbAuth = React.useContext(FirebaseContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [passed, setPassed] = React.useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const testPassword = (password: string) => {
    if (passwordRegex.test(password)) {
      setPassed(passwordRegex.test(password));
    }
    return passwordRegex.test(password);
  };

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    !passed ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const passwordPopper = open ? 'transitions-popper' : undefined;

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        fbAuth
          .auth()
          .currentUser?.updatePassword(values.password)
          .then(function() {
            actions.setSubmitting(false);
            setToast({
              message: 'Password updated',
              type: 'success',
            });
          })
          .catch(function(error) {
            actions.setSubmitting(false);
            setToast({
              message: error.message,
              type: 'error',
            });
          });
        actions.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required('Type your required password')
          .matches(
            passwordRegex,
            'Password must be more than 8 characters and contain, One Uppercase, One Lowercase, One Number and One Symbol'
          ),
        confirmPassword: Yup.string()
          .required('Confirm your password to update')
          .oneOf([Yup.ref('password'), null], "Password's don't match"),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  aria-describedby={passwordPopper}
                  name="password"
                  label="Password"
                  type="password"
                  inputProps={{
                    autoComplete: 'password',
                  }}
                  onFocus={handleFocus}
                  variant="outlined"
                />
                {!mobile ? (
                  <Popper
                    id={passwordPopper}
                    open={!testPassword(values.password) && !passed && anchorEl ? true : false}
                    anchorEl={anchorEl}
                    placement="right"
                    style={{ marginLeft: '16px' }}
                    disablePortal={false}
                    modifiers={{
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                      },
                    }}
                  >
                    <Paper elevation={4}>
                      <Box p={2} pr={4}>
                        <Typography variant="h6">Password must contain</Typography>
                        <Box className={clsx(/[A-Z]/.test(values.password) && classes.passed, classes.unpassed)}>
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">One uppercase character</Typography>
                        </Box>
                        <Box className={clsx(/[a-z]/.test(values.password) && classes.passed, classes.unpassed)}>
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">One lowercase character</Typography>
                        </Box>
                        <Box className={clsx(/[0-9]/.test(values.password) && classes.passed, classes.unpassed)}>
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">One number</Typography>
                        </Box>
                        <Box
                          className={clsx(
                            /[!@#$%^&*()\-_=+{};:,<.>]/.test(values.password) && classes.passed,
                            classes.unpassed
                          )}
                        >
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">One symbol</Typography>
                        </Box>
                        <Box className={clsx(/.{8}/.test(values.password) && classes.passed, classes.unpassed)}>
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">8 characters</Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Popper>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  inputProps={{
                    autoComplete: 'confirm-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  style={{ marginRight: '16px' }}
                >
                  Update Password
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfilePasswordForm;
