import React from 'react';
import { HighlightProps } from 'react-instantsearch-core';
import { connectHighlight } from 'react-instantsearch-dom';

const AlgoliaHighlight: React.FC<HighlightProps> = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? <mark key={index}>{part.value}</mark> : <span key={index}>{part.value}</span>
      )}
    </span>
  );
};

export default connectHighlight(AlgoliaHighlight);
