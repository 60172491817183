import React from 'react';
import Fluit from 'src/types/Fluit';

export const AlgoliaContext = React.createContext<Fluit.context.algolia.Context>({} as Fluit.context.algolia.Context);

export const AlgoliaProvider = ({ children }: Fluit.props.Children) => {
  const [search, setSearch] = React.useState<Fluit.context.algolia.State>({
    query: '',
    selected: [],
  });
  return <AlgoliaContext.Provider value={{ search, setSearch }}>{children}</AlgoliaContext.Provider>;
};
