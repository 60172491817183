import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';

export const TaskFirebase = {
  create: async (task: Partial<Fluit.tasks.Task>) => {
    try {
      const resp = await API({
        endpoint: `organisations/${task.org_id}/tasks`,
        method: 'POST',
        payload: task,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (task: Fluit.tasks.Task) => {
    try {
      const resp = await API({
        endpoint: `organisations/${task.org_id}/tasks/${task.id}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (task: Partial<Fluit.tasks.Task>) => {
    try {
      const resp = await API({
        endpoint: `organisations/${task.org_id}/tasks/${task.id}`,
        method: 'PATCH',
        payload: task,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (orgId: string, cursor?: string, parent?: string): Promise<Fluit.tasks.Task[]> => {
    let url = `organisations/${orgId}/tasks`;
    if (cursor && !parent) url = `organisations/${orgId}/tasks?cursor=${cursor}`;
    if (parent && !cursor) url = `organisations/${orgId}/tasks?parent=${parent}`;
    if (parent && cursor) url = `organisations/${orgId}/tasks?cursor=${cursor}&parent=${parent}`;

    try {
      const resp = await API({
        endpoint: url,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
