import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';

interface Props {
  currentRefinement: number;
  nbPages: number;
  refine: Function;
  createURL: Function;
}

const AlgoliaPagination: React.FC<Props> = ({ currentRefinement, nbPages, refine, createURL }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  if (nbPages === 0) {
    return <></>;
  }

  return (
    <Box justifyContent="center" display="flex">
      <Pagination
        count={nbPages}
        size="large"
        color="primary"
        defaultPage={1}
        siblingCount={!mobile ? 0 : -1}
        onChange={(e: object, page: number) => refine(page)}
        showFirstButton={!mobile}
        showLastButton={!mobile}
      />
    </Box>
  );
};

export default connectPagination(AlgoliaPagination);
