import React, { createContext, useReducer } from 'react';
import { initalise } from 'src/constants/init';
import { ToursReducer } from 'src/reducers/Tours';
import Fluit from 'src/types/Fluit';

const ToursContext = createContext<{
  tours: Fluit.context.tours.State;
  dispatchTours: React.Dispatch<any>;
}>({
  tours: initalise.tours,
  dispatchTours: () => null,
});

const ToursProvider: React.FC = ({ children }) => {
  const [tours, dispatchTours] = useReducer(ToursReducer, initalise.tours);
  return <ToursContext.Provider value={{ tours, dispatchTours }}>{children}</ToursContext.Provider>;
};

export { ToursContext, ToursProvider };
