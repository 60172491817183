import React, { createContext, useReducer } from 'react';
import { SystemActivityReducer } from 'src/reducers/SystemActivity';
import Fluit from 'src/types/Fluit';

const SystemActivityContext = createContext<{
  systemActivities: Fluit.context.systemActivity.State;
  dispatchSystemActivities: React.Dispatch<any>;
}>({
  systemActivities: [],
  dispatchSystemActivities: () => null,
});

const init_reducer: Fluit.activities.Activity[] = [];

const SystemActivityProvider: React.FC = ({ children }) => {
  const [systemActivities, dispatchSystemActivities] = useReducer(SystemActivityReducer, init_reducer);
  return (
    <SystemActivityContext.Provider value={{ systemActivities, dispatchSystemActivities }}>
      {children}
    </SystemActivityContext.Provider>
  );
};

export { SystemActivityContext, SystemActivityProvider };
