import { Box, Chip, IconButton, LinearProgress, makeStyles, Menu, MenuItem, Paper, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { BillingFirebase } from 'src/services/billing';
import colors from 'src/themes/colors';
import {
  CardAmex,
  CardDinersClub,
  CardDiscover,
  CardJCB,
  CardMastercard,
  CardUnionPay,
  CardUnknown,
  CardVisa,
  DotsHorizontal,
} from 'src/themes/icons';

interface Props {
  brand: 'amex' | 'diners' | 'discover' | 'jcb' | 'mastercard' | 'unionpay' | 'visa' | 'unknown';
  last4: string;
  exp_month: number;
  exp_year: number;
  id: string;
  main?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    fontFamily: `Monospace`,
    fontSize: 18,
    color: colors.black[900],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  icon: {
    width: 44,
    marginRight: theme.spacing(3),
  },
  svg: {
    width: 24,
    height: 24,
  },
  expired: {
    borderColor: colors.red.main,
    color: colors.red.main,
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  disabled: {
    opacity: 0.4,
    '& $query': {
      opacity: 1,
    },
  },
  query: {
    height: 4,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CreditCard: React.FC<Props> = ({ brand, last4, exp_month, exp_year, id, main }) => {
  const classes = useStyles();
  const { setToast } = React.useContext(ToastContext);
  const { dispatch } = React.useContext(AppContext);
  const handleIcon = (brand: string) => {
    switch (brand) {
      case 'amex':
        return <CardAmex className={classes.icon} />;
      case 'diners':
        return <CardDinersClub className={classes.icon} />;
      case 'discover':
        return <CardDiscover className={classes.icon} />;
      case 'jcb':
        return <CardJCB className={classes.icon} />;
      case 'mastercard':
        return <CardMastercard className={classes.icon} />;
      case 'unionpay':
        return <CardUnionPay className={classes.icon} />;
      case 'visa':
        return <CardVisa className={classes.icon} />;
      case 'unknown':
        return <CardUnknown className={classes.icon} />;
    }
  };
  const date = new Date();

  const expired = date.getFullYear() >= exp_year && date.getMonth() + 1 > exp_month;
  const expiring = date.getFullYear() === exp_year && date.getMonth() + 1 === exp_month;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // const setPrimary = async () => {
  //   const set_default = {
  //     invoice_settings: {
  //       default_payment_method: id,
  //     },
  //   };

  //   try {
  //     setLoading(true);
  //     handleClose();
  //     // await BillingFirebase.updateCustomer(state.billing.stripe_customer_id, set_default);
  //     dispatch({ type: 'CUSTOMER_UPDATE', payload: set_default });
  //     setLoading(false);
  //     setToast({
  //       type: 'info',
  //       message: `Set as Default Card`,
  //     });
  //   } catch (error) {
  //     setLoading(false);
  //     setToast({
  //       message: `${error.name} | ${error.message}`,
  //       type: 'error',
  //     });
  //   }
  // };

  const deleteCard = async () => {
    try {
      handleClose();
      setLoading(true);
      await BillingFirebase.detachPaymentMethod(id);
      setLoading(false);
      setToast({
        type: 'info',
        message: `Card Removed`,
      });
      dispatch({ type: 'BILLING_PAYMENT_METHOD_DETACH', payload: id });
    } catch (error) {
      setLoading(false);
      setToast({
        message: `${error.name} | ${error.message}`,
        type: 'error',
      });
    }
  };

  return (
    <Paper style={{ height: '100%' }}>
      <Box className={clsx(classes.card, loading && classes.disabled)}>
        <Box flex={1}>
          <Box className={classes.heading}>
            {handleIcon(brand)}
            {main || expired || expiring ? (
              <Chip
                color="primary"
                label={expired ? 'Expired' : expiring ? 'Expiring' : 'Default'}
                variant="outlined"
                size="small"
                className={clsx(expired && classes.expired, expiring && classes.expired)}
              />
            ) : null}
          </Box>
          <Box>•••• {last4}</Box>
          <Box>
            Expires {exp_month < 10 && 0}
            {exp_month}/{exp_year}
          </Box>
        </Box>
        <Box className={classes.button}>
          <IconButton onClick={handleClick} disabled={main || loading} className={clsx(main && classes.disabled)}>
            <DotsHorizontal className={classes.svg} />
          </IconButton>
        </Box>
      </Box>

      <Box className={classes.query}>{loading && <LinearProgress variant="query" />}</Box>

      <Menu
        id="investor-contextual-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={4}
      >
        {/* <MenuItem onClick={() => setPrimary()}>Make Default</MenuItem> */}
        <MenuItem onClick={() => deleteCard()}>Remove Card</MenuItem>
      </Menu>
    </Paper>
  );
};

export default CreditCard;
