import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { ReactComponent as FluitGrey } from 'src/assets/logo/fluit-greyscale-shape.svg';
import colors from 'src/themes/colors';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    width: '100vw',
    background: colors.common.background,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
  },
  svg: {
    width: 'auto',
    height: '200px',
  },
}));

const Placeholder: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <FluitGrey className={classes.svg} />
    </Box>
  );
};

export default Placeholder;
