import { Box, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { AppContext } from 'src/contexts/AppContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { ProcessesFirebase } from 'src/services/processes';
import colors from 'src/themes/colors';
import { Investors } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';
import StepDraggable from './StepDraggable';

const useStyles = makeStyles((theme: Theme) => ({
  draggable: {
    border: `2px solid ${colors.common.white}`,
    transition: '0.25s all ease-in-out',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    opacity: 0.6,
  },
  svg: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  svgButton: {
    width: '16px',
    height: '16px',
  },
  attribute: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ProcessDragDrop: React.FC = () => {
  const { state } = React.useContext(AppContext);
  const { assignments } = state;
  const classes = useStyles();
  const { setToast } = React.useContext(ToastContext);
  const { process, setProcess } = React.useContext(ProcessContext);
  const { steps } = process;
  const firstStep = _.find(steps, { name: 'Unassigned' });
  const firstStepInvestors = _.filter(assignments, { step_id: firstStep?.id });
  const committedStep = _.find(steps, { name: 'Committed' });
  const committedStepInvestors = _.filter(assignments, { step_id: committedStep?.id });
  const lastStep = _.find(steps, { name: 'Declined' });
  const lastStepInvestors = _.filter(assignments, { step_id: lastStep?.id });

  const [dropState, setDropState] = React.useState<Fluit.process.Step[]>([]);

  React.useEffect(() => {
    const editableSteps = _.filter(steps, { editable: true });
    setDropState(editableSteps);
  }, [steps]);

  const onDragEnd = (result: DropResult): void => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const localState = [...dropState];
    const draggedStep = _.find(localState, { id: draggableId });
    localState.splice(source.index, 1);
    localState.splice(destination.index, 0, draggedStep as Fluit.process.Step);
    setDropState(localState);

    const newState = _.values(
      _.concat(
        firstStep ? firstStep : localState,
        localState,
        committedStep ? committedStep : localState,
        lastStep ? lastStep : localState
      )
    );

    (async () => {
      try {
        const updated_process = { ...process, steps: newState };
        await ProcessesFirebase.update(updated_process);
        setProcess(updated_process);
      } catch (error) {
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {firstStep ? (
        <Grid container spacing={4} style={{ marginBottom: '16px' }}>
          <Grid item xs={12}>
            <Paper className={classes.draggable}>
              <Box flex={1}>
                <Typography variant="h4">{firstStep.name}</Typography>
                <Box>
                  <Typography variant="body1" className={classes.attribute}>
                    <Investors className={classes.svg} />
                    {firstStepInvestors
                      ? firstStepInvestors.length === 1
                        ? `${firstStepInvestors.length} investor`
                        : `${firstStepInvestors.length} investors`
                      : `0 investors`}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : null}
      <Droppable droppableId="process" direction="vertical">
        {(provided, snapshot) => (
          <Grid container spacing={4} innerRef={provided.innerRef} {...provided.droppableProps}>
            {_.map(dropState, (step, index) => {
              return <StepDraggable key={step.id} step={step} stepIndex={index} />;
            })}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>

      {committedStep && (
        <Grid container spacing={4} style={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <Paper className={classes.draggable}>
              <Box flex={1}>
                <Typography variant="h4">{committedStep.name}</Typography>
                <Box>
                  <Typography variant="body1" className={classes.attribute}>
                    <Investors className={classes.svg} />
                    {committedStepInvestors
                      ? committedStepInvestors.length === 1
                        ? `${committedStepInvestors.length} investor`
                        : `${committedStepInvestors.length} investors`
                      : `0 investors`}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}

      {lastStep && (
        <Grid container spacing={4} style={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <Paper className={classes.draggable}>
              <Box flex={1}>
                <Typography variant="h4">{lastStep.name}</Typography>
                <Box>
                  <Typography variant="body1" className={classes.attribute}>
                    <Investors className={classes.svg} />
                    {lastStepInvestors
                      ? lastStepInvestors.length === 1
                        ? `${lastStepInvestors.length} investor`
                        : `${lastStepInvestors.length} investors`
                      : `0 investors`}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </DragDropContext>
  );
};

export default ProcessDragDrop;
