import { Box, Card, CardActionArea, CardContent, Chip, makeStyles, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CurrencyDisplay from 'src/components/Displays/Currency';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { calculateRaise } from 'src/helpers/calculateRaise';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    border: `2px solid ${theme.palette.common.white}`,
    '&:hover': {
      border: `2px solid ${colors.purple.main}`,
      boxShadow: theme.shadows[3],
    },
  },
  cardContent: {
    flexGrow: 1,
    '& > div': {
      marginBottom: theme.spacing(1),
    },
  },
  cardActions: {
    paddingRight: theme.spacing(3),
  },
  cardActionArea: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    '&:hover': {
      borderColor: theme.palette.common.white,
      background: theme.palette.common.white,
      boxShadow: 'none',
    },
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
  focusHighlight: {},
  percentage: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
  },
  progressOuter: {
    background: theme.palette.background.default,
    height: '16px',
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  progress: {
    height: '16px',
    borderRadius: theme.spacing(2),
    background: colors.gradients.horizontal.purple,
    minWidth: '16px',
    maxWidth: '100%',
  },
  svg: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > div': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  attributeContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  attribute: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  step: {
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(2),
    border: `1.5px dotted ${colors.purple[200]}`,
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  stepDetails: {
    flex: 1,
  },
  stepCount: {
    padding: theme.spacing(0, 3),
  },
}));

interface Props {
  deal: Fluit.deals.Deal;
  step: Fluit.process.Step;
  termsheets: Fluit.deals.Termsheet[];
}

const InvestorDealCard: React.FC<Props> = ({ deal, step, termsheets }) => {
  const { state } = React.useContext(AppContext);
  const { processes } = state;
  const { investor } = React.useContext(InvestorContext);
  const { id, name, type, details } = deal;
  const { setDeal } = React.useContext(DealContext);
  const process = _.find(processes, { deal_id: deal.id });

  const classes = useStyles();
  const history = useHistory();

  let x = 0;
  let y = 0;

  if (process) {
    x = _.indexOf(process.steps, step) + 1;
    y = process.steps.length - 1;
  }

  const total_termsheets = _.sum(_.map(_.filter(state.termsheets, { deal_id: deal.id }), 'amount'));
  const investor_termsheets = _.sum(_.map(termsheets, 'amount'));
  const investor_valuation = _.sum(_.map(termsheets, 'valuation'));

  const handleClick = () => {
    setDeal(deal);
    history.push(`${ROUTES.DEALS}/${id}`);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea
        classes={{
          root: classes.cardActionArea,
          focusHighlight: classes.focusHighlight,
        }}
        onClick={() => handleClick()}
      >
        <CardContent className={classes.cardContent}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Chip color={type === 'Fundraise' ? 'primary' : 'secondary'} label={type} />
            {type === 'Fundraise' && details ? (
              <Chip
                color="default"
                variant="outlined"
                label={
                  <CurrencyDisplay
                    name={details.currency.name}
                    code={details.currency.code}
                    symbol={details.currency.symbol}
                    alpha_2={details.currency.alpha_2}
                  />
                }
              />
            ) : null}
          </Box>

          <Box>
            <Typography variant="h3">{name}</Typography>
            {details ? <Typography variant="caption">{details.round}</Typography> : null}
          </Box>

          {details && type === 'Fundraise' ? (
            <Box className={classes.details}>
              <Box flex="auto">
                <Typography variant="caption">Allocation</Typography>
                <Typography variant="h4">
                  {details.currency.symbol} {numeral(details.amount).format('0,0')}
                </Typography>
              </Box>

              {details.equity ? (
                <Box flex="auto" mr={2}>
                  <Typography variant="caption">Equity</Typography>
                  <Typography variant="h4">{numeral(details.equity).format('0')}%</Typography>
                </Box>
              ) : null}

              <Box flex="auto">
                <Typography variant="caption">Total Termsheets</Typography>
                <Typography variant="h4">
                  {details.currency.symbol} {numeral(total_termsheets).format('0,0')}
                  <Chip
                    className={classes.percentage}
                    variant="outlined"
                    size="small"
                    color="default"
                    label={`${numeral(calculateRaise(details.amount!, total_termsheets!)).format('0')}%`}
                  />
                </Typography>
              </Box>

              <Box flex="auto">
                <Typography variant="caption">{investor.name}'s Termsheet</Typography>
                <Typography variant="h4">
                  {details.currency.symbol} {numeral(investor_termsheets).format('0,0')}
                  <Chip
                    className={classes.percentage}
                    variant="outlined"
                    size="small"
                    color="default"
                    label={`${numeral(calculateRaise(details.amount!, investor_termsheets!)).format('0')}%`}
                  />
                </Typography>
              </Box>

              <Box flex="auto">
                <Typography variant="caption">{investor.name}'s Pre-Money Valuation</Typography>
                <Typography variant="h4">
                  {details.currency.symbol} {numeral(investor_valuation).format('0,0')}
                </Typography>
              </Box>
            </Box>
          ) : null}

          {step ? (
            <Box className={classes.step}>
              <Box className={classes.stepDetails}>
                <Typography variant="caption">Process Step: </Typography>
                <Typography variant="h6">{step.name}</Typography>
              </Box>
              <Box className={classes.stepCount}>
                <Typography variant="subtitle2">{x > y ? '' : `${x} of ${y}`}</Typography>
              </Box>
            </Box>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default InvestorDealCard;
