import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeError } from '@stripe/stripe-js';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { BillingFirebase } from 'src/services/billing';
import { billing_creditCardStyles } from 'src/styles/billing';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';
import Stripe from 'stripe';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      padding: theme.spacing(3),
      background: colors.common.white,
      boxShadow: shadows.black[1],
      border: `1.5px solid ${colors.grey[100]}`,
      borderRadius: theme.spacing(0.5),
      '&:hover': {
        border: `1.5px solid ${colors.purple.main}`,
        boxShadow: shadows.purple[1],
      },
    },
    complete: {
      border: `1.5px solid ${colors.green[400]}`,
      boxShadow: shadows.black[1],
      '&:hover': {
        border: `1.5px solid ${colors.green[400]}`,
        boxShadow: shadows.black[1],
      },
    },
    empty: {},
    focused: {
      border: `1.5px solid ${colors.purple.main}`,
      boxShadow: shadows.purple[1],
      '&$complete': {
        border: `1.5px solid ${colors.green[400]}`,
        boxShadow: shadows.black[1],
      },
    },
    invalid: {
      border: `1.5px solid ${colors.red.main}`,
      boxShadow: shadows.red[1],
      '&:hover': {
        border: `1.5px solid ${colors.red.main}`,
        boxShadow: shadows.red[1],
      },
    },
  })
);

const AddPaymentMethod: React.FC = () => {
  const classes = useStyles();
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { billing } = state;
  const [loading, setLoading] = React.useState(false);
  const [primary, setPrimary] = React.useState(false);
  const [error, setError] = React.useState<StripeError | undefined>();

  const stripe = useStripe();
  const elements = useElements();

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const payment_intent: Stripe.paymentIntents.IPaymentIntent = await BillingFirebase.createSetupIntent(
      billing.stripe_customer_id
    );
    const card_element = elements.getElement(CardElement);

    const result = await stripe.confirmCardSetup(payment_intent.client_secret, {
      payment_method: {
        card: card_element as StripeCardElement,
      },
    });

    if (result.error) {
      setLoading(false);
      setError(result.error);
    } else {
      if (primary) {
        const set_default = {
          invoice_settings: {
            default_payment_method: result.setupIntent?.payment_method,
          },
        };
        dispatch({ type: 'BILLING_CUSTOMER_UPDATE', payload: set_default });
      }

      const payment_methods = await BillingFirebase.listPaymentMethods(billing.stripe_customer_id);
      dispatch({ type: 'BILLING_PAYMENT_METHOD_LIST', payload: payment_methods });
      setToast({
        type: 'success',
        message: `Card Successfully Added`,
      });
      setLoading(false);
      closeModal();
    }
  };

  return (
    <Grid container spacing={4}>
      {error && (
        <Grid item xs={12}>
          <Paper>
            <Alert severity="error">{error.message}</Alert>
          </Paper>
        </Grid>
      )}

      <Grid item xs={12}>
        <CardElement
          options={{
            style: billing_creditCardStyles,
            hidePostalCode: true,
            disabled: loading,
            classes: {
              base: classes.base,
              complete: classes.complete,
              empty: classes.empty,
              focus: classes.focused,
              invalid: classes.invalid,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl name="default" component="fieldset">
          <FormControlLabel
            control={
              <Checkbox
                checked={primary}
                onChange={() => (primary ? setPrimary(false) : setPrimary(true))}
                name="accept"
                color="primary"
                disabled={loading}
              />
            }
            label={<Typography variant="body1">Set as default billing card</Typography>}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" size="large" color="primary" disabled={loading} onClick={e => handleSubmit(e)}>
          Add
        </Button>
        <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default AddPaymentMethod;
