import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import ActivityList from 'src/components/Activity/ActivityList';
import { AppContext } from 'src/contexts/AppContext';

const Activity: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;
  const { limits } = organisation;
  const hit_activity_limit = limits.activities.count >= limits.activities.limit;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const logEvent = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: hit_activity_limit ? '' : 'Log Event',
        component: hit_activity_limit ? 'UPGRADE_ACTIVITIES' : 'EVENT_LOG',
        centered: hit_activity_limit,
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" justifyItems="center" justifyContent="space-between">
          <Typography variant="h1">Activity</Typography>
          {mobile ? (
            <Button variant="outlined" color="secondary" onClick={() => logEvent()}>
              Log Event
            </Button>
          ) : null}
        </Box>
      </Grid>

      <ActivityList target="all" />
    </Grid>
  );
};
export default Activity;
