import { Box, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    border: `1.5px solid ${colors.grey[50]}`,
    borderRadius: theme.spacing(0.5),
    boxShadow: shadows.black[1],
    margin: theme.spacing(10, 0, 3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(6, 0, 3),
      flexDirection: 'column',
      justifyContent: 'normal',
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    '& h6': {
      marginRight: theme.spacing(3),
      '&:last-child': {
        marginRight: 0,
      },
      '& a': {
        color: 'inherit',
        whiteSpace: 'nowrap',
        '&$active': {
          fontWeight: 600,
          color: colors.purple[500],
        },
        '&:hover': {
          color: colors.purple[500],
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'normal',
      '& h6': {
        marginRight: 0,
        marginTop: theme.spacing(2),
      },
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  md: {
    flexDirection: 'column',
    margin: theme.spacing(0, 0, 3),
    '& > div:first-child': {
      marginBottom: theme.spacing(0.5),
    },
  },
  active: {},
}));

interface Props {
  md?: boolean;
}

const Footer: React.FC<Props> = ({ md }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, md && classes.md)}>
      <Box>
        <Typography variant="subtitle2">Copyright &copy; 2019 - 2020, Fluit Tech Limited.</Typography>
      </Box>
      <Box className={classes.links}>
        <Typography variant="subtitle2">
          <Link component={NavLink} to={ROUTES.TERMS} activeClassName={classes.active}>
            Terms &amp; Conditions
          </Link>
        </Typography>
        <Typography variant="subtitle2">
          <Link component={NavLink} to={ROUTES.PRIVACY} activeClassName={classes.active}>
            Privacy Policy
          </Link>
        </Typography>
        <Typography variant="subtitle2">
          <Link component={NavLink} to={ROUTES.COOKIE} activeClassName={classes.active}>
            Cookie Policy
          </Link>
        </Typography>
        <Typography variant="subtitle2">
          <Link component={NavLink} to={ROUTES.ACCEPTABLE_USE} activeClassName={classes.active}>
            Acceptable Use Policy
          </Link>
        </Typography>
        <Typography variant="subtitle2">
          <Link component={NavLink} to={ROUTES.SUPPORT} activeClassName={classes.active}>
            Support
          </Link>
        </Typography>
        <Typography variant="subtitle2">
          <Link href="https://www.fluit.co">Visit Fluit.co</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
