import {
  Button,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { formatDate } from 'src/helpers/formatDate';
import { BillingFirebase } from 'src/services/billing';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
  svg: {
    width: 24,
    height: 24,
    color: theme.palette.error.main,
  },
}));

const SubscriptionCancelForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { billing, subscriptions, organisation, deals, members, user, investors } = state;
  const { setToast } = React.useContext(ToastContext);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const subscriptionId = String(state.ui.modal.subscriptionId);
  const subscription = _.find(subscriptions, { id: subscriptionId });

  const disabled_deals = _.filter(_.orderBy(deals, ['created_at'], ['desc']), (deal, index) => index !== 0);
  let disabled_deal_names = '';
  _.map(disabled_deals, (deal, index) => {
    if (index + 1 !== disabled_deals.length) {
      return (disabled_deal_names = `${disabled_deal_names} ${deal.name}, `);
    }
    return (disabled_deal_names = `${disabled_deal_names} ${deal.name}`);
  });

  const removed_users = _.filter(members, member => member.id !== user.id);
  let removed_user_names = '';
  _.map(removed_users, (user, index) => {
    if (index + 1 !== removed_users.length) {
      return (removed_user_names = `${removed_user_names} ${user.first_name} ${user.last_name}, `);
    }
    return (removed_user_names = `${removed_user_names} ${user.first_name} ${user.last_name}`);
  });

  const removed_investors = _.filter(
    _.orderBy(investors, ['created_at'], ['desc']),
    (investor, index) => index + 1 > 5
  );
  let disabled_investor_names = '';
  _.map(removed_investors, (investor, index) => {
    if (index + 1 !== disabled_deals.length) {
      return (disabled_investor_names = `${disabled_investor_names} ${investor.name}, `);
    }
    return (disabled_investor_names = `${disabled_investor_names} ${investor.name}`);
  });

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const cancelSubscription = async () => {
    try {
      setLoading(true);
      const sub = await BillingFirebase.cancelSubscription(subscriptionId, subscription);
      dispatch({ type: 'SUBSCRIPTION_UPDATE', payload: sub });
      setLoading(false);
      setToast({
        message: `Subscription Cancelled`,
        type: 'info',
      });

      closeModal();
    } catch (error) {
      setLoading(false);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>
          By cancelling your subscription you will lose access to all premium features on{' '}
          <strong>{billing.latest_invoice && formatDate(billing.latest_invoice.period_end, true)}</strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <List disablePadding>
          {organisation.limits.members.count > 1 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography>
                    The following users will lose access to {organisation.name}: <strong>{removed_user_names}</strong>
                  </Typography>
                }
              />
            </ListItem>
          )}

          {organisation.limits.deals.count > 1 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography>
                    The following deals will be disabled: <strong>{disabled_deal_names}</strong>
                  </Typography>
                }
              />
            </ListItem>
          )}

          {organisation.limits.activities.count > 5 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography>
                    A total of {organisation.limits.activities.count - 5} activity logs will be deleted
                  </Typography>
                }
              />
            </ListItem>
          )}

          <ListItem disableGutters>
            <ListItemIcon>
              <ErrorOutlineIcon className={classes.svg} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={<Typography>All system (audit) activity logs will be deleted.</Typography>}
            />
          </ListItem>

          {organisation.limits.investors.count > 5 && (
            <Typography variant="subtitle2" style={{ marginTop: 24, marginBottom: 16 }}>
              <Typography variant="caption">Investors:</Typography>
              <br />
              {disabled_investor_names} and their contacts
            </Typography>
          )}

          {organisation.limits.investors.count > 5 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText primary="Will be disabled" />
            </ListItem>
          )}

          {organisation.limits.investors.count > 5 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText primary="Will be removed from your remaining deal" />
            </ListItem>
          )}

          {organisation.limits.investors.count > 5 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText primary="Their Note data will be removed." />
            </ListItem>
          )}

          {organisation.limits.investors.count > 5 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText primary="Their Termsheet data will be removed." />
            </ListItem>
          )}

          {organisation.limits.investors.count > 5 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText primary="Their Task data will be removed." />
            </ListItem>
          )}

          {organisation.limits.investors.count > 5 && (
            <ListItem disableGutters>
              <ListItemIcon>
                <ErrorOutlineIcon className={classes.svg} />
              </ListItemIcon>
              <ListItemText primary="Their Actvity data will be removed." />
            </ListItem>
          )}
        </List>
      </Grid>

      <Grid item xs={12}>
        <Alert icon={false} severity="error">
          Are you sure you want to cancel your subscription?
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => cancelSubscription()}
          disabled={loading ? true : false}
        >
          Cancel Subscription
        </Button>
        <Button
          variant="text"
          style={{ marginLeft: '16px' }}
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default SubscriptionCancelForm;
