import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import CheckoutSummary from 'src/components/Checkout/Summary';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { data_countries } from 'src/constants/countries';
import { telephoneRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { BillingFirebase } from 'src/services/billing';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const CheckoutCustomer: React.FC = () => {
  const { checkout, setCheckout } = React.useContext(CheckoutContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, user, subscriptions, billing } = state;
  const { customer } = billing;
  const [details, setDetails] = React.useState<Fluit.checkout.Customer>();
  const [loading, setLoading] = React.useState(true);

  const getCustomerData = React.useCallback(async () => {
    const customer = await BillingFirebase.getCustomerData(organisation.id, billing.stripe_customer_id);
    dispatch({ type: 'BILLING_CUSTOMER_GET', payload: customer });
    if (customer) {
      setDetails({
        id: customer.id,
        name: customer.name,
        phone: customer.phone,
        email: customer.email,
        address: customer.address,
        metadata: customer.metadata,
      });
    }
    setLoading(false);
  }, [billing.stripe_customer_id, dispatch, organisation.id, setDetails]);

  React.useEffect(() => {
    if (subscriptions.length) {
      getCustomerData();
    } else {
      setLoading(false);
    }
  }, [subscriptions, getCustomerData]);

  if (loading) {
    return <GenericLoader height="160px" variant="none" />;
  }

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          id: customer ? customer.id : details ? details.id : '',
          name: customer && customer.name ? customer.name : details ? details.name : organisation.name,
          phone: customer && customer.phone ? customer.phone : details ? details.phone : organisation.telephone,
          email: customer && customer.email ? customer.email : details ? details.email : user.email,
          address: {
            line1: customer && customer.address ? customer.address.line1 : details ? details.address.line1 : '',
            line2:
              customer && customer.address && customer.address.line2
                ? customer.address.line2
                : details
                ? details.address.line2
                : '',
            city:
              customer && customer.address && customer.address.city
                ? customer.address.city
                : details
                ? details.address.city
                : '',
            country:
              customer && customer.address && customer.address.country
                ? customer.address.country
                : details
                ? details.address.country
                : organisation.location.iso,
            state:
              customer && customer.address && customer.address.state
                ? customer.address.state
                : details
                ? details.address.state
                : '',
            postal_code:
              customer && customer.address && customer.address.postal_code
                ? customer.address.postal_code
                : details
                ? details.address.postal_code
                : '',
          },
          // tax_id_data: [
          //   {
          //     type: customer.tax_id_data ? customer.tax_id_data[0].type : '',
          //     value: customer.tax_id_data ? customer.tax_id_data[0].value : '',
          //   },
          // ],
          metadata: {
            org_id: organisation.id,
            tax_country: customer && customer.metadata.tax_country ? customer.metadata.tax_country : '',
          },
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          let v: Fluit.checkout.Customer = values;
          let newLogic = checkout.logic;
          newLogic[checkout.step] = true;
          actions.setSubmitting(false);
          setCheckout({
            ...checkout,
            customer: {
              ...v,
            },
            step: checkout.step + 1,
            logic: newLogic,
          });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Not a valid email address')
            .required('Enter a billing email address'),
          name: Yup.string(),
          phone: Yup.string().matches(telephoneRegex, 'Not a valid telephone number'),
          address: Yup.object().shape({
            line1: Yup.string().required('Address Line 1 is Required'),
            line2: Yup.string(),
            city: Yup.string(),
            country: Yup.string(),
            state: Yup.string(),
            postal_code: Yup.string().required('Postcode or Zipcode is Required'),
          }),
        })}
      >
        {props => {
          const { handleSubmit, setFieldValue, values } = props;
          const country_value = _.find(data_countries, {
            iso: values.address.country ? values.address.country.toLowerCase() : 'gb',
          });
          // const tax_label = _.find(stripeTaxCodes, { country: values.metadata.tax_country });
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={8}>
                  <Grid item xs={12} md={10}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h4">Billing Company</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="name"
                          label="Company Name"
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="email"
                          label="Billing Email"
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="phone"
                          label="Billing Telephone"
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      {/* <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          select
                          fullWidth={true}
                          name="metadata.tax_country"
                          value={values.metadata.tax_country}
                          label="Tax Jurisdiction"
                          variant="outlined"
                        >
                          <MenuItem value="">None</MenuItem>
                          {_.map(stripeTaxCodes, tax => (
                            <MenuItem key={_.uniqueId()} value={tax.country}>
                              {tax.country}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="tax_id_data[0].value"
                          label={tax_label ? tax_label.tax_name : 'Tax Number'}
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid> */}

                      <Grid item xs={12}>
                        <Typography variant="h4">Billing Address</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="address.line1"
                          label="Line 1"
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="address.line2"
                          label="Line 2"
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="address.city"
                          label="City"
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="address.state"
                          label="State, County, Province or Region"
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Autocomplete
                          id="country"
                          options={data_countries}
                          getOptionLabel={option => option.name}
                          getOptionSelected={(option, value) => option.iso === value.iso}
                          value={country_value}
                          onChange={(e: object, value: any | null) => {
                            if (value !== null) {
                              setFieldValue('address.country', value.iso);
                              const customer = { ...checkout.customer };
                              customer.address.country = value.iso;
                              setCheckout({
                                ...checkout,
                                customer: {
                                  ...customer,
                                },
                              });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              name="address.country"
                              label="Country"
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: _.uniqueId('new-password off'),
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          fullWidth={true}
                          name="address.postal_code"
                          label="Postcode or Zipcode"
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <CheckoutSummary />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default CheckoutCustomer;
