import { Box } from '@material-ui/core';
import React from 'react';
import { isoToFlag } from 'src/helpers/isoToFlag';
import Fluit from 'src/types/Fluit';

const CurrencyDisplay: React.FC<Fluit.Currency> = ({ name, code, symbol, alpha_2 }: Fluit.Currency) => {
  return (
    <Box>
      {isoToFlag(alpha_2)} {symbol} {code}
    </Box>
  );
};

export default CurrencyDisplay;
