import * as firebase from 'firebase/app';
import 'firebase/firestore';
import _ from 'lodash';

export const firebaseDateToIso = (
  date: string | firebase.firestore.Timestamp | firebase.firestore.FieldValue | null | undefined
) => {
  if (_.isNil(date) || typeof date === 'string') {
    return date;
  }
  const d = date as firebase.firestore.Timestamp;
  if (d.seconds < 0) {
    return;
  }
  const nd = new firebase.firestore.Timestamp(d.seconds, d.nanoseconds);
  return nd.toDate().toISOString();
};
