import { Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ReactComponent as Investors } from 'src/assets/images/investors.svg';
import CheckoutHanlder from 'src/components/Tabs/Checkout/Checkout';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '100%',
    maxWidth: 300,
    height: 'auto',
  },
}));

const Buy: React.FC = () => {
  const classes = useStyles();
  const { state } = React.useContext(AppContext);
  const { subscriptions, init, organisation, members } = state;
  const location = useLocation();
  const { admin, buying } = init;
  const active_subscription = _.find(subscriptions, { status: 'active' });

  const admins = _.filter(members, { role: 'owner' });

  if (subscriptions.length && active_subscription && admin && !buying) {
    return <Redirect to={`${ROUTES.ORGANISATIONS}/?tab=billing`} />;
  }

  if (subscriptions.length && active_subscription && !admin && !buying) {
    return <Redirect to={location} />;
  }

  if (!admin) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h1">Purchase</Typography>
        </Grid>
        <Grid item xs={12}>
          <Investors className={classes.svg} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Alert severity="info">Only admin's of {organisation.name} can make a purchase.</Alert>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Contact{' '}
            {_.map(admins, (admin, index) => (
              <strong key={admin.id}>
                {admin.first_name} {admin.last_name} {index + 1 !== admins.length ? ',' : null}
              </strong>
            ))}{' '}
            to make a purchase.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return <CheckoutHanlder />;
};

export default Buy;
