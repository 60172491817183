import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import NoteEditForm from 'src/components/Forms/Notes/Edit';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { initalise } from 'src/constants/init';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { NotesFirebase } from 'src/services/notes';
import Fluit from 'src/types/Fluit';

const NoteEdit: React.FC = () => {
  let { note_id } = useParams();
  const history = useHistory();
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { notes, organisation } = state;
  const [note, setNote] = React.useState<Fluit.notes.Note>(initalise.note);
  const [loading, setLoading] = React.useState(false);
  const [init, setInit] = React.useState(false);

  const listNotes = React.useCallback(async () => {
    try {
      setLoading(true);
      const result = await NotesFirebase.list(organisation.id);
      dispatch({ type: 'NOTES_LIST', payload: result });
      const find_note = _.find(result, note => note.id === note_id);
      setNote(find_note ? find_note : initalise.note);
      setLoading(false);
      setInit(true);
    } catch (error) {
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [organisation.id, dispatch, note_id, setToast]);

  React.useEffect(() => {
    if (note === initalise.note && !loading) {
      listNotes();
    }
  }, [note, listNotes, loading]);

  React.useEffect(() => {
    let n = _.find(notes, { id: note_id });
    if (!n && init) {
      history.push(ROUTES.NOTES);
    }
    if (n && init) {
      setNote(n);
    }
  }, [notes, history, note_id, init]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h1">Edit Note</Typography>
      </Grid>
      <Grid item xs={12}>
        {!loading && init ? <NoteEditForm note={note} /> : <GenericLoader height="100%" variant="none" />}
      </Grid>
    </Grid>
  );
};

export default withRouter(NoteEdit);
