import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as ROUTES from 'src/routes/routes';

const CheckoutConfirmation: React.FC = () => {
  // const { checkout } = React.useContext(CheckoutContext);
  // const { interval, result } = checkout;

  // const totalsObject = result.subscription ? result.subscription.latest_invoice : result.invoice;
  // const vat_rate = checkout.customer.address.country === 'gb' ? 0.2 : 0;
  const history = useHistory();

  const handleClick = (route: string) => {
    history.push(route);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box textAlign="center" mb={6}>
          <Player
            autoplay
            loop
            src="https://assets8.lottiefiles.com/packages/lf20_1SS3xi.json"
            style={{ height: '300px', width: '300px' }}
          ></Player>
          <Typography variant="h2">Payment Successful</Typography>
        </Box>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography variant="h2">Purchase Summary</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Subscription</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box p={3}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>{formatDate(result.subscription.current_period_start, true)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Next Billing Date</TableCell>
                  <TableCell>{formatDate(result.subscription.current_period_end, true)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bills</TableCell>
                  <TableCell style={{ textTransform: 'capitalize' }}>{interval}ly</TableCell>
                </TableRow>
                {vat_rate > 0 && (
                  <TableRow>
                    <TableCell>Subtotal</TableCell>
                    <TableCell>{formatCurrency(totalsObject.subtotal / 100)}</TableCell>
                  </TableRow>
                )}
                {vat_rate > 0 && (
                  <TableRow>
                    <TableCell>VAT</TableCell>
                    <TableCell>{formatCurrency((totalsObject.subtotal / 100) * vat_rate)}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell>{formatCurrency(totalsObject.total / 100)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Premium User Licenses</TableCell>
                  <TableCell>{result.items[0].quantity}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Deals</TableCell>
                  <TableCell>{result.items[1].quantity}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }}>Invoice</TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <Link color="secondary" href={totalsObject.invoice_pdf}>
                      Download Invoice
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginRight: 16 }}
            onClick={() => handleClick(ROUTES.DEALS)}
          >
            Dashboard
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            style={{ marginRight: 16 }}
            onClick={() => handleClick(`${ROUTES.ORGANISATIONS}/?tab=billing`)}
          >
            Manage Account
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            onClick={() => handleClick(`${ROUTES.ORGANISATIONS}/?tab=invoices`)}
          >
            Invoices
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CheckoutConfirmation;
