import {
  Box,
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import CurrencyDisplay from 'src/components/Displays/Currency';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { formatIsoDate } from 'src/helpers/formatIsoDate';
import colors from 'src/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& th, & td': {
      borderBottom: 'none',
      display: 'inline-flex',
      padding: theme.spacing(0.5, 0),
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        width: 'auto',
        padding: 0,
      },
    },
    '& th': {
      whiteSpace: 'nowrap',
      verticalAlign: 'top',
      paddingRight: theme.spacing(4),
      color: theme.palette.grey[700],
      minWidth: '160px',
      width: 'fit-content',
    },
    '& tr': {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        marginBottom: theme.spacing(2),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chip: {
    background: colors.grey[100],
    textTransform: 'capitalize',
  },
  live: {
    background: colors.blue[400],
    color: colors.common.white,
  },
  complete: {
    background: colors.green[500],
    color: colors.common.white,
  },
  cancelled: {
    background: colors.red[400],
    color: colors.common.white,
  },
  delete: {
    color: colors.grey[500],
    marginLeft: theme.spacing(2),
  },
}));

const DealDetails: React.FC = () => {
  const { dispatch } = React.useContext(AppContext);
  const { deal } = React.useContext(DealContext);
  const { termsheets, name, type, details, status, created_at } = deal;
  const total_termsheets = _.sum(_.map(termsheets, 'amount'));
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const editDetails = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Edit Deal`,
        component: 'DEAL_EDIT',
        deal_id: deal.id,
      },
    });
  };

  const deleteDeal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Delete Deal`,
        component: 'DEAL_DELETE',
        deal_id: deal.id,
      },
    });
  };

  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Details</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box p={3}>
            <TableContainer>
              <Table aria-label="investor table" className={classes.root}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Name
                    </TableCell>
                    <TableCell>{name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>
                    <TableCell>
                      <Chip
                        className={clsx(
                          classes.chip,
                          status === 'Live' && classes.live,
                          status === 'Complete' && classes.complete,
                          status === 'Canceled' && classes.cancelled
                        )}
                        label={status}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Type
                    </TableCell>
                    <TableCell>{type}</TableCell>
                  </TableRow>
                  {type === 'Fundraise' && details ? (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Round
                        </TableCell>
                        <TableCell>{details.round}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Currency
                        </TableCell>
                        <TableCell>
                          <CurrencyDisplay
                            name={details.currency.name}
                            code={details.currency.code}
                            symbol={details.currency.symbol}
                            alpha_2={details.currency.alpha_2}
                          />
                          <Box ml={1}>{details.currency.name}</Box>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Raise Amount
                        </TableCell>
                        <TableCell>
                          {details.currency.symbol} {numeral(details.amount).format('0,0')}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Equity offered
                        </TableCell>
                        <TableCell>{details.equity}%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Termsheet Values
                        </TableCell>
                        <TableCell>
                          {details.currency.symbol} {numeral(total_termsheets).format('0,0')}
                        </TableCell>
                      </TableRow>
                    </>
                  ) : null}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Created
                    </TableCell>
                    <TableCell>{formatIsoDate(created_at)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={4}>
              <Button variant="outlined" color="secondary" onClick={() => editDetails()}>
                Edit
              </Button>
              <Button variant="text" className={classes.delete} onClick={() => deleteDeal()}>
                Delete
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DealDetails;
