import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import BillingCustomerDetails from 'src/components/Billing/CustomerDetails';
import BillingPaymentMethods from 'src/components/Billing/PaymentMethods';
import BillingSubscription from 'src/components/Billing/Subscription';

const BillingTab: React.FC = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Subscription(s)</Typography>
      </Grid>
      <Grid item xs={12}>
        <BillingSubscription />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Billing Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <BillingCustomerDetails />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Payment Method(s)</Typography>
      </Grid>
      <Grid item xs={12}>
        <BillingPaymentMethods />
      </Grid>
    </Grid>
  );
};

export default BillingTab;
