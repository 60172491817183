import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Asset404 from 'src/assets/images/error404.png';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  typography: {
    fontSize: '64px',
    fontWeight: 700,
    marginBottom: theme.spacing(6),
    color: theme.palette.grey[300],
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    '& > :first-child': {
      marginRight: theme.spacing(3),
    },
  },
  svg: {
    width: '100%',
    height: 'auto',
    maxWidth: '600px',
  },
}));

const Error404: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.typography} variant="h1">
        404
      </Typography>
      <img src={Asset404} alt="404 - page not found" className={classes.svg} />
      <Box className={classes.buttons}>
        <Button variant="contained" size="large" color="primary" component={NavLink} to={ROUTES.DEALS}>
          Dashboard
        </Button>
        <Button variant="outlined" size="large" color="primary" href="https://www.fluit.co">
          Main Site
        </Button>
      </Box>
    </Box>
  );
};

export default Error404;
