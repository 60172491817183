import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useContext } from 'react';
import { ToastContext } from 'src/contexts/ToastContext';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toast: React.FC = () => {
  const { toast, setToast } = useContext(ToastContext);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({
      message: '',
      type: 'info',
    });
  };

  return (
    <Snackbar
      open={toast.message.length > 0}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      key={toast.message}
    >
      <Alert severity={toast.type} onClose={handleClose} icon={false}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
