/* ====   PRE AUTH    ==== */
export const LOGIN = '/login';
export const REGISTER = '/register';
export const REQUEST_RESET = '/forgotten-password';
export const CHANGE_PASSWORD = '/change-password';

/* ====   LEGALS    ==== */
export const TERMS = '/terms-and-conditions';
export const COOKIE = '/cookie-policy';
export const PRIVACY = '/privacy-policy';
export const ACCEPTABLE_USE = '/acceptable-use-policy';
export const SUPPORT = '/support';

/* ====   ON BOARDING   ==== */
export const VERIFY_EMAIL = '/verify-email';
export const ONBOARDING = '/onboarding';

/* ====   Account Management     ==== */
export const USERS = '/users';
export const PROFILE = '/profile';

/* ====    APP     ==== */
export const DASHBOARD = '/';
export const INVESTORS = '/investors';
export const INVESTOR = '/investors/:investor_id';
export const INVESTOR_SEARCH = '/investors/search';
export const DEALS = '/deals';
export const DEAL = '/deals/:deal_id';
export const TASKS = '/tasks';
export const NOTES = '/notes';
export const NOTE = '/notes/:note_id';
export const NOTE_EDIT = '/notes/:note_id/edit';
export const NOTE_CREATE = '/notes/create';
export const ACTIVITY = '/activity';
export const ORGANISATIONS = '/organisations';
export const BUY = '/buy';
