import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import colors from 'src/themes/colors';
import { Close } from 'src/themes/icons';
import AlgoliaHighlight from './AlgoliaHighlight';

interface Item {
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
}

interface BasicDoc {
  [k: string]: string;
}

type Hit<TDoc = BasicDoc> = TDoc & {
  objectID: string;
  _highlightResult: HighlightResult<TDoc>;
};

type HighlightResult<TDoc> = TDoc extends { [k: string]: any }
  ? { [K in keyof TDoc]?: HighlightResultField<TDoc[K]> }
  : never;

type HighlightResultField<TField> = TField extends Array<infer TItem>
  ? HighlightResultArray<TItem>
  : TField extends string
  ? HighlightResultPrimitive
  : HighlightResult<TField>;

type HighlightResultArray<TItem> = TItem extends string ? HighlightResultPrimitive[] : Array<HighlightResult<TItem>>;

interface HighlightResultPrimitive {
  value: string;
  matchLevel: 'none' | 'partial' | 'full';
  matchedWords: string[];
  fullyHighlighted?: boolean;
}

interface Props {
  title: string;
  items: Array<Hit<{ count: number; isRefined: boolean; label: string; value: string[] }>>;
  currentRefinement: any[];
  isFromSearch: boolean;
  searchForItems: (...args: any[]) => any;
  createURL: (...args: any[]) => any;
  refine: (value: any[]) => any;
  showMore?: boolean;
  limit?: number;
  showMoreLimit?: number;
  searchable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    maxHeight: 36,
  },
  checkbox: {
    minWidth: 36,
  },
  input: {
    background: colors.common.background,
    boxShadow: 'none',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      background: colors.common.white,
    },
  },
  underline: {
    '&:before': {
      borderColor: colors.grey[200],
    },
  },
  svg: {
    width: 8,
    height: 8,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(1),
      '& > div': {
        width: '100%',
        flex: 1,
      },
    },
  },
}));

const AlgoliaRefinementList: React.FC<Props> = ({
  title,
  items,
  currentRefinement,
  refine,
  isFromSearch,
  createURL,
  searchForItems,
  limit,
  showMoreLimit,
  searchable,
}) => {
  const [val, setVal] = React.useState('');

  const handleClick = (event: React.MouseEvent, item: Item) => {
    event.preventDefault();
    refine(item.value);
  };

  const handleSearch = (value: string) => {
    searchForItems(value);
    setVal(value);
  };

  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.title}>
        <Box flex={1}>
          <Typography variant="h4" data-tour="investor_search_filters">
            {title}
          </Typography>
        </Box>
        {searchable && (
          <Box>
            <TextField
              variant="standard"
              fullWidth
              value={val}
              placeholder={`search for a ${title.toLowerCase()}`}
              onChange={event => handleSearch(event.currentTarget.value)}
              InputProps={{
                classes: {
                  underline: classes.underline,
                  root: classes.input,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Clear">
                      <span>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleSearch('')}
                          edge="end"
                          disabled={val.length === 0}
                        >
                          <Close className={classes.svg} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        {/* {showMore && (
          <Box>
            <Tooltip title="view all">
              <IconButton aria-label={`search ${title}`} onClick={(e: React.MouseEvent) => handleViewAll(e)}>
                <UnfoldMoreIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )} */}
      </Box>

      <List dense disablePadding>
        {_.map(items, (item, index) => {
          if (index + 1 > (limit ? limit : 6)) return undefined;
          return (
            <ListItem
              key={item.label}
              dense
              button
              disableGutters
              onClick={(e: React.MouseEvent) => handleClick(e, item)}
              className={classes.root}
            >
              <ListItemIcon className={classes.checkbox}>
                <Checkbox
                  edge="start"
                  checked={item.isRefined}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  inputProps={{ 'aria-labelledby': item.label }}
                />
              </ListItemIcon>
              <ListItemText id={item.label}>
                {isFromSearch ? <AlgoliaHighlight attribute="label" hit={item} /> : item.label} ({item.count})
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default connectRefinementList(AlgoliaRefinementList);
