import { Box, Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import Footer from 'src/components/Layout/Footer';
import HeaderApp from 'src/components/Layout/HeaderApp';
import Sidebar from 'src/components/Layout/Sidebar';
import ProtectedRoute from 'src/pages/_layouts/RouteProtection';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gridTemplateRows: 'auto 1fr auto',
    minHeight: '100vh',
    gridRowGap: theme.spacing(3),
    gridColumnGap: theme.spacing(2),
    gridTemplateAreas: `  'sidebar header'
                          'sidebar content'
                          'sidebar footer'`,

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
      gridTemplateRows: 'max-content auto max-content min-content',
      gridTemplateAreas: `  'header'
                            'content'
                            'footer'
                            'sidebar'`,
    },
  },
  notAuthed: {
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'min-content auto min-content',
    gridTemplateAreas: `  'header'
                          'content'
                          'footer'`,
  },
  loginGrid: {
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `'content'`,
  },
  sidebar: {
    height: '100vh',
    gridArea: 'sidebar',
    position: 'sticky',
    left: 0,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      height: '80px',
      bottom: 0,
      zIndex: 15,
    },
  },
  header: {
    gridArea: 'header',
  },
  content: {
    gridArea: 'content',
  },
  footer: {
    gridArea: 'footer',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
}));

const LayoutApp: React.FC<RouteProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Box className={classes.grid}>
      <ProtectedRoute {...rest}>
        <Box className={classes.sidebar} display="flex">
          <Sidebar />
        </Box>
        <Box className={classes.header}>
          <HeaderApp />
        </Box>
        <Container className={classes.container} maxWidth={false}>
          {children as any}
        </Container>
        <Box className={classes.footer}>
          <Container maxWidth={false}>
            <Footer />
          </Container>
        </Box>
      </ProtectedRoute>
    </Box>
  );
};

export default LayoutApp;
