import {
  Box,
  Button,
  colors,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Paper,
  Popover,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { NavLink, useHistory, useParams, withRouter } from 'react-router-dom';
import ContactDisplay from 'src/components/Displays/Contact';
import NoteCreateForm from 'src/components/Forms/Notes/Create';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import NoteHTML from 'src/components/Notes/NoteHtml';
import { initalise } from 'src/constants/init';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { firebaseDateToIso } from 'src/helpers/firebaseDateToIso';
import { formatDate } from 'src/helpers/formatDate';
import * as ROUTES from 'src/routes/routes';
import { NotesFirebase } from 'src/services/notes';
import { Deals, Investors } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    note: {
      height: 'auto',
      maxHeight: 200,
      overflow: 'hidden',
      position: 'relative',
    },
    mask: {
      position: 'absolute',
      bottom: 0,
      height: 100,
      width: '100%',
      background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%)',
    },
    svgInline: {
      width: '14px',
      height: '14px',
      marginRight: theme.spacing(0.5),
      fill: theme.palette.grey[200],
      '& g, & path': {
        fill: theme.palette.grey[200],
      },
    },
    attribute: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover svg, &:hover svg g, &:hover svg path': {
        fill: theme.palette.secondary.main,
      },
    },
    svg: {
      width: '20px',
      height: '20px',
      fill: theme.palette.grey[400],
      '& g, & path': {
        fill: theme.palette.grey[400],
      },
    },
    popover: {
      pointerEvents: 'none',
    },
    contact: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      display: 'inline-flex',
      padding: theme.spacing(0.25, 0.5),
      margin: theme.spacing(0, -0.5),
      borderRadius: theme.spacing(0.5),
      '&:hover': {
        background: colors.blue[50],
        color: theme.palette.secondary.dark,
        cursor: 'pointer',
      },
    },
    paper: {
      pointerEvents: 'auto',
    },
    delete: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    noteHeader: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  })
);

const Note: React.FC = () => {
  const classes = useStyles();
  let { note_id } = useParams();
  const history = useHistory();
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { notes, organisation, contacts, deals, investors } = state;
  const [note, setNote] = React.useState<Fluit.notes.Note>(initalise.note);
  const [loading, setLoading] = React.useState(false);
  const [init, setInit] = React.useState(false);
  const [poperEl, setPoperEl] = React.useState<HTMLElement | null>(null);
  const [popEl, setPopEl] = React.useState<HTMLElement | null>(null);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const listNotes = React.useCallback(async () => {
    try {
      setLoading(true);
      const result = await NotesFirebase.list(organisation.id);
      dispatch({ type: 'NOTES_LIST', payload: result });
      const find_note = _.find(result, note => note.id === note_id);
      setNote(find_note ? find_note : initalise.note);
      setLoading(false);
      setInit(true);
    } catch (error) {
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [organisation.id, dispatch, note_id, setToast]);

  React.useEffect(() => {
    if (note === initalise.note && !loading && note_id !== 'create') {
      listNotes();
    }
  }, [note, listNotes, loading, note_id]);

  React.useEffect(() => {
    let n = _.find(notes, { id: note_id });
    if (!n && init && note_id !== 'create') {
      history.push(ROUTES.NOTES);
    }
    if (n && init) {
      setNote(n);
    }
  }, [notes, history, note_id, init]);

  // Interface Actions
  const handlePopoverClose = () => {
    setPoperEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setPoperEl(event.currentTarget);
    setPopEl(event.currentTarget);
  };

  const handlePopoverHover = () => {
    setPoperEl(popEl);
  };

  const handlePopoverExit = () => {
    setPopEl(null);
    setPoperEl(null);
  };

  const parent_display = () => {
    if (note.parent_type === 'deal') {
      let data = _.find(deals, { id: note.parent_id });
      if (data) {
        return (
          <Typography variant="body1">
            <Tooltip title={`View ${data.name}`}>
              <Link
                component={NavLink}
                to={`${ROUTES.DEALS}/${data.id}`}
                color="secondary"
                className={classes.attribute}
              >
                <Deals className={classes.svgInline} /> {data.name}
              </Link>
            </Tooltip>
          </Typography>
        );
      } else {
        return <Typography variant="body1">-</Typography>;
      }
    }
    if (note.parent_type === 'investor') {
      let data = _.find(investors, { id: note.parent_id });
      if (data) {
        return (
          <Typography variant="body1">
            <Tooltip title={`View ${data.name}`}>
              <Link
                component={NavLink}
                to={`${ROUTES.INVESTORS}/${data.id}`}
                color="secondary"
                className={classes.attribute}
              >
                <Investors className={classes.svgInline} /> {data.name}
              </Link>
            </Tooltip>
          </Typography>
        );
      } else {
        return <Typography variant="body1">-</Typography>;
      }
    }
    if (note.parent_type === 'contact') {
      let data = _.find(contacts, { id: note.child_id });
      if (data) {
        return (
          <Typography variant="body1">
            <Link
              color="secondary"
              className={classes.attribute}
              onMouseEnter={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handlePopoverOpen(e)}
              onMouseLeave={handlePopoverClose}
            >
              <Investors className={classes.svgInline} /> {data.first_name} {data.last_name}
            </Link>
          </Typography>
        );
      } else {
        return <Typography variant="body1">-</Typography>;
      }
    }
    if (note.parent_type === 'organisation') {
      return <Typography variant="body1">{organisation.name}</Typography>;
    }
  };

  const contact = _.find(contacts, { id: note.child_id });

  const NoteDelete = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Delete Note?`,
        component: 'NOTE_DELETE',
        note: note as Fluit.notes.Note,
      },
    });
  };

  if (note_id === 'create') {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h1">New Note</Typography>
        </Grid>
        <Grid item xs={12}>
          <NoteCreateForm />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      {loading && (
        <Grid item xs={12}>
          <GenericLoader height="100%" variant="none" />
        </Grid>
      )}

      {!loading && (
        <>
          <Grid item xs={12}>
            <Typography variant="h1">{note.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box p={4} display="flex" flexDirection="row" alignItems="center" className={classes.noteHeader}>
                <Box display="flex" flexDirection="row" flexWrap="wrap" flex={1}>
                  <Box mr={4}>
                    <Typography variant="overline">
                      <small>Published on</small>
                    </Typography>
                    <Typography variant="body1">{formatDate(firebaseDateToIso(note.created_at))}</Typography>
                  </Box>
                  <Box mr={4}>
                    <Typography variant="overline">
                      <small>Published by</small>
                    </Typography>
                    <Typography variant="body1">{note.creator_name}</Typography>
                  </Box>

                  {note.updated_at && (
                    <>
                      <Box mr={4}>
                        <Typography variant="overline">
                          <small>Updated on</small>
                        </Typography>
                        <Typography variant="body1">{formatDate(firebaseDateToIso(note.updated_at))}</Typography>
                      </Box>
                      {note.updated_by !== note.created_by && (
                        <Box mr={4}>
                          <Typography variant="overline">
                            <small>Updated by</small>
                          </Typography>
                          <Typography variant="body1">{note.updator_name}</Typography>
                        </Box>
                      )}
                    </>
                  )}

                  <Box mr={4}>
                    <Typography variant="overline">
                      <small>Attached to</small>
                    </Typography>
                    {parent_display()}
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    className={classes.delete}
                    style={{ marginRight: 16, marginBottom: mobile ? 16 : 0, marginTop: mobile ? 16 : 0 }}
                    onClick={() => NoteDelete()}
                  >
                    Delete Note
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => history.push(`${ROUTES.NOTES}/${note.id}/edit`)}
                  >
                    Edit Note
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box p={4}>
                <NoteHTML contents={note.contents} />
              </Box>
            </Paper>
          </Grid>
        </>
      )}

      <Popover
        open={Boolean(poperEl)}
        anchorEl={poperEl}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        id="mouse-over-popover"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        PaperProps={{ onMouseEnter: handlePopoverHover, onMouseLeave: handlePopoverExit, elevation: 5 }}
        disableRestoreFocus
      >
        <Box>{contact ? <ContactDisplay data={contact} showInvestor /> : null}</Box>
      </Popover>
    </Grid>
  );
};

export default withRouter(Note);
