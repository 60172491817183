import { Box, Grid, Typography } from '@material-ui/core';
import algoliasearch from 'algoliasearch/lite';
import React from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { useHistory } from 'react-router';
import AlgoliaHit from 'src/components/Inputs/Algolia/AlgoliaHit';
import AlgoliaPagination from 'src/components/Inputs/Algolia/AlgoliaPagination';
import AlgoliaSearchBox from 'src/components/Inputs/Algolia/AlgoliaSearchBox';
import AlgoliaSidebar from 'src/components/Inputs/Algolia/AlgoliaSidebar';
import AppTour from 'src/components/Tour/Tour';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';
const searchClient = algoliasearch(
  String(process.env.REACT_APP_ALGOLIA_PROJECT),
  String(process.env.REACT_APP_ALGOLIA_KEY)
);

const InvestorSearch: React.FC = () => {
  const { state } = React.useContext(AppContext);
  const { organisation } = state;
  const { limits } = organisation;
  const hit_investor_limits = limits.investors.count >= limits.investors.limit;
  const history = useHistory();

  if (hit_investor_limits) {
    history.push(ROUTES.INVESTORS);
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AppTour />
        <Box display="flex" flexDirection="row" justifyItems="center" justifyContent="space-between">
          <Typography variant="h1">Search Investors</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} className="ais-InstantSearch">
        <Grid container spacing={4}>
          <InstantSearch indexName="investors" searchClient={searchClient}>
            <Grid item xs={12} md={8}>
              <Box mb={4}>
                <AlgoliaSearchBox />
              </Box>
              <AlgoliaHit />
              <AlgoliaPagination />
            </Grid>

            <Grid item xs={12} md={4}>
              <AlgoliaSidebar />
              <Configure hitsPerPage={8} />
            </Grid>
          </InstantSearch>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvestorSearch;
