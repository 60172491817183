import React from 'react';
import Loading from 'src/components/Loaders/Backdrop';
import Modal from 'src/components/Modal';
import Toast from 'src/components/Toast';
import Routes from 'src/routes/router';
import Init from 'src/services/init';

const App: React.FC = () => {
  return (
    <>
      <Loading />
      <Init />
      <Modal />
      <Routes />
      <Toast />
    </>
  );
};

export default App;
