import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { PrettoSlider } from 'src/components/Inputs/Slider/PrettoSlider';
import { formatCurrency } from 'src/helpers/formatCurrency';
import Fluit from 'src/types/Fluit';

interface Props {
  product: Fluit.checkout.products.Product;
  type: 'deals' | 'users';
  quantity: Fluit.checkout.Quantity;
  setQuantity: (
    value: React.SetStateAction<{
      users: number;
      deals: number;
    }>
  ) => void;
  setQEnd: (
    value: React.SetStateAction<{
      users: number;
      deals: number;
    }>
  ) => void;
  interval: string;
}

const BillingUnitsProduct: React.FC<Props> = ({ product, type, quantity, setQuantity, interval, setQEnd }) => {
  const { product_id, year, month, name, description } = product;
  const { users, deals } = quantity;

  const handleSlider = (event: any, val: number | number[]) => {
    setQuantity({
      users: type === 'users' ? (val as number) : users,
      deals: type === 'deals' ? (val as number) : deals,
    });
  };

  const handleSliderEnd = (event: any, val: number | number[]) => {
    setQEnd({
      users: type === 'users' ? (val as number) : users,
      deals: type === 'deals' ? (val as number) : deals,
    });
  };

  /* Display Handlers */

  const handleCount = React.useCallback(() => {
    if (type === 'users') {
      return users === 1 ? `${users} User` : `${users} Users`;
    }
    return `${deals} Deals`;
  }, [type, deals, users]);

  const handlePrice = React.useCallback(() => {
    if (interval === 'month') {
      return formatCurrency(month.price);
    }
    return formatCurrency(year.price);
  }, [interval, month, year]);

  const handlePriceDisplay = React.useCallback(() => {
    if (type === 'users') {
      return `${handlePrice()} / user / ${interval}`;
    }
    return `${quantity.deals === 1 ? formatCurrency(0) : handlePrice()} / deal / ${interval}`;
  }, [type, handlePrice, interval, quantity.deals]);

  return (
    <Paper key={product_id}>
      <Box p={4}>
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h2">{name}</Typography>
            <Typography variant="caption">{description}</Typography>
            {type === 'deals' && <Typography>1st Deal is included</Typography>}
            <Box mt={2}>
              <Typography variant="subtitle2">
                {handleCount()} <Typography variant="caption">{handlePriceDisplay()}</Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          value={type === 'users' ? users : deals}
          onChange={handleSlider}
          onChangeCommitted={handleSliderEnd}
          min={1}
          max={30}
        />
      </Box>
    </Paper>
  );
};

export default BillingUnitsProduct;
