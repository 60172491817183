import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { isoToFlag } from 'src/helpers/isoToFlag';
import { UserFirebase } from 'src/services/users';
import colors from 'src/themes/colors';
import { Dots, Email, StarFilled, Telephone, Users, Website } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  spacing: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
  center: {
    textAlign: 'center',
  },
  paperButton: {
    marginTop: theme.spacing(2),
    '& button': {
      marginRight: theme.spacing(2),
    },
  },
  iconLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  svg: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    fill: theme.palette.grey[200],
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  svgButton: {
    width: '20px',
    height: '20px',
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  delete: {
    color: colors.red[500],
    marginLeft: theme.spacing(2),
    borderColor: colors.red[500],
  },
  defaultSvg: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    '& g, & path': {
      fill: theme.palette.primary.main,
    },
  },
  notDefaultSvg: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  /* Card Styles */
  disabled: {
    pointerEvents: 'none',
    '& > *': {
      opacity: 0.4,
    },
    '& $linearprogress': {
      opacity: 1,
    },
  },
  linearprogress: {},
  card: {
    border: `2px solid ${theme.palette.common.white}`,
    display: 'flex',
    '&:hover': {
      border: `2px solid ${colors.purple.main}`,
      boxShadow: theme.shadows[3],
    },
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    paddingRight: theme.spacing(3),
  },
  cardActionArea: {
    display: 'flex',
    '&:hover': {
      borderColor: theme.palette.common.white,
      background: theme.palette.common.white,
      boxShadow: 'none',
    },
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
  focusHighlight: {},
  active: {
    border: `2px solid ${colors.purple.main}`,
  },
  activity: {
    padding: theme.spacing(1.5, 0, 0),
    margin: theme.spacing(2, 0, 0),
    borderTop: `1px dashed ${colors.grey[50]}`,
  },
  icon: {
    width: '10px',
    height: '10px',
    marginRight: theme.spacing(1),
    fill: theme.palette.grey[500],
    '& g, & path': {
      fill: theme.palette.grey[500],
    },
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

interface Props {
  data: Fluit.organisations.Organisation;
}

const OrganisationCard: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, user, init } = state;
  const { telephone, email, website, id, name, created_by, limits, location, currency, year_founded } = data;

  const { admin } = init;
  const creator = created_by === user.id;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setDefault = async () => {
    const u = { ...user, settings: { default_organisation: id } };
    try {
      await UserFirebase.update(u);
      dispatch({ type: 'MEMBER_UPDATE', payload: u });
      dispatch({ type: 'USER_SET', payload: u });
    } catch (error) {
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  };

  const handleSwitch = async () => {
    /* SET ORG */
    dispatch({ type: 'ORGANISATION_SET', payload: data });
    localStorage.removeItem('fluit::organisation');
    localStorage.setItem('fluit::organisation', id);
    dispatch({ type: 'SWITCHING_ORGANISATION', payload: true });
    handleClose();
  };

  return (
    <Card
      className={clsx(
        data.system?.delete && classes.disabled,
        classes.card,
        data.id === organisation.id && classes.active
      )}
    >
      <Box display="flex" flexDirection="row" flex={1}>
        <CardActionArea
          classes={{
            root: classes.cardActionArea,
            focusHighlight: classes.focusHighlight,
          }}
          onClick={() => handleSwitch()}
          disabled={data.system?.delete}
        >
          <CardContent className={classes.cardContent}>
            <Box>
              <Box mb={2}>
                <Typography variant="h4">
                  {name} {created_by === user.id ? <Chip label="Owner" size="small" style={{ marginLeft: 8 }} /> : null}
                  {id === organisation.id && (
                    <Chip label="Current" color="primary" size="small" style={{ marginLeft: 8 }} />
                  )}
                </Typography>
              </Box>
              <Box className={classes.innerBox}>
                <Box flex={1}>
                  <Typography variant="body2" className={classes.iconLink}>
                    <Telephone className={classes.svg} /> {telephone}
                  </Typography>
                  <Typography variant="body2" className={classes.iconLink}>
                    <Email className={classes.svg} /> {email}
                  </Typography>
                  {website && (
                    <Typography variant="body2" className={classes.iconLink}>
                      <Website className={classes.svg} />
                      {website}
                    </Typography>
                  )}
                  <Typography variant="body2" className={classes.iconLink}>
                    <Users className={classes.svg} />
                    {limits.members.count} {limits.members.count > 1 ? 'Users' : 'User'}
                  </Typography>

                  {user.settings?.default_organisation === id && (
                    <Typography variant="body2" className={classes.iconLink}>
                      <StarFilled className={classes.notDefaultSvg} /> Default Organisation
                    </Typography>
                  )}
                </Box>
                <Box flex={1}>
                  <Typography variant="body2" className={classes.iconLink}>
                    Founded in: {year_founded}
                  </Typography>
                  <Typography variant="body2" className={classes.iconLink}>
                    Location: {isoToFlag(location.iso)} {location.name}
                  </Typography>
                  <Typography variant="body2" className={classes.iconLink}>
                    Currency: {isoToFlag(currency.alpha_2)} {currency.symbol} {currency.code} - {currency.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <Tooltip title="menu">
            <IconButton
              color="secondary"
              aria-controls="investor-contextual-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Dots className={classes.svgButton} />
            </IconButton>
          </Tooltip>
          <Menu
            id="investor-contextual-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={4}
          >
            {organisation.id !== id ? <MenuItem onClick={() => handleSwitch()}>Set as current</MenuItem> : null}
            {id !== user.settings?.default_organisation ? (
              <MenuItem onClick={() => setDefault()}>Set as default</MenuItem>
            ) : null}
            {!creator ? (
              <MenuItem
                onClick={() => {
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      open: true,
                      title: `Leave ${name}?`,
                      component: 'ORGANISATION_LEAVE',
                      org_id: id,
                    },
                  });
                  handleClose();
                }}
              >
                Leave
              </MenuItem>
            ) : null}
            {admin ? (
              <MenuItem
                onClick={() => {
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      open: true,
                      title: `Edit ${name}`,
                      component: 'ORGANISATION_EDIT',
                      org_id: id,
                    },
                  });
                  handleClose();
                }}
              >
                Edit
              </MenuItem>
            ) : null}
            {admin ? (
              <MenuItem
                onClick={() => {
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      open: true,
                      title: `Delete ${name}`,
                      component: 'ORGANISATION_DELETE',
                      org_id: id,
                    },
                  });
                  handleClose();
                }}
              >
                Delete
              </MenuItem>
            ) : null}
          </Menu>
        </CardActions>
      </Box>
    </Card>
  );
};

export default OrganisationCard;
