import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const UserActivityReducer = (
  state: Fluit.activities.Activity[],
  action: Fluit.reducer.actions.ActivityUserActions
) => {
  // console.log(`%c•• [USER]: ${action.type} ••`, 'color: #FF79C6');
  // console.log(action.payload);
  switch (action.type) {
    /* CREATE */
    case 'ACTIVITY_USER_CREATE':
      return _.orderBy(_.concat(_.values(state), action.payload), ['date', 'details'], ['asc', 'asc']);

    /* UPDATE */
    case 'ACTIVITY_USER_UPDATE':
      const ACTIVITY_UPDATE = _.orderBy(
        _.concat(_.values(_.filter(state, activity => activity.id !== action.payload.id)), action.payload),
        ['date', 'details'],
        ['asc', 'asc']
      );
      return ACTIVITY_UPDATE;

    /* DELETE */
    case 'ACTIVITY_USER_DELETE':
      const ACTIVITY_DELETE = _.orderBy(
        _.values(_.filter(state, activity => activity.id !== action.payload.id)),
        ['date', 'details'],
        ['asc', 'asc']
      );
      return ACTIVITY_DELETE;

    /* LIST */
    case 'ACTIVITIES_USER_LIST':
      if (_.some(state)) {
        var data = _.map(state, item => {
          const inPayload = _.find(action.payload, { id: item.id });
          if (inPayload) {
            return inPayload;
          }
          return item;
        });

        var dif = _.differenceWith(action.payload, data, _.isEqual);
        return _.orderBy(_.concat(data, dif), ['date', 'details'], ['asc', 'asc']);
      }
      return action.payload;

    /* FLUSH */
    case 'ACTIVITIES_USER_FLUSH':
      return [];

    default:
      return { ...state };
  }
};
