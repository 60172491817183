import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export const FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export class FirebaseClient {
  public firebaseApp: firebase.app.App;

  constructor() {
    this.firebaseApp = firebase.initializeApp(FirebaseConfig);
  }

  public auth() {
    return this.firebaseApp.auth();
  }

  public functions() {
    return this.firebaseApp.functions('europe-west2');
  }

  public firestore() {
    return this.firebaseApp.firestore();
  }

  /**
   * registerUser
   */
  public async registerUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth().createUserWithEmailAndPassword(email, password);
  }

  /**
   * singIn
   */
  public async signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
    await this.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    return this.auth().signInWithEmailAndPassword(email, password);
  }

  /**
   * singOut
   */
  public async signOut() {
    await this.auth().signOut();
  }

  /**
   * resetPassword
   */
  public async resetPassword(email: string) {
    await this.auth().sendPasswordResetEmail(email);
  }

  /**
   * sendEmailVerification
   */
  public async sendEmailVerification() {
    await this.auth().currentUser?.sendEmailVerification();
  }
}

export default new FirebaseClient();
