import { Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { styles_checkoutStepper } from 'src/styles/CheckoutStyles';

const CheckoutStepper: React.FC = () => {
  const { checkout, setCheckout } = React.useContext(CheckoutContext);
  const { step, logic } = checkout;
  const classes = styles_checkoutStepper();

  const setStep = (newStep: number) => {
    if (logic[newStep] === false) {
      return;
    }
    setCheckout({
      ...checkout,
      step: newStep,
    });
  };

  return (
    <Stepper activeStep={step} className={classes.stepper} alternativeLabel>
      <Step onClick={() => setStep(0)}>
        <StepLabel>Users &amp; Deals</StepLabel>
      </Step>
      <Step onClick={() => setStep(1)}>
        <StepLabel>Billing Details</StepLabel>
      </Step>
      <Step onClick={() => setStep(2)}>
        <StepLabel>Review &amp; Pay</StepLabel>
      </Step>
    </Stepper>
  );
};

export default CheckoutStepper;
