import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LinkIcon from '@material-ui/icons/Link';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import isUrl from 'is-url';
import React from 'react';
import { Editor } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor, useSlate } from 'slate-react';
import colors from 'src/themes/colors';
import { H1, H2, H3 } from 'src/themes/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      flexWrap: 'wrap',
      borderBottom: `1.5px solid ${colors.grey[50]}`,
      padding: theme.spacing(2, 3),
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'nowrap',
        overflowX: 'scroll',
      },
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
    h1: {
      fontSize: 18,
      fontWeight: 800,
    },
    svg: {
      width: 20,
      height: 20,
      fill: '#9E9E9E',
      '& *': {
        fill: '#9E9E9E',
      },
    },
    button: {
      width: 44,
    },
    help: {
      '& kbd': {
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '0.1em 0.5em',
        margin: '0 0.2em',
        boxShadow: '0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset',
        backgroundColor: '#f7f7f7',
      },
    },
  })
);

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
    },
  },
}))(ToggleButtonGroup);

const StyledButton = withStyles(theme => ({
  root: { padding: theme.spacing(1), color: `rgba(0, 0, 0, 0.38)`, fontSize: 14, fontWeight: 600 },
}))(IconButton);

interface Props {
  editor: Editor & ReactEditor & HistoryEditor;
  activeBlock: (editor: Editor, format: string) => boolean;
  activeMark: (editor: Editor, format: string) => boolean;
  toggleBlock: (editor: Editor, format: string) => void;
  toggleMark: (editor: Editor, format: string) => void;
  insertLink: (editor: Editor, url: string, text: string) => void;
}

const SlateToolbar: React.FC<Props> = ({
  editor,
  activeBlock,
  activeMark,
  toggleBlock,
  toggleMark,
  insertLink,
  ...rest
}) => {
  const handleMark = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleMark(editorHook, event.currentTarget.value);
  };

  const handleBlock = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleBlock(editorHook, event.currentTarget.value);
  };

  const handleUndo = (event: React.MouseEvent<HTMLElement>) => {
    editor.undo();
  };

  const handleRedo = (event: React.MouseEvent<HTMLElement>) => {
    editor.redo();
  };

  const classes = useStyles();

  const editorHook = useSlate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const link = React.useRef('');
  const text = React.useRef('');
  const error = React.useRef(false);
  const errorMessage = React.useRef('');
  const [linkActive, setLinkActive] = React.useState(false);

  const [content, setContent] = React.useState<JSX.Element | null>(null);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>, child: string) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setContent(handleContent(child));
    if (child === 'link') {
      setLinkActive(true);
    } else {
      setLinkActive(false);
    }
  };

  const handleContent = (child: string) => {
    switch (child) {
      case 'link':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Link"
                onChange={e => (link.current = e.currentTarget.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                error={error.current}
                autoFocus
                fullWidth
                helperText={errorMessage.current}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                label="Text to display"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                onChange={e => (text.current = e.currentTarget.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" color="secondary" size="large" onClick={() => handleSubmit()}>
                Insert
              </Button>
            </Grid>
          </Grid>
        );
      case 'help':
        return (
          <Box className={classes.help}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>Mac</TableCell>
                    <TableCell>Windows</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Bold</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>b</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>b</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Italics</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>i</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>i</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Underline</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>u</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>u</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Undo</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>z</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>z</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Redo</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>y</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>y</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Heading 1</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>option ⌥</kbd> + <kbd>1</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>alt</kbd> + <kbd>1</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Heading 2</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>option ⌥</kbd> + <kbd>2</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>alt</kbd> + <kbd>2</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Heading 3</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>option ⌥</kbd> + <kbd>3</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>alt</kbd> + <kbd>3</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Paragraph</TableCell>
                    <TableCell>
                      <kbd>cmd ⌘</kbd> + <kbd>option ⌥</kbd> + <kbd>0</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>ctrl</kbd> + <kbd>alt</kbd> + <kbd>0</kbd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>New line in formatting</TableCell>
                    <TableCell>
                      <kbd>shift ⇧</kbd> + <kbd>enter ↵</kbd>
                    </TableCell>
                    <TableCell>
                      <kbd>shift ⇧</kbd> + <kbd>enter ↵</kbd>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      default:
        return null;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    error.current = false;
    errorMessage.current = '';
    setLinkActive(false);
    link.current = '';
    text.current = '';
  };

  const handleSubmit = React.useCallback(() => {
    const l = link.current;
    const t = text.current;
    if (!isUrl(l) || !l) {
      error.current = true;
      errorMessage.current = 'Enter a valid URL';
      return;
    }
    error.current = false;
    errorMessage.current = '';
    insertLink(editor, l, t ? t : l);
    setAnchorEl(null);
  }, [link, text, editor, insertLink]);

  return (
    <Box className={classes.box} {...rest}>
      <Box display="flex" flex={1}>
        <StyledToggleButtonGroup size="small" exclusive aria-label="management">
          <Tooltip title="undo">
            <StyledButton value="undo" aria-label="undo" onClick={handleUndo}>
              <UndoIcon />
            </StyledButton>
          </Tooltip>
          <Tooltip title="redo">
            <StyledButton value="redo" aria-label="redo" onClick={handleRedo}>
              <RedoIcon />
            </StyledButton>
          </Tooltip>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup size="small" exclusive aria-label="management">
          <Tooltip title="heading 1">
            <ToggleButton
              value="heading-one"
              aria-label="heading one"
              selected={activeBlock(editorHook, 'heading-one')}
              onMouseDown={e => handleBlock(e)}
              className={classes.button}
            >
              <H1 className={classes.svg} />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="heading 2">
            <ToggleButton
              value="heading-two"
              aria-label="heading two"
              selected={activeBlock(editorHook, 'heading-two')}
              onMouseDown={e => handleBlock(e)}
              className={classes.button}
            >
              <H2 className={classes.svg} />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="heading 3">
            <ToggleButton
              value="heading-three"
              aria-label="heading three"
              selected={activeBlock(editorHook, 'heading-three')}
              onMouseDown={e => handleBlock(e)}
              className={classes.button}
            >
              <H3 className={classes.svg} />
            </ToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup size="small" aria-label="text formatting">
          <Tooltip title="bold">
            <ToggleButton
              value="bold"
              aria-label="bold"
              selected={activeMark(editorHook, 'bold')}
              onMouseDown={e => handleMark(e)}
            >
              <FormatBoldIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="italic">
            <ToggleButton
              value="italic"
              aria-label="italic"
              selected={activeMark(editorHook, 'italic')}
              onMouseDown={e => handleMark(e)}
            >
              <FormatItalicIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="underline">
            <ToggleButton
              value="underline"
              aria-label="underline"
              selected={activeMark(editorHook, 'underline')}
              onMouseDown={e => handleMark(e)}
            >
              <FormatUnderlinedIcon />
            </ToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup size="small" aria-label="lists">
          <Tooltip title="bulleted list">
            <ToggleButton
              value="bulleted-list"
              aria-label="bulleted list"
              selected={activeBlock(editorHook, 'bulleted-list')}
              onMouseDown={e => handleBlock(e)}
            >
              <FormatListBulletedIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="numbered list">
            <ToggleButton
              value="numbered-list"
              aria-label="numbered list"
              selected={activeBlock(editorHook, 'numbered-list')}
              onMouseDown={e => handleBlock(e)}
            >
              <FormatListNumberedIcon />
            </ToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" className={classes.divider} />
        <StyledToggleButtonGroup size="small" aria-label="lists">
          <Tooltip title="blockquote">
            <ToggleButton
              value="block-quote"
              aria-label="block quote"
              selected={activeBlock(editorHook, 'block-quote')}
              onMouseDown={e => handleBlock(e)}
            >
              <FormatQuoteIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="link">
            <ToggleButton
              value="link"
              aria-label="link"
              onMouseDown={e => handlePopover(e, 'link')}
              selected={linkActive}
            >
              <LinkIcon />
            </ToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
      </Box>
      {!mobile && (
        <Box>
          <IconButton onMouseDown={e => handlePopover(e, 'help')}>
            <HelpOutlineIcon />
          </IconButton>
        </Box>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>{content}</Box>
      </Popover>
    </Box>
  );
};

export default SlateToolbar;
