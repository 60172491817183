import * as firebase from 'firebase/app';
import 'firebase/firestore';

import Fluit from 'src/types/Fluit';
import { initalise } from 'src/constants/init';

export const UserReducer = (state: Fluit.users.User, action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'USER_SET':
      let currentUser = { ...action.payload };
      currentUser.created_at = firebase.firestore.Timestamp.now();
      currentUser.updated_at = firebase.firestore.Timestamp.now();
      return { ...action.payload };
    case 'USER_FLUSH':
      return initalise.user;
    default:
      return { ...state };
  }
};
