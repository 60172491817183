import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import NoteList from 'src/components/Notes/NoteList';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { NotesFirebase } from 'src/services/notes';
import Fluit from 'src/types/Fluit';

const Notes: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { notes, organisation } = state;
  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [results, setResults] = React.useState<Fluit.notes.Note[]>([]);
  const [init, setInit] = React.useState(false);
  const history = useHistory();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const listNotes = React.useCallback(async () => {
    try {
      setLoading(true);
      const result = await NotesFirebase.list(organisation.id);
      dispatch({ type: 'NOTES_LIST', payload: result });
      setResults(_.orderBy(result, ['updated_at.seconds', 'created_at.seconds'], ['asc', 'asc']));
      setLoading(false);
      setInit(true);
    } catch (error) {
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [organisation.id, dispatch, setToast]);

  React.useEffect(() => {
    listNotes();
  }, [listNotes]);

  const CreateNote = () => {
    history.push(ROUTES.NOTE_CREATE);
  };

  React.useEffect(() => {
    if (init) {
      if (filter === '') {
        setResults(notes);
      } else {
        setResults(_.filter(notes, { parent_type: filter }));
      }
    }
  }, [filter, init, notes]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
          <Typography variant="h1">Notes</Typography>
          <Box display="flex">
            <FormControl variant="outlined" style={{ width: 180, marginRight: 16 }}>
              <InputLabel id="filter-notes">Filter</InputLabel>
              <Select
                labelId="filter-notes"
                id="filter-select-notes"
                label="Filter"
                value={filter}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => setFilter(e.target.value as string)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="organisation">Organisation</MenuItem>
                <MenuItem value="deal">Deals</MenuItem>
                <MenuItem value="investor">Investors</MenuItem>
                <MenuItem value="contact">Contacts</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => CreateNote()}
              size="large"
              style={{ height: '100%' }}
            >
              New {!mobile && 'Note'}
            </Button>
          </Box>
        </Box>
      </Grid>

      {loading && (
        <Grid item xs={12}>
          <GenericLoader variant="none" height="100%" />
        </Grid>
      )}

      {!loading && <NoteList notes={results} createNote={() => CreateNote()} />}
    </Grid>
  );
};

export default Notes;
