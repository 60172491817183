import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { initalise } from 'src/constants/init';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { DealFirebase } from 'src/services/deals';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const DealDeleteForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const classes = useStyles();
  const { deal, setDeal } = React.useContext(DealContext);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const removeDeal = React.useCallback(async () => {
    setLoading(true);
    try {
      await DealFirebase.delete(deal);
      setToast({
        message: `${deal.name} deleted`,
        type: 'info',
      });
      history.push(ROUTES.DEALS);
      dispatch({ type: 'DEAL_DELETE', payload: deal });
      dispatch({ type: 'COUNT_DOWN_DEALS' });
      setDeal(initalise.deal);
      dispatch({
        type: 'MODAL',
        payload: {
          ...state.ui.modal,
          open: false,
        },
      });
    } catch (error) {
      setLoading(false);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [state.ui.modal, dispatch, setLoading, setToast, deal, history, setDeal]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">
          This will permantly delete the deal, including it's process, progress, activity, notes and tasks.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => removeDeal()}
          disabled={loading ? true : false}
        >
          Delete
        </Button>
        <Button
          variant="text"
          style={{ marginLeft: '16px' }}
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default DealDeleteForm;
