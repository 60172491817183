import { Box, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import colors from 'src/themes/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    note: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,

      '& h1': {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: 1.5,
        color: colors.navy[900],
      },
      '& h2': {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: 1.5,
        color: colors.navy[900],
      },
      '& h3': {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.5,
        color: colors.navy[900],
      },
      '& blockquote': {
        borderLeft: `4px solid ${colors.grey[100]}`,
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2, 4, 2, 2),
        borderRadius: theme.spacing(0, 1, 1, 0),
        fontSize: 16,
        lineHeight: 1.5,
        color: colors.grey[600],
        background: colors.grey[1],
        fontWeight: 500,
      },
      '& ul, & ol': {
        margin: theme.spacing(2, 0),
      },
      '& a': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        fontWeight: 600,
      },
    },
  })
);

interface Props {
  contents: string;
}

const NoteHTML: React.FC<Props> = ({ contents }) => {
  const classes = useStyles();
  if (contents === '') return null;
  const parsed = JSON.parse(contents);

  const note_html: any = _.map(parsed, n => {
    const children = _.map(n.children, c => {
      let child = c.text;
      /* Handle Line Breaks */
      if (_.includes(c.text, '\n')) {
        let arr = child.split('\n');
        child = _.map(arr, a => {
          a.replace(/(?:\r\n|\r|\n)/g, '');
          if (a.length > 0) {
            return (
              <span key={_.uniqueId()}>
                {a}
                <br />
              </span>
            );
          }
        });
      }
      if (c.underline) {
        child = <u key={_.uniqueId()}>{child}</u>;
      }
      if (c.bold) {
        child = <b key={_.uniqueId()}>{child}</b>;
      }
      if (c.italic) {
        child = <i key={_.uniqueId()}>{child}</i>;
      }
      if (c.type === 'link') {
        let text = _.map(c.children, c => c.text);
        child = (
          <Tooltip key={_.uniqueId()} title={c.url}>
            <a href={c.url} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
          </Tooltip>
        );
      }
      if (c.type === 'list-item') {
        let text = _.map(c.children, c => c.text);
        child = <li key={_.uniqueId()}>{text}</li>;
      }
      return child;
    });

    switch (n.type) {
      case 'heading-one':
        return <h1 key={_.uniqueId()}>{children}</h1>;
      case 'heading-two':
        return <h2 key={_.uniqueId()}>{children}</h2>;
      case 'heading-three':
        return <h3 key={_.uniqueId()}>{children}</h3>;
      case 'paragraph':
        return <p key={_.uniqueId()}>{children}</p>;
      case 'block-quote':
        return <blockquote key={_.uniqueId()}>{children}</blockquote>;
      case 'bulleted-list':
        return <ul key={_.uniqueId()}>{children}</ul>;
      case 'numbered-list':
        return <ol key={_.uniqueId()}>{children}</ol>;
    }
  });

  return note_html ? <Box className={classes.note}>{note_html}</Box> : null;
};

export default NoteHTML;
