import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { OrganisationFirebase } from 'src/services/organisations';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const UserDeleteForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;
  const { setToast } = React.useContext(ToastContext);
  const classes = useStyles();
  const user = _.find(state.members, user => user.id === state.ui.modal.user_id);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Fluit.firestore.Error | undefined>(undefined);

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  const removeUser = React.useCallback(async () => {
    setLoading(true);
    try {
      await OrganisationFirebase.removeMember(organisation.id, String(state.ui.modal.user_id));
      setToast({
        message: `${user?.first_name ? `${user?.first_name} ${user?.last_name}` : `${user?.email}`} removed from ${
          organisation.name
        }`,
        type: 'info',
      });
      dispatch({ type: 'MEMBER_DELETE', payload: user });
      setLoading(false);
      dispatch({
        type: 'MODAL',
        payload: {
          ...state.ui.modal,
          open: false,
        },
      });
    } catch (error) {
      setLoading(false);
      const err: Fluit.firestore.Error = error;
      setError(err);
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [state.ui.modal, dispatch, setLoading, setToast, organisation, user]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">
          {user?.first_name ? `${user?.first_name} ${user?.last_name}` : user?.email} will no longer be able to access
          deals, investors and all associated information for {state.organisation.name}.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => removeUser()}
          disabled={loading ? true : false}
        >
          Remove
        </Button>
        <Button
          variant="text"
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
          style={{ marginLeft: '16px' }}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
      {error && (
        <Grid item xs={12}>
          <Alert severity="info">
            {error.name} {error.message}{' '}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default UserDeleteForm;
