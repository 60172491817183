import { Box, Container, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';
import Footer from 'src/components/Layout/Footer';
import HeaderSite from 'src/components/Layout/HeaderSite';
import Placeholder from 'src/components/Loaders/FluitPlaceHolder';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto 1',
  },
  container: {
    marginTop: '80px',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(12),
      alignItems: 'flex-start',
    },
  },
}));

const LayoutSite: React.FC<RouteProps> = props => {
  const location = useLocation();
  const classes = useStyles();
  const { state } = React.useContext(AppContext);
  const { children } = props;
  const { from }: any = location.state || { from: { pathname: ROUTES.DEALS } };

  if (state.init.user && state.init.initalized) {
    return <Redirect to={from} />;
  }

  if (state.init.loading) {
    return <Placeholder />;
  }

  return (
    <Box className={classes.box}>
      <HeaderSite />
      <Container maxWidth="md" className={classes.container}>
        {children as any}
      </Container>
      <Container maxWidth="md">
        <Footer md={true} />
      </Container>
    </Box>
  );
};

export default LayoutSite;
