import Stripe, { subscriptions } from 'stripe';
import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const SubscriptionsReducer = (
  state: Stripe.subscriptions.ISubscription[],
  action: Fluit.reducer.actions.Actions
) => {
  switch (action.type) {
    case 'SUBSCRIPTIONS_LIST':
      return action.payload;
    case 'SUBSCRIPTION_UPDATE':
      const REMOVE_UPDATED = _.filter(state, sub => sub.id !== action.payload.id);
      const MERGE_GROUP = _.concat(REMOVE_UPDATED, action.payload);
      return MERGE_GROUP;
    /* FLUSH */
    case 'SUBSCRIPTIONS_FLUSH':
      return [];
    default:
      return state;
  }
};
