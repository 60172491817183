import {
  Box,
  Button,
  createStyles,
  Divider,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import ContactDisplay from 'src/components/Displays/Contact';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import NoteHTML from 'src/components/Notes/NoteHtml';
import { AppContext } from 'src/contexts/AppContext';
import { firebaseDateToIso } from 'src/helpers/firebaseDateToIso';
import { formatDate } from 'src/helpers/formatDate';
import * as ROUTES from 'src/routes/routes';
import { Deals, DotsHorizontal, Investors } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    note: {
      height: 'auto',
      maxHeight: 200,
      overflow: 'hidden',
      position: 'relative',
    },
    mask: {
      position: 'absolute',
      bottom: 0,
      height: 100,
      width: '100%',
      background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%)',
    },
    svgInline: {
      width: '14px',
      height: '14px',
      marginRight: theme.spacing(0.5),
      fill: theme.palette.grey[200],
      '& g, & path': {
        fill: theme.palette.grey[200],
      },
    },
    attribute: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover svg, &:hover svg g, &:hover svg path': {
        fill: theme.palette.secondary.main,
      },
    },
    svg: {
      width: '20px',
      height: '20px',
      fill: theme.palette.grey[400],
      '& g, & path': {
        fill: theme.palette.grey[400],
      },
    },
  })
);

interface Props {
  note: Fluit.notes.Note;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>, content: JSX.Element) => void;
  handlePopoverClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleMenu: (event: React.MouseEvent<HTMLButtonElement>, task_id: string) => void;
}

const NoteCard: React.FC<Props> = ({ note, handlePopoverOpen, handlePopoverClose, handleMenu }) => {
  const classes = useStyles();
  const { state } = React.useContext(AppContext);
  const { organisation, investors, deals, contacts } = state;

  const contact = _.find(contacts, { id: note.child_id });

  const contactInfo = <Box>{contact ? <ContactDisplay data={contact} showInvestor /> : null}</Box>;

  const parent_display = () => {
    if (note.parent_type === 'deal') {
      let data = _.find(deals, { id: note.parent_id });
      if (data) {
        return (
          <Typography variant="body1">
            <Tooltip title={`View ${data.name}`}>
              <Link
                component={NavLink}
                to={`${ROUTES.DEALS}/${data.id}`}
                color="secondary"
                className={classes.attribute}
              >
                <Deals className={classes.svgInline} /> {data.name}
              </Link>
            </Tooltip>
          </Typography>
        );
      } else {
        return <Typography variant="body1">-</Typography>;
      }
    }
    if (note.parent_type === 'investor') {
      let data = _.find(investors, { id: note.parent_id });
      if (data) {
        return (
          <Typography variant="body1">
            <Tooltip title={`View ${data.name}`}>
              <Link
                component={NavLink}
                to={`${ROUTES.INVESTORS}/${data.id}`}
                color="secondary"
                className={classes.attribute}
              >
                <Investors className={classes.svgInline} /> {data.name}
              </Link>
            </Tooltip>
          </Typography>
        );
      } else {
        return <Typography variant="body1">-</Typography>;
      }
    }
    if (note.parent_type === 'contact') {
      let data = _.find(contacts, { id: note.child_id });
      if (data) {
        return (
          <Typography variant="body1">
            <Link
              color="secondary"
              className={classes.attribute}
              onMouseEnter={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handlePopoverOpen(e, contactInfo)}
              onMouseLeave={handlePopoverClose}
            >
              <Investors className={classes.svgInline} /> {data.first_name} {data.last_name}
            </Link>
          </Typography>
        );
      } else {
        return <Typography variant="body1">-</Typography>;
      }
    }
    if (note.parent_type === 'organisation') {
      return <Typography variant="body1">{organisation.name}</Typography>;
    }
  };

  return (
    <Paper>
      {note.system?.delete ? (
        <Box display="flex" justifyContent="center" width="100%">
          <GenericLoader height="100%" variant="none" />
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="row" alignItems="center" p={3}>
            <Box display="flex" flexDirection="row" flexWrap="wrap" flex={1}>
              <Box mr={4}>
                <Typography variant="overline">
                  <small>Published on</small>
                </Typography>
                <Typography variant="body1">{formatDate(firebaseDateToIso(note.created_at))}</Typography>
              </Box>
              <Box mr={4}>
                <Typography variant="overline">
                  <small>Published by</small>
                </Typography>
                <Typography variant="body1">{note.creator_name}</Typography>
              </Box>

              {note.updated_at && (
                <>
                  <Box mr={4}>
                    <Typography variant="overline">
                      <small>Updated on</small>
                    </Typography>
                    <Typography variant="body1">{formatDate(firebaseDateToIso(note.updated_at))}</Typography>
                  </Box>
                  {note.updated_by !== note.created_by ? (
                    <Box mr={4}>
                      <Typography variant="overline">
                        <small>Updated by</small>
                      </Typography>
                      <Typography variant="body1">{note.updator_name}</Typography>
                    </Box>
                  ) : null}
                </>
              )}

              <Box mr={4}>
                <Typography variant="overline">
                  <small>Attached to</small>
                </Typography>
                {parent_display()}
              </Box>
            </Box>

            <Box>
              <IconButton aria-label="menu" onClick={e => handleMenu(e, note.id)}>
                <DotsHorizontal className={classes.svg} />
              </IconButton>
            </Box>
          </Box>

          <Divider light={true} />
          <Box p={3}>
            <Typography variant="h2">{note.title}</Typography>
            <Box className={classes.note} mb={3}>
              <NoteHTML contents={note.contents} />
              <Box className={classes.mask} />
            </Box>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              component={NavLink}
              to={`${ROUTES.NOTES}/${note.id}`}
            >
              Read Note
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default NoteCard;
