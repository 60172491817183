import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Grid, InputLabel, LinearProgress, makeStyles, MenuItem, Theme } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, Formik } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';
import _ from 'lodash';
import React from 'react';
import AutocompleteContactsbyInvestorforEvents from 'src/components/Inputs/Autocomplete/ContactsbyInvestorforEvents';
import FormikTextField from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { UserActivityContext } from 'src/contexts/UserActivityContext';
import { ActivityFirebase } from 'src/services/activities';
import colors from 'src/themes/colors';
import { Email, Telephone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: 24,
    height: 24,
    fill: colors.grey[500],
    '& g, & path': {
      fill: colors.grey[500],
    },
  },
  toggle: {
    '& .Mui-selected': {
      background: theme.palette.secondary.main,
    },
    '& button': {
      '&:hover': {
        backgroundColor: colors.blue[100],
        '&.Mui-selected': {
          background: theme.palette.secondary.main,
        },
      },
      '&.Mui-selected': {
        background: theme.palette.secondary.main,
        '& svg, & g, & path': {
          fill: colors.common.white,
        },
      },
    },
  },
}));

const EventEdit: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { deals } = state;
  const { setToast } = React.useContext(ToastContext);
  const activity = state.ui.modal.activity as Fluit.activities.Activity;
  const { dispatchUserActivities } = React.useContext(UserActivityContext);

  const classes = useStyles();

  /* Handle Modal */
  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        initialValues={{
          id: activity.id,
          org_id: activity.org_id,

          event: {
            action: activity.event.action,
            details: activity.event.details,
            date: activity.event.date ? activity.event.date : new Date().toISOString(),
            source: activity.event.source,
          },

          target: {
            id: activity.target.id,
            type: activity.target.type,
            parent: {
              id: activity.target.parent ? activity.target.parent.id : '',
              type: activity.target.parent?.type,
            },
          },

          metadata: {
            deal_id: activity.metadata?.deal_id,
            deal_name: activity.metadata?.deal_name,
            name: activity.metadata?.name,
            investor_name: activity.metadata?.investor_name,
            contact_method: activity.metadata?.contact_method,
            document_name: activity.metadata?.document_name,
            document_url: activity.metadata?.document_url,
          },

          creator_name: activity.creator_name ? activity.creator_name : '',
          updater_name: activity.updater_name ? activity.updater_name : '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          const deal = _.find(deals, { id: values.metadata.deal_id });
          values.metadata.deal_name = deal ? deal.name : '';

          try {
            const result = await ActivityFirebase.update(values);
            dispatchUserActivities({ type: 'ACTIVITY_USER_UPDATE', payload: result });
            setToast({
              message: 'Event logged',
              type: 'success',
            });
            actions.setSubmitting(false);
            closeModal();
          } catch (error) {
            actions.setSubmitting(false);
            const err = error as Fluit.firestore.Error;
            setToast({
              message: `${err.name} | ${err.message}`,
              type: 'error',
            });
          }
        }}
        validationSchema={Yup.object().shape({
          event: Yup.object().shape({
            action: Yup.string()
              .required()
              .oneOf(['contacted', 'meeting', 'document'], 'Select a value'),
            details: Yup.string(),
          }),
          target: Yup.object().shape({
            id: Yup.string().required('You must log against an investor or contact'),
          }),
        })}
      >
        {props => {
          const { isSubmitting, handleSubmit, setFieldValue, errors, touched, values } = props;

          const handleContactMethod = (event: React.MouseEvent<HTMLElement>, method: string | null) => {
            if (method !== null) {
              setFieldValue('metadata.contact_method', method);
            }
          };

          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    select
                    name="event.action"
                    label="Event Type"
                    variant="outlined"
                  >
                    <MenuItem value="contacted">Contacted</MenuItem>
                    <MenuItem value="meeting">Meeting</MenuItem>
                    <MenuItem value="document">Document Received</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteContactsbyInvestorforEvents
                    setFieldValue={setFieldValue}
                    label={
                      values.event.action === 'meeting'
                        ? 'With'
                        : values.event.action === 'contacted'
                        ? 'Who'
                        : values.event.action === 'document'
                        ? 'From'
                        : 'Investor or Contact'
                    }
                    name="target.id"
                    touched={touched.target?.id}
                    error={errors.target?.id}
                    value={values.target.id}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Field
                      component={FormikTextField}
                      fullWidth={true}
                      select
                      name="metadata.deal_id"
                      label="Regarding Deal"
                      variant="outlined"
                    >
                      <MenuItem value="">None</MenuItem>
                      {_.map(deals, deal => (
                        <MenuItem key={_.uniqueId()} value={deal.id}>
                          {deal.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>

                {values.event.action === 'contacted' && (
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Box mr={2}>
                        <InputLabel>By</InputLabel>
                      </Box>
                      <ToggleButtonGroup
                        className={classes.toggle}
                        value={values.metadata.contact_method}
                        exclusive
                        onChange={handleContactMethod}
                        aria-label="contact method"
                      >
                        <ToggleButton value="email" aria-label="contacted by email">
                          <Email className={classes.svg} />
                        </ToggleButton>
                        <ToggleButton value="phone" aria-label="contacted by phone">
                          <Telephone className={classes.svg} />
                        </ToggleButton>
                        <ToggleButton value="linkedin" aria-label="contacted by linkedin">
                          <LinkedInIcon className={classes.svg} style={{ width: 30, height: 30 }} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Grid>
                )}

                {values.event.action === 'document' && (
                  <>
                    <Grid item xs={12}>
                      <Field
                        component={FormikTextField}
                        fullWidth={true}
                        name="metadata.document_name"
                        label="Document Name"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={FormikTextField}
                        fullWidth={true}
                        name="metadata.document_url"
                        label="Document Link"
                        type="url"
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Field
                    component={DatePicker}
                    name="event.date"
                    label="On"
                    fullWidth
                    autoOk
                    disableFuture
                    format="iii, do MMM yyyy"
                    inputVariant="outlined"
                    variant="inline"
                    onAccept={(date: Date) => {
                      setFieldValue('event.date', date.toISOString());
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    multiline
                    rows={4}
                    name="event.details"
                    label="Event Details"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                    Log
                  </Button>
                  <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                    Cancel
                  </Button>
                </Grid>
                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress variant="query" />
                  </Grid>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default EventEdit;
