import { Box, Button, Chip, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import { ProductDeals, ProductUsers } from 'src/constants/products';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import { BillingFirebase } from 'src/services/billing';
import { styles_billingUnitInverval } from 'src/styles/billing';
import Stripe from 'stripe';
import BillingUnitsProduct from './Product';

const BillingUnits: React.FC = () => {
  const intervalClasses = styles_billingUnitInverval();
  const { setToast } = React.useContext(ToastContext);

  const { state, dispatch } = React.useContext(AppContext);
  const { billing, ui } = state;
  const { modal } = ui;

  /* Modal Values */
  const subscription_id = String(modal.subscriptionId);
  const subscription_items = modal.subscriptionItems as Stripe.subscriptionItems.ISubscriptionItem[];
  const subscription_cycle = subscription_items[0].plan.interval;

  /* Stripe Config Product */
  const products = {
    users: ProductUsers,
    deals: ProductDeals,
  };

  /* States */
  const [interval, setInterval] = React.useState(subscription_cycle);
  const [quantity, setQuantity] = React.useState({
    users: subscription_items[0].quantity ? subscription_items[0].quantity : state.organisation.limits.members.limit,
    deals: subscription_items[1].quantity ? subscription_items[1].quantity : state.organisation.limits.deals.limit,
  });
  const [qEnd, setQEnd] = React.useState({
    users: subscription_items[0].quantity ? subscription_items[0].quantity : state.organisation.limits.members.limit,
    deals: subscription_items[1].quantity ? subscription_items[1].quantity : state.organisation.limits.deals.limit,
  });
  const [total, setTotal] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);
  const [iLoading, setILoading] = React.useState(true);
  const [exit, setExit] = React.useState(false);

  // * Set Product Items & Quantity
  const handleItems = React.useCallback(() => {
    const products = {
      users: ProductUsers,
      deals: ProductDeals,
    };

    const subscription_items = modal.subscriptionItems as Stripe.subscriptionItems.ISubscriptionItem[];

    if (interval === 'month') {
      return [
        {
          // * Users
          price: products.users.standard.month.price_id,
          quantity: qEnd.users,
          id: subscription_items[0].id,
        },
        {
          // * Deals
          price: products.deals.fundraise.month.price_id,
          quantity: qEnd.deals,
          id: subscription_items[1].id,
        },
      ];
    }
    return [
      {
        // * Users
        price: products.users.standard.year.price_id,
        quantity: qEnd.users,
        id: subscription_items[0].id,
      },
      {
        // * Deals
        price: products.deals.fundraise.year.price_id,
        quantity: qEnd.deals,
        id: subscription_items[1].id,
      },
    ];
  }, [interval, qEnd, modal.subscriptionItems]);

  const getInvoice = React.useCallback(async () => {
    let customer_country = billing.customer?.address?.country;
    if (customer_country === '') {
      customer_country = state.organisation.location.iso;
    }
    const tax_rate = customer_country === 'gb' ? 1.2 : 1;
    const proration_date = Math.floor(Date.now() / 1000);
    setILoading(true);
    const items = handleItems();
    const proration = {
      customer: billing.stripe_customer_id,
      subscription: subscription_id,
      subscription_items: items,
      subscription_proration_date: proration_date,
    };
    const inv: Stripe.invoices.IInvoice = await BillingFirebase.upcomingInvoice(proration);
    const current_prorations = [];
    var cost = 0;
    for (var i = 0; i < inv.lines.data.length; i++) {
      const invoice_item = inv.lines.data[i];
      if (invoice_item.period.start - proration_date <= 1) {
        current_prorations.push(invoice_item);
        cost += invoice_item.amount;
      }
    }
    setTotal((cost * tax_rate) / 100);
    setILoading(false);
  }, [billing.stripe_customer_id, subscription_id, handleItems, billing.customer, state.organisation.location.iso]);

  React.useEffect(() => {
    if (exit) {
      return;
    }
    getInvoice();
  }, [getInvoice, exit, qEnd, interval]);

  const handleCost = React.useCallback(
    (interval: 'month' | 'year') => {
      if (interval === 'month') {
        if (quantity.deals > 0) {
          return (
            <>
              <Typography>{formatCurrency(products.users.standard.month.price)} / user</Typography>
              <Typography>{formatCurrency(products.deals.fundraise.month.price)} / deal</Typography>
            </>
          );
        }
        return <Typography>{formatCurrency(products.users.standard.month.price)} / user</Typography>;
      } else {
        if (quantity.deals > 0) {
          return (
            <>
              <Typography>{formatCurrency(products.users.standard.year.price / 12)} / user</Typography>
              <Typography>{formatCurrency(products.deals.fundraise.year.price / 12)} / deal</Typography>
            </>
          );
        }
        return <Typography>{formatCurrency(products.users.standard.year.price / 12)} / user</Typography>;
      }
    },
    [quantity, products]
  );

  const calculateSaving = React.useCallback(() => {
    const month =
      quantity.users * products.users.standard.month.price +
      (quantity.deals - 1) * products.deals.fundraise.month.price;
    const year =
      quantity.users * products.users.standard.year.price + (quantity.deals - 1) * products.deals.fundraise.year.price;
    return `save: ${formatCurrency(month * 12 - year)}`;
  }, [products, quantity]);

  const handleInterval = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, val: 'year' | 'month') => {
      setInterval(val);
    },
    [setInterval]
  );

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...modal,
        open: false,
      },
    });
  }, [dispatch, modal]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const update = {
        items: handleItems(),
      };
      const result = await BillingFirebase.updatePaymentSubscription(subscription_id, update);
      setExit(true);
      dispatch({ type: 'SUBSCRIPTION_UPDATE', payload: result });
      setToast({
        message: 'Successfully purchased',
        type: 'success',
      });
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
      setToast({
        message: error,
        type: 'error',
      });
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={interval}
          exclusive
          onChange={handleInterval}
          aria-label="interval"
          className={intervalClasses.toggle}
        >
          <ToggleButton
            value="month"
            aria-label="monthly billing"
            classes={{
              root: intervalClasses.toggleButton,
              label: intervalClasses.toggleLabel,
              selected: intervalClasses.toggleSelected,
            }}
          >
            <Typography variant="h4">Monthly</Typography>
            {handleCost('month')}
          </ToggleButton>
          <ToggleButton
            value="year"
            aria-label="yearly billing"
            classes={{
              root: intervalClasses.toggleButton,
              label: intervalClasses.toggleLabel,
              selected: intervalClasses.toggleSelected,
            }}
          >
            <Typography variant="h4">Yearly</Typography>
            <Box display="flex" flexDirection="column">
              {handleCost('year')}
              <Chip className={intervalClasses.chip} color="primary" size="small" label={calculateSaving()} />
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <BillingUnitsProduct
          product={products.users.standard}
          type="users"
          quantity={quantity}
          setQuantity={setQuantity}
          interval={interval}
          setQEnd={setQEnd}
        />
      </Grid>
      <Grid item xs={12}>
        <BillingUnitsProduct
          product={products.deals.fundraise}
          type="deals"
          quantity={quantity}
          setQuantity={setQuantity}
          interval={interval}
          setQEnd={setQEnd}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Box p={3}>
            {total < 0 ? (
              <Typography>
                We will credit your fluit account <strong>{formatCurrency(total * -1)}</strong> today.
              </Typography>
            ) : (
              <Typography>
                Your card will be charged <strong>{formatCurrency(total)}</strong> today.
              </Typography>
            )}
          </Box>
          <Box height={4}>{iLoading && <LinearProgress variant="query" />}</Box>
        </Paper>
      </Grid>

      {quantity.deals <
        (subscription_items[1].quantity ? subscription_items[1].quantity : state.organisation.limits.deals.limit) && (
        <Grid item xs={12}>
          <Paper>
            <Alert severity="error">By downgrading your deals, your oldest deal will be deleted automatically.</Alert>
          </Paper>
        </Grid>
      )}

      <Grid item xs={12}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          disabled={loading}
          onClick={() => handleSubmit()}
          style={{ marginRight: '16px' }}
        >
          Confirm Change
        </Button>
        <Button color="default" size="large" onClick={() => closeModal()}>
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default BillingUnits;
