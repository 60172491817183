import { Box, Link, Menu, MenuItem, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _ from 'lodash';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import React, { forwardRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { isoToFlag } from 'src/helpers/isoToFlag';
import * as ROUTES from 'src/routes/routes';
import { styles_investorTable } from 'src/styles/table';
import { DotsHorizontal } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const tableIcons = {
  Add: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ViewColumn {...props} ref={ref} />),
};

interface Row {
  id: string;
  name: string;
  location: Fluit.address.Country;
  contacts: number;
  deals: number;
  termsheets: number;
  tasks: number;
}

interface TableState {
  columns: Array<Column<Row>>;
  data: Row[];
}

const InvestorsTable: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { investors, contacts, tasks, assignments, processes, deals } = state;
  const { investor, setInvestor } = React.useContext(InvestorContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const history = useHistory();
  const classes = styles_investorTable();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const tableData = _.map(investors, investor => {
    const contact = _.filter(contacts, { investor_id: investor.id });
    const contacts_tasks = _.filter(tasks, function(task) {
      _.map(contact, contact => contact.id === task.parent_id);
    });
    const investor_tasks = _.filter(tasks, { parent_id: investor.id, completed: false });
    const assignedTo = _.filter(assignments, { investor_id: investor.id });
    const inProcesses = _.map(assignedTo, assignment => _.find(processes, { id: assignment.process_id }));
    const filterProcess = _.filter(inProcesses, _.size) as Fluit.process.Process[];
    const investorDeals = _.map(filterProcess, process => _.find(deals, { id: process?.deal_id }));
    const termsheets_count = _.filter(state.termsheets, { investor_id: investor.id }).length;

    const data: Row = {
      id: investor.id,
      name: investor.name,
      location: investor.address.country,
      contacts: contact.length,
      deals: investorDeals.length,
      termsheets: termsheets_count,
      tasks: contacts_tasks.length + investor_tasks.length,
    };
    return data;
  });

  const [data] = React.useState<TableState>({
    columns: [
      {
        title: 'Name',
        field: 'name',
        render: rowData => (
          <Typography>
            <Tooltip title={`View ${rowData.name}`}>
              <Link component={NavLink} to={`${ROUTES.INVESTORS}/${rowData.id}`}>
                {rowData.name}
              </Link>
            </Tooltip>
          </Typography>
        ),
      },
      {
        title: 'Location',
        field: 'location',
        render: rowData => (
          <Typography variant="body2">
            <span style={{ marginRight: '4px' }}>{isoToFlag(rowData.location.iso)}</span>
            {rowData.location.name}
          </Typography>
        ),
      },
      {
        width: 100,
        title: 'Contacts',
        field: 'contacts',
        type: 'numeric',
        render: rowData => (
          <Typography variant="body2" align="right">
            {rowData.contacts}
          </Typography>
        ),
      },
      {
        width: 100,
        title: 'Deals',
        field: 'deals',
        type: 'numeric',
        render: rowData => (
          <Typography variant="body2" align="right">
            {rowData.deals}
          </Typography>
        ),
      },
      {
        width: 100,
        title: 'Termsheets',
        field: 'termsheets',
        type: 'numeric',
        render: rowData => (
          <Typography variant="body2" align="right">
            {rowData.termsheets}
          </Typography>
        ),
      },
      {
        width: 100,
        title: 'Tasks',
        field: 'tasks',
        type: 'numeric',
        render: rowData => (
          <Typography variant="body2" align="right">
            {rowData.tasks}
          </Typography>
        ),
      },
    ],
    data: tableData,
  });

  const removeInvestor = () => {
    setAnchorEl(null);
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Permantly delete ${investor?.name}?`,
        component: 'INVESTOR_DELETE',
        investor_id: investor?.id,
      },
    });
  };

  return (
    <>
      <MaterialTable
        title="Editable Example"
        columns={data.columns}
        data={data.data}
        icons={tableIcons}
        options={{
          search: true,
          showTitle: false,
          pageSize: 10,
          searchFieldVariant: 'outlined',
          draggable: false,
          searchFieldAlignment: 'right',
          actionsColumnIndex: -1,
          actionsCellStyle: {
            paddingRight: 3 * 8,
          },
          headerStyle: {
            borderRadius: '4px 4px 0px 0px',
          },
          searchFieldStyle: {
            width: '480px',
            maxWidth: '100%',
            marginRight: mobile ? 0 : 24,
          },
        }}
        actions={[
          {
            icon: () => <VisibilityIcon className={classes.actionIcon} />,
            tooltip: 'View Investor',
            onClick: (event, rowData) => {
              const data = rowData as Row;
              const inv = _.find(investors, { id: data.id });
              setInvestor(inv as Fluit.investors.Investor);
              history.push(`${ROUTES.INVESTORS}/${data.id}`);
            },
          },
          {
            icon: () => <DotsHorizontal className={classes.actionIcon} />,
            tooltip: 'Actions',
            onClick: (event, rowData) => {
              const data = rowData as Row;
              const inv = _.find(investors, { id: data.id });
              setInvestor(inv as Fluit.investors.Investor);
              setAnchorEl(event.currentTarget);
            },
          },
        ]}
        localization={{
          header: {
            actions: '',
          },
          toolbar: {
            searchPlaceholder: 'Search Investors',
          },
        }}
        components={{
          Toolbar: props => (
            <Box className={classes.toolbar}>
              <MTableToolbar className={classes.toolbar} {...props} />
            </Box>
          ),
        }}
      />
      <Menu
        id="investor-contextual-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={4}
      >
        <MenuItem component={NavLink} to={`investors/${investor.id}`}>
          View
        </MenuItem>
        <MenuItem onClick={() => removeInvestor()}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default InvestorsTable;
