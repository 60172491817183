import _ from 'lodash';
import React from 'react';
import { Redirect, Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import Placeholder from 'src/components/Loaders/FluitPlaceHolder';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';

const ProtectedRoute: React.FC<RouteProps> = props => {
  const { state } = React.useContext(AppContext);
  const { user, init, organisations } = state;
  const location = useLocation();
  const history = useHistory();

  if (init.initalized) {
    if (init.user === false) {
      return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location } }} />;
    }
    if (!user.email_verified) {
      return <Redirect to={ROUTES.VERIFY_EMAIL} />;
    }
    if (!_.some(organisations) || !user.is_onboarded) {
      return <Redirect to={{ pathname: ROUTES.ONBOARDING, state: { from: location } }} />;
    } else {
      const { component: Component, ...rest } = props;

      if (init.payment_failed) {
        if (rest.path === ROUTES.PROFILE || rest.path === ROUTES.ORGANISATIONS) {
        } else {
          history.push(ROUTES.ORGANISATIONS);
        }
      }
      return <Route {...rest} component={Component} />;
    }
  }

  if (init.initalized === false && init.user === false) {
    return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location } }} />;
  }

  return <Placeholder />;
};

export default ProtectedRoute;
