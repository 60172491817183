import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import { ProcessesFirebase } from 'src/services/processes';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const StepEditForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { process, setProcess } = React.useContext(ProcessContext);
  const { steps } = process;

  const oldName = String(state.ui.modal.step_name);

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        name: oldName,
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        let newSteps = _.map(steps, step => {
          if (step.name === oldName) {
            step.name = trimString(String(values.name));
          }
          return step;
        });

        try {
          const updated_process = { ...process, steps: newSteps };
          await ProcessesFirebase.update(updated_process);
          setProcess(updated_process);
          actions.setSubmitting(false);
          setToast({
            message: `Step renamed from ${oldName} to ${values.name}`,
            type: 'info',
          });
          closeModal();
        } catch (error) {
          actions.setSubmitting(false);
          const err = error as Fluit.firestore.Error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('A name is required for this step'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="name"
                  label="Step Name"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default StepEditForm;
