import { Box, Button, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import 'firebase/firestore';
import _ from 'lodash';
import * as React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Investors } from 'src/assets/images/investors.svg';
import OrganisationCard from 'src/components/Cards/Organisation';
import BillingTab from 'src/components/Tabs/Organisation/Billing';
import InvoicesTab from 'src/components/Tabs/Organisation/Invoices';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';
import { styles_OrganisationCard } from 'src/styles/cards';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Organisations: React.FC = () => {
  const classes = styles_OrganisationCard();
  let history = useHistory();
  const { state, dispatch } = React.useContext(AppContext);
  const { organisations, billing, init } = state;
  let query = useQuery();
  const currentTab = query.get('tab') ? query.get('tab') : 'organisations';
  const [tab, setTab] = React.useState(currentTab);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { admin } = init;

  const handleTabs = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTab(newTab);
    history.push(`${ROUTES.ORGANISATIONS}/?tab=${newTab}`);
  };

  const no_billing = billing.stripe_customer_id === '';

  const createOrganisation = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `New Organisation`,
        component: 'ORGANISATION_CREATE',
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box className={classes.heading}>
          <Typography variant="h1">Organisations</Typography>
          <Button variant="outlined" color="secondary" onClick={() => createOrganisation()}>
            New {!mobile && 'Organisation'}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Tabs
          value={tab}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant={!mobile ? 'standard' : 'scrollable'}
        >
          <Tab value="organisations" label="Organisations" />
          <Tab value="billing" label="Billing" />
          <Tab value="invoices" label="Invoices" />
        </Tabs>
      </Grid>

      {tab === 'organisations' &&
        _.map(organisations, org => (
          <Grid item xs={12} lg={6} xl={4} key={org.id}>
            <OrganisationCard data={org} />
          </Grid>
        ))}

      {tab === 'billing' && admin && !no_billing ? (
        <Grid item xs={12}>
          <BillingTab />
        </Grid>
      ) : null}

      {tab === 'invoices' && admin && !no_billing ? (
        <Grid item xs={12}>
          <InvoicesTab />
        </Grid>
      ) : null}

      {tab === 'billing' && no_billing ? (
        <>
          <Grid item xs={12}>
            <Investors className={classes.svg} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Once you make a purchase, billing information will go here.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="large" color="primary" component={NavLink} to={ROUTES.BUY}>
              Purchase Now
            </Button>
          </Grid>
        </>
      ) : null}

      {tab === 'invoices' && no_billing ? (
        <>
          <Grid item xs={12}>
            <Investors className={classes.svg} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Once you make a purchase, invoices will be displayed here.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="large" color="primary" component={NavLink} to={ROUTES.BUY}>
              Purchase Now
            </Button>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default Organisations;
