import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';
import FormikTextField from 'src/components/Inputs/Textfields/FormikTextField';
import { fundingRounds } from 'src/constants/funding_rounds';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  label: string;
}

const SelectFundingRounds: React.FC<Props> = ({ setFieldValue, name, id, label }) => {
  return (
    <Field
      component={FormikTextField}
      fullWidth={true}
      select
      name={name}
      id={id ? id : name}
      label={label}
      InputProps={{
        autoComplete: 'new-password',
      }}
      variant="outlined"
      onChange={(event: object, value: string) => {
        setFieldValue(name, value);
      }}
    >
      {_.map(fundingRounds, round => (
        <MenuItem key={_.uniqueId()} value={round}>
          {round}
        </MenuItem>
      ))}
    </Field>
  );
};

export default SelectFundingRounds;
