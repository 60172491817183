import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Popover,
  Select,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Alert, Timeline } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import { AppContext } from 'src/contexts/AppContext';
import { SystemActivityContext } from 'src/contexts/SystemActivityContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { UserActivityContext } from 'src/contexts/UserActivityContext';
import * as ROUTES from 'src/routes/routes';
import { ActivityFirebase } from 'src/services/activities';
import { styles_activityList } from 'src/styles/activity';
import { Activity } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';
import NoData from '../Empty';
import GenericLoader from '../Loaders/GenericLoader';
import ActivityEnd from './ActivityEnd';
import ActivityItem from './ActivityItem';
import ExampleSystemItem from './ExampleSystemItem';
import SystemItem from './SystemItem';

interface Props {
  target: 'all' | 'deal' | 'investor';
  target_field?: string;
  target_id?: string;
}

const ActivityList: React.FC<Props> = ({ target, target_field, target_id }) => {
  /* Context */
  const { state, dispatch } = React.useContext(AppContext);
  const { userActivities, dispatchUserActivities } = React.useContext(UserActivityContext);
  const { systemActivities, dispatchSystemActivities } = React.useContext(SystemActivityContext);
  const { setToast } = React.useContext(ToastContext);

  /* State */
  const [activities, setActivities] = React.useState<Fluit.activities.Activity[]>([]);
  const [activity, setActivity] = React.useState<Fluit.activities.Activity>();
  const [tab, setTab] = React.useState('user');
  const [filter, setFilter] = React.useState('none');

  /* Menu & Popover State */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [poperEl, setPoperEl] = React.useState<HTMLElement | null>(null);
  const [popEl, setPopEl] = React.useState<HTMLElement | null>(null);
  const [content, setContent] = React.useState<JSX.Element>();

  /* Destructured Values */
  const { organisation, init } = state;
  const { limits } = organisation;

  /* Cursor Values */
  const [userCursor, setUserCursor] = React.useState('');
  const [systemCursor, setSystemCursor] = React.useState('');

  /* Boolean Flags */
  const [fetching, setFetching] = React.useState(false);
  const [userMore, setUserMore] = React.useState(init.activity_user ? false : true);
  const [systemMore, setSystemMore] = React.useState(init.activity_system ? false : true);
  const [fetched, setFetched] = React.useState(false);

  /* Interface Values */
  const hit_activity_limit = limits.activities.count >= limits.activities.limit;

  /* Style Hooks */
  const classes = styles_activityList();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  /* UseEffect for Getting Last Item */
  React.useEffect(() => {
    if (!target_field && !_.some(activities)) {
      if (init.activity_user_called && tab === 'user') {
        setActivities(userActivities);
      }
      if (init.activity_system_called && tab === 'system') {
        setActivities(systemActivities);
      }
    }
  }, [
    activities,
    target_field,
    init.activity_user_called,
    init.activity_system_called,
    systemActivities,
    userActivities,
    tab,
  ]);

  /* API Call to Get Activities */
  const getActivities = React.useCallback(async () => {
    if ((tab === 'user' && init.activity_user) || (tab === 'system' && init.activity_system)) {
      return undefined;
    }

    try {
      setFetching(true);

      const result = await ActivityFirebase.list(
        organisation.id,
        tab,
        target_field,
        target_id,
        tab === 'user' ? userCursor : systemCursor
      );
      setFetched(true);

      if (!target_field && !target_id) {
        if (result.length < 25) {
          dispatch({
            type: tab === 'user' ? 'API_ACTIVITY_USER_ALL_LOADED' : 'API_ACTIVITY_SYSTEM_ALL_LOADED',
            payload: true,
          });
        }

        dispatch({
          type: tab === 'user' ? 'API_ACTIVITY_USER_CALLED' : 'API_ACTIVITY_SYSTEM_CALLED',
          payload: true,
        });
      }

      if (result.length < 25) {
        if (tab === 'user') {
          setUserMore(false);
        } else if (tab === 'system') {
          setSystemMore(false);
        }
      }

      let last = '';
      if (_.some(result)) {
        last = result[result.length - 1].id;
      }

      if (tab === 'user') {
        dispatchUserActivities({ type: 'ACTIVITIES_USER_LIST', payload: result });
        setUserCursor(last);
      } else {
        dispatchSystemActivities({ type: 'ACTIVITIES_SYSTEM_LIST', payload: result });
        setSystemCursor(last);
      }

      setFetching(false);
    } catch (error) {
      setFetching(false);
      setToast({
        message: `[Get Activities] : ${error.message}`,
        type: 'error',
      });
    }
  }, [
    dispatchSystemActivities,
    dispatchUserActivities,
    setToast,
    dispatch,
    systemCursor,
    tab,
    target_field,
    target_id,
    userCursor,
    organisation.id,
    init.activity_system,
    init.activity_user,
  ]);

  /* UseEffect for Document Fetching */
  React.useEffect(() => {
    if (
      fetching ||
      (_.some(activities) && !fetching) ||
      (!_.some(activities) && fetched && tab === 'user' ? !userMore : !systemMore)
    ) {
      return undefined;
    }
    getActivities();
  }, [fetching, tab, getActivities, activities, fetched, userMore, systemMore]);

  /* Tab Switching */
  const handleTabs = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTab(newTab);
  };

  /* Menu Modals */
  const EventDelete = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Delete Event?`,
        component: 'EVENT_DELETE',
        activity: activity as Fluit.activities.Activity,
      },
    });
  };

  const EventEdit = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Edit Event`,
        component: 'EVENT_EDIT',
        activity: activity as Fluit.activities.Activity,
      },
    });
  };

  const logEvent = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: hit_activity_limit ? '' : 'Log Event',
        component: hit_activity_limit ? 'UPGRADE_ACTIVITIES' : 'EVENT_LOG',
        centered: hit_activity_limit,
      },
    });
  };

  // Interface Actions
  const handlePopoverClose = () => {
    setPoperEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, content: JSX.Element) => {
    setPoperEl(event.currentTarget);
    setPopEl(event.currentTarget);
    setContent(content);
  };

  const handlePopoverHover = () => {
    setPoperEl(popEl);
  };

  const handlePopoverExit = () => {
    setPopEl(null);
    setPoperEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, activity_id: string) => {
    setAnchorEl(event.currentTarget);
    setActivity(_.find(activities, { id: activity_id }));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /* UseEffect for Document Setting */
  React.useEffect(() => {
    let userActivity = userActivities;
    let systemActivity = systemActivities;
    if (target_field === 'deal_id') {
      userActivity = _.filter(userActivities, { metadata: { deal_id: target_id } });
      systemActivity = _.filter(systemActivities, { metadata: { deal_id: target_id } });
    } else if (target_field === 'investor_id') {
      userActivity = _.filter(userActivities, { metadata: { investor_id: target_id } });
      systemActivity = _.filter(systemActivities, { metadata: { investor_id: target_id } });
    }
    return setActivities(tab === 'user' ? userActivity : systemActivity);
  }, [userActivities, systemActivities, tab, target_id, target_field]);

  React.useEffect(() => {
    if (tab === 'user') {
      let filteredData = _.orderBy(_.filter(userActivities, { event: { source: 'user' } }), ['date'], ['desc']);
      if (filter !== 'none') {
        if (target_field === 'investor_id') {
          filteredData = _.orderBy(
            _.filter(userActivities, {
              event: { source: 'user', action: filter },
              metadata: { investor_id: target_id },
            }),
            ['date'],
            ['desc']
          );
        }
        if (target_field === 'deal_id') {
          filteredData = _.orderBy(
            _.filter(userActivities, { event: { source: 'user', action: filter }, metadata: { deal_id: target_id } }),
            ['date'],
            ['desc']
          );
        }
        if (!target_field) {
          filteredData = _.orderBy(
            _.filter(userActivities, { event: { source: 'user', action: filter } }),
            ['date'],
            ['desc']
          );
        }
      }
      if (filter === 'none') {
        if (target_field === 'investor_id') {
          filteredData = _.orderBy(
            _.filter(userActivities, { event: { source: 'user' }, metadata: { investor_id: target_id } }),
            ['date'],
            ['desc']
          );
        }
        if (target_field === 'deal_id') {
          filteredData = _.orderBy(
            _.filter(userActivities, { event: { source: 'user' }, metadata: { deal_id: target_id } }),
            ['date'],
            ['desc']
          );
        }
      }
      return setActivities(filteredData);
    } else if (tab === 'system') {
      let filteredData = _.orderBy(_.filter(userActivities, { event: { source: 'system' } }), ['date'], ['desc']);

      if (target_field === 'investor_id') {
        filteredData = _.orderBy(
          _.filter(userActivities, {
            event: { source: 'user', action: filter },
            metadata: { investor_id: target_id },
          }),
          ['date'],
          ['desc']
        );
      }
      if (target_field === 'deal_id') {
        filteredData = _.orderBy(
          _.filter(userActivities, { event: { source: 'system' }, metadata: { deal_id: target_id } }),
          ['date'],
          ['desc']
        );
      }

      return setActivities(filteredData);
    }
  }, [filter, userActivities, tab, target_id, target_field]);

  /* HTML for Render */
  const html_limit_banner = (
    <Grid item xs={12}>
      <Alert
        severity="info"
        variant="outlined"
        action={
          <Button color="inherit" size="medium" component={NavLink} to={ROUTES.BUY}>
            Upgrade
          </Button>
        }
      >
        {tab === 'user' ? (
          <>
            Using {limits.activities.count} of {limits.activities.limit} activity logs, upgrade your account for
            unlimited logs.
          </>
        ) : (
          <>Upgrade to start logging all events on fluit</>
        )}
      </Alert>
    </Grid>
  );

  const html_header = (
    <Grid item xs={12}>
      <Box display={!mobile ? 'flex' : ''} flexDirection="row" justifyContent="space-between" flexWrap="wrap">
        <Tabs
          value={tab}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant={!mobile ? 'standard' : 'fullWidth'}
          centered={!mobile ? false : true}
          className={classes.filters}
        >
          <Tab value="user" label={`User Events`} />
          <Tab value="system" label={`System Events`} />
        </Tabs>

        {!mobile && (
          <Box display="flex">
            {tab === 'user' && (
              <FormControl variant="outlined" style={{ width: 220, marginRight: 16 }}>
                <InputLabel id="filter-activity">Filter</InputLabel>
                <Select
                  labelId="filter-activity"
                  id="filter-select-activity"
                  label="Filter"
                  value={filter}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => setFilter(e.target.value as string)}
                >
                  <MenuItem value="none">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="contacted">Contacted</MenuItem>
                  <MenuItem value="document">Document Received</MenuItem>
                  <MenuItem value="meeting">Meetings</MenuItem>
                </Select>
              </FormControl>
            )}

            <Button variant="outlined" color="secondary" onClick={() => logEvent()}>
              Log Event
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  );

  const html_popover = (
    <>
      <Popover
        open={Boolean(poperEl)}
        anchorEl={poperEl}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        id="mouse-over-popover"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        PaperProps={{ onMouseEnter: handlePopoverHover, onMouseLeave: handlePopoverExit, elevation: 5 }}
        disableRestoreFocus
      >
        {content ? content : null}
      </Popover>
      <Menu
        id="activity-contextual-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        elevation={4}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            EventEdit();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            EventDelete();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      {limits.limit_activities && html_limit_banner}
      {html_header}
      <Grid item xs={12}>
        {!_.some(activities) && tab === 'user' ? (
          <NoData
            icon={<Activity />}
            text={`No events created for ${organisation.name}`}
            label="Log an Event"
            action={logEvent}
          />
        ) : null}
        {tab === 'system' && limits.limit_activities ? <ExampleSystemItem /> : null}
        {_.some(activities) && (
          <Timeline className={classes.root}>
            <InfiniteScroll
              dataLength={activities.length}
              next={getActivities}
              hasMore={tab === 'user' ? userMore : systemMore}
              loader={<GenericLoader height="100%" variant="none" />}
              endMessage={<ActivityEnd message="No More Events" />}
              style={{ overflowY: 'hidden' }}
            >
              {tab === 'user'
                ? _.map(activities, activity => (
                    <ActivityItem
                      activity={activity}
                      key={activity.id}
                      handlePopoverClose={handlePopoverClose}
                      handlePopoverOpen={handlePopoverOpen}
                      handleMenu={handleMenuOpen}
                    />
                  ))
                : null}

              {tab === 'system' && !limits.limit_activities
                ? _.map(activities, activity => <SystemItem activity={activity} key={activity.id} />)
                : null}
            </InfiniteScroll>
          </Timeline>
        )}
      </Grid>
      {html_popover}
    </>
  );
};

export default ActivityList;
