import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import ActivityList from 'src/components/Activity/ActivityList';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';

const DealTabActivity: React.FC = () => {
  const { deal } = React.useContext(DealContext);
  const { dispatch } = React.useContext(AppContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const logEvent = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Log Event`,
        component: 'EVENT_LOG',
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Activity</Typography>
            {mobile ? (
              <Box>
                <Button style={{ marginLeft: 16 }} variant="outlined" color="secondary" onClick={() => logEvent()}>
                  Log Event
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Grid>

      <ActivityList target="deal" target_field="deal_id" target_id={deal.id} />
    </Grid>
  );
};

export default DealTabActivity;
