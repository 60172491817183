import { Button, Grid, LinearProgress, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { data_countries } from 'src/constants/countries';
import { telephoneRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { BillingFirebase } from 'src/services/billing';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

interface ValueProps {
  id: string;
  name: string;
  phone: string;
  email: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    country: string;
    state: string;
    postal_code: string;
  };
  metadata: {
    tax_country: string;
    org_id: string;
  };
}

const EditBillingDetails: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, billing } = state;
  const { customer } = billing;

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  const initVals: ValueProps = {
    id: customer && customer.id ? customer.id : '',
    name: customer && customer.name ? customer.name : organisation.name,
    phone: customer && customer.phone ? customer.phone : organisation.telephone,
    email: customer && customer.email ? customer.email : organisation.email,
    address: {
      line1: customer && customer.address && customer.address.line1 ? customer.address.line1 : '',
      line2: customer && customer.address && customer.address.line2 ? customer.address.line2 : '',
      city: customer && customer.address && customer.address.city ? customer.address.city : '',
      country: customer && customer.address && customer.address.country ? customer.address.country.toLowerCase() : 'gb',
      state: customer && customer.address && customer.address.state ? customer.address.state : '',
      postal_code: customer && customer.address && customer.address.postal_code ? customer.address.postal_code : '',
    },
    metadata: {
      org_id: customer ? customer.metadata.org_id : organisation.id,
      tax_country: customer ? customer.metadata.tax_country : '',
    },
  };

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={initVals}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          try {
            await BillingFirebase.updateCustomer(values);
            actions.setSubmitting(false);
            setToast({
              message: `Updated Details`,
              type: 'success',
            });
            dispatch({ type: 'BILLING_CUSTOMER_UPDATE', payload: values });
            closeModal();
          } catch (error) {
            actions.setSubmitting(false);
            const err: Fluit.firestore.Error = error;
            setToast({
              message: `${err.name} | ${err.message}`,
              type: 'error',
            });
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Not a valid email address')
            .required('Enter a billing email address'),
          name: Yup.string(),
          phone: Yup.string().matches(telephoneRegex, 'Not a valid telephone number'),
          address: Yup.object().shape({
            line1: Yup.string().required('Address Line 1 is Required'),
            line2: Yup.string(),
            city: Yup.string(),
            country: Yup.string(),
            state: Yup.string(),
            postal_code: Yup.string().required('Postcode or Zipcode is Required'),
          }),
        })}
      >
        {props => {
          const { handleSubmit, setFieldValue, values, isSubmitting } = props;
          const country_value = _.find(data_countries, { iso: values.address.country });
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h4">Billing Company</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="name"
                    label="Company Name"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="email"
                    label="Billing Email"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="phone"
                    label="Billing Telephone"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h4">Billing Address</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="address.line1"
                    label="Line 1"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="address.line2"
                    label="Line 2"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="address.city"
                    label="City"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    id="country"
                    options={data_countries}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.iso === value.iso}
                    value={country_value}
                    onChange={(e: object, value: any | null) => {
                      if (value !== null) {
                        setFieldValue('address.country', value.iso);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="address.country"
                        label="Country"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: _.uniqueId('new-password off'),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="address.state"
                    label="State, County, Province or Region"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="address.postal_code"
                    label="Postcode or Zipcode"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                    Update
                  </Button>
                  <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                    Cancel
                  </Button>
                </Grid>
                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress variant="query" />
                  </Grid>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default EditBillingDetails;
