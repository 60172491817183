import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import _ from 'lodash';
import React from 'react';
import { initalise } from 'src/constants/init';
import { localStorageInit } from 'src/constants/localStorage';
import { AlgoliaContext } from 'src/contexts/AlgoliaContext';
import { AppContext } from 'src/contexts/AppContext';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { DealContext } from 'src/contexts/DealContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import Fluit from 'src/types/Fluit';
import { AssignmentsFirebase } from './assignments';
import { BillingFirebase } from './billing';
import { ContactFirebase } from './contacts';
import { DealFirebase } from './deals';
import { InvestorFirebase } from './investors';
import { OrganisationFirebase } from './organisations';
import { ProcessesFirebase } from './processes';
import { TermsheetFirebase } from './termsheets';
import { UserFirebase } from './users';

const Init: React.FC = () => {
  const fb = React.useContext(FirebaseContext);
  const { setToast } = React.useContext(ToastContext);
  const local = localStorageInit;
  const { state, dispatch } = React.useContext(AppContext);
  const {
    assignments,
    billing,
    contacts,
    deals,
    init,
    investors,
    members,
    organisation,
    organisations,
    processes,
    subscriptions,
    termsheets,
  } = state;

  /* Contexts for Flushing */
  const { setSearch } = React.useContext(AlgoliaContext);
  const { setCheckout } = React.useContext(CheckoutContext);
  const { setDeal } = React.useContext(DealContext);
  const { setInvestor } = React.useContext(InvestorContext);
  const { setOnboard } = React.useContext(OnboardingContext);
  const { setProcess } = React.useContext(ProcessContext);

  /* API FETCH LOGIC */
  const [fetchedOrgs, setFetchedOrgs] = React.useState(false);
  const [fetchedMembers, setFetchedMembers] = React.useState(false);
  const [fetchedDeals, setFetchedDeals] = React.useState(false);
  const [fetchedInvestors, setFetchedInvestors] = React.useState(false);
  const [fetchedContacts, setFetchedContacts] = React.useState(false);
  const [fetchedProcesses, setFetchedProcesses] = React.useState(false);
  const [fetchedSubs, setFetchedSubs] = React.useState(false);
  const [fetchedAssignments, setFetchedAssignments] = React.useState(false);
  const [fetchedTermsheets, setFetchedTermsheets] = React.useState(false);

  // Error Display Function
  const handleError = React.useCallback(
    (error: any) => {
      // console.log(error);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    },
    [setToast]
  );

  const getInvites = React.useCallback(async () => {
    try {
      const result = await OrganisationFirebase.getInvites();
      dispatch({ type: 'INVITES_LIST', payload: result });
    } catch (error) {
      handleError(error);
    }
  }, [dispatch, handleError]);

  // Handle Loggedin User
  const getUser = React.useCallback(
    async (user: firebase.User) => {
      try {
        let user_exists = await UserFirebase.get();
        if (user_exists) {
          user_exists.email_verified = user.emailVerified;
          dispatch({ type: 'USER_SET', payload: user_exists });
          dispatch({ type: 'API_USER_INIT', payload: true });
          getInvites();
        }
        return user_exists;
      } catch (error) {
        handleError(error);
      }
    },
    [dispatch, handleError, getInvites]
  );

  const createUser = React.useCallback(
    async (user: firebase.User) => {
      const new_user: Partial<Fluit.users.User> = {
        id: user.uid,
        email: user.email ? user.email : '',
        email_verified: user.emailVerified,
        is_onboarded: false,
        first_name: user.displayName ? user.displayName.split(' ')[0] : '',
        last_name: user.displayName ? user.displayName.split(' ')[1] : '',
        telephone: user.phoneNumber ? user.phoneNumber : '',
        position: '',
        invites: null,
        created_at: firebase.firestore.Timestamp.now(),
        updated_at: firebase.firestore.Timestamp.now(),
        settings: {
          default_organisation: '',
        },
      };
      try {
        await UserFirebase.create(new_user);
        dispatch({ type: 'USER_SET', payload: new_user });
        getInvites();
        dispatch({ type: 'API_USER_INIT', payload: true });
        dispatch({ type: 'API_LOADING', payload: false });
        dispatch({ type: 'API_FINISHED', payload: true });
      } catch (error) {
        handleError(error);
      }
    },
    [dispatch, handleError, getInvites]
  );

  const handleOrganisation = React.useCallback(async () => {
    if (init.registering) {
      return;
    }
    try {
      const organisations = await OrganisationFirebase.list();
      if (!_.some(organisations)) {
        dispatch({ type: 'API_LOADING', payload: false });
        dispatch({ type: 'API_FINISHED', payload: true });
        return undefined;
      }

      dispatch({ type: 'ORGANISATIONS_LIST', payload: organisations });

      const local_organisation = _.find(organisations, { id: String(local.organisation) });
      const default_organisation = _.find(organisations, { id: state.user.settings?.default_organisation });

      if (organisations.length > 1) {
        if (local.organisation && local_organisation) {
          dispatch({ type: 'ORGANISATION_SET', payload: local_organisation });
        } else if (default_organisation) {
          dispatch({ type: 'ORGANISATION_SET', payload: default_organisation });
        } else {
          dispatch({ type: 'ORGANISATION_SET', payload: organisations[0] });
        }
      } else {
        dispatch({ type: 'ORGANISATION_SET', payload: organisations[0] });
      }
      setFetchedOrgs(true);
    } catch (error) {
      handleError(error);
    }
    dispatch({ type: 'API_ORGANISATIONS_INIT', payload: true });
  }, [dispatch, local.organisation, state.user.settings, handleError, init.registering, setFetchedOrgs]);

  const handleMembers = React.useCallback(async () => {
    dispatch({ type: 'API_MEMBERS_INIT', payload: true });
    try {
      const members: Fluit.organisations.Member[] = await OrganisationFirebase.listMembers(organisation.id);
      const admin = _.find(members, member => member.id === state.user.id && member.role === 'owner');
      dispatch({ type: 'ADMIN_SET', payload: admin ? true : false });
      dispatch({ type: 'MEMBERS_LIST', payload: members });
      setFetchedMembers(true);
    } catch (error) {
      if (error.message === 'Payment Error') {
        dispatch({ type: 'SYSTEM_PAYMENT_FAILED', payload: true });
        setFetchedMembers(true);
        return undefined;
      }
      handleError(error);
    }
  }, [dispatch, organisation, handleError, state.user, setFetchedMembers]);

  const handleSubscriptions = React.useCallback(async () => {
    dispatch({ type: 'API_SUBSCRIPTIONS_INIT', payload: true });
    try {
      const customer = await BillingFirebase.getCustomerId(organisation.id);
      const result = await BillingFirebase.getSubscriptions(organisation.id);
      setFetchedSubs(true);
      if (customer) dispatch({ type: 'BILLING_LIST', payload: customer });
      if (_.some(result)) dispatch({ type: 'SUBSCRIPTIONS_LIST', payload: result });
    } catch (error) {
      if (error.message === 'Payment Error') {
        dispatch({ type: 'SYSTEM_PAYMENT_FAILED', payload: true });
        setFetchedSubs(true);
        return undefined;
      }
      handleError(error);
    }
  }, [organisation, handleError, dispatch, setFetchedSubs]);

  const handleDeals = React.useCallback(async () => {
    dispatch({ type: 'API_DEALS_INIT', payload: true });
    try {
      const result = await DealFirebase.list(organisation.id);
      dispatch({ type: 'DEALS_LIST', payload: result });
      setFetchedDeals(true);
    } catch (error) {
      if (error.message === 'Payment Error') {
        dispatch({ type: 'SYSTEM_PAYMENT_FAILED', payload: true });
        setFetchedDeals(true);
        return undefined;
      }
      handleError(error);
    }
  }, [dispatch, handleError, organisation, setFetchedDeals]);

  const handleInvestors = React.useCallback(async () => {
    dispatch({ type: 'API_INVESTORS_INIT', payload: true });
    try {
      const result = await InvestorFirebase.list(organisation.id);
      const updatedInvestors = _.map(result, investor =>
        _.assign(investor, {
          firstLetter: /[0-9]/.test(investor.name[0].toUpperCase()) ? '0-9' : investor.name[0].toUpperCase(),
        })
      );
      dispatch({ type: 'INVESTORS_LIST', payload: updatedInvestors });
      setFetchedInvestors(true);
    } catch (error) {
      if (error.message === 'Payment Error') {
        dispatch({ type: 'SYSTEM_PAYMENT_FAILED', payload: true });
        setFetchedInvestors(true);
        return undefined;
      }
      handleError(error);
    }
  }, [dispatch, handleError, organisation, setFetchedInvestors]);

  const handleContacts = React.useCallback(async () => {
    dispatch({ type: 'API_CONTACTS_INIT', payload: true });
    try {
      const result = await ContactFirebase.list(organisation.id);
      const updatedContacts = _.map(result, contact =>
        _.assign(contact, {
          investor_name: _.find(investors, { id: contact.investor_id })?.name,
        })
      );
      dispatch({ type: 'CONTACTS_LIST', payload: updatedContacts });
      setFetchedContacts(true);
      return;
    } catch (error) {
      if (error.message === 'Payment Error') {
        dispatch({ type: 'SYSTEM_PAYMENT_FAILED', payload: true });
        setFetchedContacts(true);
        return undefined;
      }
      handleError(error);
      return;
    }
  }, [dispatch, handleError, organisation, investors, setFetchedContacts]);

  const handleProcesses = React.useCallback(async () => {
    dispatch({ type: 'API_PROCESSES_INIT', payload: true });
    try {
      const result = await ProcessesFirebase.list(organisation.id);
      dispatch({ type: 'PROCESSES_LIST', payload: result });
      setFetchedProcesses(true);
    } catch (error) {
      // console.log(error.message);
      if (error.message === 'Payment Error') {
        setFetchedProcesses(true);
        dispatch({ type: 'SYSTEM_PAYMENT_FAILED', payload: true });
        return undefined;
      }
      handleError(error);
    }
  }, [dispatch, handleError, organisation, setFetchedProcesses]);

  const handleAssignments = React.useCallback(async () => {
    dispatch({ type: 'API_ASSIGNMENTS_INIT', payload: true });
    try {
      const result = await AssignmentsFirebase.list(organisation.id);
      dispatch({ type: 'ASSIGNMENTS_LIST', payload: result });
      setFetchedAssignments(true);
    } catch (error) {
      if (error.message === 'Payment Error') {
        setFetchedAssignments(true);
        dispatch({ type: 'SYSTEM_PAYMENT_FAILED', payload: true });
        return undefined;
      }
      handleError(error);
    }
  }, [dispatch, organisation.id, setFetchedAssignments, handleError]);

  const handleTermsheets = React.useCallback(async () => {
    dispatch({ type: 'API_TERMSHEETS_INIT', payload: true });
    try {
      const result = await TermsheetFirebase.list(organisation.id);
      dispatch({ type: 'TERMSHEETS_LIST', payload: result });
      setFetchedTermsheets(true);
    } catch (error) {
      if (error.message === 'Payment Error') {
        setFetchedTermsheets(true);
        dispatch({ type: 'SYSTEM_PAYMENT_FAILED', payload: true });
        return undefined;
      }
      handleError(error);
    }
  }, [dispatch, organisation.id, setFetchedTermsheets, handleError]);

  const handleFlush = React.useCallback(
    (org: boolean) => {
      setSearch({
        query: '',
        selected: [],
      });
      setCheckout(initalise.checkout);
      setDeal(initalise.deal);
      setInvestor(initalise.investor);
      setOnboard({
        user: initalise.user,
        organisation: initalise.organisation,
        step: 0,
        currency_step: false,
      });
      setProcess(initalise.process);
      dispatch({ type: 'ACTIVITIES_FLUSH' });
      dispatch({ type: 'ASSIGNMENTS_FLUSH' });
      dispatch({ type: 'BILLING_FLUSH' });
      dispatch({ type: 'CONTACTS_FLUSH' });
      dispatch({ type: 'DEALS_FLUSH' });
      dispatch({ type: 'INVESTORS_FLUSH' });
      dispatch({ type: 'INVITE_FLUSH' });
      dispatch({ type: 'INVITED_FLUSH' });
      dispatch({ type: 'MEMBERS_FLUSH' });
      dispatch({ type: 'NOTES_FLUSH' });
      dispatch({ type: 'PROCESSES_FLUSH' });
      dispatch({ type: 'SUBSCRIPTIONS_FLUSH' });
      dispatch({ type: 'TASKS_FLUSH' });
      dispatch({ type: 'TERMSHEETS_FLUSH' });
      dispatch({ type: 'UI_FLUSH' });
      if (org) {
        dispatch({ type: 'ORGANISATION_FLUSH' });
        dispatch({ type: 'ORGANISATIONS_FLUSH' });
        dispatch({ type: 'USER_FLUSH' });
      }
      dispatch({ type: 'API_FLUSH_INIT' });
    },
    [dispatch, setSearch, setCheckout, setDeal, setInvestor, setOnboard, setProcess]
  );

  const clearFetch = React.useCallback(() => {
    setFetchedAssignments(false);
    setFetchedContacts(false);
    setFetchedDeals(false);
    setFetchedInvestors(false);
    setFetchedMembers(false);
    setFetchedOrgs(false);
    setFetchedProcesses(false);
    setFetchedSubs(false);
    setFetchedTermsheets(false);
  }, []);

  const handleSwitch = React.useCallback(() => {
    dispatch({ type: 'API_LOADING', payload: true });
    dispatch({ type: 'API_USER_INIT', payload: true });
    dispatch({ type: 'API_ORGANISATIONS_INIT', payload: true });
    setFetchedOrgs(true);
  }, [dispatch]);

  /*
   * AUTH USE EFFECT
   */

  React.useEffect(() => {
    if (!init.loading && !init.user && !init.initalized) {
      fb.auth().onAuthStateChanged(function(user) {
        if (user) {
          dispatch({ type: 'API_LOADING', payload: true });
          if (init.registering) {
            createUser(user);
            return;
          } else {
            (async () => {
              const user_exists = await getUser(user);
              if (!user_exists) {
                createUser(user);
              }
            })();
          }
        } else {
          clearFetch();
          dispatch({ type: 'API_USER_INIT', payload: false });
          dispatch({ type: 'API_LOADING', payload: false });
          dispatch({ type: 'API_FINISHED', payload: false });
        }
      });
    }
  }, [init.loading, init.user, init.initalized, fb, dispatch, init.registering, createUser, getUser, clearFetch]);

  /* ORG SWITCH USE EFFECT */
  React.useEffect(() => {
    if (init.switching) {
      handleFlush(false);
      clearFetch();
      handleSwitch();
    }
  }, [init.switching, clearFetch, handleFlush, handleSwitch]);

  /* APP RELOAD USE EFFECT */
  React.useEffect(() => {
    if (init.reload) {
      handleFlush(true);
      clearFetch();
    }
  });

  React.useEffect(() => {
    if (init.loading && init.user && !init.initalized && !init.registering) {
      if (!init.organisation && !_.some(organisations)) {
        handleOrganisation();
      }
      if (init.organisation && !init.users && !_.some(members)) {
        handleMembers();
      }
      if (init.organisation && !init.deals && !_.some(deals)) {
        handleDeals();
      }
      if (init.organisation && !init.processes && !_.some(processes)) {
        handleProcesses();
      }
      if (init.organisation && !init.investors && !_.some(investors)) {
        handleInvestors();
      }
      if (init.organisation && init.investors && !init.contacts && !_.some(contacts)) {
        handleContacts();
      }
      if (init.organisation && !init.subscriptions && !_.some(subscriptions) && billing.stripe_customer_id === '') {
        handleSubscriptions();
      }
      if (init.organisation && !init.termsheets && !_.some(termsheets)) {
        handleTermsheets();
      }
      if (init.organisation && !init.assignments && !_.some(assignments)) {
        handleAssignments();
      }
      if (
        fetchedOrgs &&
        fetchedMembers &&
        fetchedProcesses &&
        fetchedSubs &&
        fetchedContacts &&
        fetchedDeals &&
        fetchedInvestors &&
        fetchedAssignments &&
        fetchedTermsheets
      ) {
        dispatch({ type: 'API_LOADING', payload: false });
        dispatch({ type: 'API_FINISHED', payload: true });
      }
    }
  }, [
    dispatch,
    handleAssignments,
    handleContacts,
    handleDeals,
    handleInvestors,
    handleMembers,
    handleOrganisation,
    handleProcesses,
    handleSubscriptions,
    handleTermsheets,
    init.assignments,
    init.contacts,
    init.deals,
    init.initalized,
    init.investors,
    init.loading,
    init.organisation,
    init.processes,
    init.registering,
    init.subscriptions,
    init.termsheets,
    init.user,
    init.users,
    assignments,
    billing,
    contacts,
    deals,
    investors,
    members,
    organisations,
    processes,
    subscriptions,
    termsheets,
    fetchedAssignments,
    fetchedContacts,
    fetchedDeals,
    fetchedInvestors,
    fetchedMembers,
    fetchedOrgs,
    fetchedProcesses,
    fetchedSubs,
    fetchedTermsheets,
  ]);

  return null;
};

export default Init;
