import { Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { OrganisationFirebase } from 'src/services/organisations';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const UserInviteForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, members, user, invited_members } = state;

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  const listUserEmails = React.useCallback(() => {
    const member_emails = _.map(members, user => user.email);
    const invited_emails = _.map(invited_members, invitee => invitee.email);
    return _.concat(member_emails, invited_emails);
  }, [members, invited_members]);

  const userEmails = listUserEmails();

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await OrganisationFirebase.invite(organisation, values.email, user);
          setToast({
            message: `Invited ${values.email}`,
            type: 'success',
          });
          closeModal();
        } catch (error) {
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .notOneOf(userEmails, 'This user has already been invited')
          .required('Email is required to invite a user'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  All users are invited as members, you can change their role once they join your organisation.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  type="email"
                  autoComplete="email"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Invite
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default UserInviteForm;
