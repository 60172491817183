import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field } from 'formik';
import React from 'react';
import { industries } from 'src/constants/industries';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  touched: boolean | undefined;
  error: string | undefined;
  label: string;
  value?: string;
}

const AutocompleteIndustries: React.FC<Props> = ({ setFieldValue, name, id, touched, error, label, value }) => {
  const val = value ? value : null;

  return (
    <Field
      name={name}
      id={id ? id : name}
      component={Autocomplete}
      options={industries}
      getOptionLabel={(option: string) => option}
      disableClearable={true}
      autoSelect={true}
      value={val}
      onChange={(event: object, value: string) => {
        setFieldValue(name, value);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

export default AutocompleteIndustries;
