import { Box, Button, Chip, Divider, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { data_countries } from 'src/constants/countries';
import { initalise } from 'src/constants/init';
import { AlgoliaContext } from 'src/contexts/AlgoliaContext';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { InvestorFirebase } from 'src/services/investors';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';

const AlgoliaSelectedInvestors: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { search, setSearch } = React.useContext(AlgoliaContext);
  const { selected } = search;
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;
  const { limits } = organisation;
  const available = limits.investors.limit - limits.investors.count;
  const remaining = available - selected.length;
  const history = useHistory();
  const [count, setCount] = React.useState(selected.length);
  const [executed, setExecuted] = React.useState(false);
  const [effect, setEffect] = React.useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    setCount(selected.length);
  }, [selected]);

  React.useEffect(() => {
    if (!executed || effect) {
      return undefined;
    }

    dispatch({ type: 'API_LOADING', payload: false });

    if (count !== selected.length) {
      setToast({
        message: `Error: ${count} of ${selected.length} investors added - contact support for help`,
        type: 'error',
      });
    } else {
      setToast({
        message: `${count} Investor${count !== 1 ? 's' : ''} added`,
        type: 'success',
      });
    }
    setSearch({
      query: '',
      selected: [],
    });

    history.push(ROUTES.INVESTORS);

    var i: number;
    for (i = 0; i < count; i++) {
      dispatch({ type: 'COUNT_UP_INVESTORS' });
    }

    setEffect(true);
  }, [selected, count, executed, effect, dispatch, setToast, setSearch, history]);

  const handleDelete = (obj: Fluit.context.algolia.Selected) => {
    setSearch({
      selected: _.filter(selected, item => item.objectID !== obj.objectID),
      query: search.query,
    });
  };

  const handleFetch = React.useCallback(
    async (investor: Fluit.context.algolia.Selected, index: number) => {
      try {
        const fetched_investor: Fluit.investors.Firm | undefined = investor;

        if (!fetched_investor) {
          setCount(count - 1);
          if (index === selected.length) {
            setExecuted(true);
          }
          return undefined;
        }

        const country: Fluit.address.Country | undefined = _.find(
          data_countries,
          item => item.name === fetched_investor?.location
        );

        const org_investor = {
          ...initalise.investor,
          org_id: organisation.id,
          fluit_investor_id: fetched_investor.objectID,
          name: fetched_investor.name ? fetched_investor.name : '',
          telephone: '',
          fax: '',
          email: '',
          website: fetched_investor.website ? fetched_investor.website : '',
          regions: fetched_investor.regions ? fetched_investor.regions : undefined,
          types: fetched_investor.types ? fetched_investor.types : undefined,
          stages: fetched_investor.stages ? fetched_investor.stages : undefined,
          sectors: fetched_investor.sectors ? fetched_investor.sectors : undefined,
          location: fetched_investor.location ? fetched_investor.location : undefined,
          investment_min: fetched_investor.investment_min ? fetched_investor.investment_min : undefined,
          investment_max: fetched_investor.investment_max ? fetched_investor.investment_max : undefined,
          address: {
            city: '',
            country: {
              name: country ? country.name : '',
              iso: country ? country.iso : '',
              currency: country ? country.currency : '',
            },
          },
        };
        const new_investor = await InvestorFirebase.create(org_investor);
        if (limits.limit_activities) {
          dispatch({ type: 'INVESTOR_CREATE', payload: new_investor });
        }

        if (index === selected.length) {
          setExecuted(true);
        }
      } catch (error) {
        dispatch({ type: 'API_LOADING', payload: false });
        const err = error as Fluit.firestore.Error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    },
    [organisation.id, setToast, dispatch, count, selected.length, limits.limit_activities]
  );

  const addInvestors = React.useCallback(() => {
    dispatch({ type: 'API_LOADING', payload: true });
    _.map(selected, (investor, index) => {
      handleFetch(investor, index + 1);
    });
  }, [selected, handleFetch, dispatch]);

  return (
    <Box mb={3}>
      <Box mb={2}>
        <Typography variant="h4" data-tour="investor_search_selected">
          Selected Investors <Typography variant="caption">({selected.length})</Typography>
        </Typography>
      </Box>
      <Box mb={2}>
        {_.map(selected, item => (
          <Chip
            key={item.objectID}
            color="secondary"
            label={item.name}
            onDelete={() => handleDelete(item)}
            style={{ marginRight: 8, marginBottom: 8, backgroundColor: colors.navy[900], maxWidth: '100%' }}
          />
        ))}
      </Box>
      <Box mb={2}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          disabled={selected.length <= 0}
          fullWidth={mobile}
          onClick={() => addInvestors()}
        >
          Add Investors
        </Button>
      </Box>

      {remaining === 0 && (
        <Box>
          <Paper>
            <Alert
              severity="warning"
              icon={false}
              action={
                <Button color="inherit" size="medium" component={NavLink} to={ROUTES.BUY}>
                  Upgrade
                </Button>
              }
            >
              You've reached the maximum investors you can add on the free tier, you can add unlimited investors on a
              paid tier.
            </Alert>
          </Paper>
        </Box>
      )}

      <Box my={4}>
        <Divider light />
      </Box>
    </Box>
  );
};

export default AlgoliaSelectedInvestors;
