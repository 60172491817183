import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  LinearProgress,
  Link,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { compareAsc, parseISO } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { formatDate } from 'src/helpers/formatDate';
import * as ROUTES from 'src/routes/routes';
import { TaskFirebase } from 'src/services/tasks';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';
import ContactDisplay from '../Displays/Contact';

const useStyles = makeStyles((theme: Theme) => ({
  overdue: {
    background: theme.palette.error.main,
    color: `${theme.palette.common.white} !important`,
    marginTop: -5,
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    padding: theme.spacing(3, 6, 3, 3),
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  date: {
    padding: theme.spacing(3),
    background: `${colors.common.background}80`,
    display: 'flex',
    alignItems: 'center',
    width: 320,
    '& p': {
      fontSize: 13,
    },
  },
  metadata: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 13,
      marginRight: theme.spacing(4),
    },
    '& p > span': {
      color: colors.grey[400],
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  label: {
    color: colors.grey[400],
  },
  completed: {
    '& p': {
      opacity: 0.5,
      textDecorationLine: 'line-through',
      textDecorationColor: colors.grey[200],
      textDecorationStyle: 'solid',
    },
  },
  attribute: {
    '&:hover svg, &:hover svg g, &:hover svg path': {
      fill: theme.palette.secondary.main,
    },
  },
  icon: {
    height: 20,
    width: 20,
    color: colors.grey[200],
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dateText: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  task: Fluit.tasks.Task;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>, content: JSX.Element) => void;
  handlePopoverClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Task: React.FC<Props> = ({ task, handlePopoverClose, handlePopoverOpen }) => {
  const { state, dispatch } = React.useContext(AppContext);
  const { deals, contacts, investors } = state;
  const classes = useStyles();
  const { setToast } = React.useContext(ToastContext);
  const [loading, setLoading] = React.useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const assignee = _.find(state.members, { id: task.assigned_to });

  const TaskEdit = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Edit Task`,
        component: 'TASK_EDIT',
        task: task,
      },
    });
  };

  const TaskDelete = async () => {
    setLoading(true);
    try {
      await TaskFirebase.delete(task);
      setLoading(false);
      dispatch({ type: 'TASK_DELETE', payload: task });
      setToast({
        message: `Task Deleted`,
        type: 'info',
      });
    } catch (error) {
      setLoading(false);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  };

  const handleToggle = async () => {
    try {
      setLoading(true);
      task.completed = task.completed ? false : true;
      await TaskFirebase.update(task);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToast({
        message: error,
        type: 'error',
      });
    }
  };

  const displayParent = () => {
    if (task.parent_type === 'deal') {
      const deal = _.find(deals, { id: task.parent_id });
      return deal ? (
        <Tooltip title="View Deal">
          <Link component={NavLink} to={`${ROUTES.DEALS}/${deal.id}`} color="secondary" className={classes.attribute}>
            {deal.name}
          </Link>
        </Tooltip>
      ) : (
        '-'
      );
    }
    if (task.parent_type === 'investor') {
      const investor = _.find(investors, { id: task.parent_id });
      return investor ? (
        <Tooltip title="View Investor">
          <Link
            component={NavLink}
            to={`${ROUTES.INVESTORS}/${investor.id}`}
            color="secondary"
            className={classes.attribute}
          >
            {investor.name}
          </Link>
        </Tooltip>
      ) : (
        '-'
      );
    }
    if (task.parent_type === 'contact') {
      const contact = _.find(contacts, { id: task.child_id });
      return contact ? (
        <Link
          color="secondary"
          className={classes.attribute}
          onMouseEnter={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handlePopoverOpen(e, contactInfo)}
          onMouseLeave={handlePopoverClose}
        >
          {contact.first_name} {contact.last_name}
        </Link>
      ) : (
        '-'
      );
    }
    if (task.parent_type === 'organisation') {
      return state.organisation.name;
    }

    return '-';
  };

  const contact = _.find(contacts, { id: task.child_id });

  const contactInfo = <Box>{contact ? <ContactDisplay data={contact} /> : null}</Box>;

  return (
    <Paper>
      <Box className={clsx(classes.container, task.completed && classes.completed)}>
        <Box className={classes.content}>
          <Box className={classes.checkbox}>
            <Checkbox checked={task.completed} onChange={handleToggle} name="task" color="primary" />
          </Box>
          <Box width="100%">
            <Typography>{task.contents}</Typography>
            <Box className={classes.actionRow}>
              <Box className={classes.metadata}>
                <Typography>
                  <Typography variant="caption">Attached to: </Typography>
                  {mobile && <br />}
                  {displayParent()}
                </Typography>
                <Typography>
                  <Typography variant="caption">Assigned to: </Typography>
                  {mobile && <br />}
                  {assignee ? `${assignee.first_name} ${assignee.last_name}` : 'Unassigned'}
                </Typography>
                {mobile && (
                  <Typography>
                    <Typography variant="caption">Due Date:</Typography> {mobile && <br />}
                    <span className={classes.dateText}>{task.due_date ? formatDate(task.due_date) : '-'}</span>
                    {task.due_date && !task.completed ? (
                      compareAsc(parseISO(task.due_date), new Date()) === -1 ? (
                        <Chip component="span" label="overdue" size="small" className={classes.overdue} />
                      ) : null
                    ) : null}
                  </Typography>
                )}
              </Box>
              <Box>
                <Tooltip title="edit">
                  <IconButton size="small" onClick={() => TaskEdit()}>
                    <EditIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="delete">
                  <IconButton size="small" onClick={() => TaskDelete()}>
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
        {!mobile && (
          <Box className={classes.date}>
            <Typography>
              <Typography variant="caption" className={classes.label}>
                Due Date:
              </Typography>{' '}
              <span className={classes.dateText}>{task.due_date ? formatDate(task.due_date) : '-'}</span>
              {task.due_date && !task.completed ? (
                compareAsc(parseISO(task.due_date), new Date()) === -1 ? (
                  <Chip component="span" label="overdue" size="small" className={classes.overdue} />
                ) : null
              ) : null}
            </Typography>
          </Box>
        )}
      </Box>
      {loading && <LinearProgress variant="query" />}
    </Paper>
  );
};

export default Task;
