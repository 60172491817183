import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';

export const TermsheetFirebase = {
  create: async (termsheet: Fluit.deals.Termsheet) => {
    try {
      const resp = await API({
        endpoint: `organisations/${termsheet.org_id}/termsheets`,
        method: 'POST',
        payload: termsheet,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  get: async (orgId: string, dealId: string): Promise<Fluit.deals.Termsheet> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/termsheets/${dealId}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (termsheet: Partial<Fluit.deals.Termsheet>) => {
    try {
      const resp = await API({
        endpoint: `organisations/${termsheet.org_id}/termsheets/${termsheet.id}`,
        method: 'PATCH',
        payload: termsheet,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (termsheet: Fluit.deals.Termsheet) => {
    try {
      const resp = await API({
        endpoint: `organisations/${termsheet.org_id}/termsheets/${termsheet.id}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (orgId: string): Promise<Fluit.deals.Termsheet[]> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/termsheets`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
