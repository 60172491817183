import { Box, Button, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ResetAsset } from 'src/assets/images/forgot_password.svg';
import { ReactComponent as VerifyAsset } from 'src/assets/images/verify_email.svg';
import RequestResetForm from 'src/components/Auth/RequestReset';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles(() => ({
  svg: {
    width: '100%',
    height: 'auto',
  },
}));

const RequestReset: React.FC = () => {
  const [page, setPage] = React.useState('reset');
  const [email, setEmail] = React.useState('');
  const classes = useStyles();
  return (
    <Box width="100%">
      <Box id="reset" hidden={page !== 'reset'}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Box mb={4}>
                <Typography variant="h1">Forgotten Password</Typography>
              </Box>
              <Box mb={4}>
                <RequestResetForm setPage={() => setPage('emailed')} setEmail={setEmail} />
              </Box>
              <Typography variant="subtitle1">
                Already have an account?{' '}
                <Link component={NavLink} to={ROUTES.LOGIN}>
                  Login here
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%" alignItems="flex-end">
              <ResetAsset className={classes.svg} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box id="emailed" hidden={page !== 'emailed'}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8} md={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Box mb={2}>
                <Typography variant="h1">Check your email</Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body1">
                  We've sent an email to <strong>{email}</strong> to reset your password.
                </Typography>
              </Box>
              <Box mb={4}>
                <Button variant="outlined" size="large" color="secondary" onClick={() => setPage('reset')}>
                  Request again
                </Button>
              </Box>
              <Typography variant="subtitle1">
                Already have an account?{' '}
                <Link component={NavLink} to={ROUTES.LOGIN}>
                  Login here
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={6} style={{ textAlign: 'right' }}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%" alignItems="flex-end">
              <VerifyAsset className={classes.svg} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RequestReset;
