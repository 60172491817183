import React from 'react';
import { initalise } from 'src/constants/init';
import Fluit from 'src/types/Fluit';

export const OnboardingContext = React.createContext<Fluit.context.onboarding.Context>(
  {} as Fluit.context.onboarding.Context
);

export const OnboardingProvider = ({ children }: Fluit.props.Children) => {
  const [onboard, setOnboard] = React.useState<Fluit.context.onboarding.State>({
    user: initalise.user,
    organisation: initalise.organisation,
    step: 0,
    currency_step: false,
  });
  return <OnboardingContext.Provider value={{ onboard, setOnboard }}>{children}</OnboardingContext.Provider>;
};
