export const fundingRounds = [
  'None',
  'Angel',
  'Pre-Seed',
  'Seed',
  'Series-A',
  'Series-B',
  'Series-C',
  'Series-D',
  'Series-E',
  'Growth Round (PE)',
];
