import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const AssignmentReducer = (state: Fluit.assignments.Assignment[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'ASSIGNMENT_SET':
      const ASSIGNMENT_SET = () => {
        const exists = _.find(state, { id: action.payload.id });
        if (exists) {
          const updated = _.filter(state, assignment => assignment.id !== action.payload.id);
          return _.concat(_.values(updated), action.payload);
        }
        return _.concat(_.values(state), action.payload);
      };

      return ASSIGNMENT_SET();

    case 'ASSIGNMENTS_LIST':
      return action.payload;
    case 'ASSIGNMENT_UPDATE':
      return state;
    case 'ASSIGNMENT_CREATE':
      return state;
    case 'ASSIGNMENT_DELETE':
      return _.filter(state, assignment => assignment.id !== action.payload.id);
    case 'ASSIGNMENTS_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
