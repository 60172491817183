import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { formatIsoDate } from 'src/helpers/formatIsoDate';
import colors from 'src/themes/colors';
import { DotsHorizontal, Investors, Meeting, Money } from 'src/themes/icons';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  draggable: {
    border: `2px solid ${colors.common.white}`,
    transition: '0.25s all ease-in-out',
    minHeight: '170px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:hover': {
      boxShadow: shadows.purple[4],
      borderColor: colors.purple.main,
    },
    '&$dragging': {
      boxShadow: shadows.purple[4],
      borderColor: colors.purple.main,
    },
  },
  dragging: {},
  svg: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  svgButton: {
    width: '16px',
    height: '16px',
  },
  attribute: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

interface Props {
  stepInv: Fluit.assignments.Assignment;
  investor: Fluit.investors.Investor;
  index: number;
  stepIndex: number;
  assignmentId: string;
}
const InvestorDraggable: React.FC<Props> = ({ stepInv, investor, index, stepIndex, assignmentId }) => {
  const { state, dispatch } = React.useContext(AppContext);
  const { deal } = React.useContext(DealContext);
  const classes = useStyles();
  let termsheet = _.find(
    state.termsheets,
    termsheet => termsheet.investor_id === stepInv.investor_id && termsheet.deal_id === deal.id
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Draggable draggableId={assignmentId} index={index}>
        {(provided, snapshot) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            xl={3}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            innerRef={provided.innerRef}
          >
            <Paper className={clsx(classes.draggable, snapshot.isDragging && classes.dragging)}>
              <Box p={3} display="flex">
                <Box flex={1}>
                  <Box mb={1}>
                    <Typography variant="h4">{investor.name}</Typography>
                  </Box>
                  {`Joined step on ${formatIsoDate(stepInv.assigned_at)}`}
                  <Tooltip title="">
                    <Typography variant="body1" className={classes.attribute}>
                      <Meeting className={classes.svg} />
                      {formatIsoDate(stepInv.assigned_at)}
                    </Typography>
                  </Tooltip>
                  <Typography variant="body1" className={classes.attribute}>
                    <Investors className={classes.svg} />{' '}
                    {stepInv.contacts
                      ? `${stepInv.contacts.length} ${stepInv.contacts.length === 1 ? `contact` : `contacts`} in deal`
                      : `0 contacts in deal`}
                  </Typography>
                  <Typography variant="body1" className={classes.attribute}>
                    <Money className={classes.svg} />
                    {deal.details?.currency.symbol} {termsheet ? numeral(termsheet.amount).format('0,0') : 0}
                  </Typography>
                </Box>
                <Box flex={0}>
                  <IconButton
                    color="secondary"
                    aria-controls="investor-contextual-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <DotsHorizontal className={classes.svgButton} />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          </Grid>
        )}
      </Draggable>
      <Menu
        id="investor-contextual-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={4}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch({
              type: 'MODAL',
              payload: {
                open: true,
                title: `Manage ${investor.name}'s Contact's for this deal`,
                component: 'DEAL_ASSIGN_CONTACTS',
                id: investor.id,
                stage: stepIndex,
                name: investor.name,
                assignment_id: assignmentId,
              },
            });
          }}
        >
          Manage Contacts
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch({
              type: 'MODAL',
              payload: {
                open: true,
                title: `Remove ${investor.name}`,
                component: 'DEAL_UNASSIGN_INVESTOR',
                id: assignmentId,
              },
            });
          }}
        >
          Remove from Deal
        </MenuItem>
      </Menu>
    </>
  );
};

export default InvestorDraggable;
