import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { NavLink } from 'react-router-dom';
import ContactDisplay from 'src/components/Displays/Contact';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { DotsHorizontal } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '20px',
    height: '20px',
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  contact: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    display: 'inline-flex',
    padding: theme.spacing(0.25, 0.5),
    margin: theme.spacing(0, -0.5),
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: colors.blue[50],
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  },
  paper: {
    pointerEvents: 'auto',
  },
}));

interface Props {
  termsheets: Fluit.deals.Termsheet[];
}

const InvestorTable: React.FC<Props> = ({ termsheets }) => {
  const { deal } = React.useContext(DealContext);
  const { process } = React.useContext(ProcessContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { investors, contacts, assignments } = state;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [poperEl, setPoperEl] = React.useState<HTMLElement | null>(null);
  const [popEl, setPopEl] = React.useState<HTMLElement | null>(null);
  const [target, setTarget] = React.useState<Fluit.investors.Investor>();
  const [contact, setContact] = React.useState<Fluit.investors.Contact | undefined>();
  const [stage, setStage] = React.useState(0);
  const [assignmentId, setAssignmentId] = React.useState('');

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    contact: Fluit.investors.Contact | undefined
  ) => {
    setPoperEl(event.currentTarget);
    setPopEl(event.currentTarget);
    setContact(contact);
  };

  const handlePopoverClose = () => {
    setPoperEl(null);
  };

  const handlePopoverHover = () => {
    setPoperEl(popEl);
  };

  const handlePopoverExit = () => {
    setPopEl(null);
    setPoperEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    index: number,
    assignment_id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setAssignmentId(assignment_id);
    setTarget(_.find(investors, investor => investor.id === id));
    setStage(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Investor</TableCell>
            <TableCell>Deal Contacts</TableCell>
            <TableCell>Step</TableCell>
            <TableCell align="right">Commitments</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(process.steps, (step, index) => {
            const assigned = _.filter(assignments, { step_id: step.id });

            if (_.some(assigned)) {
              return _.map(assigned, inv => {
                let investor = _.find(investors, investor => investor.id === inv.investor_id);
                let termsheet = _.find(termsheets, termsheet => termsheet.investor_id === inv.investor_id);
                return (
                  <TableRow key={inv.id}>
                    <TableCell>
                      <Typography variant="h6">
                        <Tooltip title={`View ${investor?.name}`}>
                          <Link component={NavLink} to={`${ROUTES.INVESTORS}/${investor?.id}`}>
                            {investor?.name}
                          </Link>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {_.map(inv.contacts, ctct => {
                        let contact = _.find(contacts, contact => contact.id === ctct);
                        return (
                          <Box key={ctct}>
                            <Typography
                              variant="body1"
                              className={classes.contact}
                              onMouseEnter={e => handlePopoverOpen(e, contact)}
                              onMouseLeave={handlePopoverClose}
                              aria-haspopup="true"
                            >
                              {contact?.first_name} {contact?.last_name}
                            </Typography>
                          </Box>
                        );
                      })}
                    </TableCell>
                    <TableCell key={index}>{step.name}</TableCell>
                    <TableCell align="right">
                      {deal.details?.currency.symbol} {termsheet ? numeral(termsheet.amount).format('0,0') : 0}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="secondary"
                        aria-controls="investor-contextual-menu"
                        aria-haspopup="true"
                        onClick={e => handleClick(e, investor ? investor.id : '', index, inv.id)}
                      >
                        <DotsHorizontal className={classes.svg} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              });
            }
          })}
          <Popover
            open={Boolean(poperEl)}
            anchorEl={poperEl}
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            id="mouse-over-popover"
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            PaperProps={{ onMouseEnter: handlePopoverHover, onMouseLeave: handlePopoverExit, elevation: 5 }}
            disableRestoreFocus
          >
            {contact ? (
              <ContactDisplay data={contact} />
            ) : (
              <Typography variant="body1">Error Loading Contact</Typography>
            )}
          </Popover>
          <Menu
            id="investor-contextual-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={4}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                dispatch({
                  type: 'MODAL',
                  payload: {
                    open: true,
                    title: `Manage ${target?.name}'s Contact's for this deal`,
                    component: 'DEAL_ASSIGN_CONTACTS',
                    id: target?.id,
                    stage: stage,
                    name: target?.name,
                    assignment_id: assignmentId,
                  },
                });
              }}
            >
              Manage Contacts
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                dispatch({
                  type: 'MODAL',
                  payload: {
                    open: true,
                    title: `Move ${target?.name}`,
                    component: 'DEAL_REASSIGN_INVESTOR',
                    id: target?.id,
                    stage: stage,
                    name: target?.name,
                    assignment_id: assignmentId,
                  },
                });
              }}
            >
              Move in Process
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                dispatch({
                  type: 'MODAL',
                  payload: {
                    open: true,
                    title: `Remove ${target?.name}`,
                    component: 'DEAL_UNASSIGN_INVESTOR',
                    id: assignmentId,
                  },
                });
              }}
            >
              Remove from Deal
            </MenuItem>
          </Menu>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvestorTable;
