import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import OnboardStepper from 'src/components/Stepper/Onboarding';
import OnboardDisplayHandler from 'src/components/Tabs/Onboarding/DisplayHandler';
import { AppContext } from 'src/contexts/AppContext';

const OnBoard: React.FC = () => {
  /* Context */
  const { state } = React.useContext(AppContext);
  const { invites } = state;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const belongs_to_org = state.organisation.id !== '' || _.some(state.organisations);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={7} md={8}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%" mb={mobile ? 8 : 0}>
          <OnboardDisplayHandler />
        </Box>
      </Grid>
      {!_.some(invites) && !belongs_to_org && !mobile ? (
        <Grid item xs={12} sm={5} md={4}>
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%" alignItems="flex-end">
            <OnboardStepper />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default OnBoard;
