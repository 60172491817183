import {
  Box,
  Chip,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { AppContext } from 'src/contexts/AppContext';
import { styles_ContactCard } from 'src/styles/cards';
import { DotsHorizontal } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

interface Props {
  data: Fluit.investors.Contact;
}

const ContactCard: React.FC<Props> = ({ data }) => {
  const classes = styles_ContactCard();
  const { dispatch } = React.useContext(AppContext);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper>
      <Box p={3} alignItems="center" display="flex" flexDirection="row" className={classes.box}>
        {data.system?.delete ? (
          <Box display="flex" justifyContent="center" width="100%">
            <GenericLoader height="100%" variant="none" />
          </Box>
        ) : (
          <Box className={classes.content}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="h4">
                  {data.first_name} {data.last_name}
                  {data.decision_maker ? (
                    <Chip size="small" label="Decision Maker" color="primary" style={{ marginLeft: mobile ? 0 : 8 }} />
                  ) : null}
                </Typography>
                {data.position ? <Typography variant="caption">{data.position}</Typography> : ''}
              </Box>
              <Box className={classes.button}>
                <IconButton
                  color="secondary"
                  aria-controls="user-contextual-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <DotsHorizontal className={classes.svg} />
                </IconButton>
              </Box>
            </Box>

            <Box className={classes.details}>
              {data.telephone ? (
                <Box>
                  <Typography variant="caption">Tel: </Typography>
                  <Typography variant="body2">
                    <Link color="secondary" href={`tel:${data.telephone}`}>
                      {data.telephone}
                    </Link>
                  </Typography>
                </Box>
              ) : null}

              {data.mobile ? (
                <Box>
                  <Typography variant="caption">Mob: </Typography>
                  <Typography variant="body2">
                    <Link color="secondary" href={`tel:${data.mobile}`}>
                      {data.mobile}
                    </Link>
                  </Typography>
                </Box>
              ) : null}

              {data.fax ? (
                <Box>
                  <Typography variant="caption">Fax: </Typography>
                  <Typography variant="body2">{data.fax}</Typography>
                </Box>
              ) : null}

              {data.email ? (
                <Box>
                  <Typography variant="caption">Email: </Typography>
                  <Typography variant="body2">
                    <Link color="secondary" href={`mailto:${data.email}`} target="_blank">
                      {data.email}
                    </Link>
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        )}
        <Menu
          id="user-contextual-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={4}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              dispatch({
                type: 'MODAL',
                payload: {
                  open: true,
                  title: `Edit ${data.first_name} ${data.last_name}`,
                  component: 'CONTACT_EDIT',
                  contact_id: data.id,
                },
              });
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              dispatch({
                type: 'MODAL',
                payload: {
                  open: true,
                  title: `Remove ${data.first_name} ${data.last_name}`,
                  component: 'CONTACT_DELETE',
                  contact_id: data.id,
                },
              });
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </Box>
    </Paper>
  );
};

export default ContactCard;
