import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const OrganisationsReducer = (
  state: Fluit.organisations.Organisation[],
  action: Fluit.reducer.actions.Actions
) => {
  switch (action.type) {
    case 'ORGANISATIONS_LIST':
      return {
        ...action.payload,
      };
    case 'ORGANISATIONS_SET':
      const ORGANISATIONS_SET = _.concat(
        _.filter(state, org => org.id !== action.payload.id),
        action.payload
      );
      return ORGANISATIONS_SET;
    case 'ORGANISATION_UPDATE':
      let filteredOrganisations = _.filter(state, org => org.id !== action.payload.id);
      let updatedOrganisations = _.concat(filteredOrganisations, action.payload);
      return updatedOrganisations;
    case 'ORGANISATION_DELETE':
      let keptOrganisations = _.filter(state, org => org.id !== action.payload.id);
      let newOrganisationArray = _.some(keptOrganisations) ? keptOrganisations : [];
      return { ...newOrganisationArray };
    case 'ORGANISATIONS_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
