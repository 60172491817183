import { makeStyles, Theme } from '@material-ui/core';
import colors from 'src/themes/colors';

export const styles_dealStatus = makeStyles((theme: Theme) => ({
  pulse: {
    borderRadius: '50%',
    background: colors.grey[100],
    width: '10px',
    height: '10px',
    margin: `0px 11px 0px 3px`,
  },
  live: {
    background: colors.blue[400],
  },
  complete: {
    background: colors.green[500],
  },
  cancelled: {
    background: colors.red[400],
  },
}));
