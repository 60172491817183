import { Breadcrumbs, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import * as React from 'react';
import { Route } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { CaretRight, Company } from 'src/themes/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svg: {
      width: '10px',
      height: '10px',
    },
    svgCompany: {
      width: '18px',
      height: '18px',
      marginRight: theme.spacing(0.5),
      fill: colors.black[400],
      '& g, & path': {
        fill: colors.black[400],
      },
    },
    org: {
      display: 'flex',
      alignItems: 'center',
    },
    breadcrumbs: {
      textTransform: 'capitalize',
      '& p': {
        color: theme.palette.grey[500],
        fontSize: '14px',
      },
      '& a': {
        fontSize: '14px',
        color: theme.palette.secondary.main,
        fontWeight: 600,
        textDecoration: 'none',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
    },
  })
);

const Breadcrumb: React.FC = () => {
  const { state } = React.useContext(AppContext);
  const { init } = state;
  const { payment_failed } = init;

  let { investor_id, deal_id, note_id } = useParams();
  let investor = _.find(state.investors, investor => investor.id === investor_id);
  let deal = _.find(state.deals, deal => deal.id === deal_id);
  let note = _.find(state.notes, note => note.id === note_id);

  const handleTitle = (value: string) => {
    if (value === investor_id) {
      return investor_id === 'search' ? 'search' : investor?.name;
    }
    if (value === deal_id) {
      return deal?.name;
    }
    if (value === note_id) {
      return note_id === 'create' ? 'create' : note?.title;
    }
    return value;
  };

  const classes = useStyles();
  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split('/').filter(x => x);

        return (
          <Breadcrumbs
            separator={<CaretRight className={classes.svg} />}
            aria-label="Breadcrumb"
            className={classes.breadcrumbs}
          >
            <RouterLink
              color="inherit"
              to={payment_failed ? ROUTES.ORGANISATIONS : ROUTES.DASHBOARD}
              className={classes.org}
            >
              <Company className={classes.svgCompany} />
              {state.organisation.name}
            </RouterLink>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;

              return last ? (
                <Typography color="textPrimary" key={to}>
                  {handleTitle(value)}
                </Typography>
              ) : (
                <RouterLink color="inherit" to={to} key={to}>
                  {handleTitle(value)}
                </RouterLink>
              );
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
};

export default Breadcrumb;
