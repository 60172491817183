import {
  Box,
  Button,
  Link,
  makeStyles,
  SwipeableDrawer,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import { AlgoliaContext } from 'src/contexts/AlgoliaContext';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';
import AlgoliaClearRefinements from './AlgoliaClearRefinements';
import AlgoliaRefinementList from './AlgoliaRefinementList';
import AlgoliaSelectedInvestors from './AlgoliaSelection';

const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    position: 'fixed',
    bottom: 100,
    right: 0,
    background: colors.navy[900],
    color: colors.common.white,
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(8, 0, 0, 8),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 99,
    '&:hover': {
      backgroundColor: colors.navy[700],
    },
  },
  textIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  drawer: {
    width: '100vw',

    [theme.breakpoints.only('sm')]: {
      width: '60vw',
    },
    padding: theme.spacing(3, 3, 12),
  },
  svgClose: {
    width: 24,
    height: 24,
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  apply: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    background: colors.common.white,
    boxShadow: shadows.black[2],
    width: '100%',
    [theme.breakpoints.only('sm')]: {
      width: '60vw',
      right: 0,
      left: 'initial',
    },
    padding: theme.spacing(2, 3),
  },
}));

const AlgoliaSidebar: React.FC = () => {
  const { search } = React.useContext(AlgoliaContext);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const filters = (
    <>
      <Box mb={3}>
        <AlgoliaRefinementList attribute="location" title="Head Office" searchable limit={6} />
      </Box>

      <Box mb={3}>
        <AlgoliaRefinementList attribute="regions" title="Region" searchable limit={6} />
      </Box>

      <Box mb={2}>
        <AlgoliaRefinementList attribute="sectors" title="Sector" searchable limit={6} />
      </Box>

      <Box mb={2}>
        <AlgoliaRefinementList attribute="stages" title="Stages" searchable limit={6} />
      </Box>
    </>
  );

  if (mobile) {
    return (
      <Box>
        <Button className={classes.sidebar} onClick={toggleDrawer(true)}>
          <Typography style={{ marginRight: 16 }}>{search.selected.length} Selected</Typography>
          <Typography className={classes.textIcon}>
            <FilterListIcon /> Filters
          </Typography>
        </Button>
        <AlgoliaSelectedInvestors />
        {/* <Button variant="contained" size="large" color="primary" fullWidth disabled={search.selected.length <= 0}>
          Add Selected Investors ({search.selected.length})
        </Button> */}
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          PaperProps={{
            className: classes.drawer,
          }}
        >
          <Box mb={2}>
            <Link onClick={toggleDrawer(false)} className={classes.link}>
              <ArrowBackIcon className={classes.svgClose} /> Back
            </Link>
          </Box>
          <AlgoliaSelectedInvestors />
          <Box mb={2} display="flex" alignItems="center">
            <Box flex={1}>
              <Typography variant="h2">Filters</Typography>
            </Box>
            <Box>
              <AlgoliaClearRefinements />
            </Box>
          </Box>

          {filters}
          <Box className={classes.apply}>
            <Button fullWidth onClick={toggleDrawer(false)} variant="outlined" color="primary" size="large">
              Apply
            </Button>
          </Box>
        </SwipeableDrawer>
      </Box>
    );
  }

  return (
    <Box>
      <AlgoliaSelectedInvestors />
      <Box mb={2} display="flex" alignItems="center">
        <Box flex={1}>
          <Typography variant="h2">Filters</Typography>
        </Box>
        <Box>
          <AlgoliaClearRefinements />
        </Box>
      </Box>

      {filters}
    </Box>
  );
};

export default AlgoliaSidebar;
