import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  LinearProgress,
  Link,
  makeStyles,
  Paper,
  Popper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { ErrorMessage, Field, Formik } from 'formik';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as GoogleIcon } from 'src/assets/images/google.svg';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { passwordRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { Check } from 'src/themes/icons';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  passed: {
    color: colors.green[700],
    '& $icon': {
      background: colors.green[700],
    },
  },
  unpassed: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1, 0),
    alignItems: 'center',
  },
  icon: {
    width: '20px',
    height: '20px',
    borderRadius: theme.spacing(20),
    background: colors.grey[50],
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svg: {
    width: '12px',
    height: '12px',
    fill: colors.common.white,
    '& g, & path': {
      fill: colors.common.white,
    },
  },
  google: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
    fill: theme.palette.primary.main,
    '& > *': {
      fill: theme.palette.primary.main,
    },
  },
}));

const RegisterForm: React.FC = () => {
  const classes = useStyles();
  const { dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const fbAuth = React.useContext(FirebaseContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [passed, setPassed] = React.useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const testPassword = (password: string) => {
    if (passwordRegex.test(password)) {
      setPassed(passwordRegex.test(password));
    }
    return passwordRegex.test(password);
  };

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    !passed ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const passwordPopper = open ? 'transitions-popper' : undefined;

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            if (result.additionalUserInfo?.isNewUser) {
              dispatch({ type: 'USER_REGISTERING', payload: true });
            }
          })
          .catch(e =>
            setToast({
              message: e.message,
              type: 'error',
            })
          );
      });
  };

  return (
    <Formik
      initialValues={{ email: '', password: '', confirmPassword: '', accept: false }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        dispatch({ type: 'USER_REGISTERING', payload: true });
        try {
          await fbAuth.registerUser(values.email, values.password);
          await fbAuth.sendEmailVerification();
        } catch (error) {
          setToast({
            message: error.message,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required to register'),
        password: Yup.string()
          .required('Password is required to register')
          .matches(
            passwordRegex,
            'Password must be more than 8 characters and contain, One Uppercase, One Lowercase, One Number and One Symbol'
          ),
        confirmPassword: Yup.string()
          .required('Confirm your password to register')
          .oneOf([Yup.ref('password'), null], "Password's don't match"),
        accept: Yup.boolean().oneOf([true], 'You must accept our Terms & Conditions before you can register.'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values, setFieldValue, errors } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  type="email"
                  inputProps={{
                    autoComplete: 'email',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  aria-describedby={passwordPopper}
                  name="password"
                  label="Password"
                  type="password"
                  inputProps={{
                    autoComplete: 'password',
                  }}
                  onFocus={handleFocus}
                  variant="outlined"
                />
                {!mobile ? (
                  <Popper
                    id={passwordPopper}
                    open={!testPassword(values.password) && !passed && anchorEl ? true : false}
                    anchorEl={anchorEl}
                    placement="right"
                    style={{ marginLeft: '16px' }}
                    disablePortal={false}
                    modifiers={{
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                      },
                    }}
                  >
                    <Paper elevation={4}>
                      <Box p={2} pr={4}>
                        <Typography variant="h6">Password must contain</Typography>
                        <Box className={clsx(/[A-Z]/.test(values.password) && classes.passed, classes.unpassed)}>
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">One uppercase character</Typography>
                        </Box>
                        <Box className={clsx(/[a-z]/.test(values.password) && classes.passed, classes.unpassed)}>
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">One lowercase character</Typography>
                        </Box>
                        <Box className={clsx(/[0-9]/.test(values.password) && classes.passed, classes.unpassed)}>
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">One number</Typography>
                        </Box>
                        <Box
                          className={clsx(
                            /[!@#$%^&*()\-_=+{};:,<.>]/.test(values.password) && classes.passed,
                            classes.unpassed
                          )}
                        >
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">One symbol</Typography>
                        </Box>
                        <Box className={clsx(/.{8}/.test(values.password) && classes.passed, classes.unpassed)}>
                          <Box className={classes.icon}>
                            <Check className={classes.svg} />
                          </Box>
                          <Typography variant="subtitle2">8 characters</Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Popper>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  inputProps={{
                    autoComplete: 'confirm-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl name="accept" component="fieldset" error={errors.accept ? true : false}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.accept}
                        onChange={e => setFieldValue('accept', e.target.checked)}
                        name="accept"
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body1">
                        I agree to Fluit's{' '}
                        <Link target="_blank" component={NavLink} to={ROUTES.TERMS}>
                          Terms of Service
                        </Link>
                      </Typography>
                    }
                  />
                  <FormHelperText>
                    <ErrorMessage name="accept" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth={mobile}
                  style={{ marginRight: mobile ? 0 : '16px', marginBottom: mobile ? 16 : 0 }}
                >
                  Register
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  disabled={isSubmitting}
                  fullWidth={mobile}
                  color="primary"
                  onClick={signInWithGoogle}
                >
                  <GoogleIcon className={classes.google} /> Signup with Google
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
