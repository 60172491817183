import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React from 'react';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import FluitStyles from 'src/themes/Styles';

const OnboardStepper: React.FC = () => {
  const { onboard } = React.useContext(OnboardingContext);
  const { step } = onboard;

  const classes = FluitStyles.Stepper.stepper();

  return (
    <Stepper activeStep={step} orientation="vertical" className={classes.stepper}>
      <Step key="user">
        <StepLabel>Your Details</StepLabel>
      </Step>

      <Step key="organisation">
        <StepLabel>Organisation Details</StepLabel>
      </Step>

      <Step key="funding">
        <StepLabel>Funding Details</StepLabel>
      </Step>

      <Step key="review">
        <StepLabel>Review &amp; Complete</StepLabel>
      </Step>
    </Stepper>
  );
};

export default OnboardStepper;
