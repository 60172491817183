import { Box, Chip, IconButton, Link, makeStyles, Menu, MenuItem, Paper, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { formatDate } from 'src/helpers/formatDate';
import colors from 'src/themes/colors';
import { DotsHorizontal, UsersInvited } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '20px',
    height: '20px',
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  chip: {
    textTransform: 'capitalize',
    color: theme.palette.common.white,
  },
  owner: { background: colors.purple[500] },
  member: { background: colors.purple[300] },
  pending: { background: colors.grey[300], marginLeft: theme.spacing(1) },
  invited: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(2),
  },
  card: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  inner: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

interface Props {
  user: Fluit.organisations.Invitee;
  role: string;
}

const UserInvitedCard: React.FC<Props> = ({ user, role }) => {
  const { email, created_at, invited_by } = user;
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AppContext);
  const admin = state.init;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const invitor = _.find(state.members, { id: invited_by });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper style={{ height: '100%' }}>
      <Box px={3} py={2} className={classes.card}>
        <Box className={classes.inner}>
          <UsersInvited className={classes.invited} />

          <Typography variant="body2" style={{ flexGrow: 1 }}>
            <Link color="secondary" href={`mailto:${email}`} target="_blank">
              {email}
            </Link>
          </Typography>

          <Typography variant="body2" style={{ flexGrow: 1 }}>
            <em>invited on:</em> {formatDate(created_at)}
          </Typography>

          <Typography variant="body2" style={{ flexGrow: 1 }}>
            <em>by:</em> {invitor?.first_name} {invitor?.last_name}
          </Typography>

          <Box flexDirection="row" display="flex">
            <Chip
              className={clsx(classes.chip, role === 'member' && classes.member, role === 'owner' && classes.owner)}
              label="Member"
            />

            <Chip className={clsx(classes.chip, classes.pending)} label="Invited" />
          </Box>
        </Box>
        {admin ? (
          <>
            <IconButton
              color="secondary"
              aria-controls="user-contextual-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ marginLeft: '16px' }}
            >
              <DotsHorizontal className={classes.svg} />
            </IconButton>
            <Menu
              id="user-contextual-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              elevation={4}
            >
              <MenuItem
                onClick={() => {
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      open: true,
                      title: `Revoke invite for ${email}?`,
                      component: 'USER_REVOKE',
                      user: email,
                    },
                  });
                  setAnchorEl(null);
                }}
              >
                Revoke Invite
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </Box>
    </Paper>
  );
};

export default UserInvitedCard;
