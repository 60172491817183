import colors from 'src/themes/colors';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const billing_creditCardStyles = {
  base: {
    fontSize: '16px',
    backgroundColor: colors.common.white,
    color: colors.black[900],
    fontFamily: `'montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'`,
    '::placeholder': {
      color: colors.black[400],
    },
  },
  invalid: {
    color: colors.red[500],
    iconColor: colors.red[500],
  },
};

export const styles_billingUnitInverval = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300 + theme.spacing(3) * 2,
    },
    icon: {
      fill: colors.grey[50],
      '& g, & path': {
        fill: colors.grey[50],
      },
    },
    paper: {
      marginBottom: theme.spacing(4),
    },
    margin: {
      height: theme.spacing(3),
    },
    chip: {
      marginTop: theme.spacing(1),
    },
    toggle: {
      width: '100%',
      display: 'flex',
      flexDirect: 'row',
    },
    toggleButton: {
      display: 'flex',
      flex: 1,
      textTransform: 'none',
      border: `2px solid ${colors.common.background}`,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: colors.common.background,
      },
      '&$toggleSelected': {
        border: `2px solid ${colors.purple.main}`,
        backgroundColor: colors.common.white,
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: colors.common.white,
        },
      },
    },
    toggleLabel: {
      display: 'flex',
      flexDirection: 'column',
    },
    toggleSelected: {},
    check: {
      color: colors.purple.main,
    },
  })
);

export const styles_subscription = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    content: {
      flex: 1,
      padding: theme.spacing(0, 4, 0, 0),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    actions: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(2),
      },
    },
    button: {
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(0.5),
      },
    },
    button_delete: {
      color: colors.red.main,
      '& $button_icon': {
        width: 16,
        height: 16,
        '& g, & path': {
          fill: colors.red.main,
        },
      },
      '&:hover': {
        background: colors.red[50],
      },
    },
    button_icon_container: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button_icon: {
      width: 20,
      height: 20,
      '& g, & path': {
        fill: theme.palette.secondary.main,
      },
    },
    chip: {
      marginBottom: theme.spacing(1),
    },
  })
);

export const styles_billingPlan = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300 + theme.spacing(3) * 2,
    },
    margin: {
      height: theme.spacing(3),
    },
    chip: {
      marginLeft: theme.spacing(1),
    },
    toggle: {
      width: '100%',
    },
    toggleButton: {
      display: 'flex',
      textTransform: 'none',
      border: `2px solid ${colors.grey[100]}`,
      color: theme.palette.text.primary,
      padding: theme.spacing(3),
      '&:hover': {
        backgroundColor: colors.common.white,
      },
      '&$toggleSelected': {
        border: `2px solid ${colors.purple.main}`,
        backgroundColor: colors.common.white,
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: colors.common.white,
        },
      },
      '&:not(:first-child)': {
        borderTopWidth: '2px',
        marginTop: 0,
      },
    },
    toggleLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    toggleSelected: {},
    check: {
      color: colors.purple.main,
    },
  })
);

export const styles_billingCycle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300 + theme.spacing(3) * 2,
    },
    margin: {
      height: theme.spacing(3),
    },
    chip: {
      marginLeft: theme.spacing(1),
    },
    toggle: {
      width: '100%',
      display: 'flex',
      flexDirect: 'row',
    },
    toggleButton: {
      display: 'flex',
      flex: 1,
      textTransform: 'none',
      border: `2px solid ${colors.grey[100]}`,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: colors.common.white,
      },
      '&$toggleSelected': {
        border: `2px solid ${colors.purple.main}`,
        backgroundColor: colors.common.white,
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: colors.common.white,
        },
      },
    },
    toggleLabel: {
      display: 'flex',
      flexDirection: 'column',
    },
    toggleSelected: {},
    check: {
      color: colors.purple.main,
    },
  })
);
