import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';
import FirebaseClient from 'src/services/firebaseAuth';
const fb = FirebaseClient;

export const InvestorFirebase = {
  create: async (investor: Fluit.investors.Investor) => {
    try {
      const resp = await API({
        endpoint: `organisations/${investor.org_id}/investors`,
        method: 'POST',
        payload: investor,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  get: async (orgId: string, investorId: string): Promise<Fluit.investors.Investor> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/investors/${investorId}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (investor: Fluit.investors.Investor) => {
    delete investor.created_at;
    delete investor.updated_at;
    try {
      const resp = await API({
        endpoint: `organisations/${investor.org_id}/investors/${investor.id}`,
        method: 'PATCH',
        payload: investor,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (investor: Fluit.investors.Investor, orgId: string) => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/investors/${investor.id}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (orgId: string) => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/investors`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  fluitGet: async (investorId: string) => {
    const investor = await fb
      .firestore()
      .collection('investors')
      .doc(investorId)
      .get();
    const result: unknown = Promise.resolve(investor.data());
    return result as Fluit.investors.Firm;
  },
};
