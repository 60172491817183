import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { isoToFlag } from 'src/helpers/isoToFlag';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& th, & td': {
      borderBottom: 'none',
      padding: theme.spacing(0.5, 0),
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        width: 'auto',
        padding: 0,
      },
    },
    '& th': {
      whiteSpace: 'nowrap',
      verticalAlign: 'top',
      paddingRight: theme.spacing(4),
      color: theme.palette.grey[700],
      minWidth: '160px',
      width: '160px',
    },
    '& tr': {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        marginBottom: theme.spacing(2),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const InvestorTabLegal: React.FC = () => {
  const classes = useStyles();
  const { dispatch } = React.useContext(AppContext);
  const { investor } = React.useContext(InvestorContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Legal &amp; Signatory</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Box p={3}>
            <TableContainer>
              <Table aria-label="investor table" className={classes.root}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Legal Name
                    </TableCell>
                    <TableCell>{investor.legal.legal_name ? investor.legal.legal_name : '-'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Registration №
                    </TableCell>
                    <TableCell>{investor.legal.registration_no ? investor.legal.registration_no : '-'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Telephone
                    </TableCell>
                    <TableCell>
                      {investor.legal.telephone ? (
                        <Link color="secondary" href={`tel:${investor.legal.telephone}`} target="_blank">
                          {investor.legal.telephone}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Fax
                    </TableCell>
                    <TableCell>{investor.legal.fax ? investor.legal.fax : '-'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Email
                    </TableCell>
                    <TableCell>
                      {investor.legal.email ? (
                        <Link color="secondary" href={`mailto:${investor.legal.email}`} target="_blank">
                          {investor.legal.email}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Website
                    </TableCell>
                    <TableCell>
                      {investor.legal.website ? (
                        <Link color="secondary" href={investor.legal.website} target="_blank">
                          {investor.legal.website}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Address
                    </TableCell>
                    <TableCell>
                      {investor.legal.address.line_1 ? `${investor.legal.address.line_1}, ` : ''}
                      {investor.legal.address.line_2 ? `${investor.legal.address.line_2}, ` : ''}
                      {investor.legal.address.town ? `${investor.legal.address.town}, ` : ''}
                      {investor.legal.address.city ? `${investor.legal.address.city}, ` : ''}
                      {investor.legal.address.postcode ? `${investor.legal.address.postcode}, ` : ''}
                      {investor.legal.address.country.name ? (
                        <>
                          {isoToFlag(investor.legal.address.country.iso)} {investor.legal.address.country.name}
                        </>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Notes
                    </TableCell>
                    <TableCell>{investor.legal.notes ? investor.legal.notes : '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={3}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      open: true,
                      title: `Edit ${investor.name}'s legal details`,
                      component: 'INVESTOR_LEGAL',
                      investor_id: investor.id,
                    },
                  })
                }
              >
                Edit Legal Details
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4">Signatory</Typography>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Box p={3}>
            <TableContainer>
              <Table aria-label="investor table" className={classes.root}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Name
                    </TableCell>
                    <TableCell>
                      {investor.signatory.first_name
                        ? `${investor.signatory.first_name} ${investor.signatory.last_name}`
                        : '-'}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Telephone
                    </TableCell>
                    <TableCell>
                      {investor.signatory.telephone ? (
                        <Link color="secondary" href={`tel:${investor.signatory.telephone}`} target="_blank">
                          {investor.signatory.telephone}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Fax
                    </TableCell>
                    <TableCell>{investor.signatory.fax ? investor.signatory.fax : '-'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Email
                    </TableCell>
                    <TableCell>
                      {investor.signatory.email ? (
                        <Link color="secondary" href={`mailto:${investor.signatory.email}`} target="_blank">
                          {investor.signatory.email}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Address
                    </TableCell>
                    <TableCell>
                      {investor.signatory.address.line_1 ? `${investor.signatory.address.line_1}, ` : ''}
                      {investor.signatory.address.line_2 ? `${investor.signatory.address.line_2}, ` : ''}
                      {investor.signatory.address.town ? `${investor.signatory.address.town}, ` : ''}
                      {investor.signatory.address.postcode ? `${investor.signatory.address.postcode}, ` : ''}
                      {investor.signatory.address.city ? `${investor.signatory.address.city}, ` : ''}
                      {investor.signatory.address.country.name ? (
                        <>
                          {isoToFlag(investor.signatory.address.country.iso)} {investor.signatory.address.country.name}
                        </>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Witness
                    </TableCell>
                    <TableCell>{investor.signatory.witness === true ? 'Required' : 'Not Required'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Notes
                    </TableCell>
                    <TableCell>{investor.signatory.notes ? investor.signatory.notes : '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={3}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      open: true,
                      title: `Edit ${investor.name}'s signatory details`,
                      component: 'INVESTOR_SIGNATORY',
                      investor_id: investor.id,
                    },
                  })
                }
              >
                Edit Signatory Details
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InvestorTabLegal;
