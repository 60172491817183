import { Box, Button, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import DealCard from 'src/components/Cards/Deal';
import NoData from 'src/components/Empty';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { firebaseDateToIso } from 'src/helpers/firebaseDateToIso';
import { DealFirebase } from 'src/services/deals';
import { TaskFirebase } from 'src/services/tasks';
import { DealsNone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const Deals: React.FC = () => {
  const firebase = React.useContext(FirebaseContext);
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { deals, organisation } = state;
  const { limits } = organisation;

  const hit_deal_limit = limits.deals.count >= limits.deals.limit;

  const newDeal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: hit_deal_limit ? '' : 'New Deal',
        component: hit_deal_limit ? 'UPGRADE_DEALS' : 'DEAL_CREATE',
        centered: hit_deal_limit,
      },
    });
  };

  React.useEffect(() => {
    (async () => {
      try {
        const result = await DealFirebase.list(organisation.id);
        dispatch({ type: 'DEALS_LIST', payload: result });
      } catch (error) {
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();
  }, [organisation.id, dispatch, setToast]);

  React.useEffect(() => {
    (async () => {
      try {
        const result = await TaskFirebase.list(organisation.id);
        dispatch({ type: 'TASKS_LIST', payload: result });
      } catch (error) {
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();
  }, [organisation.id, dispatch, setToast]);

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('organisations')
      .doc(organisation.id)
      .collection('assignments')
      .where('system.disabled', '==', false)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          const assignment = change.doc.data() as Fluit.assignments.Assignment;
          assignment.assigned_at = firebaseDateToIso(assignment.assigned_at);
          assignment.updated_at = firebaseDateToIso(assignment.updated_at);
          dispatch({ type: 'ASSIGNMENT_SET', payload: assignment });
        });
      });

    return () => {
      unsubscribe();
    };
  }, [firebase, dispatch, organisation.id]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" justifyItems="center" justifyContent="space-between">
          <Typography variant="h1">Deals</Typography>
          <Button variant="outlined" color="secondary" onClick={() => newDeal()}>
            New Deal
          </Button>
        </Box>
      </Grid>

      {_.some(deals) ? (
        _.map(deals, deal => {
          return (
            <Grid item xs={12} sm={6} md={12} lg={6} xl={4} key={deal.id}>
              <DealCard deal={deal} />
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12}>
          <NoData
            text={`Deals you create will display here.`}
            icon={<DealsNone />}
            label="Create Deal"
            action={newDeal}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Deals;
