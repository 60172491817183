import { CssBaseline, MuiThemeProvider, StylesProvider, ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import history from 'src/helpers/history';
import App from 'src/pages/App';
import themeLight from 'src/themes/fluit-light';
import ScrollToTop from './components/Layout/ScrollToTop';
import { AlgoliaProvider } from './contexts/AlgoliaContext';
import { AppProvider } from './contexts/AppContext';
import { CheckoutProvider } from './contexts/CheckoutContext';
import { DealProvider } from './contexts/DealContext';
import { FirebaseContext } from './contexts/FirebaseContext';
import { InvestorProvider } from './contexts/InvestorContext';
import { OnboardingProvider } from './contexts/OnboardContext';
import { ProcessProvider } from './contexts/ProcessContext';
import { SystemActivityProvider } from './contexts/SystemActivityContext';
import { ToastProvider } from './contexts/ToastContext';
import { ToursProvider } from './contexts/TourContext';
import { UserActivityProvider } from './contexts/UserActivityContext';
import FirebaseClient from './services/firebaseAuth';

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));
Sentry.init({ dsn: String(process.env.REACT_APP_SENTRY_KEY) });

ReactDOM.render(
  <MuiThemeProvider theme={themeLight}>
    <ThemeProvider theme={themeLight}>
      <StylesProvider injectFirst>
        <ToastProvider>
          <ToursProvider>
            <AppProvider>
              <InvestorProvider>
                <DealProvider>
                  <ProcessProvider>
                    <OnboardingProvider>
                      <CheckoutProvider>
                        <FirebaseContext.Provider value={FirebaseClient}>
                          <Elements stripe={stripePromise}>
                            <AlgoliaProvider>
                              <UserActivityProvider>
                                <SystemActivityProvider>
                                  <Router history={history}>
                                    <ScrollToTop />
                                    <CssBaseline />
                                    <App />
                                  </Router>
                                </SystemActivityProvider>
                              </UserActivityProvider>
                            </AlgoliaProvider>
                          </Elements>
                        </FirebaseContext.Provider>
                      </CheckoutProvider>
                    </OnboardingProvider>
                  </ProcessProvider>
                </DealProvider>
              </InvestorProvider>
            </AppProvider>
          </ToursProvider>
        </ToastProvider>
      </StylesProvider>
    </ThemeProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
