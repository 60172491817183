import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const InvitedMembersReducer = (state: Fluit.organisations.Invitee[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'INVITED_LIST':
      return _.orderBy(action.payload, ['email', 'created_at'], ['asc', 'asc']);
    case 'INVITED_CREATE':
      const INVITED_CREATE = () => {
        const exists = _.find(state, { email: action.payload.email });
        if (exists) {
          return state;
        }
        return _.orderBy(_.concat(_.values(state), action.payload), ['email', 'created_at'], ['asc', 'asc']);
      };
      return INVITED_CREATE();
    case 'INVITED_SET':
      const INVITED_SET = _.orderBy(_.concat(_.values(state), action.payload), ['email', 'created_at'], ['asc', 'asc']);
      return INVITED_SET;
    case 'INVITED_REMOVE':
      let removedUser = _.filter(state, user => user.email !== action.payload.email);
      return _.orderBy(removedUser, ['email', 'created_at'], ['asc', 'asc']);
    case 'INVITED_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
