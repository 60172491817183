import { initalise } from 'src/constants/init';
import Fluit from 'src/types/Fluit';

export const InitReducer = (state: Fluit.services.Initalisation, action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'API_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'API_ACTIVITY_USER_ALL_LOADED':
      return {
        ...state,
        activity_user: action.payload,
      };
    case 'API_ACTIVITY_SYSTEM_ALL_LOADED':
      return {
        ...state,
        activity_system: action.payload,
      };
    case 'API_ACTIVITY_USER_CALLED':
      return {
        ...state,
        activity_user_called: action.payload,
      };
    case 'API_ACTIVITY_SYSTEM_CALLED':
      return {
        ...state,
        activity_system_called: action.payload,
      };
    case 'API_ASSIGNMENTS_INIT':
      return {
        ...state,
        assignments: action.payload,
      };
    case 'API_TERMSHEETS_INIT':
      return {
        ...state,
        termsheets: action.payload,
      };
    case 'API_SUBSCRIPTIONS_INIT':
      return {
        ...state,
        subscriptions: action.payload,
      };
    case 'API_USER_INIT':
      return {
        ...state,
        user: action.payload,
      };
    case 'USER_REGISTERING':
      return {
        ...state,
        registering: action.payload,
      };

    case 'API_MEMBERS_INIT':
      return {
        ...state,
        users: action.payload,
      };
    case 'API_INVESTORS_INIT':
      return {
        ...state,
        investors: action.payload,
      };
    case 'API_PROCESSES_INIT':
      return {
        ...state,
        processes: action.payload,
      };
    case 'API_DEALS_INIT':
      return {
        ...state,
        deals: action.payload,
      };
    case 'API_CONTACTS_INIT':
      return {
        ...state,
        contacts: action.payload,
      };
    case 'API_TASKS_INIT':
      return {
        ...state,
        tasks: action.payload,
      };
    case 'API_ORGANISATIONS_INIT':
      return {
        ...state,
        organisation: action.payload,
      };
    case 'API_FINISHED':
      return {
        ...state,
        initalized: action.payload,
      };
    case 'ADMIN_SET':
      return {
        ...state,
        admin: action.payload,
      };
    case 'BUYING_SET':
      return {
        ...state,
        buying: action.payload,
      };
    case 'SWITCHING_ORGANISATION':
      return {
        ...state,
        switching: action.payload,
      };
    case 'APP_RELOAD':
      return {
        ...state,
        reload: action.payload,
      };
    case 'API_FLUSH_INIT': {
      return initalise.application.init;
    }
    case 'SYSTEM_PAYMENT_FAILED': {
      return {
        ...state,
        payment_failed: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
