import { Box, CircularProgress, Divider, TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderGroupParams, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { ContactFirebase } from 'src/services/contacts';
import Fluit from 'src/types/Fluit';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  touched: boolean | undefined;
  error: string | undefined;
  label: string;
  value?: string;
  childName?: string;
}

const AutocompleteContactsbyInvestor: React.FC<Props> = ({
  setFieldValue,
  name,
  id,
  touched,
  error,
  label,
  value,
  childName,
}) => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { contacts, organisation, investors } = state;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Fluit.investors.Contact[]>([]);
  const [fetched, setFetched] = React.useState(false);
  const loading = open && options.length === 0 && !fetched;

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    if (_.some(contacts)) {
      const contacts_with_investor_name = _.map(contacts, contact => {
        const investor = _.find(investors, { id: contact.investor_id });
        contact.investor_name = investor?.name;
        return contact;
      });
      setOptions(_.values(contacts_with_investor_name));
      return undefined;
    }

    if (fetched) {
      return undefined;
    }

    (async () => {
      try {
        const result = await ContactFirebase.list(organisation.id);
        dispatch({ type: 'CONTACTS_LIST', payload: result });
        setFetched(true);
      } catch (error) {
        const err = error as Fluit.firestore.Error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();
  }, [contacts, organisation, loading, dispatch, setToast, fetched, investors]);

  let val: Fluit.investors.Contact | null = null;

  if (value) {
    const find = _.find(contacts, { id: value });
    val = find ? find : null;
  }

  return (
    <Field
      name={name}
      id={id ? id : name}
      component={Autocomplete}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      groupBy={(option: Fluit.investors.Contact) => option.investor_name}
      getOptionLabel={(option: Fluit.investors.Contact) => `${option.first_name} ${option.last_name}`}
      value={val}
      onChange={(event: object, value: Fluit.investors.Contact) => {
        if (childName) {
          setFieldValue(name, value ? value.investor_id : '');
          setFieldValue(childName, value ? value.id : '');
        } else {
          setFieldValue(name, value ? value.id : '');
        }
      }}
      noOptionsText="No contacts available"
      renderGroup={(option: AutocompleteRenderGroupParams) => (
        <Box key={option.key} mb={1}>
          <Box px={2} py={1}>
            <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
              {option.group}
            </Typography>
          </Box>
          {option.children}
          <Divider light={true} />
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteContactsbyInvestor;
