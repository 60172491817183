import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import CheckoutInterval from 'src/components/Checkout/Interval';
import CheckoutProduct from 'src/components/Checkout/Product';
import CheckoutSummary from 'src/components/Checkout/Summary';
import { AppContext } from 'src/contexts/AppContext';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { BillingFirebase } from 'src/services/billing';

const CheckoutCatalog: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { billing, organisation, user } = state;
  const { customer } = billing;
  const { checkout, setCheckout } = React.useContext(CheckoutContext);
  const { products } = checkout;
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  React.useEffect(() => {
    if (!firstLoad) {
      return undefined;
    }

    if (!customer) {
      (async () => {
        try {
          const result = await BillingFirebase.getCustomerData(organisation.id, billing.stripe_customer_id);
          dispatch({ type: 'BILLING_CUSTOMER_GET', payload: result });
        } catch (error) {}
      })();
    }

    if (customer) {
      const cust = {
        id: customer.id,
        name: customer.name ? customer.name : organisation.name,
        phone: customer.phone ? customer.phone : organisation.telephone,
        email: customer.email ? customer.email : user.email,
        address: {
          line1: customer.address?.line1 ? customer.address?.line1 : '',
          line2: customer.address?.line2 ? customer.address?.line2 : '',
          city: customer.address?.city ? customer.address?.city : '',
          country: customer.address?.country ? customer.address?.country.toLowerCase() : organisation.location.iso,
          state: customer.address?.state ? customer.address?.state : '',
          postal_code: customer.address?.postal_code ? customer.address?.postal_code : '',
        },
        metadata: {
          org_id: customer.metadata.org_id ? customer.metadata.org_id : organisation.id,
          tax_country: customer.metadata.tax_country ? customer.metadata.tax_country : '',
        },
      };
      setCheckout({
        ...checkout,
        customer: cust,
      });
    }
    setFirstLoad(false);
  }, [customer, checkout, organisation, setCheckout, user, firstLoad, dispatch, billing.stripe_customer_id]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={7} lg={8}>
        <Box mr={desktop ? 8 : 0}>
          <CheckoutInterval />
          <CheckoutProduct product={products.users.standard} type="users" />
          <CheckoutProduct product={products.deals.fundraise} type="deals" />
        </Box>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <CheckoutSummary />
      </Grid>
    </Grid>
  );
};

export default CheckoutCatalog;
