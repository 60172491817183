import { Box, Button, LinearProgress, Link, Paper, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { isoToFlag } from 'src/helpers/isoToFlag';
import { OrganisationFirebase } from 'src/services/organisations';
import Fluit from 'src/types/Fluit';

const OnboardJoinOrganisation: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { user, invites, organisations } = state;
  const { setToast } = React.useContext(ToastContext);

  const [submitting, setSubmitting] = React.useState(false);

  const getInvites = React.useCallback(async () => {
    try {
      const result = await OrganisationFirebase.getInvites();
      if (!_.some(result)) {
        dispatch({
          type: 'MODAL',
          payload: {
            ...state.ui.modal,
            open: false,
            close: false,
          },
        });
      }
      dispatch({ type: 'INVITES_LIST', payload: result });
    } catch (error) {
      const err: Fluit.firestore.Error = error;
      setToast({
        type: 'error',
        message: `${err.name} | ${err.message}`,
      });
    }
  }, [setToast, dispatch, state.ui.modal]);

  const acceptInvite = React.useCallback(
    async (organisation: Fluit.organisations.Organisation) => {
      setSubmitting(true);

      try {
        await OrganisationFirebase.acceptInvite(organisation.id, user);
        const result = await OrganisationFirebase.get(organisation.id);
        setToast({
          message: `You have joined ${organisation.name}`,
          type: 'success',
        });
        getInvites();
        setSubmitting(false);
        if (_.values(organisations).length > 0) {
          dispatch({ type: 'ORGANISATIONS_SET', payload: result });
        } else {
          /* SET ORG */
          dispatch({ type: 'ORGANISATION_SET', payload: result });
          dispatch({ type: 'ORGANISATIONS_LIST', payload: [result] });
          localStorage.removeItem('fluit::organisation');
          localStorage.setItem('fluit::organisation', result.id);
          dispatch({ type: 'SWITCHING_ORGANISATION', payload: true });
        }
      } catch (error) {
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
        setSubmitting(false);
      }
    },
    [setToast, dispatch, getInvites, user, organisations]
  );

  const declineInvite = React.useCallback(
    (organisation: Fluit.organisations.Organisation) => {
      setSubmitting(true);
      try {
        OrganisationFirebase.revokeInvite(organisation, user.email);
        setToast({
          message: `You have declined to join ${organisation.name}`,
          type: 'info',
        });
        dispatch({ type: 'INVITE_DECLINE', payload: organisation });
        setSubmitting(false);
        if (_.values(invites).length <= 1) {
          dispatch({
            type: 'MODAL',
            payload: {
              ...state.ui.modal,
              open: false,
            },
          });
        }
      } catch (error) {
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
        setSubmitting(false);
      }
    },
    [setToast, dispatch, invites, state.ui.modal, user.email]
  );

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h1">Join Organisation{invites.length > 1 ? `'s` : null}</Typography>
      </Box>

      {_.map(invites, invite => (
        <Box key={invite.id}>
          <Paper>
            <Box p={3} mb={3}>
              <Typography variant="h4">{invite.name}</Typography>
              <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                {isoToFlag(invite.location.iso)} {invite.location.name}
              </Typography>
              <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                <Typography variant="caption">Industry: </Typography>
                {invite.industry}
              </Typography>
              <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                <Typography variant="caption">Founded: </Typography>
                {invite.year_founded}
              </Typography>

              <Typography variant="body1">
                <Link color="secondary" href={`tel:${invite.telephone}`}>
                  <Typography variant="caption">Telephone: </Typography> {invite.telephone}
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link color="secondary" href={`mailto:${invite.email}`}>
                  <Typography variant="caption">Email: </Typography> {invite.email}
                </Link>
              </Typography>
              {invite.website && (
                <Typography variant="body1">
                  <Link color="secondary" href={`${invite.website}`} target="_blank">
                    <Typography variant="caption">Website: </Typography> {invite.website}
                  </Link>
                </Typography>
              )}
            </Box>
          </Paper>
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => acceptInvite(invite)}
              style={{ marginRight: '16px' }}
              disabled={submitting}
            >
              Accept Invitation
            </Button>
            <Button variant="outlined" size="large" disabled={submitting} onClick={() => declineInvite(invite)}>
              Decline Invitation
            </Button>
          </Box>
          {submitting ? (
            <Box mt={3}>
              <LinearProgress variant="query" />
            </Box>
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

export default OnboardJoinOrganisation;
