import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';

export const DealFirebase = {
  create: async (deal: Fluit.deals.Deal) => {
    try {
      const resp = await API({
        endpoint: `organisations/${deal.org_id}/deals`,
        method: 'POST',
        payload: deal,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  get: async (org_id: string, deal_id: string): Promise<Fluit.deals.Deal> => {
    try {
      const resp = await API({
        endpoint: `organisations/${org_id}/deals/${deal_id}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (deal: Fluit.deals.Deal) => {
    try {
      const resp = await API({
        endpoint: `organisations/${deal.org_id}/deals/${deal.id}`,
        method: 'PATCH',
        payload: deal,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (deal: Fluit.deals.Deal) => {
    try {
      const resp = await API({
        endpoint: `organisations/${deal.org_id}/deals/${deal.id}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (orgId: string): Promise<Fluit.deals.Deal[]> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/deals`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
