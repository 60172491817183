import { Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles(() => ({
  legal: {
    '& h2': {
      fontSize: 30,
    },
    '& h3': {
      fontSize: 24,
      marginBottom: 24,
    },
    '& p': {
      marginBottom: 16,
    },
  },
}));

const TermsAndConditions: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.legal}>
      <Grid item xs={12}>
        <Typography variant="h1">Terms &amp; Conditions</Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert variant="outlined" severity="info">
          READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">TERMS OF WEBSITE USE</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          This terms of use (together with the documents referred to in it) tells you the terms of use on which you may
          make use of our website <Link href="https://fluit.co">fluit.co</Link> (<b>our site</b>), whether as a guest or
          a registered user. Use of our site includes accessing, browsing, or registering to use our site.
        </Typography>
        <Typography variant="body1">
          Please read these terms of use carefully before you start to use our site, as these will apply to your use of
          our site. We recommend that you print a copy of this for future reference.
        </Typography>
        <Typography variant="body1">
          By using our site, you confirm that you accept these terms of use and that you agree to comply with them.
        </Typography>
        <Typography variant="body1">If you do not agree to these terms of use, you must not use our site.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Other applicable terms</Typography>
        <Typography variant="body1">
          These terms of use refer to the following additional terms, which also apply to your use of our site:
        </Typography>
        <ul>
          <li>
            <Link component={NavLink} to={ROUTES.PRIVACY}>
              Our Privacy Policy
            </Link>{' '}
            which sets out the terms on which we process any personal data we collect from you, or that you provide to
            us. By using our site, you consent to such processing and you warrant that all data provided by you is
            accurate.
          </li>
          <li>
            <Link component={NavLink} to={ROUTES.ACCEPTABLE_USE}>
              Our Acceptable Use Policy
            </Link>{' '}
            which sets out the permitted uses and prohibited uses of our site. When using our site, you must comply with
            this Acceptable Use Policy.
          </li>
          <li>
            <Link component={NavLink} to={ROUTES.COOKIE}>
              Our Cookie Policy
            </Link>{' '}
            which sets out information about the cookies on our site.
          </li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Information about us</Typography>
        <Typography variant="body1">
          <Link href="https://fluit.co">fluit.co</Link> is a site operated by Fluit Tech Ltd (“We”). We are registered
          in England and Wales under company number 09495698 and have our registered office at 55 Commodore House, 2
          Admiralty Avenue, London, E16 2PY. Our main trading address is 55 Commodore House, 2 Admiralty Avenue, London,
          E16 2PY. We are a limited company.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Changes to these terms</Typography>
        <Typography variant="body1">We may revise these terms of use at any time by amending this page.</Typography>
        <Typography variant="body1">
          Please check this page from time to time to take notice of any changes we made, as they are binding on you.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Changes to our site</Typography>
        <Typography variant="body1">
          We may update our site from time to time, and may change the content at any time. However, please note that
          any of the content on our site may be out of date at any given time, and we are under no obligation to update
          it.
        </Typography>
        <Typography variant="body1">
          We do not guarantee that our site, or any content on it, will be free from errors or omissions.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Accessing our site</Typography>
        <Typography variant="body1">Our site is made available free of charge.</Typography>
        <Typography variant="body1">
          We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. Access
          to our site is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part
          of our site without notice. We will not be liable to you if for any reason our site is unavailable at any time
          or for any period.
        </Typography>
        <Typography variant="body1">
          You are also responsible for ensuring that all persons who access our site through your internet connection
          are aware of these terms of use and other applicable terms and conditions, and that they comply with them.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Your account and password</Typography>
        <Typography variant="body1">
          If you choose, or you are provided with, a user identification code, password or any other piece of
          information as part of our security procedures, you must treat such information as confidential. You must not
          disclose it to any third party.
        </Typography>
        <Typography variant="body1">
          We have the right to disable any user identification code or password, whether chosen by you or allocated by
          us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these
          terms of use.
        </Typography>
        <Typography variant="body1">
          If you know or suspect that anyone other than you know your user identification code or password, you must
          promptly notify us at{' '}
          <Link href="mailto:security@fluit.co" target="_blank">
            security@fluit.co
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Intellectual property rights</Typography>
        <Typography variant="body1">
          We are the owner or the licensee of all intellectual property rights in our site, and in the material
          published on it. Those works are protected by copyright laws and treaties around the world. All such rights
          are reserved.
        </Typography>
        <Typography variant="body1">
          You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and
          you may draw the attention of others within your organisation to content posted on our site.
        </Typography>
        <Typography variant="body1">
          You must not modify the paper or digital copies of any materials you have printed off or downloaded in any
          way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately
          from any accompanying text.
        </Typography>
        <Typography variant="body1">
          Our status (and that of any identified contributors) as the authors of content on our site must always be
          acknowledged.
        </Typography>
        <Typography variant="body1">
          You must not use any part of the content on our site for commercial purposes without obtaining a licence to do
          so from us or our licensors.
        </Typography>
        <Typography variant="body1">
          If you print off, copy or download any part of our site in breach of these terms of use, your right to use our
          site will cease immediately and you must, at our option, return or destroy any copies of the materials you
          have made.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">No reliance on information</Typography>
        <Typography variant="body1">
          The content on our site is provided for general information only. It is not intended to amount to advice on
          which you should rely. You must obtain professional or specialist advice before taking, or refraining from,
          any action on the basis of the content on our site.
        </Typography>
        <Typography variant="body1">
          Although we make reasonable efforts to update the information on our site, we make no representations,
          warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or
          up-to-date.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Limitation of our liability</Typography>
        <Typography variant="body1">
          Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our
          negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or
          limited by English law.
        </Typography>
        <Typography variant="body1">
          To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which
          may apply to our site or any content on it, whether express or implied.
        </Typography>
        <Typography variant="body1">
          We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence),
          breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
        </Typography>
        <ul>
          <li>use of, or inability to use, our site; or</li>
          <li>use of or reliance on any content displayed on our site.</li>
        </ul>
        <Typography variant="body1">
          If you are a business user, please note that in particular, we will not be liable for:
        </Typography>
        <ul>
          <li>loss of profits, sales, business, or revenue;</li>
          <li>business interruption;</li>
          <li>loss of anticipated savings;</li>
          <li>loss of business opportunity, goodwill or reputation; or</li>
          <li>any indirect or consequential loss or damage.</li>
        </ul>
        <Typography variant="body1">
          If you are a consumer user, please note that we only provide our site for domestic and private use. You agree
          not to use our site for any commercial or business purposes, and we have no liability to you for any loss of
          profit, loss of business, business interruption, or loss of business opportunity.
        </Typography>
        <Typography variant="body1">
          We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other
          technologically harmful material that may infect your computer equipment, computer programs, data or other
          proprietary material due to your use of our site or to your downloading of any content on it, or on any
          website linked to it.
        </Typography>
        <Typography variant="body1">
          We assume no responsibility for the content of websites linked on our site. Such links should not be
          interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that
          may arise from your use of them.
        </Typography>
        <Typography variant="body1">
          Different limitations and exclusions of liability will apply to liability arising as a result of the supply of
          any goods by use to you, which will be set out in our{' '}
          <Link component={NavLink} to={ROUTES.ACCEPTABLE_USE}>
            Terms and conditions of supply.
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Uploading content to our site</Typography>
        <Typography variant="body1">
          Whenever you make use of a feature that allows you to upload content to our site, or to make contact with
          other users of our site, you must comply with the content standards set out in our{' '}
          <Link component={NavLink} to={ROUTES.ACCEPTABLE_USE}>
            Acceptable Use Policy.
          </Link>
        </Typography>
        <Typography variant="body1">
          You warrant that any such contribution does comply with those standards, and you will be liable to us and
          indemnify us for any breach of that warranty.
        </Typography>
        <Typography variant="body1">
          Any content you upload to our site will be considered non-confidential and non-proprietary. You retain all of
          your ownership rights in your content, but you are required to grant us [and other users of the Site] a
          limited licence to use, store and copy that content and to distribute and make it available to third parties.
          The rights you license to us are described in the next paragraph (Rights you licence).
        </Typography>
        <Typography variant="body1">
          We also have the right to disclose your identity to any third party who is claiming that any content posted or
          uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right
          to privacy.
        </Typography>
        <Typography variant="body1">
          We will not be responsible, or liable to any third party, for the content or accuracy of any content posted by
          you or any other user of our site.
        </Typography>
        <Typography variant="body1">
          We have the right to remove any posting you make on our site if, in our opinion, your post does not comply
          with the content standards set out in our Acceptable Use Policy.
        </Typography>
        <Typography variant="body1">
          The views expressed by other users on our site do not represent our views or values.
        </Typography>
        <Typography variant="body1">You are solely responsible for securing and backing up your content.</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Viruses</Typography>
        <Typography variant="body1">
          We do not guarantee that our site will be secure or free from bugs or viruses.
        </Typography>
        <Typography variant="body1">
          You are responsible for configuring your information technology, computer programmes and platform in order to
          access our site. You should use your own virus protection software.
        </Typography>
        <Typography variant="body1">
          You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material
          which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site,
          the server on which our site is stored or any server, computer or database connected to our site. You must not
          attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this
          provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such
          breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing
          your identity to them. In the event of such a breach, your right to use our site will cease immediately.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Linking to our site</Typography>
        <Typography variant="body1">
          You may link to our home page, provided you do so in a way that is fair and legal and does not damage our
          reputation or take advantage of it.
        </Typography>
        <Typography variant="body1">
          You must not establish a link in such a way as to suggest any form of association, approval or endorsement on
          our part where none exists.
        </Typography>
        <Typography variant="body1">
          You must not establish a link to our site in any website that is not owned by you.
        </Typography>
        <Typography variant="body1">
          Our site must not be framed on any other site, nor may you create a link to any part of our site other than
          the home page.
        </Typography>
        <Typography variant="body1">We reserve the right to withdraw linking permission without notice.</Typography>
        <Typography variant="body1">
          The website in which you are linking must comply in all respects with the content standards set out in our{' '}
          <Link component={NavLink} to={ROUTES.ACCEPTABLE_USE}>
            Acceptable Use Policy.
          </Link>
        </Typography>
        <Typography variant="body1">
          If you wish to make any use of content on our site other than that set out above, please contact{' '}
          <Link href="mailto:info@fluit.co">info@fluit.co</Link>.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Third party links and resources in our site</Typography>
        <Typography variant="body1">
          Where our site contains links to other sites and resources provided by third parties, these links are provided
          for your information only.
        </Typography>
        <Typography variant="body1">We have no control over the contents of those sites or resources.</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Acceptable law</Typography>
        <Typography variant="body1">
          If you are a consumer, please note that these terms of use, its subject matter and its formation, are governed
          by English law. You and we both agree to that the courts of England and Wales will have non-exclusive
          jurisdiction. However, if you are a resident of Northern Ireland you may also bring proceedings in Northern
          Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.
        </Typography>
        <Typography variant="body1">
          If you are a business, these terms of use, its subject matter and its formation (and any non-contractual
          disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of
          England and Wales.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Contact us</Typography>
        <Typography variant="body1">
          To contact us, please email <Link href="mailto:info@fluit.co">info@fluit.co</Link>
        </Typography>
        <Typography variant="body1">Thank you for visiting our site.</Typography>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
