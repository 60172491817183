import { Button, makeStyles, Theme, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: '70px',
    width: '80px',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0),
    '&:hover': {
      background: colors.navy[600],
      '& svg, & g, & path': {
        opacity: 1,
      },
    },
    '& svg, & g, & path': {
      width: '20px',
      height: '20px',
      fill: theme.palette.common.white,
      opacity: 0.8,
    },
  },
  active: {
    '& svg, & g, & path': {
      fill: theme.palette.primary.main,
      opacity: 1,
    },
    background: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.background.default,
    },
  },
  mobileItem: {
    height: '100%',
    margin: 0,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0),
    flexGrow: 1,
    '& button': {
      height: '54px',
      minWidth: 'auto',
      width: '100%',
    },
    '& svg, & g, & path': {
      width: '24px',
      height: '24px',
      fill: theme.palette.common.white,
      opacity: 0.6,
    },
  },
  mobileActive: {
    '& button': {
      background: colors.navy[600],
      '&:hover': {
        background: colors.navy[600],
      },
    },
    '& svg, & g, & path': {
      opacity: 1,
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

interface Props {
  link: Fluit.props.Navigation;
}

const NavItem: React.FC<Props> = ({ link }) => {
  const { state } = React.useContext(AppContext);
  const { init } = state;
  const { payment_failed } = init;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (mobile) {
    return (
      <NavLink
        to={link.route}
        className={clsx(classes.mobileItem, payment_failed && link.route !== ROUTES.ORGANISATIONS && classes.disabled)}
        activeClassName={classes.mobileActive}
        exact={link.route === '/' ? true : false}
      >
        <Button>{link.icon}</Button>
      </NavLink>
    );
  }

  return (
    <Tooltip title={link.title} placement="right">
      <NavLink
        to={link.route}
        data-tour={link.tour}
        className={clsx(classes.item, payment_failed && link.route !== ROUTES.ORGANISATIONS && classes.disabled)}
        activeClassName={classes.active}
        exact={link.route === '/' ? true : false}
      >
        {link.icon}
      </NavLink>
    </Tooltip>
  );
};

export default NavItem;
