import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as React from 'react';
import { Redirect, useLocation } from 'react-router';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import * as Yup from 'yup';

interface Props {
  setPage: Function;
  setEmail: Function;
}

const RequestResetForm: React.FC<Props> = ({ setPage, setEmail }) => {
  const location = useLocation();
  const { setToast } = React.useContext(ToastContext);
  const { from }: any = location.state || { from: { pathname: ROUTES.DEALS } };
  const fbAuth = React.useContext(FirebaseContext);
  const { state } = React.useContext(AppContext);

  if (state.init.user) {
    return <Redirect to={from} />;
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        setEmail(values.email);
        try {
          await fbAuth.resetPassword(values.email);
          setPage();
        } catch (error) {
          setToast({
            message: error.message,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required to reset your password'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  type="email"
                  inputProps={{
                    autoComplete: 'email',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  style={{ marginRight: '16px' }}
                >
                  Reset Password
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default RequestResetForm;
