import { Box, CircularProgress, Divider, TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderGroupParams, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { ContactFirebase } from 'src/services/contacts';
import Fluit from 'src/types/Fluit';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  touched: boolean | undefined;
  error: string | undefined;
  label: string;
  value?: string;
}

const AutocompleteContactsbyInvestorforEvents: React.FC<Props> = ({
  setFieldValue,
  name,
  id,
  touched,
  error,
  label,
  value,
}) => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { contacts, organisation, investors } = state;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Fluit.investors.Contact[]>([]);
  const [fetched, setFetched] = React.useState(false);
  const loading = open && options.length === 0 && !fetched;

  React.useEffect(() => {
    if (_.some(contacts) && !_.some(options)) {
      const investor_as_contacts = _.map(investors, investor => {
        const data = {
          id: investor.id,
          org_id: investor.org_id,
          investor_id: investor.id,
          first_name: investor.name,
          last_name: '',
          position: '',
          telephone: '',
          mobile: '',
          fax: '',
          email: '',
          decision_maker: false,
          investor_name: investor.name,
        };
        return data;
      });
      const contacts_with_investor_names: Fluit.investors.Contact[] = _.map(contacts, contact => {
        contact.investor_name = _.find(investors, { id: contact.investor_id })?.name;
        return contact;
      });
      const contacts_with_investors = _.concat(_.values(contacts_with_investor_names), investor_as_contacts);
      setOptions(_.values(contacts_with_investors));
    }
  }, [contacts, options, investors]);

  React.useEffect(() => {
    let active = true;

    if (!loading || _.some(contacts) || fetched) {
      return undefined;
    }

    (async () => {
      try {
        const result = await ContactFirebase.list(organisation.id);
        dispatch({ type: 'CONTACTS_LIST', payload: result });
        setFetched(true);
        if (active) {
          setOptions(result);
        }
      } catch (error) {
        const err = error as Fluit.firestore.Error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [contacts, organisation, loading, dispatch, setToast, fetched]);

  let val: Fluit.investors.Contact | null = null;

  if (value) {
    const find = _.find(options, { id: value });
    val = find ? find : null;
  }

  return (
    <Field
      name={name}
      id={id ? id : name}
      component={Autocomplete}
      options={_.sortBy(options, ['investor_name'], ['asc'])}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      groupBy={(option: Fluit.investors.Contact) => option.investor_name}
      getOptionLabel={(option: Fluit.investors.Contact) => `${option.first_name} ${option.last_name}`}
      selectOnFocus
      value={val}
      onChange={(event: object, value: Fluit.investors.Contact) => {
        setFieldValue(name, value ? value.id : '');
        const investor = _.find(investors, { id: value.investor_id });

        if (value.id === value.investor_id) {
          setFieldValue('parent.type', value ? 'Investor' : '');
          setFieldValue('metadata.investor_id', investor ? investor.id : '');
          setFieldValue('metadata.investor_name', investor ? investor.name : '');
          setFieldValue('metadata.contact_name', '');
        } else {
          setFieldValue('target.type', value ? 'Contact' : '');
          setFieldValue('target.parent.id', value ? value.investor_id : '');
          setFieldValue('target.parent.type', value ? 'Investor' : '');
          setFieldValue('metadata.contact_name', value ? `${value.first_name} ${value.last_name}` : '');
          setFieldValue('metadata.investor_name', investor ? investor.name : '');
          setFieldValue('metadata.investor_id', investor ? investor.id : '');
        }
      }}
      noOptionsText="No contacts available"
      renderGroup={(option: AutocompleteRenderGroupParams) => (
        <Box key={option.key} mb={1}>
          <Box px={2} py={1}>
            <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
              {option.group}
            </Typography>
          </Box>
          {option.children}
          <Divider light={true} />
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteContactsbyInvestorforEvents;
