import FirebaseClient from 'src/services/firebaseAuth';
import Stripe from 'stripe';
import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';
const fb = FirebaseClient;

/*
  @Steven
  TODO: updatePaymentMethod
  TODO: schedule a sbuscription

  @Xavier
  TODO: Test updatePaymentMethod
*/

export const BillingFirebase = {
  createCustomer: async (customer: Fluit.checkout.Customer) => {
    try {
      const resp = await API({
        endpoint: `payments/customers`,
        method: 'POST',
        payload: customer,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  updateCustomer: async (customer: Partial<Fluit.checkout.Customer>) => {
    try {
      const resp = await API({
        endpoint: `payments/customers/${customer.id}`,
        method: 'POST',
        payload: customer,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  createPaymentSubscription: async (
    customer_id: string,
    payment_method_id: string,
    org_id: string,
    items: object,
    billing_cycle_date?: number,
    tax_rates?: string[]
  ) => {
    try {
      const submission = {
        customer_id: customer_id,
        payment_method_id: payment_method_id,
        org_id: org_id,
        items: items,
        tax_rates: tax_rates,
        billing_cycle_anchor: billing_cycle_date,
      };
      const resp = await API({
        endpoint: `payments/subscriptions/`,
        method: 'POST',
        payload: submission,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  updatePaymentSubscription: async (subscriptionId: string, subscription: any) => {
    const sub = { ...subscription, cancel_at_period_end: false };
    try {
      const resp = await API({
        endpoint: `payments/subscriptions/${subscriptionId}`,
        method: 'POST',
        payload: sub,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  cancelSubscription: async (subscriptionId: string, subscription: any) => {
    const items = subscription.items;
    const sub = { ...items, cancel_at_period_end: true };
    try {
      const resp = await API({
        endpoint: `payments/subscriptions/${subscriptionId}`,
        method: 'POST',
        payload: sub,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  reActivateSubscription: async (subscription: Stripe.subscriptions.ISubscription) => {
    const items = subscription.items;
    const sub = { ...items, cancel_at_period_end: false };
    try {
      const resp = await API({
        endpoint: `payments/subscriptions/${subscription.id}`,
        method: 'POST',
        payload: sub,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  upcomingInvoice: async (obj: any) => {
    try {
      const resp = await API({
        endpoint: `payments/invoices/${obj.customer}`,
        method: 'POST',
        payload: obj,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  retryInvoice: async (customerId: string, paymentMethodId: string, invoiceId: string) => {
    try {
      const submission = { customer_id: customerId, payment_method_id: paymentMethodId, invoice_id: invoiceId };
      const resp = await API({
        endpoint: `payments/invoices/`,
        method: 'POST',
        payload: submission,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  getSubscription: async (orgId: string, subscriptionId: string) => {
    const r = await fb
      .firestore()
      .collection('billing')
      .doc(orgId)
      .collection('subscriptions')
      .doc(subscriptionId)
      .get();
    return Promise.resolve(r.data());
  },
  // cancelSubscription: async (subscriptionId: string) => {
  //   const r = await fb.functions().httpsCallable('billing-cancelSubscription')({
  //     subscriptionId: subscriptionId,
  //   });
  //   return Promise.resolve(r.data);
  // },
  /* 
  TODO: ADD ALL STATUSES YOU WANT TO RETRIEVE */
  getSubscriptions: async (orgId: string) => {
    const unix_today = new Date().getTime() / 1000;
    const snapshots = await fb
      .firestore()
      .collection('billing')
      .doc(orgId)
      .collection('subscriptions')
      .where('status', 'in', ['active', 'canceled', 'past_due', 'unpaid', 'canceled'])
      .where('current_period_end', '>', unix_today)
      .get();
    let subscriptions: Stripe.subscriptions.ISubscription[] = [];
    snapshots.forEach(snapshot => {
      subscriptions.push(snapshot.data() as Stripe.subscriptions.ISubscription);
    });
    return subscriptions;
  },
  getCustomerId: async (orgId: string) => {
    const r = await fb
      .firestore()
      .collection('billing')
      .doc(orgId)
      .get();
    return Promise.resolve(r.data());
  },
  getCustomerData: async (orgId: string, customerId: string) => {
    const r = await fb
      .firestore()
      .collection('billing')
      .doc(orgId)
      .collection('customers')
      .doc(customerId)
      .get();

    return Promise.resolve(r.data());
  },
  listInvoices: async (customerId: string, limit: number) => {
    try {
      const resp = await API({
        endpoint: `payments/invoices/${customerId}?limit=${limit}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  createSetupIntent: async (customerId: string) => {
    try {
      const resp = await API({
        endpoint: `payments/intents/`,
        method: 'POST',
        payload: { customer_id: customerId },
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  listPaymentMethods: async (customerId: string) => {
    try {
      const resp = await API({
        endpoint: `payments/methods/${customerId}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  detachPaymentMethod: async (paymentMethodId: string) => {
    try {
      const resp = await API({
        endpoint: `payments/methods/${paymentMethodId}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  /* 
  ? NOT SURE WE NEED THIS 
  */
  // updatePaymentMethod: async (paymentMethodId: string, method: any) => {
  //   const r = await fb.functions().httpsCallable('billing-listPaymentMethods')({
  //     paymentMethodId: paymentMethodId,
  //     method: method, // { billing_details: {}, metadata: {}} https://stripe.com/docs/api/payment_methods/update
  //   });
  //   return Promise.resolve(r.data);
  // },
};
