import { Box, CircularProgress, Paper } from '@material-ui/core';
import React from 'react';

interface Props {
  height: string;
  variant?: 'paper' | 'none';
}

const GenericLoader: React.FC<Props> = ({ height, variant }) => {
  if (variant === 'none') {
    return (
      <Box height={height} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper style={{ height: height }}>
      <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    </Paper>
  );
};

export default GenericLoader;
