import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '& tr td': {
        border: 'none',
      },
    },
  })
);

interface Props {
  customer: Fluit.checkout.Customer;
}
const BillingDetails: React.FC<Props> = ({ customer }) => {
  const classes = useStyles();

  return (
    <Paper>
      <Box p={4}>
        <Typography variant="h4">Billing Details</Typography>

        <Table padding="none" size="small" className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Company</Typography>
              </TableCell>
              <TableCell>
                <Typography>{customer.name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Email</Typography>
              </TableCell>
              <TableCell>
                <Typography>{customer.email}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Phone</Typography>
              </TableCell>
              <TableCell>
                <Typography>{customer.phone}</Typography>
              </TableCell>
            </TableRow>
            {customer.tax_id_data && (
              <TableRow>
                <TableCell>
                  <Typography>VAT Number</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{customer.tax_id_data[0].value}</Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Typography>Address</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {customer.address.line1} {customer.address.line2} {customer.address.city} {customer.address.state}{' '}
                  {customer.address.country} {customer.address.postal_code}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default BillingDetails;
