import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import InvestorCard from 'src/components/Cards/Investor';
import NoData from 'src/components/Empty';
import LayoutView from 'src/components/Inputs/ToggleButtons/LayoutView';
import InvestorsTable from 'src/components/Investors/InvestorsTable';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { ContactFirebase } from 'src/services/contacts';
import { Styles } from 'src/styles/styles';
import { InvestorsNone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const Investors: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { investors, organisation } = state;
  const { limits } = organisation;
  const hit_investor_limits = limits.investors.count >= limits.investors.limit;

  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);

  const firebase = React.useContext(FirebaseContext);
  const localStorageView = localStorage.getItem('fluit::investorsview');
  const [view, setView] = React.useState(localStorageView ? localStorageView : 'grid');
  const history = useHistory();

  const classes = Styles.app();

  const investorLimits = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: '',
        component: 'UPGRADE_INVESTORS',
        centered: true,
      },
    });
  };

  const listContacts = React.useCallback(async () => {
    try {
      const contacts = await ContactFirebase.list(organisation.id);
      dispatch({ type: 'CONTACTS_LIST', payload: contacts });
      setLoading(false);
    } catch (error) {
      const err: Fluit.firestore.Error = error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [organisation.id, dispatch, setToast]);

  React.useEffect(() => {
    listContacts();
  }, [listContacts]);

  React.useEffect(() => {
    if (!limits.limit_activities) {
      const unsubscribe = firebase
        .firestore()
        .collection('organisations')
        .doc(organisation.id)
        .collection('investors')
        .onSnapshot(function(snapshot) {
          snapshot.docChanges().forEach(function(change) {
            const investor = change.doc.data() as Fluit.investors.Investor;
            if (change.type === 'added') {
              const investor_exists = _.find(investors, { id: investor.id });
              if (!investor_exists) {
                dispatch({ type: 'INVESTOR_CREATE', payload: investor });
                dispatch({ type: 'COUNT_UP_INVESTOR' });
              } else {
                dispatch({ type: 'INVESTOR_UPDATE', payload: investor });
              }
            }
            if (change.type === 'modified') {
              dispatch({ type: 'INVESTOR_UPDATE', payload: investor });
            }
            if (change.type === 'removed') {
              dispatch({ type: 'INVESTOR_DELETE', payload: investor });
            }
          });
          setLoading(false);
        });

      return () => {
        unsubscribe();
      };
    }
  }, [firebase, dispatch, organisation.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleView = (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
    if (newView !== null) {
      setView(newView);
      localStorage.setItem('fluit::investorsview', newView);
    }
  };

  const goToSearch = () => {
    history.push(ROUTES.INVESTOR_SEARCH);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box className={classes.heading}>
          <Typography variant="h1">Investors</Typography>
          <Box className={classes.buttonBox}>
            <LayoutView value={view} onChange={handleView} />
            {hit_investor_limits ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => investorLimits()}
                style={{ height: '100%', marginLeft: 16 }}
              >
                Add
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="secondary"
                  component={NavLink}
                  to={ROUTES.INVESTOR_SEARCH}
                  style={{ height: '100%', marginLeft: 16 }}
                >
                  Search
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    dispatch({
                      type: 'MODAL',
                      payload: {
                        open: true,
                        title: 'Add a new investor',
                        component: 'INVESTOR_CREATE',
                        investor_name: '',
                      },
                    });
                  }}
                  style={{ height: '100%', marginLeft: 16 }}
                >
                  Add
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Grid>

      {hit_investor_limits && (
        <Grid item xs={12}>
          <Alert
            severity="info"
            variant="outlined"
            action={
              <Button color="inherit" size="medium" component={NavLink} to={ROUTES.BUY}>
                Upgrade
              </Button>
            }
          >
            You've reached the maximum number of investors you can add on the free tier. To add unlimited investors
            upgrade now.
          </Alert>
        </Grid>
      )}

      {loading && (
        <Grid item xs={12}>
          <GenericLoader variant="none" height="160px" />
        </Grid>
      )}

      {!loading && _.some(investors) && view === 'grid'
        ? _.map(investors, (investor, index) => {
            const i = index;
            const prev = page - 1;
            if (i >= prev * 12 && i < page * 12) {
              return (
                <Grid item xs={12} sm={6} lg={4} key={investor.id}>
                  <InvestorCard data={investor} />
                </Grid>
              );
            }
          })
        : null}

      {!loading && !_.some(investors) ? (
        <Grid item xs={12}>
          <NoData
            text={`Investors you add will display here.`}
            icon={<InvestorsNone />}
            label="Add Investor "
            action={hit_investor_limits ? investorLimits : goToSearch}
          />
        </Grid>
      ) : null}

      {_.values(investors).length > 12 && !loading && view === 'grid' ? (
        <Grid item xs={12}>
          <Box justifyContent="center" display="flex">
            <Pagination
              count={Math.ceil(investors.length / 12)}
              size="large"
              color="primary"
              defaultPage={1}
              siblingCount={0}
              onChange={(e: object, page: number) => setPage(page)}
            />
          </Box>
        </Grid>
      ) : null}

      {!loading && _.some(investors) && view === 'table' ? (
        <Grid item xs={12}>
          <InvestorsTable />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Investors;
