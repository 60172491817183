import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { UserActivityContext } from 'src/contexts/UserActivityContext';
import { ActivityFirebase } from 'src/services/activities';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const EventDelete: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;
  const { setToast } = React.useContext(ToastContext);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { dispatchUserActivities } = React.useContext(UserActivityContext);

  const activity = state.ui.modal.activity as Fluit.activities.Activity;

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const deleteActivity = React.useCallback(async () => {
    setLoading(true);
    try {
      await ActivityFirebase.delete(organisation.id, activity.id);
      if (organisation.limits.limit_activities) {
        dispatchUserActivities({ type: 'ACTIVITY_USER_DELETE', payload: activity });
        dispatch({ type: 'COUNT_DOWN_ACTIVITIES' });
      } else {
        dispatchUserActivities({ type: 'ACTIVITY_USER_DELETE', payload: activity });
      }
      setLoading(false);
      setToast({
        message: `Event deleted`,
        type: 'info',
      });
      dispatch({
        type: 'MODAL',
        payload: {
          ...state.ui.modal,
          open: false,
        },
      });
    } catch (error) {
      setLoading(false);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [state.ui.modal, dispatch, setLoading, setToast, activity, organisation, dispatchUserActivities]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">This will permantly delete the event from Fluit.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => deleteActivity()}
          disabled={loading ? true : false}
        >
          Delete
        </Button>
        <Button
          variant="text"
          style={{ marginLeft: '16px' }}
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default EventDelete;
