import React, { createContext, useReducer } from 'react';
import { UserActivityReducer } from 'src/reducers/UserActivity';
import Fluit from 'src/types/Fluit';

const UserActivityContext = createContext<{
  userActivities: Fluit.context.userActivity.State;
  dispatchUserActivities: React.Dispatch<any>;
}>({
  userActivities: [],
  dispatchUserActivities: () => null,
});

const init_reducer: Fluit.activities.Activity[] = [];

const UserActivityProvider: React.FC = ({ children }) => {
  const [userActivities, dispatchUserActivities] = useReducer(UserActivityReducer, init_reducer);
  return (
    <UserActivityContext.Provider value={{ userActivities, dispatchUserActivities }}>
      {children}
    </UserActivityContext.Provider>
  );
};

export { UserActivityContext, UserActivityProvider };
