import { Box, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import clsx from 'clsx';
import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { DealFirebase } from 'src/services/deals';
import { styles_dealStatus } from 'src/styles/deals';
import Fluit from 'src/types/Fluit';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  touched: boolean | undefined;
  error: string | undefined;
  label: string;
  value?: string;
}

const AutocompleteDeals: React.FC<Props> = ({ setFieldValue, name, id, touched, error, label, value }) => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { deals, organisation } = state;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Fluit.deals.Deal[]>([]);
  const [fetched, setFetched] = React.useState(false);
  const loading = open && options.length === 0 && !fetched;

  const classes = styles_dealStatus();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (_.some(deals)) {
      setOptions(_.values(deals));
      return undefined;
    }

    if (fetched) {
      return undefined;
    }

    (async () => {
      try {
        const result = await DealFirebase.list(organisation.id);
        dispatch({ type: 'DEALS_LIST', payload: result });
        if (active) {
          setOptions(result);
        }
        setFetched(true);
      } catch (error) {
        const err = error as Fluit.firestore.Error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [deals, organisation, loading, dispatch, setToast, fetched]);

  let val: Fluit.deals.Deal | null = null;

  if (value) {
    const deal = _.find(deals, { id: value });
    val = deal ? deal : null;
  }

  return (
    <Field
      name={name}
      id={id ? id : name}
      component={Autocomplete}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      getOptionLabel={(option: Fluit.deals.Deal) => option.name}
      autoSelect={true}
      value={val}
      onChange={(event: object, value: Fluit.deals.Deal) => {
        setFieldValue(name, value ? value.id : '');
      }}
      noOptionsText="No deals available"
      renderOption={(option: Fluit.deals.Deal) => (
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
          <Box flex={1}>
            <Typography variant="subtitle1" style={{ textTransform: 'capitalize' }}>
              {option.name}
            </Typography>
            <Typography variant="caption">{option.details?.round}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              className={clsx(
                classes.pulse,
                option.status === 'Live' && classes.live,
                option.status === 'Complete' && classes.complete,
                option.status === 'Canceled' && classes.cancelled
              )}
            />
            <Typography variant="caption">
              <span style={{ textTransform: 'capitalize' }}>{option.status}</span>
            </Typography>
          </Box>
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteDeals;
