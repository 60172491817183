import { Grid } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { AppContext } from 'src/contexts/AppContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { AssignmentsFirebase } from 'src/services/assignments';
import Fluit from 'src/types/Fluit';
import InvestorDroppable from './InvestorDroppable';

const InvestorDragDrop: React.FC = () => {
  const { state } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const { investors, assignments } = state;
  const { process } = React.useContext(ProcessContext);
  const { steps } = process;
  const [dropState, setDropState] = React.useState<Fluit.assignments.Assignment[]>(_.values(assignments));
  const [startDroppable, setStartDroppable] = React.useState('');

  React.useEffect(() => {
    setDropState(_.values(assignments));
  }, [assignments]);

  const onDragStart = (result: DropResult): void => {
    const { source } = result;

    const localState = [...steps];

    // identify source object
    const sourceStep = _.find(localState, { id: source.droppableId });
    const sourceStepIndex = _.indexOf(localState, sourceStep);
    setStartDroppable(localState[sourceStepIndex].id);
  };

  const onDragEnd = (result: DropResult): void => {
    const { destination, source, draggableId } = result;
    setStartDroppable('');

    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // new version of state
    const localState = [...steps];

    // identify source object
    const sourceStep = _.find(localState, { id: source.droppableId });

    // identify destination object
    const destinationStep = _.find(localState, { id: destination.droppableId });

    // dragged investor
    const assignmentItem = _.find(assignments, { id: draggableId }) as Fluit.assignments.Assignment;

    if (sourceStep && destinationStep && sourceStep.id !== destinationStep.id) {
      (async () => {
        try {
          const removeAssignment = _.filter(dropState, item => item.id !== assignmentItem?.id);
          const assignment = {
            ...assignmentItem,
            step_id: destinationStep.id,
            metadata: {
              activity_previous_step_id: sourceStep.id,
              activity_previous_step_name: sourceStep.name,
              activity_event_action: 'assignment_moved',
            },
          };
          const newDropState = _.concat(removeAssignment, assignment);
          setDropState(newDropState);
          await AssignmentsFirebase.update(assignment);
        } catch (error) {
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      })();
    }
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Grid container spacing={4}>
        {_.map(steps, (step, index) => {
          const stepInvestors = _.flattenDeep(
            _.map(_.filter(dropState, { step_id: step.id }), step_investor =>
              _.filter(investors, { id: step_investor.investor_id })
            )
          );
          return (
            <InvestorDroppable
              key={step.id}
              step={step}
              stepIndex={index}
              investors={stepInvestors}
              startId={startDroppable}
              dropState={dropState}
            />
          );
        })}
      </Grid>
    </DragDropContext>
  );
};

export default InvestorDragDrop;
