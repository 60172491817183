import { TextField } from 'formik-material-ui';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';
import styled from 'styled-components';

export default styled(TextField)`
  border-radius: '4px';
  & input {
    background-color: ${colors.common.white};
  }
  & fieldset {
    border-color: ${colors.grey[100]};
  }
  &:hover {
    & fieldset {
      border-color: ${colors.purple[300]} !important;
    }
    & .Mui-focused {
      & fieldset {
        border-color: ${colors.purple.main} !important;
      }
    }
    & .Mui-error {
      & fieldset {
        border-color: ${colors.red.main} !important;
      }
    }
  }
  & .Mui-error {
    & input {
      box-shadow: ${shadows.red[1]};
    }
  }
`;
