import { Box, Button, Divider, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import numeral from 'numeral';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { ToursContext } from 'src/contexts/TourContext';
import { OrganisationFirebase } from 'src/services/organisations';
import { UserFirebase } from 'src/services/users';
import Fluit from 'src/types/Fluit';

const OnboardReview: React.FC = () => {
  /* Context */
  const { dispatchTours } = React.useContext(ToursContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { onboard, setOnboard } = React.useContext(OnboardingContext);
  const { setToast } = React.useContext(ToastContext);

  /* State */
  const [submitting, setSubmitting] = React.useState(false);

  const prevPage = () => {
    setOnboard({
      ...onboard,
      step: onboard.step - 1,
    });
  };

  const setPage = (page: number) => {
    setOnboard({
      ...onboard,
      step: page,
    });
  };

  const handleSubmission = async () => {
    setSubmitting(true);
    try {
      const o: Partial<Fluit.organisations.Organisation> = {
        ...onboard.organisation,
      };
      delete o.limits;
      const organisation = await OrganisationFirebase.create(o);
      const u: Partial<Fluit.users.User> = {
        ...onboard.user,
        id: state.user.id,
        invites: [],
        settings: {
          default_organisation: organisation.id,
        },
      };

      await UserFirebase.update(u);
      setSubmitting(false);
      dispatch({ type: 'USER_SET', payload: u });
      dispatch({ type: 'ORGANISATION_SET', payload: organisation });
      dispatch({ type: 'ORGANISATIONS_SET', payload: organisation });
      dispatch({ type: 'MEMBER_CREATE', payload: u });
      dispatch({ type: 'ADMIN_SET', payload: true });
      dispatchTours({ type: 'TOURS_SET_FIRSTLOAD', payload: true });
      dispatch({
        type: 'MODAL',
        payload: {
          open: true,
          title: `Welcome ${state.user.first_name}`,
          component: 'TOUR_WELCOME',
          centered: true,
        },
      });
    } catch (error) {
      setSubmitting(false);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h1">Reivew &amp; Complete</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Your Details
          </Typography>
          <Button color="secondary" size="large" onClick={() => setPage(0)} disabled={submitting}>
            Edit
          </Button>
        </Box>
        <Paper>
          <Box p={3}>
            <Typography variant="h4">
              {onboard.user.first_name} {onboard.user.last_name}
            </Typography>
            <Typography variant="body1">{onboard.user.position}</Typography>
            <Typography variant="body1">{onboard.user.telephone}</Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Your Organisation
          </Typography>
          <Button color="secondary" size="large" onClick={() => setPage(1)} disabled={submitting}>
            Edit
          </Button>
        </Box>
        <Paper>
          <Box p={3}>
            <Typography variant="h4">{onboard.organisation.name}</Typography>
            <Typography>
              <Typography variant="caption">Founded:</Typography> {onboard.organisation.year_founded}
            </Typography>
            <Typography>
              <Typography variant="caption">Employees:</Typography> {onboard.organisation.employees}
            </Typography>
            <Typography>
              <Typography variant="caption">Industry:</Typography> {onboard.organisation.industry}
            </Typography>
            <Typography variant="body1">{onboard.organisation.telephone}</Typography>
            <Typography variant="body1">{onboard.organisation.email}</Typography>
            <Typography variant="body1">{onboard.organisation.website}</Typography>
            <Box my={2}>
              <Divider light={true} />
            </Box>
            <Typography>
              <Typography variant="caption">Current Round:</Typography> {onboard.organisation.current_round}
            </Typography>
            <Typography>
              <Typography variant="caption">Total Funding:</Typography> {onboard.organisation.currency.symbol}
              {numeral(onboard.organisation.total_funding).format('0,0')}
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleSubmission()}
          disabled={submitting}
          style={{ marginRight: '16px' }}
        >
          Complete
        </Button>
        <Button color="default" size="large" onClick={() => prevPage()} disabled={submitting}>
          Back
        </Button>
      </Grid>
      {submitting && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default OnboardReview;
