import { API } from 'src/services/api';
import Fluit from 'src/types/Fluit';

export const ProcessesFirebase = {
  get: async (org_id: string, deal_id: string): Promise<Fluit.process.Process> => {
    try {
      const resp = await API({
        endpoint: `organisations/${org_id}/processes/${deal_id}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (org_id: string): Promise<Fluit.process.Process[]> => {
    try {
      const resp = await API({
        endpoint: `organisations/${org_id}/processes`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (process: Fluit.process.Process) => {
    try {
      const resp = await API({
        endpoint: `organisations/${process.org_id}/processes/${process.id}`,
        method: 'PATCH',
        payload: process,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
