export const industries = [
  'Advertising',
  'AgTech',
  'AI',
  'Analytics',
  'AR/VR',
  'Biotech',
  'Blockchain',
  'Cars / autonomous vehicles',
  'Chemicals',
  'Clean tech / environment',
  'Cloud infrastructure',
  'Consumer health & fitness',
  'Consumer products',
  'Cosmetics',
  'Cryptocurrency',
  'Data services',
  'Developer tools',
  'Distributed workforce',
  'E-commerce',
  'Education',
  'Energy tech',
  'Enterprise',
  'Entertainment & sports',
  'Fashion',
  'Fintech',
  'Food and beverage',
  'Games',
  'Gaming/eSports',
  'Govtech',
  'Hardware',
  'Health & hospital services',
  'Health IT',
  'Human capital',
  'Impact',
  'Insurance',
  'IoT',
  'Local commerce',
  'Lodging/hospitality',
  'Logistics',
  'Manufacturing',
  'Marketing automation',
  'Marketplaces',
  'Material science',
  'Media/content',
  'Medical devices',
  'Messaging',
  'Network infrastructure',
  'Parenting/families',
  'Payments',
  'Pharmaceuticals',
  'Real estate/proptech',
  'Retail',
  'Robotics',
  'SaaS',
  'Sales and CRM',
  'Security',
  'Semiconductors',
  'SMB software',
  'Social commerce',
  'Social mobile',
  'Space',
  'Travel',
];
