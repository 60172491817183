import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { TermsheetFirebase } from 'src/services/termsheets';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const TermsheetDeleteForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { termsheets } = state;
  const { setToast } = React.useContext(ToastContext);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const removeTermsheet = React.useCallback(async () => {
    setLoading(true);

    const termsheet = _.find(termsheets, { id: String(state.ui.modal.id) });

    try {
      await TermsheetFirebase.delete(termsheet as Fluit.deals.Termsheet);
      dispatch({ type: 'TERMSHEET_DELETE', payload: termsheet });
      setToast({
        message: `Termsheet Removed`,
        type: 'info',
      });
      setLoading(false);
      dispatch({
        type: 'MODAL',
        payload: {
          ...state.ui.modal,
          open: false,
        },
      });
    } catch (error) {
      const err: Fluit.firestore.Error = error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
      setLoading(false);
    }
  }, [dispatch, setToast, state.ui.modal, termsheets]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">Permantly delete this termsheet from the current deal?</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => removeTermsheet()}
          disabled={loading ? true : false}
        >
          Delete
        </Button>
        <Button
          variant="text"
          style={{ marginLeft: '16px' }}
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default TermsheetDeleteForm;
