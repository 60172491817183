import { Box, Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import FormikTextField from 'src/components/Inputs/Textfields/FormikTextField';
import { telephoneRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import { trimString } from 'src/helpers/trim';
import * as Yup from 'yup';

const OnboardNewUser: React.FC = () => {
  /* Context */
  const { state } = React.useContext(AppContext);
  const { user } = state;
  const { onboard, setOnboard } = React.useContext(OnboardingContext);

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h1">Your Details</Typography>
      </Box>
      <Formik
        initialValues={{
          first_name: onboard.user.first_name ? onboard.user.first_name : user.first_name,
          last_name: onboard.user.last_name ? onboard.user.last_name : user.last_name,
          position: onboard.user.position ? onboard.user.position : user.position,
          telephone: onboard.user.telephone ? onboard.user.telephone : user.telephone,
          is_onboarded: true,
          email_verified: user.email_verified ? user.email_verified : false,
          email: user.email,
        }}
        onSubmit={(values, actions) => {
          setOnboard({
            ...onboard,
            user: {
              id: '',
              first_name: trimString(values.first_name),
              last_name: trimString(values.last_name),
              position: trimString(values.position),
              telephone: trimString(values.telephone),
              is_onboarded: true,
              email_verified: values.email_verified,
              email: trimString(values.email),
              invites: [],
              settings: {
                default_organisation: '',
              },
            },
            step: onboard.step + 1,
          });
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required('Your first name is required'),
          last_name: Yup.string().required('Your last name is required'),
          position: Yup.string().required('Your job position is required'),
          telephone: Yup.string()
            .required('Your telephone number is required')
            .matches(telephoneRegex, 'Not a valid telephone number'),
        })}
      >
        {props => {
          const { isSubmitting, handleSubmit } = props;
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="first_name"
                    label="First name"
                    autoComplete="first name, name, forname"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth={true}
                    component={FormikTextField}
                    name="last_name"
                    label="Last name"
                    autoComplete="last name, last_name, name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth={true}
                    component={FormikTextField}
                    name="position"
                    label="Position"
                    autoComplete="position, job role"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth={true}
                    component={FormikTextField}
                    name="telephone"
                    label="Telephone"
                    autoComplete="phone, mobile, telephone"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                    Next
                  </Button>
                </Grid>
                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress variant="query" />
                  </Grid>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OnboardNewUser;
