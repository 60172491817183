import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, Formik } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import AutocompleteContactsbyInvestor from 'src/components/Inputs/Autocomplete/ContactsbyInvestor';
import AutocompleteDeals from 'src/components/Inputs/Autocomplete/Deals';
import AutocompleteInvestorsByLetter from 'src/components/Inputs/Autocomplete/InvestorsbyLetter';
import AutocompleteMembers from 'src/components/Inputs/Autocomplete/Members';
import FormikTextField from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { TaskFirebase } from 'src/services/tasks';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const TaskEditForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, ui } = state;
  const { modal } = ui;
  const task: Fluit.tasks.Task = modal.task as Fluit.tasks.Task;

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        initialValues={{
          id: task.id,
          contents: task.contents,
          parent_type: task.parent_type,
          parent_id: task.parent_id,
          org_id: task.org_id,
          child_id: task.child_id,
          assigned_to: task.assigned_to,
          created_by: task.created_by,
          due_date: task.due_date ? task.due_date : null,
          completed: task.completed,
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          try {
            const task = await TaskFirebase.update(values);
            dispatch({ type: 'TASK_UPDATE', payload: task });
            actions.setSubmitting(false);
            setToast({
              message: `Task Updated`,
              type: 'info',
            });
            closeModal();
          } catch (error) {
            actions.setSubmitting(false);
            const err = error as Fluit.firestore.Error;
            setToast({
              message: `${err.name} | ${err.message}`,
              type: 'error',
            });
          }
        }}
        validationSchema={Yup.object().shape({
          contents: Yup.string().required('Task description is required to create a task'),
          parent_id: Yup.string().when('parent_type', {
            is: 'organisation',
            then: Yup.string(),
            otherwise: Yup.string().required('You need to attach the note to a deal, investor or contact'),
          }),
        })}
      >
        {props => {
          const { isSubmitting, handleSubmit, values, setFieldValue, errors, touched } = props;

          const handleParentType = (event: React.ChangeEvent<HTMLInputElement>, newType: string) => {
            if (newType !== null) {
              setFieldValue('parent_type', newType);
              setFieldValue('parent_id', newType === 'organisation' ? organisation.id : '');
            }
          };

          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    fullWidth={true}
                    name="contents"
                    label="Description"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Attach to</FormLabel>
                    <RadioGroup
                      aria-label="attached to"
                      name="parent_type"
                      value={values.parent_type}
                      onChange={handleParentType}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <FormControlLabel value="organisation" control={<Radio color="primary" />} label="Organisation" />
                      <FormControlLabel value="deal" control={<Radio color="primary" />} label="Deal" />
                      <FormControlLabel value="investor" control={<Radio color="primary" />} label="Investor" />
                      <FormControlLabel value="contact" control={<Radio color="primary" />} label="Contact" />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {values.parent_type === 'deal' && (
                  <Grid item xs={12}>
                    <AutocompleteDeals
                      setFieldValue={setFieldValue}
                      name="parent_id"
                      label="Attached Deal"
                      value={values.parent_id}
                      error={errors.parent_id}
                      touched={touched.parent_id}
                    />
                  </Grid>
                )}

                {values.parent_type === 'investor' && (
                  <Grid item xs={12}>
                    <AutocompleteInvestorsByLetter
                      key={values.parent_type}
                      name="parent_id"
                      setFieldValue={setFieldValue}
                      label="Attached Investor"
                      error={errors.parent_id}
                      touched={touched.parent_id}
                      value={values.parent_id}
                    />
                  </Grid>
                )}

                {values.parent_type === 'contact' && (
                  <Grid item xs={12}>
                    <AutocompleteContactsbyInvestor
                      key={values.parent_type}
                      name="parent_id"
                      setFieldValue={setFieldValue}
                      label="Attached Contact"
                      error={errors.parent_id}
                      touched={touched.parent_id}
                      value={values.child_id}
                      childName="child_id"
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Field
                    component={DatePicker}
                    name="due_date"
                    label="Due Date"
                    fullWidth
                    autoOk
                    format="iii, do MMM yyyy"
                    inputVariant="outlined"
                    variant="inline"
                    onAccept={(date: Date) => {
                      setFieldValue('due_date', date.toISOString());
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteMembers
                    name="assigned_to"
                    setFieldValue={setFieldValue}
                    label="Assigned to"
                    error={errors.assigned_to}
                    touched={touched.assigned_to}
                    value={values.assigned_to}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                    Update
                  </Button>
                  <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                    Cancel
                  </Button>
                </Grid>
                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress variant="query" />
                  </Grid>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default TaskEditForm;
