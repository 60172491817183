import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const TermsheetReducer = (state: Fluit.deals.Termsheet[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'TERMSHEET_CREATE':
      const TERMSHEET_CREATE = _.orderBy(_.concat(_.values(state), action.payload), ['created_at'], ['asc']);
      return TERMSHEET_CREATE;
    case 'TERMSHEET_UPDATE':
      const TERMSHEET_UPDATE = _.orderBy(
        _.concat(_.values(_.filter(state, termsheet => termsheet.id !== action.payload.id)), action.payload),
        ['created_at'],
        ['asc']
      );
      return TERMSHEET_UPDATE;
    case 'TERMSHEET_DELETE':
      const TERMSHEET_DELETE = _.orderBy(
        _.values(_.filter(state, termsheet => termsheet.id !== action.payload.id)),
        ['created_at'],
        ['asc']
      );
      return TERMSHEET_DELETE;
    case 'TERMSHEETS_SET':
      const TERMSHEETS_SET = _.map(action.payload, termsheet => {
        const TERMSHEET_EXISTS = _.find(state, t => t.id === termsheet.id);
        if (TERMSHEET_EXISTS) {
          return _.merge(TERMSHEET_EXISTS, termsheet);
        } else {
          return termsheet;
        }
      });
      return _.orderBy(TERMSHEETS_SET, ['created_at'], ['asc']);
    case 'TERMSHEETS_LIST':
      const TERMSHEETS_LIST = _.orderBy(action.payload, ['created_at'], ['asc']);
      return TERMSHEETS_LIST;
    case 'TERMSHEETS_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
