import { Box, Chip, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import CurrencyDisplay from 'src/components/Displays/Currency';
import { DealContext } from 'src/contexts/DealContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { formatIsoDate } from 'src/helpers/formatIsoDate';
import colors from 'src/themes/colors';
import { Meeting, Process } from 'src/themes/icons';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  attribute: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pulse: {
    borderRadius: '50%',
    background: colors.grey[100],
    width: '10px',
    height: '10px',
    margin: `0px 11px 0px 3px`,
  },
  live: {
    background: colors.blue[400],
  },
  complete: {
    background: colors.green[500],
  },
  cancelled: {
    background: colors.red[400],
  },
}));

const DealSnapshop: React.FC = () => {
  const { deal } = React.useContext(DealContext);
  const { process } = React.useContext(ProcessContext);
  const { type, details, status, created_at } = deal;
  const classes = useStyles();

  return (
    <Paper>
      <Box p={3}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Box>
            <Typography variant="h4">{type}</Typography>
            {details ? <Typography variant="body1">{details.round}</Typography> : null}
          </Box>
          {type === 'Fundraise' && details ? (
            <Chip
              color="default"
              variant="outlined"
              label={
                <CurrencyDisplay
                  name={details.currency.name}
                  code={details.currency.code}
                  symbol={details.currency.symbol}
                  alpha_2={details.currency.alpha_2}
                />
              }
            />
          ) : null}
        </Box>

        <Box className={classes.attribute}>
          <Process className={classes.svg} />
          <Typography variant="body1">{`${process.steps.length} steps in process`}</Typography>
        </Box>

        <Box className={classes.attribute}>
          <Box
            className={clsx(
              classes.pulse,
              status === 'Live' && classes.live,
              status === 'Complete' && classes.complete,
              status === 'Canceled' && classes.cancelled
            )}
          />
          <Typography variant="body1">
            <span style={{ textTransform: 'capitalize' }}>{status}</span>
          </Typography>
        </Box>

        <Box className={classes.attribute}>
          <Meeting className={classes.svg} />
          <Typography variant="body1">
            {formatIsoDate(created_at)}
            <Typography variant="caption">
              <em> (Created)</em>
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default DealSnapshop;
