import { makeStyles, Theme } from '@material-ui/core';
import colors from 'src/themes/colors';

export const styles_investorTable = makeStyles((theme: Theme) => ({
  svg: {
    width: 20,
    height: 20,
    fill: theme.palette.grey[200],
    marginRight: theme.spacing(1),
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  iconText: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionIcon: {
    width: 20,
    height: 20,
    fill: colors.grey[400],
  },
  toolbar: {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${colors.grey[50]}`,
    backgroundColor: `${colors.grey[50]}20`,
  },
}));
