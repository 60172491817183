import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { data_currencies } from 'src/constants/currencies';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import { isoToFlag } from 'src/helpers/isoToFlag';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& th, & td': {
      borderBottom: 'none',
      display: 'inline-flex',
      padding: theme.spacing(0.5, 0),
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        width: 'auto',
        padding: 0,
      },
    },
    '& th': {
      whiteSpace: 'nowrap',
      verticalAlign: 'top',
      paddingRight: theme.spacing(4),
      color: theme.palette.grey[700],
      minWidth: '160px',
      width: 'fit-content',
    },
    '& tr': {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        marginBottom: theme.spacing(2),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  delete: {
    color: colors.grey[500],
    marginLeft: theme.spacing(2),
  },
}));

const InvestorTabDetails: React.FC = () => {
  const classes = useStyles();
  const { dispatch } = React.useContext(AppContext);
  const { investor } = React.useContext(InvestorContext);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const editDetails = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Edit ${investor.name}'s Details`,
        component: 'INVESTOR_EDIT',
        investor_id: investor.id,
      },
    });
  };

  const removeInvestor = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Permantly delete ${investor.name}?`,
        component: 'INVESTOR_DELETE',
        investor_id: investor.id,
      },
    });
  };

  const currency: Fluit.Currency | undefined = _.find(
    data_currencies,
    item => item.code === investor.address.country.currency
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Details</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box p={3}>
            <TableContainer>
              <Table aria-label="investor table" className={classes.root}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Investor Name
                    </TableCell>
                    <TableCell>{investor.name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Telephone
                    </TableCell>
                    <TableCell>
                      {investor.telephone ? (
                        <Link color="secondary" href={`tel:${investor.telephone}`} target="_blank">
                          {investor.telephone}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Fax
                    </TableCell>
                    <TableCell>{investor.fax ? investor.fax : '-'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Email
                    </TableCell>
                    <TableCell>
                      {investor.email ? (
                        <Link color="secondary" href={`mailto:${investor.email}`} target="_blank">
                          {investor.email}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Website
                    </TableCell>
                    <TableCell>
                      {investor.website ? (
                        <Link color="secondary" href={investor.website} target="_blank">
                          {investor.website}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Address
                    </TableCell>
                    <TableCell>
                      {investor.address.line_1 ? `${investor.address.line_1}, ` : ''}
                      {investor.address.line_2 ? `${investor.address.line_2}, ` : ''}
                      {investor.address.town ? `${investor.address.town}, ` : ''}
                      {investor.address.city ? `${investor.address.city}, ` : ''}
                      {investor.address.postcode ? `${investor.address.postcode}, ` : ''}
                      {investor.address.country.name ? (
                        <>
                          {isoToFlag(investor.address.country.iso)} {investor.address.country.name}
                        </>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Regions
                    </TableCell>
                    <TableCell>
                      {_.some(investor.regions)
                        ? _.map(
                            investor.regions,
                            (region, index) => `${region}${index + 1 === investor.regions?.length ? '' : ', '}`
                          )
                        : '-'}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Stages
                    </TableCell>
                    <TableCell>
                      {_.some(investor.stages)
                        ? _.map(
                            investor.stages,
                            (stage, index) => `${stage}${index + 1 === investor.stages?.length ? '' : ', '}`
                          )
                        : '-'}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sectors
                    </TableCell>
                    <TableCell>
                      {_.some(investor.sectors)
                        ? _.map(
                            investor.sectors,
                            (sector, index) => `${sector}${index + 1 === investor.sectors?.length ? '' : ', '}`
                          )
                        : '-'}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Investment Size
                    </TableCell>
                    <TableCell>
                      {investor.investment_min && investor.investment_max
                        ? `${formatCurrency(investor.investment_min, currency?.symbol, true)} - ${formatCurrency(
                            investor.investment_max,
                            currency?.symbol,
                            true
                          )}`
                        : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={3}>
              <Button variant="outlined" color="secondary" onClick={() => editDetails()}>
                Edit Details
              </Button>
              <Button variant="text" className={classes.delete} onClick={() => removeInvestor()}>
                Delete
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InvestorTabDetails;
