import * as React from 'react';
import * as ROUTES from 'src/routes/routes';
import { Activity, Company, Deals, Investors, Notes, Tasks, Users } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

export const desktopLinks: Fluit.props.Navigation[] = [
  {
    title: 'Deals',
    route: ROUTES.DEALS,
    icon: <Deals />,
    position: 'top',
    tour: 'app_navigation_deals',
  },
  {
    title: 'Investors',
    route: ROUTES.INVESTORS,
    icon: <Investors />,
    position: 'top',
    tour: 'app_navigation_investors',
  },
  {
    title: 'Activity',
    route: ROUTES.ACTIVITY,
    icon: <Activity />,
    position: 'top',
    tour: 'app_navigation_activity',
  },
  {
    title: 'Tasks',
    route: ROUTES.TASKS,
    icon: <Tasks />,
    position: 'top',
    tour: 'app_navigation_tasks',
  },
  {
    title: 'Notes',
    route: ROUTES.NOTES,
    icon: <Notes />,
    position: 'top',
    tour: 'app_navigation_notes',
  },
  {
    title: 'Users',
    route: ROUTES.USERS,
    icon: <Users />,
    position: 'bottom',
    tour: 'app_navigation_users',
  },
  {
    title: 'Organisations',
    route: ROUTES.ORGANISATIONS,
    icon: <Company />,
    position: 'bottom',
    tour: 'app_navigation_organisations',
  },
];

export const mobileLinks: Fluit.props.Navigation[] = [
  {
    title: 'Deals',
    route: ROUTES.DEALS,
    icon: <Deals />,
  },
  {
    title: 'Investors',
    route: ROUTES.INVESTORS,
    icon: <Investors />,
  },
];
