import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import ContactDisplay from 'src/components/Displays/Contact';
import { AppContext } from 'src/contexts/AppContext';
import { formatDate } from 'src/helpers/formatDate';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { Document, DotsHorizontal, Email, Meeting, Telephone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  right: {
    '&::before': {
      flex: 0,
      padding: 0,
      display: 'none',
    },
  },
  content: {
    paddingRight: 0,
    paddingTop: 3,
    marginBottom: theme.spacing(8),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  svg: {
    width: 24,
    height: 24,
    fill: colors.grey[200],
    '& > *': {
      fill: colors.grey[200],
    },
  },
  inlineSvg: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& svg': {
      width: 14,
      height: 14,
      fill: colors.grey[200],
      marginRight: theme.spacing(1),
      '& > *': {
        fill: colors.grey[200],
      },
    },
  },
  overline: {
    lineHeight: 1,
    color: colors.grey[400],
    marginRight: theme.spacing(1),
  },
  date: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    display: 'block',
  },
  dot: {
    backgroundColor: colors.grey[100],
  },
  line: {
    backgroundColor: `${colors.grey[100]}50`,
  },
  meta: {
    fontSize: 12,
  },
  cardLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

interface Props {
  activity: Fluit.activities.Activity;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>, content: JSX.Element) => void;
  handlePopoverClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleMenu: (event: React.MouseEvent<HTMLButtonElement>, activity_id: string) => void;
}

const ActivityItem: React.FC<Props> = ({ activity, handlePopoverOpen, handlePopoverClose, handleMenu }) => {
  const { id, created_at, created_by, creator_name, updated_at, updater_name, event, target, metadata } = activity;

  const classes = useStyles();
  const { state } = React.useContext(AppContext);
  const { user, contacts, init } = state;
  const { admin } = init;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleIcon = () => {
    if (event.action === 'contacted') {
      if (metadata?.contact_method === 'phone') {
        return <Telephone className={classes.svg} />;
      }
      if (metadata?.contact_method === 'email') {
        return <Email className={classes.svg} />;
      }
      if (metadata?.contact_method === 'linkedin') {
        return <LinkedInIcon className={classes.svg} style={{ width: 30, height: 30 }} />;
      }
    }
    if (event.action === 'meeting') {
      return <Meeting className={classes.svg} />;
    }
    if (event.action === 'document') {
      return <Document className={classes.svg} />;
    }
    return '';
  };

  /* Content for Popover */
  const contact = _.find(contacts, { id: target.id });
  const contactInfo = <Box>{contact ? <ContactDisplay data={contact} showInvestor /> : null}</Box>;

  const handleMessage = () => {
    let message;

    if (metadata?.investor_name !== '') {
      message = (
        <>
          {target.parent?.id ? (
            <Link
              color="secondary"
              className={classes.capitalize}
              onMouseEnter={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handlePopoverOpen(e, contactInfo)}
              onMouseLeave={handlePopoverClose}
            >
              {metadata?.contact_name}
            </Link>
          ) : null}{' '}
          {target.parent?.id ? `at ` : null}
          {target.id ? (
            <Tooltip title="view investor">
              <Link
                color="secondary"
                className={classes.capitalize}
                component={NavLink}
                to={`${ROUTES.INVESTORS}/${target.id}`}
              >
                {metadata?.investor_name}
              </Link>
            </Tooltip>
          ) : (
            metadata?.investor_name
          )}
          {metadata?.deal_id ? ` about ` : null}
          {metadata?.deal_id ? (
            <Tooltip title="view deal">
              <Link
                color="secondary"
                className={classes.capitalize}
                component={NavLink}
                to={`${ROUTES.DEALS}/${metadata.deal_id}`}
              >
                {metadata?.deal_name}
              </Link>
            </Tooltip>
          ) : null}
        </>
      );
    } else if (metadata?.contact_name === '') {
      message = (
        <>
          {target.id ? (
            <Tooltip title="view investor">
              <Link
                color="secondary"
                className={classes.capitalize}
                component={NavLink}
                to={`${ROUTES.INVESTORS}/${target.id}`}
              >
                {metadata?.investor_name}
              </Link>
            </Tooltip>
          ) : (
            metadata?.investor_name
          )}
          {metadata?.deal_id ? ` about ` : null}
          {metadata ? (
            <Tooltip title="view deal">
              <Link
                color="secondary"
                className={classes.capitalize}
                component={NavLink}
                to={`${ROUTES.DEALS}/${metadata.deal_id}`}
              >
                {metadata.deal_name}
              </Link>
            </Tooltip>
          ) : null}
        </>
      );
    }

    if (event.action === 'contacted') {
      return (
        <>
          Contacted {message} {metadata?.contact_method === 'linkedin' ? 'via' : 'by'} {metadata?.contact_method}
        </>
      );
    }

    if (event.action === 'document') {
      return (
        <>
          {metadata?.document_url ? (
            <Tooltip title={metadata?.document_url}>
              <Link
                color="secondary"
                href={metadata?.document_url}
                rel="noopener noreferer"
                className={classes.capitalize}
              >
                {metadata?.document_name}
              </Link>
            </Tooltip>
          ) : (
            metadata?.document_name
          )}{' '}
          received from {message}
        </>
      );
    }

    if (event.action === 'meeting') {
      return <>Meeting with {message}</>;
    }

    return '';
  };

  return (
    <TimelineItem
      classes={{
        missingOppositeContent: classes.right,
      }}
    >
      <TimelineSeparator>
        <TimelineDot className={classes.dot} />
        <TimelineConnector className={classes.line} />
      </TimelineSeparator>

      <TimelineContent className={classes.content}>
        <Typography variant="overline" className={classes.date}>
          {event.date ? formatDate(event.date) : null}
        </Typography>

        <Paper>
          <Box p={3} className={classes.cardLayout}>
            <Box display="flex" justifyContent="space-between" width={mobile ? '100%' : 'auto'}>
              <Box width={48} display="flex" alignItems="center">
                {handleIcon()}
              </Box>
              {(user.id === created_by || admin) && mobile ? (
                <Box>
                  <IconButton aria-label="menu" onClick={e => handleMenu(e, id)}>
                    <DotsHorizontal className={classes.svg} />
                  </IconButton>
                </Box>
              ) : null}
            </Box>

            <Box flex={1}>
              <Box mb={1}>
                <Typography variant="body1">
                  {handleMessage()}
                  {event.details ? <span style={{ margin: '0px 8px' }}>-</span> : null}
                  {event.details}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" className={classes.meta}>
                <Box mr={3}>
                  <Typography variant="body2">
                    <Typography variant="caption" className={classes.overline}>
                      Logged by
                    </Typography>
                    {creator_name}
                  </Typography>
                </Box>

                <Box mr={3}>
                  <Typography variant="body2">
                    <Typography variant="caption" className={classes.overline}>
                      Logged at
                    </Typography>
                    {formatDate(created_at as string)}
                  </Typography>
                </Box>
                {updated_at && creator_name !== updater_name ? (
                  <>
                    <Box mr={3}>
                      <Typography variant="body2">
                        <Typography variant="caption" className={classes.overline}>
                          Updated by
                        </Typography>
                        {updater_name}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="body2">
                        <Typography variant="caption" className={classes.overline}>
                          Updated at
                        </Typography>
                        {formatDate(updated_at as string)}
                      </Typography>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Box>
            {(user.id === created_by || admin) && !mobile ? (
              <Box>
                <IconButton aria-label="menu" onClick={e => handleMenu(e, id)}>
                  <DotsHorizontal className={classes.svg} />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ActivityItem;
