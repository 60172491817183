import { Box, CircularProgress, makeStyles, Paper, Theme, Tooltip, Typography } from '@material-ui/core';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { DealContext } from 'src/contexts/DealContext';
import { calculateRaise } from 'src/helpers/calculateRaise';
import { postMoney } from 'src/helpers/postMoney';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  progress: {
    height: '160px',
    width: '160px',
    position: 'relative',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  progressTop: {
    position: 'absolute',
    zIndex: 1,
  },
  progressBottom: {
    position: 'relative',
    zIndex: 0,
    '& svg, & g, & path': {
      fill: theme.palette.background.default,
      color: theme.palette.background.default,
    },
  },
  progressValue: {
    position: 'absolute',
    zIndex: 2,
    width: '160px',
    height: '160px',
    textAlign: 'center',
    lineHeight: '160px',
  },
  progressDetails: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

interface Props {
  termsheets: Fluit.deals.Termsheet[];
}

const DealProgress: React.FC<Props> = ({ termsheets }) => {
  const classes = useStyles();
  const { deal } = React.useContext(DealContext);
  const { details } = deal;

  const total_termsheets = _.sum(_.map(termsheets, 'amount'));

  const setProgress = () => {
    if (details) {
      return Number(calculateRaise(Number(details.amount!), Number(total_termsheets!)));
    }
    return Number(0);
  };

  const setTooltip = () => {
    if (details) {
      let sum = Number(details.amount!) - Number(total_termsheets!);
      let percentage = Number(calculateRaise(Number(details.amount!), Number(total_termsheets!)));
      if (Math.sign(sum) === 1 || Math.sign(sum) === 0 || Math.sign(sum) === -0) {
        return `${details?.currency.symbol} ${numeral(sum).format('0,0')} - ${100 - percentage}% remaining`;
      } else if (Math.sign(sum) === -1) {
        return `oversubscribed by ${details?.currency.symbol} ${numeral(Math.abs(sum)).format('0,0')} - ${Number(
          calculateRaise(details.amount!, total_termsheets!)
        )}%`;
      }
    }
    return '';
  };

  return (
    <Paper className={classes.paper}>
      <Box className={classes.progress}>
        <Tooltip title={setTooltip()}>
          <Typography className={classes.progressValue} variant="h4" component="div">
            {`${numeral(setProgress()).format('0')}%`}
          </Typography>
        </Tooltip>
        <CircularProgress
          className={classes.progressTop}
          variant="static"
          value={setProgress() > 100 ? 100 : setProgress()}
          size={160}
        />
        <CircularProgress className={classes.progressBottom} variant="static" value={100} size={160} color="inherit" />
      </Box>
      <Box display="flex" flexDirection="column" className={classes.progressDetails}>
        <Box mb={2}>
          <Typography variant="h4">
            {details?.currency.symbol} {numeral(total_termsheets).format('0,0')}
          </Typography>
          <Typography variant="body2">Total value of Termsheets</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="h4">
            {details?.currency.symbol} {numeral(details?.amount).format('0,0')}
          </Typography>
          <Typography variant="body2">Available Allocation</Typography>
        </Box>
        <Box>
          <Typography variant="h4">
            {details?.currency.symbol} {postMoney(details?.amount, details?.equity)}
          </Typography>
          <Typography variant="body2">Implied Valuation</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default DealProgress;
