import { makeStyles, Theme } from '@material-ui/core';
import colors from 'src/themes/colors';

export const styles_activitySystemItem = makeStyles((theme: Theme) => ({
  right: {
    '&::before': {
      flex: 0,
      padding: 0,
      display: 'none',
    },
  },
  content: {
    paddingRight: 0,
    paddingTop: 3,
    marginBottom: theme.spacing(4),
  },
  capitalize: {},
  svg: {
    width: 24,
    height: 24,
    fill: colors.grey[200],
    '& > *': {
      fill: colors.grey[200],
    },
  },
  inlineSvg: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& svg': {
      width: 14,
      height: 14,
      fill: colors.grey[200],
      marginRight: theme.spacing(1),
      '& > *': {
        fill: colors.grey[200],
      },
    },
  },
  overline: {
    lineHeight: 1,
    color: colors.grey[400],
    marginRight: theme.spacing(1),
  },
  date: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    display: 'block',
  },
  dot: {
    backgroundColor: colors.grey[100],
  },
  line: {
    backgroundColor: `${colors.grey[100]}50`,
  },
  meta: {
    fontSize: 12,
  },
  accordion: {
    '&::before': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummary: {
    padding: theme.spacing(2, 3),
  },
  accordionContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  rounded: {
    borderRadius: theme.spacing(1),
    '&:last-child': {
      borderRadius: theme.spacing(1),
    },
  },
  details: {
    padding: theme.spacing(0, 3, 3),
    display: 'block',
  },
  json: {
    background: colors.grey[1],
    border: `1px dotted ${colors.grey[50]}`,
    padding: theme.spacing(1),
    color: colors.grey[400],
    fontFamily: 'Lucida Console, Courier, monospace',
    wordBreak: 'break-all',
  },
  chipDelete: {
    background: colors.red[50],
    color: colors.red.main,
  },
  chipCreate: {
    background: colors.green[50],
    color: colors.green[600],
  },
  chipUpdate: {
    background: colors.blue[50],
    color: colors.blue[600],
  },
}));

export const styles_activityList = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  popover: {
    pointerEvents: 'none',
  },
  contact: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    display: 'inline-flex',
    padding: theme.spacing(0.25, 0.5),
    margin: theme.spacing(0, -0.5),
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: colors.blue[50],
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  },
  paper: {
    pointerEvents: 'auto',
  },
  filters: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));
