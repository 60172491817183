import { Grid, makeStyles, Paper, Tab, Tabs, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import * as React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import colors from 'src/themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    '& button': {
      maxWidth: '100%',
      padding: theme.spacing(1.5, 3),
      '& span': {
        alignItems: 'flex-start',
      },
      '&:hover': {
        backgroundColor: colors.purple[1],
      },
    },
  },
  mobileHotfix: {
    width: '100vw',
  },
  paper: {
    background: 'none',
    boxShadow: 'none',
  },
}));

interface Props {
  handleTabs: (event: React.ChangeEvent<{}>, value: any) => void;
  tab: string | null;
  investorCount: number;
  termsheetCount: number;
}

const DealNavigation: React.FC<Props> = ({ handleTabs, tab, investorCount, termsheetCount }) => {
  const { state } = React.useContext(AppContext);
  const { deal } = React.useContext(DealContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const dealTasks = _.filter(state.tasks, { parent_id: deal.id, completed: false });

  return (
    <Grid item xs={12} className={clsx(mobile && classes.mobileHotfix)}>
      <Paper className={clsx(mobile && classes.paper)}>
        <Tabs
          value={tab}
          orientation={mobile ? 'horizontal' : 'vertical'}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
          className={clsx(!mobile && classes.tabs)}
          aria-label="scrollable"
        >
          <Tab value="overview" label="Overview" data-tour="deal_overview" />
          <Tab value="investors" label={`Investors (${investorCount})`} data-tour="deal_investors" />
          <Tab value="termsheets" label={`Termsheets (${termsheetCount})`} data-tour="deal_termsheets" />
          <Tab value="activity" label="Activity" data-tour="deal_activity" />
          <Tab value="tasks" label={`Tasks (${dealTasks.length})`} data-tour="deal_tasks" />
          <Tab value="notes" label="Notes" data-tour="deal_notes" />
          <Tab value="details" label="Details" data-tour="deal_details" />
          <Tab value="process" label="Process" data-tour="deal_process" />
        </Tabs>
      </Paper>
    </Grid>
  );
};

export default DealNavigation;
