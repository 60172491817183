import {
  Box,
  Chip,
  Grid,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoData from 'src/components/Empty';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { AppContext } from 'src/contexts/AppContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import { formatDate } from 'src/helpers/formatDate';
import { BillingFirebase } from 'src/services/billing';
import { Document, DocumentDownload } from 'src/themes/icons';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
    '& svg, & g, & path': {
      fill: theme.palette.secondary.main,
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const InvoicesTab: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { billing } = state;
  const { invoices } = billing;
  const [count, setCount] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();

  const getInvoices = React.useCallback(async () => {
    if (hasMore) {
      const result = await BillingFirebase.listInvoices(billing.stripe_customer_id, count + 10);
      setHasMore(result.has_more);
      setCount(count + 10);
      dispatch({ type: 'BILLING_INVOICES_LIST', payload: result.data });
      setLoading(false);
    }
  }, [billing.stripe_customer_id, count, setCount, dispatch, hasMore]);

  React.useEffect(() => {
    getInvoices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <GenericLoader height="160px" variant="none" />
        </Grid>
      </Grid>
    );
  }

  return (
    <InfiniteScroll
      dataLength={_.values(invoices).length}
      next={getInvoices}
      hasMore={hasMore}
      loader={
        <Box my={4}>
          <GenericLoader height="100%" variant="none" />
        </Box>
      }
      endMessage={
        <Box py={2}>
          <Typography>All invoices loaded</Typography>
        </Box>
      }
    >
      <Grid container spacing={4}>
        {invoices && (
          <Grid item xs={12}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(invoices, invoice => (
                    <TableRow key={invoice.id}>
                      <TableCell>{invoice.number}</TableCell>
                      <TableCell>{formatDate(invoice.period_start, true)}</TableCell>
                      <TableCell align="right">{formatCurrency(invoice.amount_due / 100)}</TableCell>
                      <TableCell>
                        {invoice.paid ? (
                          <Chip color="primary" label="Paid" size="small" />
                        ) : (
                          <Chip label="Due" size="small" />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {invoice.invoice_pdf ? (
                          <Link color="secondary" href={invoice.invoice_pdf} className={classes.link}>
                            <DocumentDownload className={classes.svg} /> Download
                          </Link>
                        ) : (
                          <Typography>Invoice unavailable</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        )}

        {!invoices && (
          <Grid item xs={12}>
            <NoData icon={<Document />} text={`No invoices to display`} />
          </Grid>
        )}
      </Grid>
    </InfiniteScroll>
  );
};

export default InvoicesTab;
