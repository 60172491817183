import { Button, Grid, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import _ from 'lodash';
import * as React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { OrganisationFirebase } from 'src/services/organisations';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const OrganisationLeaveForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisations, user } = state;
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  let find_organisation = _.find(organisations, {
    id: String(state.ui.modal.org_id),
  }) as Fluit.organisations.Organisation;
  const organisation = find_organisation ? find_organisation : state.organisation;

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const leaveOrg = React.useCallback(async () => {
    setLoading(true);
    try {
      await OrganisationFirebase.removeMember(organisation.id, user.id);
      setToast({
        message: `Left ${organisation.name}`,
        type: 'info',
      });
      dispatch({ type: 'ORGANISATION_DELETE', payload: organisation });
      dispatch({ type: 'APP_RELOAD', payload: true });
    } catch (error) {
      setLoading(false);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [setLoading, setToast, organisation, dispatch, user.id]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ul>
          <li>You will no longer have access to {organisation.name}</li>
          <li>This includes all deals associated to {organisation.name}</li>
          <li>and all investors associated to {organisation.name}</li>
          <li>and all notes, tasks, and activity logs</li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          style={{ marginRight: '16px' }}
          onClick={() => leaveOrg()}
          disabled={loading}
        >
          Leave
        </Button>
        <Button variant="text" size="large" onClick={() => closeModal()}>
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default OrganisationLeaveForm;
