import React, { createContext, useReducer } from 'react';
import { initalise } from 'src/constants/init';
import { AssignmentReducer } from 'src/reducers/Assignments';
import { BillingReducer } from 'src/reducers/Billing';
import { ContactsReducer } from 'src/reducers/Contacts';
import { DealsReducer } from 'src/reducers/Deals';
import { InitReducer } from 'src/reducers/Init';
import { InvestorsReducer } from 'src/reducers/Investors';
import { InvitedMembersReducer } from 'src/reducers/InvitedMembers';
import { InvitesReducer } from 'src/reducers/Invites';
import { MembersReducer } from 'src/reducers/Members';
import { NotesReducer } from 'src/reducers/Notes';
import { OrganisationReducer } from 'src/reducers/Organisation';
import { OrganisationsReducer } from 'src/reducers/Organisations';
import { ProcessReducer } from 'src/reducers/Process';
import { SubscriptionsReducer } from 'src/reducers/Subscriptions';
import { TasksReducer } from 'src/reducers/Tasks';
import { TermsheetReducer } from 'src/reducers/Termsheets';
import { UiReducer } from 'src/reducers/Ui';
import { UserReducer } from 'src/reducers/User';
import Fluit from 'src/types/Fluit';

const AppContext = createContext<{
  state: Fluit.context.application.State;
  dispatch: React.Dispatch<any>;
}>({
  state: initalise.application,
  dispatch: () => null,
});

const AppReducer = (
  {
    assignments,
    billing,
    contacts,
    deals,
    init,
    investors,
    invited_members,
    invites,
    members,
    notes,
    organisation,
    organisations,
    processes,
    subscriptions,
    tasks,
    termsheets,
    ui,
    user,
  }: Fluit.context.application.State,
  action: Fluit.reducer.actions.Actions
) => {
  return {
    assignments: AssignmentReducer(assignments, action),
    billing: BillingReducer(billing, action),
    contacts: ContactsReducer(contacts, action),
    deals: DealsReducer(deals, action),
    init: InitReducer(init, action),
    investors: InvestorsReducer(investors, action),
    invited_members: InvitedMembersReducer(invited_members, action),
    invites: InvitesReducer(invites, action),
    members: MembersReducer(members, action),
    notes: NotesReducer(notes, action),
    organisation: OrganisationReducer(organisation, action),
    organisations: OrganisationsReducer(organisations, action),
    processes: ProcessReducer(processes, action),
    subscriptions: SubscriptionsReducer(subscriptions, action),
    tasks: TasksReducer(tasks, action),
    termsheets: TermsheetReducer(termsheets, action),
    ui: UiReducer(ui, action),
    user: UserReducer(user, action),
  };
};

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initalise.application);
  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
