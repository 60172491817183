import { API } from './api';
import Fluit from 'src/types/Fluit';
import { firebaseDateToIso } from 'src/helpers/firebaseDateToIso';

export const UserFirebase = {
  create: async (user: Partial<Fluit.users.User>) => {
    user.created_at = firebaseDateToIso(user.created_at);
    delete user.updated_at;
    try {
      const resp = await API({
        endpoint: `users`,
        method: 'POST',
        payload: user,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  get: async (): Promise<Fluit.users.User | undefined> => {
    try {
      const resp = await API({
        endpoint: `users`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (user: Partial<Fluit.users.User>) => {
    delete user.updated_at;
    delete user.created_at;
    try {
      const resp = await API({
        endpoint: `users/${user.id}`,
        method: 'PATCH',
        payload: user,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async () => {
    try {
      const resp = await API({
        endpoint: `users`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
