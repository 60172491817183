import { Box, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    boxShadow: 'none',
    height: '64px',
    '& input': {
      fontSize: 16,
      fontWeight: 600,
      color: colors.navy[900],
    },
    '& fieldset': {
      border: `2px solid ${colors.common.white}`,
      boxShadow: 'none',
    },
    '& > div, & > div > div': {
      height: '64px',
    },
  },
  svg: {
    width: '14px',
    height: '14px',
    marginRight: theme.spacing(1),
    fill: theme.palette.grey[200],
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  inlineIcon: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  paper: {
    boxShadow: shadows.black[3],
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  pulse: {
    borderRadius: '50%',
    background: colors.grey[100],
    width: '10px',
    height: '10px',
    marginRight: theme.spacing(1),
  },
  live: {
    background: colors.green[400],
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  setFetchedProcess: (value: React.SetStateAction<boolean>) => void;
}

const DealSwitch: React.FC<Props> = ({ setFetchedProcess }) => {
  const { state } = React.useContext(AppContext);
  const { deals } = state;

  // const theme = useTheme();
  // const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  let query = useQuery();
  let dealsArray = _.values(deals);
  const { deal, setDeal } = React.useContext(DealContext);
  const classes = useStyles();
  const history = useHistory();

  const handleChange = (event: object, values: any) => {
    if (values) {
      setDeal(values);
      setFetchedProcess(false);
      history.push(`${ROUTES.DEALS}/${values.id}?tab=${query.get('tab') ? query.get('tab') : 'overview'}`);
    }
  };

  // const handleChangeMobile = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
  //   const id = event.target.value as string;
  //   if (id) {
  //     setDeal(_.find(deals, { id: id }) as IDealType);
  //     history.push(`${ROUTES.DEALS}/${id}`);
  //   }
  // };

  return (
    <Box>
      <Autocomplete
        id="deals-switch"
        options={dealsArray}
        getOptionLabel={option => option.name}
        fullWidth
        className={classes.autocomplete}
        onChange={handleChange}
        value={deal}
        getOptionSelected={(option, value) => option.id === value.id}
        classes={{
          paper: classes.paper,
        }}
        renderOption={option => (
          <Box py={1}>
            <Typography variant="caption">{option.type}</Typography>
            <Typography variant="h4">{option.name}</Typography>
            <Typography variant="caption" className={classes.status}>
              <Box className={clsx(classes.pulse, option.status === 'Live' && classes.live)} />
              {option.status}
            </Typography>
          </Box>
        )}
        renderInput={params => <TextField {...params} variant="outlined" label="Deal" />}
      />

      {/* <FormControl variant="outlined" style={{ width: '100%' }}>
          <InputLabel htmlFor="deal-mobile-switch">Deal</InputLabel>
          <Select
            native
            value={deal.id}
            onChange={handleChangeMobile}
            label="Deal"
            fullWidth
            inputProps={{
              name: 'deal',
              id: 'deal-mobile-switch',
            }}
          >
            {_.map(dealsArray, deal => (
              <option value={deal.id} key={deal.id}>
                {deal.name}
              </option>
            ))}
          </Select>
        </FormControl> */}
    </Box>
  );
};

export default DealSwitch;
