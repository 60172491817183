import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { data_currencies } from 'src/constants/currencies';
import { AppContext } from 'src/contexts/AppContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { Deals, DotsHorizontal } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '20px',
    height: '20px',
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  contact: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    display: 'inline-flex',
    padding: theme.spacing(0.25, 0.5),
    margin: theme.spacing(0, -0.5),
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: colors.blue[50],
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  },
  paper: {
    pointerEvents: 'auto',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > *': {
        marginBottom: theme.spacing(1),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
  svgInline: {
    width: '14px',
    height: '14px',
    marginRight: theme.spacing(0.5),
    fill: theme.palette.grey[200],
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  attribute: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover svg, &:hover svg g, &:hover svg path': {
      fill: theme.palette.secondary.main,
    },
  },
}));

interface Props {
  termsheet: Fluit.deals.Termsheet;
  deal: Fluit.deals.Deal | undefined;
  showDeal?: boolean;
}

const TermsheetCard: React.FC<Props> = ({ termsheet, deal, showDeal }) => {
  const { id, investor_id, url, amount, valuation } = termsheet;
  const { state, dispatch } = React.useContext(AppContext);
  const postMoney = Number(amount ? amount : 0) + Number(valuation ? valuation : 0);
  const equity = ((Number(amount ? amount : 0) / Number(valuation ? valuation : 0)) * 100).toFixed(1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const investor = _.find(state.investors, { id: investor_id });
  const currency = _.find(data_currencies, { code: termsheet.currency_iso });

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Paper>
      <Box p={4}>
        <Box display="flex" flexDirection="row" mb={2}>
          <Box flex={1} display="flex" alignItems="center" mr={2}>
            <Box flex={1}>
              <Typography variant="caption">Investor</Typography>
              <Typography variant="h2">{investor?.name}</Typography>
            </Box>

            {showDeal ? (
              <Box mr={2}>
                <Tooltip title="View Deal">
                  <Button
                    component={NavLink}
                    to={`${ROUTES.DEALS}/${deal?.id}`}
                    color="secondary"
                    className={classes.attribute}
                  >
                    <Deals className={classes.svgInline} /> {deal?.name}
                  </Button>
                </Tooltip>
              </Box>
            ) : null}

            {url && !mobile ? (
              <Box>
                <Button color="secondary" href={url} target="_blank">
                  View Termsheet
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box flex={0} alignItems="center" display="flex">
            <IconButton
              color="secondary"
              aria-controls="investor-contextual-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <DotsHorizontal className={classes.svg} />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.content}>
          <Box flex={1}>
            <Typography variant="caption">Investment Amount</Typography>
            <Typography variant="h4">{formatCurrency(amount, currency?.symbol, true)}</Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="caption">Pre-Money Valuation</Typography>
            <Typography variant="h4">{formatCurrency(valuation, currency?.symbol, true)}</Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="caption">Post-Money Valuation</Typography>
            <Typography variant="h4">{formatCurrency(postMoney, currency?.symbol, true)}</Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="caption">Equity</Typography>
            <Typography variant="h4">{equity}%</Typography>
          </Box>
        </Box>
        {url && mobile ? (
          <Box mt={2}>
            <Button color="secondary" href={url} target="_blank" variant="outlined">
              View Termsheet
            </Button>
          </Box>
        ) : null}
      </Box>
      <Menu
        id="investor-contextual-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={4}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch({
              type: 'MODAL',
              payload: {
                open: true,
                title: `Edit Termsheet`,
                component: 'DEAL_TERMSHEET_EDIT',
                id: id,
              },
            });
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch({
              type: 'MODAL',
              payload: {
                open: true,
                title: `Delete Termsheet`,
                component: 'DEAL_TERMSHEET_DELETE',
                id: id,
              },
            });
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Paper>
  );
};

export default TermsheetCard;
