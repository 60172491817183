import { Box, Chip, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import { styles_checkoutCatalog } from 'src/styles/CheckoutStyles';

const CheckoutInterval: React.FC = () => {
  const { checkout, setCheckout } = React.useContext(CheckoutContext);
  const { quantity, interval, products } = checkout;

  const classes = styles_checkoutCatalog();

  const handlePlan = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, val: 'year' | 'month') => {
      setCheckout({
        ...checkout,
        interval: val,
      });
    },
    [checkout, setCheckout]
  );

  /* Display Handlers */
  const handleCost = React.useCallback(
    (interval: 'month' | 'year') => {
      if (interval === 'month') {
        if (quantity.deals > 0) {
          return (
            <>
              <Typography>{formatCurrency(products.users.standard.month.price)} / user</Typography>
              <Typography>{formatCurrency(products.deals.fundraise.month.price)} / deal</Typography>
            </>
          );
        }
        return <Typography>{formatCurrency(products.users.standard.month.price)} / user</Typography>;
      } else {
        if (quantity.deals > 0) {
          return (
            <>
              <Typography>{formatCurrency(products.users.standard.year.price / 12)} / user</Typography>
              <Typography>{formatCurrency(products.deals.fundraise.year.price / 12)} / deal</Typography>
            </>
          );
        }
        return <Typography>{formatCurrency(products.users.standard.year.price / 12)} / user</Typography>;
      }
    },
    [quantity, products]
  );

  const calculateSaving = React.useCallback(() => {
    const month =
      quantity.users * products.users.standard.month.price +
      (quantity.deals - 1) * products.deals.fundraise.month.price;
    const year =
      quantity.users * products.users.standard.year.price + (quantity.deals - 1) * products.deals.fundraise.year.price;
    return `save: ${formatCurrency(month * 12 - year)}`;
  }, [products, quantity]);

  return (
    <Box mb={4}>
      <Box mb={2}>
        <Typography variant="subtitle2">Billing Cycle</Typography>
      </Box>

      <ToggleButtonGroup
        value={interval}
        exclusive
        onChange={handlePlan}
        aria-label="interval"
        className={classes.toggle}
      >
        <ToggleButton
          value="month"
          aria-label="monthly billing"
          classes={{
            root: classes.toggleButton,
            label: classes.toggleLabel,
            selected: classes.toggleSelected,
          }}
        >
          <Typography variant="h4">Monthly</Typography>
          <Box className={classes.pricing}>{handleCost('month')}</Box>
        </ToggleButton>
        <ToggleButton
          value="year"
          aria-label="yearly billing"
          classes={{
            root: classes.toggleButton,
            label: classes.toggleLabel,
            selected: classes.toggleSelected,
          }}
        >
          <Typography variant="h4">Yearly</Typography>
          <Box className={classes.pricing}>
            {handleCost('year')}
            <Chip className={classes.chip} color="primary" size="small" label={calculateSaving()} />
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default CheckoutInterval;
