import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as React from 'react';
import AutocompleteCountries from 'src/components/Inputs/Autocomplete/Countries';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { initalise } from 'src/constants/init';
import { telephoneRegex, urlRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import { InvestorFirebase } from 'src/services/investors';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const InvestorCreateForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;
  const investor_name = state.ui.modal.investor_name;

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        name: investor_name ? String(investor_name) : '',
        telephone: '',
        fax: '',
        email: '',
        website: '',
        address: {
          country: {
            name: 'United Kingdom',
            iso: 'gb',
            currency: 'GBP',
          },
        },
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await InvestorFirebase.create({
            ...initalise.investor,
            id: '',
            org_id: organisation.id,
            name: trimString(values.name),
            telephone: trimString(values.telephone),
            fax: trimString(values.fax),
            email: trimString(values.email),
            website: trimString(values.website),
            address: {
              country: {
                name: values.address.country.name,
                iso: values.address.country.iso,
                currency: values.address.country.currency,
              },
            },
          });
          setToast({
            message: `Created ${values.name}`,
            type: 'success',
          });
          closeModal();
        } catch (error) {
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('A name is required to create an investor')
          .trim(),
        email: Yup.string()
          .email('Invalid email address')
          .trim(),
        telephone: Yup.string()
          .matches(telephoneRegex, 'Not a valid telephone number')
          .trim(),
        fax: Yup.string()
          .matches(telephoneRegex, 'Not a valid fax number')
          .trim(),
        website: Yup.string()
          .matches(urlRegex, 'Not a valid website address')
          .trim(),
        address: Yup.object().shape({
          country: Yup.object().shape({
            country: Yup.string(),
            iso: Yup.string(),
          }),
        }),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values, setFieldValue, errors, touched } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="name"
                  label="Name"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="telephone"
                  label="Telephone"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="fax"
                  label="Fax"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="website"
                  label="Website"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteCountries
                  setFieldValue={setFieldValue}
                  name="address.country"
                  label="Country"
                  error={errors.address?.country?.iso}
                  touched={touched.address?.country?.iso}
                  value={values.address.country}
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Create
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default InvestorCreateForm;
