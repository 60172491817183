import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import InvestorDealCard from 'src/components/Cards/InvestorDeal';
import NoData from 'src/components/Empty';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { TermsheetFirebase } from 'src/services/termsheets';
import { DealsNone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const InvestorTabDeals: React.FC = () => {
  const { investor } = React.useContext(InvestorContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const { organisation, deals, termsheets, assignments, processes } = state;
  const assignedTo = _.filter(assignments, { investor_id: investor.id });
  const inProcesses = _.map(assignedTo, assignment => _.find(processes, { id: assignment.process_id }));
  const filterProcess = _.filter(inProcesses, _.size) as Fluit.process.Process[];
  const investorDeals = _.map(filterProcess, process => _.find(deals, { id: process?.deal_id }));
  const investorTermsheets = _.filter(termsheets, { investor_id: investor.id });
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const navigateToDeals = () => {
    history.push(ROUTES.DEALS);
  };

  React.useEffect(() => {
    (async () => {
      if (organisation.id !== '' && investor.id !== '') {
        try {
          const termsheets = await TermsheetFirebase.list(organisation.id);
          dispatch({ type: 'TERMSHEETS_SET', payload: termsheets });
        } catch (error) {
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }
    })();
  }, [organisation.id, investor.id, dispatch, setToast]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Deals</Typography>
          </Box>
        </Box>
      </Grid>

      {_.some(investorDeals) ? (
        _.map(investorDeals, deal => {
          if (deal) {
            let dealProcess = _.find(filterProcess, { deal_id: deal.id });
            let assignment = _.find(assignedTo, { process_id: dealProcess?.id });
            let dealStep = _.find(dealProcess?.steps, { id: assignment?.step_id }) as Fluit.process.Step;
            let termsheets = _.filter(investorTermsheets, { deal_id: deal?.id });

            return (
              <Grid item xs={12} key={deal?.id}>
                <InvestorDealCard deal={deal} step={dealStep} termsheets={termsheets} />
              </Grid>
            );
          }
        })
      ) : (
        <Grid item xs={12}>
          <NoData
            text={`No deals for ${investor.name}`}
            icon={<DealsNone />}
            label="Manage Deals"
            action={navigateToDeals}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InvestorTabDeals;
