import { Typography } from '@material-ui/core';
import GridOnTwoToneIcon from '@material-ui/icons/GridOnTwoTone';
import TableChartTwoToneIcon from '@material-ui/icons/TableChartTwoTone';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import { styles_toggleView } from 'src/styles/toggle';

interface Props {
  value: string;
  onChange: (event: React.MouseEvent<HTMLElement>, newView: string | null) => void;
}

const LayoutView: React.FC<Props> = ({ value, onChange }) => {
  const classes = styles_toggleView();
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      aria-label="investor view"
      size="small"
      classes={{ grouped: classes.grouped }}
    >
      <ToggleButton
        value="grid"
        aria-label="grid view"
        classes={{ root: classes.buttonToggle, label: classes.buttonLabel, selected: classes.buttonSelected }}
      >
        <GridOnTwoToneIcon color={value === 'grid' ? 'secondary' : 'inherit'} className={classes.icon} />
        <Typography variant="subtitle2">Grid</Typography>
      </ToggleButton>
      <ToggleButton
        value="table"
        aria-label="table view"
        classes={{ root: classes.buttonToggle, label: classes.buttonLabel, selected: classes.buttonSelected }}
      >
        <TableChartTwoToneIcon color={value === 'table' ? 'secondary' : 'inherit'} className={classes.icon} />
        <Typography variant="subtitle2">Table</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default LayoutView;
