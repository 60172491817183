import { Box, CircularProgress, Divider, TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderGroupParams, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { InvestorFirebase } from 'src/services/investors';
import Fluit from 'src/types/Fluit';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  touched: boolean | undefined;
  error: string | undefined;
  label: string;
  value?: string;
}

const AutocompleteFilteredInvestorsByLetter: React.FC<Props> = ({
  setFieldValue,
  name,
  id,
  touched,
  error,
  label,
  value,
}) => {
  const { state, dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const { investors, organisation, assignments } = state;

  const { process } = React.useContext(ProcessContext);
  const { steps } = process;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Fluit.investors.Investor[]>([]);
  const [fired, setFired] = React.useState(false);
  const loading = open && !fired;

  const handleOptions = React.useCallback(
    (data: Fluit.investors.Investor[]) => {
      const assigned = _.flattenDeep(
        _.map(steps, step => {
          return _.filter(assignments, { step_id: step.id });
        })
      );

      if (!_.some(assigned)) {
        setOptions([]);
        return;
      }

      const assignedInvestors = _.map(assigned, assignee =>
        _.find(data, investor => investor.id === assignee.investor_id)
      );

      setOptions(assignedInvestors as Fluit.investors.Investor[]);
    },
    [assignments, steps]
  );

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (_.some(investors)) {
      setFired(true);
      handleOptions(investors);
      return undefined;
    }

    (async () => {
      try {
        const result = await InvestorFirebase.list(organisation.id);
        setFired(true);
        const updatedInvestors = _.map(result, investor =>
          _.assign(investor, {
            firstLetter: /[0-9]/.test(investor.name[0].toUpperCase()) ? '0-9' : investor.name[0].toUpperCase(),
          })
        );
        dispatch({ type: 'INVESTORS_LIST', payload: updatedInvestors });
        if (active) {
          handleOptions(updatedInvestors);
        }
      } catch (error) {
        const err = error as Fluit.firestore.Error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [investors, organisation, loading, dispatch, setToast, handleOptions]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
      setFired(false);
    }
  }, [open]);

  let val: Fluit.investors.Investor | null = null;

  if (value) {
    const investor = _.find(investors, { id: value });
    val = investor ? investor : null;
  }

  return (
    <Field
      name={name}
      id={id ? id : name}
      component={Autocomplete}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      groupBy={(option: Fluit.investors.Investor) => option.firstLetter}
      getOptionLabel={(option: Fluit.investors.Investor) => option.name}
      autoSelect={true}
      value={val}
      onChange={(event: object, value: Fluit.investors.Investor) => {
        setFieldValue(name, value ? value.id : '');
      }}
      noOptionsText="No investors in this deal"
      renderGroup={(option: AutocompleteRenderGroupParams) => (
        <Box key={option.key} mb={1}>
          <Box px={2} py={1}>
            <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
              {option.group}
            </Typography>
          </Box>
          {option.children}
          <Divider light={true} />
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteFilteredInvestorsByLetter;

// <Autocomplete
//                   options={array}
//                   loading={loading}
//                   open={open}
//                   onOpen={onOpen}
//                   onClose={onClose}
//                   className={clsx(classes.autocomplete, errors.investor_id && touched.investor_id && classes.muiError)}
//                   getOptionLabel={option => option.name}
//                   getOptionSelected={(option, value) => option.id === value.id}
//                   autoSelect={true}
//                   autoComplete={true}
//                   noOptionsText="No investors in this deal, add some first"
//                   onChange={(e: object, value: any | null) => {
//                     if (value !== null) {
//                       setFieldValue('investor_id', value.id);
//                     }
//                   }}
//                   renderInput={params => (
//                     <Field
//                       {...params}
//                       component={FormikTextField}
//                       fullWidth={true}
//                       label="Investors"
//                       name="investor_id"
//                       inputProps={{
//                         ...params.inputProps,
//                         autoComplete: _.uniqueId('new-password off'),
//                       }}
//                       InputProps={{
//                         ...params.InputProps,
//                         endAdornment: (
//                           <React.Fragment>
//                             {loading ? <CircularProgress color="primary" size={20} /> : null}
//                             {params.InputProps.endAdornment}
//                           </React.Fragment>
//                         ),
//                       }}
//                       helperText={errors.investor_id && touched.investor_id ? errors.investor_id : null}
//                       variant="outlined"
//                     />
//                   )}
//                 />
