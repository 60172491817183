import { Box, Grid, IconButton, makeStyles, Menu, MenuItem, Paper, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { AppContext } from 'src/contexts/AppContext';
import colors from 'src/themes/colors';
import { DotsHorizontal, Investors } from 'src/themes/icons';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  draggable: {
    border: `2px solid ${colors.common.white}`,
    transition: '0.25s all ease-in-out',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    '&:hover': {
      boxShadow: shadows.purple[4],
      borderColor: colors.purple.main,
    },
    '&$dragging': {
      boxShadow: shadows.purple[4],
      borderColor: colors.purple.main,
    },
  },
  dragging: {},
  svg: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  svgButton: {
    width: '16px',
    height: '16px',
  },
  attribute: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

interface Props {
  step: Fluit.process.Step;
  stepIndex: number;
}
const StepDraggable: React.FC<Props> = ({ step, stepIndex }) => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AppContext);
  const { assignments } = state;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const assigned = _.filter(assignments, { step_id: step.id });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Draggable draggableId={step.id} index={stepIndex}>
        {(provided, snapshot) => (
          <Grid item xs={12} {...provided.draggableProps} {...provided.dragHandleProps} innerRef={provided.innerRef}>
            <Paper className={clsx(classes.draggable, snapshot.isDragging && classes.dragging)}>
              <Box flex={1}>
                <Typography variant="h4">{step.name}</Typography>
                <Box>
                  <Typography variant="body1" className={classes.attribute}>
                    <Investors className={classes.svg} />
                    {assigned
                      ? assigned.length === 1
                        ? `${assigned.length} investor`
                        : `${assigned.length} investors`
                      : `0 investors`}
                  </Typography>
                </Box>
              </Box>
              <Box flex={0}>
                <IconButton
                  color="secondary"
                  aria-controls="investor-contextual-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <DotsHorizontal className={classes.svgButton} />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        )}
      </Draggable>
      <Menu
        id="investor-contextual-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={4}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch({
              type: 'MODAL',
              payload: {
                open: true,
                title: `Rename ${step.name}`,
                component: 'PROCESS_STEP_RENAME',
                step_id: step.id,
                step_name: step.name,
                step_investors: assigned ? assigned.length : 0,
              },
            });
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch({
              type: 'MODAL',
              payload: {
                open: true,
                title: `Delete ${step.name}?`,
                component: 'PROCESS_STEP_DELETE',
                step_id: step.id,
                step_investors: assigned,
                step_name: step.name,
              },
            });
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default StepDraggable;
