import { Box, Button, Grid, InputAdornment, LinearProgress, MenuItem, Paper, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import numeral from 'numeral';
import React from 'react';
import AutocompleteCurrencies from 'src/components/Inputs/Autocomplete/Currencies';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import NumberInput from 'src/components/Inputs/Textfields/NumberInput';
import { fundingRounds } from 'src/constants/funding_rounds';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { postMoney } from 'src/helpers/postMoney';
import { trimString } from 'src/helpers/trim';
import { DealFirebase } from 'src/services/deals';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

type dealType = 'Fundraise';

const DealEditForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { deal, setDeal } = React.useContext(DealContext);

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        id: deal.id,
        status: deal.status,
        name: deal.name,
        type: deal.type,
        details: {
          round: deal.details ? deal.details.round : 'None',
          currency: {
            name: deal.details ? deal.details.currency.name : '',
            code: deal.details ? deal.details.currency.code : '',
            symbol: deal.details ? deal.details.currency.symbol : '',
            alpha_2: deal.details ? deal.details.currency.alpha_2 : '',
          },
          amount: deal.details ? deal.details.amount : 0,
          equity: deal.details ? deal.details.equity : 0,
        },
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        values.details.amount = parseInt(String(values.details.amount), 10);
        try {
          const result = await DealFirebase.update({
            ...deal,
            name: trimString(values.name),
            type: values.type as dealType,
            status: values.status,
            details: values.details,
          });
          setDeal({ ...deal, ...result });
          dispatch({ type: 'DEAL_UPDATE', payload: result });
          setToast({
            message: `Deal Updated`,
            type: 'info',
          });
          closeModal();
        } catch (error) {
          const err = error as Fluit.firestore.Error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('A name is required to create your deal'),
        type: Yup.string().required('You must pick a deal type'),
        status: Yup.string().required('You must select a status for this deal'),
        details: Yup.object().when('type', {
          is: 'Fundraise',
          then: Yup.object({
            round: Yup.string().required('Select a funding round'),
            currency: Yup.object()
              .shape({
                name: Yup.string().required(`Select the currency you're raising in`),
                code: Yup.string().required(`Select the currency you're raising in`),
                symbol: Yup.string().required(`Select the currency you're raising in`),
                alpha_2: Yup.string().required(`Select the currency you're raising in`),
              })
              .when('name', {
                is: '',
                then: Yup.object().required('This is required'),
              }),
            amount: Yup.number()
              .required()
              .min(1, 'Amount is required'),
            equity: Yup.number(),
          }),
        }),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values, setFieldValue, errors, touched } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="name"
                  label="Name"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  select
                  name="status"
                  label="Status"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                >
                  <MenuItem value="Inactive">Inactive</MenuItem>
                  <MenuItem value="Live">Live</MenuItem>
                  <MenuItem value="Complete">Complete</MenuItem>
                  <MenuItem value="Cancelled">Cancelled</MenuItem>
                </Field>
              </Grid>
              {/* <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  select
                  name="type"
                  label="Type"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                >
                  <MenuItem value="Fundraise">Fundraise</MenuItem>
                </Field>
              </Grid> */}

              <Grid item xs={12}>
                <Box hidden={values.type !== 'Fundraise'}>
                  <Grid item container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={FormikTextField}
                        fullWidth={true}
                        select
                        name="details.round"
                        label="Funding Round"
                        InputProps={{
                          autoComplete: 'new-password',
                        }}
                        variant="outlined"
                      >
                        {fundingRounds.map(round => (
                          <MenuItem key={round} value={round}>
                            {round}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <AutocompleteCurrencies
                        setFieldValue={setFieldValue}
                        name="details.currency"
                        label="Raise Currency"
                        error={errors.details?.currency?.alpha_2}
                        touched={touched.details?.currency?.alpha_2}
                        value={values.details.currency}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Field
                        component={FormikTextField}
                        fullWidth={true}
                        name="details.amount"
                        label="Investment Required"
                        value={values.details.amount}
                        onChange={(e: object, value: any | null) => {
                          if (value !== null) {
                            setFieldValue('details.amount', parseInt(value, 10));
                          }
                        }}
                        InputProps={{
                          autoComplete: 'new-password',
                          startAdornment: (
                            <InputAdornment position="start" disableTypography={true}>
                              {values.details ? (values.details.currency ? values.details.currency?.symbol : '') : ''}
                            </InputAdornment>
                          ),
                          inputComponent: NumberInput as any,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={FormikTextField}
                        fullWidth={true}
                        name="details.equity"
                        label="Equity on offer"
                        type="number"
                        InputProps={{
                          autoComplete: 'new-password',
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Paper>
                        <Box p={2}>
                          <Typography variant="body2">Implied Post Money Valuation</Typography>
                          <Typography variant="h4">
                            {values.details.currency.symbol}
                            {postMoney(values.details.amount, values.details.equity)}{' '}
                            <Typography variant="caption">
                              <i>
                                ({values.details.currency.symbol}
                                {numeral(values.details.amount).format('0,0')} x {values.details.equity}%)
                              </i>
                            </Typography>
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default DealEditForm;
