import Fluit from 'src/types/Fluit';

export const data_currencies: Fluit.Currency[] = [
  {
    name: 'United States Dollar',
    code: 'USD',
    symbol: '$',
    alpha_2: 'us',
  },
  {
    name: 'Euro',
    code: 'EUR',
    symbol: '€',
    alpha_2: 'eu',
  },
  {
    name: 'Japan Yen',
    code: 'JPY',
    symbol: '¥',
    alpha_2: 'jp',
  },
  {
    name: 'United Kingdom Pound',
    code: 'GBP',
    symbol: '£',
    alpha_2: 'gb',
  },
  {
    name: 'Australia Dollar',
    code: 'AUD',
    symbol: '$',
    alpha_2: 'au',
  },
  {
    name: 'Canada Dollar',
    code: 'CAD',
    symbol: '$',
    alpha_2: 'ca',
  },
  {
    name: 'Switzerland Franc',
    code: 'CHF',
    symbol: 'CHF',
    alpha_2: 'ch',
  },
  {
    name: 'China Yuan Renminbi',
    code: 'CNY',
    symbol: '¥',
    alpha_2: 'cn',
  },
  {
    name: 'Sweden Krona',
    code: 'SEK',
    symbol: 'kr',
    alpha_2: 'se',
  },
  {
    name: 'New Zealand Dollar',
    code: 'NZD',
    symbol: '$',
    alpha_2: 'nz',
  },
  {
    name: 'Albania Lek',
    code: 'ALL',
    symbol: 'Lek',
    alpha_2: 'al',
  },
  {
    name: 'Afghanistan Afghani',
    code: 'AFN',
    symbol: '؋',
    alpha_2: 'af',
  },
  {
    name: 'Argentina Peso',
    code: 'ARS',
    symbol: '$',
    alpha_2: 'ar',
  },
  {
    name: 'Aruba Guilder',
    code: 'AWG',
    symbol: 'ƒ',
    alpha_2: 'aw',
  },
  {
    name: 'Azerbaijan Manat',
    code: 'AZN',
    symbol: '₼',
    alpha_2: 'az',
  },
  {
    name: 'Bahamas Dollar',
    code: 'BSD',
    symbol: '$',
    alpha_2: 'bh',
  },
  {
    name: 'Barbados Dollar',
    code: 'BBD',
    symbol: '$',
    alpha_2: 'bb',
  },
  {
    name: 'Belarus Ruble',
    code: 'BYN',
    symbol: 'Br',
    alpha_2: 'by',
  },
  {
    name: 'Belize Dollar',
    code: 'BZD',
    symbol: 'BZ$',
    alpha_2: 'bz',
  },
  {
    name: 'Bermuda Dollar',
    code: 'BMD',
    symbol: '$',
    alpha_2: 'bm',
  },
  {
    name: 'Bolivia Bolíviano',
    code: 'BOB',
    symbol: '$b',
    alpha_2: 'bo',
  },
  {
    name: 'Bosnia and Herzegovina Convertible Mark',
    code: 'BAM',
    symbol: 'KM',
    alpha_2: 'ba',
  },
  {
    name: 'Botswana Pula',
    code: 'BWP',
    symbol: 'P',
    alpha_2: 'bw',
  },
  {
    name: 'Bulgaria Lev',
    code: 'BGN',
    symbol: 'лв',
    alpha_2: 'bg',
  },
  {
    name: 'Brazil Real',
    code: 'BRL',
    symbol: 'R$',
    alpha_2: 'br',
  },
  {
    name: 'Brunei Darussalam Dollar',
    code: 'BND',
    symbol: '$',
    alpha_2: 'bn',
  },
  {
    name: 'Cambodia Riel',
    code: 'KHR',
    symbol: '៛',
    alpha_2: 'kh',
  },
  {
    name: 'Cayman Islands Dollar',
    code: 'KYD',
    symbol: '$',
    alpha_2: 'ky',
  },
  {
    name: 'Chile Peso',
    code: 'CLP',
    symbol: '$',
    alpha_2: 'cl',
  },
  {
    name: 'Colombia Peso',
    code: 'COP',
    symbol: '$',
    alpha_2: 'co',
  },
  {
    name: 'Costa Rica Colon',
    code: 'CRC',
    symbol: '₡',
    alpha_2: 'cr',
  },
  {
    name: 'Croatia Kuna',
    code: 'HRK',
    symbol: 'kn',
    alpha_2: 'hr',
  },
  {
    name: 'Cuba Peso',
    code: 'CUP',
    symbol: '₱',
    alpha_2: 'cu',
  },
  {
    name: 'Czech Republic Koruna',
    code: 'CZK',
    symbol: 'Kč',
    alpha_2: 'cz',
  },
  {
    name: 'Denmark Krone',
    code: 'DKK',
    symbol: 'kr',
    alpha_2: 'dk',
  },
  {
    name: 'Dominican Republic Peso',
    code: 'DOP',
    symbol: 'RD$',
    alpha_2: 'do',
  },
  {
    name: 'East Caribbean Dollar',
    code: 'XCD',
    symbol: '$',
    alpha_2: 'lc',
  },
  {
    name: 'Egypt Pound',
    code: 'EGP',
    symbol: '£',
    alpha_2: 'eg',
  },
  {
    name: 'El Salvador Colon',
    code: 'SVC',
    symbol: '$',
    alpha_2: 'sv',
  },
  {
    name: 'Falkland Islands (Malvinas) Pound',
    code: 'FKP',
    symbol: '£',
    alpha_2: 'fk',
  },
  {
    name: 'Fiji Dollar',
    code: 'FJD',
    symbol: '$',
    alpha_2: 'fj',
  },
  {
    name: 'Ghana Cedi',
    code: 'GHS',
    symbol: '¢',
    alpha_2: 'gh',
  },
  {
    name: 'Gibraltar Pound',
    code: 'GIP',
    symbol: '£',
    alpha_2: 'gi',
  },
  {
    name: 'Guatemala Quetzal',
    code: 'GTQ',
    symbol: 'Q',
    alpha_2: 'gt',
  },
  {
    name: 'Guernsey Pound',
    code: 'GGP',
    symbol: '£',
    alpha_2: 'gg',
  },
  {
    name: 'Guyana Dollar',
    code: 'GYD',
    symbol: '$',
    alpha_2: 'gy',
  },
  {
    name: 'Honduras Lempira',
    code: 'HNL',
    symbol: 'L',
    alpha_2: 'hn',
  },
  {
    name: 'Hong Kong Dollar',
    code: 'HKD',
    symbol: '$',
    alpha_2: 'hk',
  },
  {
    name: 'Hungary Forint',
    code: 'HUF',
    symbol: 'Ft',
    alpha_2: 'hu',
  },
  {
    name: 'Iceland Krona',
    code: 'ISK',
    symbol: 'kr',
    alpha_2: 'is',
  },
  {
    name: 'India Rupee',
    code: 'INR',
    symbol: '₹',
    alpha_2: 'in',
  },
  {
    name: 'Indonesia Rupiah',
    code: 'IDR',
    symbol: 'Rp',
    alpha_2: 'id',
  },
  {
    name: 'Iran Rial',
    code: 'IRR',
    symbol: '﷼',
    alpha_2: 'ir',
  },
  {
    name: 'Isle of Man Pound',
    code: 'IMP',
    symbol: '£',
    alpha_2: 'im',
  },
  {
    name: 'Israel Shekel',
    code: 'ILS',
    symbol: '₪',
    alpha_2: 'il',
  },
  {
    name: 'Jamaica Dollar',
    code: 'JMD',
    symbol: 'J$',
    alpha_2: 'jm',
  },
  {
    name: 'Jersey Pound',
    code: 'JEP',
    symbol: '£',
    alpha_2: 'je',
  },
  {
    name: 'Kazakhstan Tenge',
    code: 'KZT',
    symbol: 'лв',
    alpha_2: 'kz',
  },
  {
    name: 'Korea (North) Won',
    code: 'KPW',
    symbol: '₩',
    alpha_2: 'kp',
  },
  {
    name: 'Korea (South) Won',
    code: 'KRW',
    symbol: '₩',
    alpha_2: 'kr',
  },
  {
    name: 'Kyrgyzstan Som',
    code: 'KGS',
    symbol: 'лв',
    alpha_2: 'kg',
  },
  {
    name: 'Laos Kip',
    code: 'LAK',
    symbol: '₭',
    alpha_2: 'la',
  },
  {
    name: 'Lebanon Pound',
    code: 'LBP',
    symbol: '£',
    alpha_2: 'lb',
  },
  {
    name: 'Liberia Dollar',
    code: 'LRD',
    symbol: '$',
    alpha_2: 'lr',
  },
  {
    name: 'Macedonia Denar',
    code: 'MKD',
    symbol: 'ден',
    alpha_2: 'mk',
  },
  {
    name: 'Malaysia Ringgit',
    code: 'MYR',
    symbol: 'RM',
    alpha_2: 'my',
  },
  {
    name: 'Mauritius Rupee',
    code: 'MUR',
    symbol: '₨',
    alpha_2: 'mu',
  },
  {
    name: 'Mexico Peso',
    code: 'MXN',
    symbol: '$',
    alpha_2: 'mx',
  },
  {
    name: 'Mongolia Tughrik',
    code: 'MNT',
    symbol: '₮',
    alpha_2: 'mn',
  },
  {
    name: 'Mozambique Metical',
    code: 'MZN',
    symbol: 'MT',
    alpha_2: 'mz',
  },
  {
    name: 'Namibia Dollar',
    code: 'NAD',
    symbol: '$',
    alpha_2: 'na',
  },
  {
    name: 'Nepal Rupee',
    code: 'NPR',
    symbol: '₨',
    alpha_2: 'np',
  },
  {
    name: 'Netherlands Antilles Guilder',
    code: 'ANG',
    symbol: 'ƒ',
    alpha_2: 'nl',
  },
  {
    name: 'Nicaragua Cordoba',
    code: 'NIO',
    symbol: 'C$',
    alpha_2: 'ni',
  },
  {
    name: 'Nigeria Naira',
    code: 'NGN',
    symbol: '₦',
    alpha_2: 'ng',
  },
  {
    name: 'Norway Krone',
    code: 'NOK',
    symbol: 'kr',
    alpha_2: 'no',
  },
  {
    name: 'Oman Rial',
    code: 'OMR',
    symbol: '﷼',
    alpha_2: 'om',
  },
  {
    name: 'Pakistan Rupee',
    code: 'PKR',
    symbol: '₨',
    alpha_2: 'pk',
  },
  {
    name: 'Panama Balboa',
    code: 'PAB',
    symbol: 'B/.',
    alpha_2: 'pa',
  },
  {
    name: 'Paraguay Guarani',
    code: 'PYG',
    symbol: 'Gs',
    alpha_2: 'py',
  },
  {
    name: 'Peru Sol',
    code: 'PEN',
    symbol: 'S/.',
    alpha_2: 'pe',
  },
  {
    name: 'Philippines Peso',
    code: 'PHP',
    symbol: '₱',
    alpha_2: 'ph',
  },
  {
    name: 'Poland Zloty',
    code: 'PLN',
    symbol: 'zł',
    alpha_2: 'pl',
  },
  {
    name: 'Qatar Riyal',
    code: 'QAR',
    symbol: '﷼',
    alpha_2: 'qa',
  },
  {
    name: 'Romania Leu',
    code: 'RON',
    symbol: 'lei',
    alpha_2: 'ro',
  },
  {
    name: 'Russia Ruble',
    code: 'RUB',
    symbol: '₽',
    alpha_2: 'ru',
  },
  {
    name: 'Saint Helena Pound',
    code: 'SHP',
    symbol: '£',
    alpha_2: 'sh',
  },
  {
    name: 'Saudi Arabia Riyal',
    code: 'SAR',
    symbol: '﷼',
    alpha_2: 'sa',
  },
  {
    name: 'Serbia Dinar',
    code: 'RSD',
    symbol: 'Дин.',
    alpha_2: 'rs',
  },
  {
    name: 'Seychelles Rupee',
    code: 'SCR',
    symbol: '₨',
    alpha_2: 'sc',
  },
  {
    name: 'Singapore Dollar',
    code: 'SGD',
    symbol: '$',
    alpha_2: 'sg',
  },
  {
    name: 'Solomon Islands Dollar',
    code: 'SBD',
    symbol: '$',
    alpha_2: 'sb',
  },
  {
    name: 'Somalia Shilling',
    code: 'SOS',
    symbol: 'S',
    alpha_2: 'so',
  },
  {
    name: 'South Africa Rand',
    code: 'ZAR',
    symbol: 'R',
    alpha_2: 'za',
  },
  {
    name: 'Sri Lanka Rupee',
    code: 'LKR',
    symbol: '₨',
    alpha_2: 'lk',
  },
  {
    name: 'Suriname Dollar',
    code: 'SRD',
    symbol: '$',
    alpha_2: 'sr',
  },
  {
    name: 'Syria Pound',
    code: 'SYP',
    symbol: '£',
    alpha_2: 'sy',
  },
  {
    name: 'Taiwan New Dollar',
    code: 'TWD',
    symbol: 'NT$',
    alpha_2: 'tw',
  },
  {
    name: 'Thailand Baht',
    code: 'THB',
    symbol: '฿',
    alpha_2: 'th',
  },
  {
    name: 'Trinidad and Tobago Dollar',
    code: 'TTD',
    symbol: 'TT$',
    alpha_2: 'tt',
  },
  {
    name: 'Turkey Lira',
    code: 'TRY',
    symbol: '₺',
    alpha_2: 'tr',
  },
  {
    name: 'Tuvalu Dollar',
    code: 'TVD',
    symbol: '$',
    alpha_2: 'tv',
  },
  {
    name: 'Ukraine Hryvnia',
    code: 'UAH',
    symbol: '₴',
    alpha_2: 'ua',
  },
  {
    name: 'Uruguay Peso',
    code: 'UYU',
    symbol: '$U',
    alpha_2: 'uy',
  },
  {
    name: 'Uzbekistan Som',
    code: 'UZS',
    symbol: 'лв',
    alpha_2: 'uz',
  },
  {
    name: 'Venezuela Bolívar',
    code: 'VEF',
    symbol: 'Bs',
    alpha_2: 've',
  },
  {
    name: 'Viet Nam Dong',
    code: 'VND',
    symbol: '₫',
    alpha_2: 'vn',
  },
  {
    name: 'Yemen Rial',
    code: 'YER',
    symbol: '﷼',
    alpha_2: 'ye',
  },
  {
    name: 'Zimbabwe Dollar',
    code: 'ZWD',
    symbol: 'Z$',
    alpha_2: 'zw',
  },
];
