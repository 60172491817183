import { Box, Button, Paper, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { AppContext } from 'src/contexts/AppContext';
import { BillingFirebase } from 'src/services/billing';

const BillingCustomerDetails: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, billing } = state;
  const { customer } = billing;
  const [loading, setLoading] = React.useState(true);

  const getCustomerData = React.useCallback(async () => {
    const result = await BillingFirebase.getCustomerData(organisation.id, billing.stripe_customer_id);
    dispatch({ type: 'BILLING_CUSTOMER_GET', payload: result });
    setLoading(false);
  }, [billing.stripe_customer_id, dispatch, organisation.id]);

  React.useEffect(() => {
    if (loading) {
      getCustomerData();
    }
  }, [getCustomerData, loading]);

  const editCustomerDetails = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: 'Edit Billing Details',
        component: 'BILLING_DETAILS',
      },
    });
  };

  if (loading) {
    return (
      <Paper>
        <Box p={4} justifyContent="center">
          <GenericLoader height="100px" variant="none" />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper>
      {customer && (
        <Box p={4} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography>
              <strong>{customer.name}</strong>
            </Typography>
            {customer.address && (
              <Typography>
                {customer.address.line1 && `${customer.address.line1}, `}
                {customer.address.line2 && `${customer.address.line2}, `}
                {customer.address.city && `${customer.address.city}, `}
                {customer.address.state && `${customer.address.state}, `}
                {customer.address.postal_code && `${customer.address.postal_code}`}
              </Typography>
            )}
            {customer.phone && <Typography>{customer.phone}</Typography>}
            {customer.email && <Typography>{customer.email}</Typography>}

            {_.map(customer.tax_ids?.data, tax => (
              <Typography key={tax.value}>VAT: {tax.value}</Typography>
            ))}
          </Box>
          <Box>
            <Button color="secondary" variant="outlined" onClick={editCustomerDetails}>
              Edit Billing Details
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default BillingCustomerDetails;
