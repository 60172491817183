import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import Fluit from 'src/types/Fluit';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  touched: boolean | undefined;
  error: string | undefined;
  label: string;
  value?: string;
}

const AutocompleteMembers: React.FC<Props> = ({ setFieldValue, name, id, touched, error, label, value }) => {
  const { state } = React.useContext(AppContext);
  const { members } = state;

  let val: Fluit.organisations.Member | null = null;

  if (value) {
    const member = _.find(members, { id: value });
    val = member ? member : null;
  }

  return (
    <Field
      name={name}
      id={id ? id : name}
      component={Autocomplete}
      options={_.values(members)}
      getOptionLabel={(option: Fluit.organisations.Member) => `${option.first_name} ${option.last_name}`}
      autoSelect={true}
      value={val}
      onChange={(event: object, value: Fluit.organisations.Member) => {
        setFieldValue(name, value ? value.id : '');
      }}
      noOptionsText="No members available"
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

export default AutocompleteMembers;
