import { Box, IconButton, InputAdornment, TextField, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { AlgoliaContext } from 'src/contexts/AlgoliaContext';
import { Styles } from 'src/styles/styles';
import { Close } from 'src/themes/icons';
import AlgoliaPoweredBy from './AlgoliaPoweredBy';

interface Props {
  currentRefinement: string;
  isSearchStalled: boolean;
  refine: (...args: any[]) => any;
}

const AlgoliaSearchBox: React.FC<Props> = ({ currentRefinement, isSearchStalled, refine }) => {
  const { search, setSearch } = React.useContext(AlgoliaContext);
  const classes = Styles.icon({ height: 12, width: 12 });
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  const handleSearch = (query: string) => {
    setSearch({
      selected: search.selected,
      query: query,
    });
    refine(query);
  };

  return (
    <Box display="flex" flexDirection={mobile ? 'column' : 'row'}>
      <form
        noValidate
        action=""
        role="search"
        style={{ flex: 1, marginRight: mobile ? 0 : 24, marginBottom: mobile ? 16 : 0 }}
      >
        <TextField
          variant="outlined"
          autoFocus={mobile ? false : true}
          value={currentRefinement}
          onChange={event => handleSearch(event.currentTarget.value)}
          placeholder="Type to start searching for investors"
          fullWidth
          helperText={isSearchStalled ? 'Search is stalled, try refreshing' : ''}
          data-tour="investor_search_field"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ marginRight: 8 }}>
                {currentRefinement.length > 0 && (
                  <Tooltip title="Reset Search">
                    <IconButton aria-label="toggle password visibility" onClick={() => handleSearch('')} edge="end">
                      <Close className={classes.svg} />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      </form>
      <AlgoliaPoweredBy />
    </Box>
  );
};

export default connectSearchBox(AlgoliaSearchBox);
