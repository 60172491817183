import React from 'react';
import { initalise } from 'src/constants/init';
import Fluit from 'src/types/Fluit';

export const CheckoutContext = React.createContext<Fluit.context.checkout.Context>(
  {} as Fluit.context.checkout.Context
);

export const CheckoutProvider = ({ children }: Fluit.props.Children) => {
  const [checkout, setCheckout] = React.useState<Fluit.context.checkout.State>(initalise.checkout);
  return <CheckoutContext.Provider value={{ checkout, setCheckout }}>{children}</CheckoutContext.Provider>;
};
