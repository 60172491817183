import { Box, Chip, Link, Paper, Tooltip, Typography } from '@material-ui/core';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { data_currencies } from 'src/constants/currencies';
import { AppContext } from 'src/contexts/AppContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import { formatDateandTime } from 'src/helpers/formatDateandTime';
import * as ROUTES from 'src/routes/routes';
import { styles_activitySystemItem } from 'src/styles/activity';
import {
  Company,
  Deals,
  Document,
  Investors,
  Notes,
  Process,
  Tasks,
  Users,
  UsersAdd,
  UsersInvited,
  UsersPermissions,
} from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

interface Props {
  activity: Fluit.activities.Activity;
}

const SystemItem: React.FC<Props> = ({ activity }) => {
  const { created_at, target, event, metadata, creator_name } = activity;

  const classes = styles_activitySystemItem();
  const { state } = React.useContext(AppContext);
  const { investors, deals } = state;

  const handleIcon = React.useCallback(() => {
    switch (event.action) {
      case 'member_invited':
        if (target.type === 'Organisation') return <UsersInvited className={classes.svg} />;
        break;
      case 'member_joined':
        if (target.type === 'Organisation') return <UsersAdd className={classes.svg} />;
        break;
      case 'member_removed':
        if (target.type === 'Organisation') return <Users className={classes.svg} />;
        break;
      case 'member_declined':
        if (target.type === 'Organisation') return <Users className={classes.svg} />;
        break;
      case 'member_role_changed':
        if (target.type === 'Organisation') return <UsersPermissions className={classes.svg} />;
        break;
      default:
        if (target.type === 'Assignment') return <Process className={classes.svg} />;
        if (target.type === 'Note') return <Notes className={classes.svg} />;
        if (target.type === 'Deal') return <Deals className={classes.svg} />;
        if (target.type === 'Investor') return <Investors className={classes.svg} />;
        if (target.type === 'Task') return <Tasks className={classes.svg} />;
        if (target.type === 'Contact') return <Investors className={classes.svg} />;
        if (target.type === 'Organisation') return <Company className={classes.svg} />;
        if (target.type === 'Termsheet') return <Document className={classes.svg} />;
        return null;
    }
  }, [target.type, event.action, classes.svg]);

  const handleRoute = React.useCallback(
    (id: string) => {
      switch (target.type) {
        case 'Deal':
          return `${ROUTES.DEALS}/${id}`;
        case 'Investor':
          return `${ROUTES.INVESTORS}/${id}`;
        case 'Task':
          return `${ROUTES.TASKS}`;
        case 'Note':
          return `${ROUTES.NOTES}/${id}`;
        default:
          return '';
      }
    },
    [target]
  );

  const handleContent = React.useCallback(() => {
    switch (target.type) {
      case 'Deal':
        const dealContent = (
          <>
            <Tooltip title={`View ${target.type}`}>
              <Link color="secondary" component={NavLink} to={handleRoute(metadata?.deal_id)}>
                {metadata?.deal_name ? metadata?.deal_name : ''}
              </Link>
            </Tooltip>{' '}
            was {event.action} by {creator_name}{' '}
          </>
        );
        return dealContent;

      case 'Investor':
        const investorContent = (
          <>
            <Tooltip title={`View ${target.type}`}>
              <Link color="secondary" component={NavLink} to={handleRoute(metadata?.investor_id)}>
                {metadata?.investor_name ? metadata?.investor_name : ''}
              </Link>
            </Tooltip>{' '}
            was {event.action} by {creator_name}{' '}
          </>
        );
        return investorContent;

      case 'Contact':
        const contactContent = (
          <>
            <Tooltip title={`View ${target.type}`}>
              <Link color="secondary" component={NavLink} to={handleRoute(metadata?.investor_id)}>
                {metadata?.contact_name ? metadata?.contact_name : ''}
              </Link>
            </Tooltip>{' '}
            at{' '}
            <Tooltip title={`View Investor`}>
              <Link color="secondary" component={NavLink} to={handleRoute(metadata?.investor_id)}>
                {metadata?.investor_name ? metadata?.investor_name : ''}
              </Link>
            </Tooltip>{' '}
            was {event.action} by {creator_name}{' '}
          </>
        );
        return contactContent;

      case 'Assignment':
        const assignmentContent = () => {
          let message = event.action as string;
          if (event.action === 'created') message = 'added to';
          if (event.action === 'assignment_moved') message = 'moved to';
          if (event.action === 'deleted') message = 'removed from';

          let moved = '';
          if (event.action === 'assignment_moved') {
            moved = ` from ${metadata?.activity_previous_step_name}`;
          }

          if (event.action === 'assignment_added_contacts') {
            let activity_contacts = metadata?.activity_contacts as string[];
            return (
              <>
                {_.map(
                  activity_contacts,
                  (contact, index) => `${contact}${index + 1 < activity_contacts.length ? ', ' : ''}`
                )}{' '}
                from{' '}
                <Tooltip title="View Investor">
                  <Link color="secondary" component={NavLink} to={`${ROUTES.INVESTORS}/${metadata?.investor_id}`}>
                    {metadata?.investor_name}
                  </Link>
                </Tooltip>{' '}
                assigned as primary contacts for{' '}
                <Tooltip title="View Deal">
                  <Link color="secondary" component={NavLink} to={`${ROUTES.DEALS}/${metadata?.deal_id}`}>
                    {metadata?.deal_name}
                  </Link>
                </Tooltip>{' '}
                deal, by {creator_name}
              </>
            );
          }

          if (event.action === 'deleted') {
            return (
              <>
                <Tooltip title="View Investor">
                  <Link color="secondary" component={NavLink} to={`${ROUTES.INVESTORS}/${metadata?.investor_id}`}>
                    {metadata?.investor_name}
                  </Link>
                </Tooltip>{' '}
                was {message}{' '}
                <Tooltip title="View Deal">
                  <Link color="secondary" component={NavLink} to={`${ROUTES.DEALS}/${metadata?.deal_id}`}>
                    {metadata?.deal_name}
                  </Link>
                </Tooltip>{' '}
                deal, by {creator_name}
              </>
            );
          }

          return (
            <>
              <Tooltip title="View Investor">
                <Link color="secondary" component={NavLink} to={`${ROUTES.INVESTORS}/${metadata?.investor_id}`}>
                  {metadata?.investor_name}
                </Link>
              </Tooltip>{' '}
              was {message} {metadata?.step_name}
              {moved} on the{' '}
              <Tooltip title="View Deal">
                <Link color="secondary" component={NavLink} to={`${ROUTES.DEALS}/${metadata?.deal_id}`}>
                  {metadata?.deal_name}
                </Link>
              </Tooltip>{' '}
              deal, by {creator_name}
            </>
          );
        };

        return assignmentContent();

      case 'Termsheet':
        const termsheetContent = () => {
          const currency = _.find(data_currencies, currency => currency.code === (metadata?.deal_currency as string));
          return (
            <>
              <Tooltip title={`View ${target.type}`}>
                <Link color="secondary" href={metadata?.termsheet_url} target="_blank">
                  Termsheet
                </Link>
              </Tooltip>{' '}
              for {formatCurrency(metadata?.termsheet_amount, currency?.symbol)} from{' '}
              <Tooltip title="View Investor">
                <Link color="secondary" component={NavLink} to={`${ROUTES.INVESTORS}/${metadata?.investor_id}`}>
                  {metadata?.investor_name}
                </Link>
              </Tooltip>{' '}
              on{' '}
              <Tooltip title="View Deal">
                <Link color="secondary" component={NavLink} to={`${ROUTES.DEALS}/${metadata?.deal_id}`}>
                  {metadata?.deal_name}
                </Link>
              </Tooltip>{' '}
              was {event.action === 'created' ? 'added' : event.action} by {creator_name}{' '}
            </>
          );
        };
        return termsheetContent();

      case 'Task':
        const taskContent = () => {
          const task = metadata?.task_name;
          const parent_type = metadata?.task_parent_type;
          const completed = metadata?.task_completed;
          const parent_id = metadata?.task_parent_id;

          let deal, investor;
          if (parent_type === 'investor' || parent_type === 'contact') investor = _.find(investors, { id: parent_id });
          if (parent_type === 'deal') deal = _.find(deals, { id: parent_id });

          return (
            <>
              {completed ? 'Completed ' : ''}
              {task} {deal && 'for'}
              {investor && 'for'}{' '}
              {deal && (
                <Tooltip title="View Deal">
                  <Link color="secondary" component={NavLink} to={`${ROUTES.DEALS}/${parent_id}`}>
                    {deal.name ? deal.name : ''}
                  </Link>
                </Tooltip>
              )}
              {deal && ' '}
              {investor && (
                <Tooltip title="View Investor">
                  <Link color="secondary" component={NavLink} to={`${ROUTES.INVESTORS}/${parent_id}`}>
                    {investor.name ? investor.name : ''}
                  </Link>
                </Tooltip>
              )}
              {investor && ' '}
              {completed ? '' : `was ${event.action}`} by {creator_name}
            </>
          );
        };

        return taskContent();

      case 'Note':
        const noteContent = () => {
          const note = metadata?.note_name;
          const parent_type = metadata?.note_parent_type;
          const parent_id = metadata?.note_parent_id;

          let deal, investor;
          if (parent_type === 'investor' || parent_type === 'contact') investor = _.find(investors, { id: parent_id });
          if (parent_type === 'deal') deal = _.find(deals, { id: parent_id });

          return (
            <>
              {note} {deal && 'for'}
              {investor && 'for'}{' '}
              {deal && (
                <Tooltip title="View Deal">
                  <Link color="secondary" component={NavLink} to={`${ROUTES.DEALS}/${parent_id}`}>
                    {deal.name ? deal.name : ''}
                  </Link>
                </Tooltip>
              )}
              {deal && ' '}
              {investor && (
                <Tooltip title="View Investor">
                  <Link color="secondary" component={NavLink} to={`${ROUTES.INVESTORS}/${parent_id}`}>
                    {investor.name ? investor.name : ''}
                  </Link>
                </Tooltip>
              )}
              {investor && ' '}
              was {event.action} by {creator_name}
            </>
          );
        };

        return noteContent();

      case 'Organisation':
        const organisationContent = () => {
          if (event.action === 'member_invited') {
            return (
              <>
                <Link color="secondary" href={`mailto:${metadata?.member_invited}`} target="_blank">
                  {metadata?.member_name ? metadata?.member_name : metadata?.member_invited}
                </Link>{' '}
                invited to Organisation by {creator_name}
              </>
            );
          }
          if (event.action === 'member_declined') {
            return (
              <>
                <Link color="secondary" href={`mailto:${metadata?.member_email}`} target="_blank">
                  {metadata?.member_name ? metadata?.member_name : metadata?.member_email}
                </Link>{' '}
                invite revoked by {creator_name}
              </>
            );
          }
          if (event.action === 'member_removed') {
            return (
              <>
                <Link color="secondary" href={`mailto:${metadata?.member_email}`} target="_blank">
                  {metadata?.member_name ? metadata?.member_name : metadata?.member_email}
                </Link>{' '}
                removed from Organisation by {creator_name}
              </>
            );
          }
          if (event.action === 'member_joined') {
            return (
              <>
                <Link color="secondary" href={`mailto:${metadata?.member_email}`} target="_blank">
                  {metadata?.member_name !== '' ? metadata?.member_name : metadata?.member_email}
                </Link>{' '}
                joined {state.organisation.name}
              </>
            );
          }
          if (event.action === 'member_role_changed') {
            return (
              <>
                {metadata?.member_name}'s role was changed to {metadata?.member_role} by {creator_name}
              </>
            );
          }
          return (
            <>
              {state.organisation.name} was {event.action} by {creator_name}{' '}
            </>
          );
        };
        return organisationContent();

      default:
        const defaultContent = () => {
          return (
            <>
              <Tooltip title={`View ${target.type}`}>
                <Link color="secondary" component={NavLink} to={handleRoute(target.id)}>
                  {metadata?.name ? metadata?.name : ''}
                </Link>
              </Tooltip>{' '}
              was {event.action} by {creator_name}{' '}
            </>
          );
        };
        return defaultContent();
    }
  }, [creator_name, event, handleRoute, investors, metadata, target, deals, state.organisation.name]);

  const handleAction = () => {
    let message;
    if (target.type === 'Termsheet') {
      if (event.action === 'created') message = 'Added Termsheet';
      if (event.action === 'updated') message = 'Updated Termsheet';
      if (event.action === 'deleted') message = 'Deleted Termsheet';
    } else if (target.type === 'Task') {
      if (event.action === 'created') message = 'Added Task';
      if (event.action === 'updated' && metadata?.task_completed) message = 'Completed Task';
      if (event.action === 'updated' && !metadata?.task_completed) message = 'Updated Task';
      if (event.action === 'deleted') message = 'Deleted Task';
    } else if (target.type === 'Assignment') {
      if (event.action === 'created') message = 'Assigned to Deal';
      if (event.action === 'updated') message = 'Moved in Deal';
      if (event.action === 'deleted') message = 'Removed from Deal';
      if (event.action === 'assignment_moved') message = 'Moved in Deal';
      if (event.action === 'assignment_added_contacts') message = 'Contacts Assigned to Deal';
    } else {
      if (event.action === 'created') message = `Created ${target.type}`;
      if (event.action === 'updated') message = `Updated ${target.type}`;
      if (event.action === 'deleted') message = `Deleted ${target.type}`;
      if (event.action === 'member_role_changed') message = 'User Role Changed';
      if (event.action === 'member_removed') message = 'Removed User';
      if (event.action === 'member_declined') message = 'Invite Revoked or Declined';
      if (event.action === 'member_invited') message = 'Invited User';
      if (event.action === 'member_joined') message = 'User Joined';
    }
    return message;
  };

  const chip_blue =
    event.action === 'updated' ||
    event.action === 'assignment_moved' ||
    event.action === 'assignment_added_contacts' ||
    event.action === 'member_role_changed';
  const chip_red =
    event.action === 'deleted' || event.action === 'member_removed' || event.action === 'member_declined';
  const chip_green =
    event.action === 'created' || event.action === 'member_joined' || event.action === 'member_invited';

  return (
    <TimelineItem
      classes={{
        missingOppositeContent: classes.right,
      }}
    >
      <TimelineSeparator>
        <TimelineDot className={classes.dot} />
        <TimelineConnector className={classes.line} />
      </TimelineSeparator>

      <TimelineContent className={classes.content}>
        <Typography variant="overline" className={classes.date}>
          {formatDateandTime(created_at as string)}
        </Typography>

        <Paper>
          <Box p={3} display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
              <Box width={48} display="flex" alignItems="center">
                {handleIcon()}
              </Box>

              <Box>
                <Chip
                  size="small"
                  label={handleAction()}
                  className={clsx(
                    chip_green && classes.chipCreate,
                    chip_blue && classes.chipUpdate,
                    chip_red && classes.chipDelete
                  )}
                />
              </Box>
            </Box>
            <Box flex={1}>
              <Box>
                <Typography variant="body1" className={classes.capitalize}>
                  {handleContent()}
                  {event.details ? <span style={{ margin: '0px 8px' }}>-</span> : null}
                  {event.details}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default SystemItem;
