import { Button, Grid, LinearProgress, MenuItem } from '@material-ui/core';
import 'firebase/firestore';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import AutocompleteInvestorMultiSelect from 'src/components/Inputs/Autocomplete/MultiSelect';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { AssignmentsFirebase } from 'src/services/assignments';
import { InvestorFirebase } from 'src/services/investors';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

interface ArrayType {
  id: string;
  name: string;
}

const InvestorsAssignForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, assignments } = state;
  const [array, setArray] = React.useState<ArrayType[]>([]);
  const { process } = React.useContext(ProcessContext);
  const { steps } = process;
  const [open, setOpen] = React.useState(false);
  const [fired, setFired] = React.useState(false);
  const loading = open && array.length === 0 && !fired;
  const { deal } = React.useContext(DealContext);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const api_investors = await InvestorFirebase.list(organisation.id);
        setFired(true);
        if (active) {
          const filteredInvestors = _.flattenDeep(
            _.map(steps, step => {
              return _.filter(assignments, { step_id: step.id });
            })
          );

          const investorsList = _.map(filteredInvestors, filteredInvestor => {
            if (filteredInvestor) {
              return _.find(api_investors, investor => investor.id === filteredInvestor.investor_id);
            }
          });

          const investorsObject = _.differenceWith(_.values(api_investors), _.values(investorsList), _.isEqual);
          const investorsArray = _.map(investorsObject, item => {
            return {
              id: item.id,
              name: item.name,
            };
          });

          setArray(investorsArray);
          dispatch({ type: 'INVESTORS_LIST', payload: api_investors });
        }
      } catch (error) {}
    })();

    return () => {
      active = false;
    };
  }, [loading, organisation.id, dispatch, process, setToast, steps, assignments]);

  React.useEffect(() => {
    if (!open) {
      setArray([]);
      setFired(false);
    }
  }, [open]);

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Formik
      initialValues={{
        investors: [],
        step_id: '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const investor_ids = _.map(values.investors, (investor: Fluit.investors.Investor) => {
          return investor.id;
        });
        const step = _.find(steps, { id: values.step_id });

        _.map(values.investors, (investor: ArrayType, index) => {
          const data = {
            id: '',
            step_id: values.step_id,
            deal_id: deal.id,
            process_id: process.id,
            investor_id: investor.id,
            org_id: organisation.id,
            contacts: [],
          };

          (async () => {
            try {
              await AssignmentsFirebase.create(data);
              if (index + 1 === values.investors.length) {
                setToast({
                  message: `Added ${investor_ids.length === 1 ? `1 investor` : `${investor_ids.length} investors`} to ${
                    step ? `${step.name}` : 'deal'
                  }`,
                  type: 'success',
                });
                actions.setSubmitting(false);
                closeModal();
              }
            } catch (error) {
              const err: Fluit.firestore.Error = error;
              setToast({
                message: `${err.name} | ${err.message}`,
                type: 'error',
              });
              actions.setSubmitting(false);
            }
          })();
        });
      }}
      validationSchema={Yup.object().shape({
        investors: Yup.array()
          .of(Yup.string())
          .required(`Select the investors you'd like to add.`),
        step_id: Yup.string().required('Select a stage to add too'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, setFieldValue } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AutocompleteInvestorMultiSelect
                  data={array}
                  loading={loading}
                  open={open}
                  onOpen={onOpen}
                  onClose={onClose}
                  setFieldValue={setFieldValue}
                  noOptionsText="No more investors to add"
                  label="Investors"
                  name="investors"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  select
                  name="step_id"
                  label="Process Stage"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                >
                  {steps.map((step, index) => (
                    <MenuItem key={index} value={step.id}>
                      {index + 1} - {step.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Add
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default InvestorsAssignForm;
