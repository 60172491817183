import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as React from 'react';
import AutocompleteCountries from 'src/components/Inputs/Autocomplete/Countries';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { telephoneRegex, urlRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import { InvestorFirebase } from 'src/services/investors';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const InvestorDetailsForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { investor, setInvestor } = React.useContext(InvestorContext);

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        name: investor?.name ? investor?.name : '',
        telephone: investor?.telephone ? investor?.telephone : '',
        fax: investor?.fax ? investor?.fax : '',
        email: investor?.email ? investor?.email : '',
        website: investor?.website ? investor?.website : '',
        address: {
          line_1: investor?.address.line_1 ? investor?.address.line_1 : '',
          line_2: investor?.address.line_2 ? investor?.address.line_2 : '',
          town: investor?.address.town ? investor?.address.town : '',
          city: investor?.address.city ? investor?.address.city : '',
          postcode: investor?.address.postcode ? investor?.address.postcode : '',
          country: {
            name: investor?.address.country.name ? investor?.address.country.name : '',
            iso: investor?.address.country.iso ? investor?.address.country.iso : '',
            currency: investor?.address.country.currency ? investor?.address.country.currency : '',
          },
        },
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          const result = await InvestorFirebase.update({
            ...investor,
            name: trimString(values.name),
            telephone: trimString(values.telephone),
            fax: trimString(values.fax),
            email: trimString(values.email),
            website: trimString(values.website),
            address: {
              line_1: trimString(values.address.line_1),
              line_2: trimString(values.address.line_2),
              town: trimString(values.address.town),
              city: trimString(values.address.city),
              postcode: trimString(values.address.postcode),
              country: {
                name: values.address.country.name,
                iso: values.address.country.iso,
                currency: values.address.country.currency,
              },
            },
          });
          setInvestor(result);
          setToast({
            message: `Updated ${values.name}'s details`,
            type: 'info',
          });
          closeModal();
        } catch (error) {
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('A name is required to create an investor')
          .trim(),
        email: Yup.string()
          .email('Invalid email address')
          .trim(),
        telephone: Yup.string()
          .matches(telephoneRegex, 'Not a valid telephone number')
          .trim(),
        fax: Yup.string()
          .matches(telephoneRegex, 'Not a valid fax number')
          .trim(),
        website: Yup.string()
          .matches(urlRegex, 'Not a valid website address')
          .trim(),
        address: Yup.object().shape({
          country: Yup.object().shape({
            name: Yup.string(),
            iso: Yup.string(),
            currency: Yup.string(),
          }),
        }),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values, setFieldValue, errors, touched } = props;
        return (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="name"
                  label="Name"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="telephone"
                  label="Telephone"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="fax"
                  label="Fax"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="website"
                  label="Website"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteCountries
                  setFieldValue={setFieldValue}
                  name="address.country"
                  label="Country"
                  error={errors.address?.country?.iso}
                  touched={touched.address?.country?.iso}
                  value={values.address.country}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.line_1"
                  label="Address Line 1"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.line_2"
                  label="Address Line 2"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.town"
                  label="Town"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.city"
                  label="City"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.postcode"
                  label="Postcode"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default InvestorDetailsForm;
