import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { telephoneRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import { UserFirebase } from 'src/services/users';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const ProfileDetailsForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const { user } = state;
  return (
    <Formik
      initialValues={{
        first_name: user.first_name,
        last_name: user.last_name,
        position: user.position,
        telephone: user.telephone,
        is_onboarded: user.is_onboarded,
        email_verified: user.email_verified,
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const u = {
          ...user,
          first_name: trimString(values.first_name),
          last_name: trimString(values.last_name),
          position: trimString(values.position),
          telephone: trimString(values.telephone),
        };
        try {
          await UserFirebase.update(u);
          dispatch({ type: 'MEMBER_UPDATE', payload: u });
          dispatch({ type: 'USER_SET', payload: u });
          setToast({
            message: 'Profile updated',
            type: 'success',
          });
        } catch (error) {
          actions.setSubmitting(false);
          const err = error as Fluit.firestore.Error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required('Your first name is required'),
        last_name: Yup.string().required('Your last_name is required'),
        position: Yup.string().required('Your job position is required'),
        telephone: Yup.string()
          .required('Your telephone number is required')
          .matches(telephoneRegex, 'Not a valid telephone number'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="first_name"
                  label="First name"
                  autoComplete="first name, name, forname"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="last_name"
                  label="Last name"
                  autoComplete="last name, last_name, name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="position"
                  label="Position"
                  autoComplete="position, job role"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="telephone"
                  label="Telephone"
                  autoComplete="phone, mobile, telephone"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update Details
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfileDetailsForm;
