import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as RegisterAsset } from 'src/assets/images/register_account.svg';
import RegisterForm from 'src/components/Auth/Register';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles(() => ({
  svg: {
    width: '100%',
    height: 'auto',
  },
}));

const Register: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
          <Box mb={4}>
            <Typography variant="h1">Register</Typography>
          </Box>
          <Box mb={4}>
            <RegisterForm />
          </Box>
          <Typography variant="subtitle1">
            Already have an account?{' '}
            <Link component={NavLink} to={ROUTES.LOGIN}>
              Login here
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%" alignItems="flex-end">
          <RegisterAsset className={classes.svg} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Register;
