import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const InvestorsReducer = (state: Fluit.investors.Investor[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'INVESTORS_LIST':
      const INVESTORS_MERGE = _.merge(_.keyBy(state, 'id'), _.keyBy(action.payload, 'id'));
      // const INVESTORS_LIST = _.orderBy(action.payload, ['name', 'country'], ['asc', 'asc']);
      return _.values(INVESTORS_MERGE);
    case 'INVESTOR_CREATE':
      const INVESTOR_CREATE = _.orderBy(_.concat(_.values(state), action.payload), ['name', 'country'], ['asc', 'asc']);
      return INVESTOR_CREATE;
    case 'INVESTOR_DELETE':
      const INVESTOR_DELETE = _.filter(state, function(investor) {
        return investor.id !== action.payload.id;
      });
      return INVESTOR_DELETE;
    case 'INVESTOR_UPDATE':
      const INVESTOR_UPDATE = _.orderBy(
        _.concat(
          _.filter(state, function(investor) {
            return investor.id !== action.payload.id;
          }),
          action.payload
        ),
        ['name', 'country'],
        ['asc', 'asc']
      );
      return INVESTOR_UPDATE;
    case 'INVESTORS_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
