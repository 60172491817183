import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import CheckoutStepper from 'src/components/Stepper/Checkout';
import CheckoutCatalog from 'src/components/Tabs/Checkout/Catalog';
import CheckoutConfirmation from 'src/components/Tabs/Checkout/Confirmation';
import CheckoutCustomer from 'src/components/Tabs/Checkout/Customer';
import CheckoutPay from 'src/components/Tabs/Checkout/Pay';
import { CheckoutContext } from 'src/contexts/CheckoutContext';

const CheckoutHanlder: React.FC = () => {
  const { checkout } = React.useContext(CheckoutContext);
  const { step } = checkout;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {step === 0 && <Typography variant="h1">Users &amp; Deals</Typography>}
        {step === 1 && <Typography variant="h1">Billing Details</Typography>}
        {step === 2 && <Typography variant="h1">Review &amp; Pay</Typography>}
      </Grid>

      {step !== 3 && (
        <Grid item xs={12}>
          <CheckoutStepper />
        </Grid>
      )}

      {step === 0 && (
        <Grid item xs={12}>
          <CheckoutCatalog />
        </Grid>
      )}
      {step === 1 && (
        <Grid item xs={12}>
          <CheckoutCustomer />
        </Grid>
      )}
      {step === 2 && (
        <Grid item xs={12}>
          <CheckoutPay />
        </Grid>
      )}

      {step === 3 && (
        <Grid item xs={12}>
          <CheckoutConfirmation />
        </Grid>
      )}
    </Grid>
  );
};

export default CheckoutHanlder;
