import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';

export const ContactFirebase = {
  create: async (contact: Fluit.investors.Contact) => {
    try {
      const resp = await API({
        endpoint: `organisations/${contact.org_id}/contacts`,
        method: 'POST',
        payload: contact,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  get: async (orgId: string, contactId: string): Promise<Fluit.investors.Contact> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/contacts/${contactId}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (contact: Partial<Fluit.investors.Contact>) => {
    try {
      const resp = await API({
        endpoint: `organisations/${contact.org_id}/contacts/${contact.id}`,
        method: 'PATCH',
        payload: contact,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (contact: Fluit.investors.Contact) => {
    try {
      const resp = await API({
        endpoint: `organisations/${contact.org_id}/contacts/${contact.id}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (orgId: string): Promise<Fluit.investors.Contact[]> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/contacts`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
