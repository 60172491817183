import Fluit from 'src/types/Fluit';

export const ProductUsers: Fluit.checkout.products.Users = {
  standard: {
    name: 'Premium Users',
    description: 'Add Unlimited Investors & Activities',
    product_id: String(process.env.REACT_APP_STRIPE_PRODUCT_USER),
    year: {
      price_id: String(process.env.REACT_APP_STRIPE_PRICE_USER_YEARLY),
      price: 275.0,
    },
    month: {
      price_id: String(process.env.REACT_APP_STRIPE_PRICE_USER_MONTHLY),
      price: 25.0,
    },
  },
};

export const ProductDeals: Fluit.checkout.products.Deals = {
  fundraise: {
    name: 'Fundraise Deal',
    description: 'Angel, Pre-Seed, Seed, Series-A, Series-B, Series-C, Series-D, Series-E & Growth Round (PE) Deals',
    product_id: String(process.env.REACT_APP_STRIPE_PRODUCT_DEAL),
    year: {
      price_id: String(process.env.REACT_APP_STRIPE_PRICE_DEAL_YEARLY),
      price: 550.0,
    },
    month: {
      price_id: String(process.env.REACT_APP_STRIPE_PRICE_DEAL_MONTHLY),
      price: 50.0,
    },
  },
};
