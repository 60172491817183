import { Button, Checkbox, FormControlLabel, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { telephoneRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import { ContactFirebase } from 'src/services/contacts';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const ContactEditForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;
  const { contacts } = state;
  const contact = _.find(contacts, { id: String(state.ui.modal.contact_id) });

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        id: contact ? contact.id : '',
        investor_id: contact ? contact.investor_id : '',
        first_name: contact ? contact.first_name : '',
        last_name: contact ? contact.last_name : '',
        position: contact ? contact.position : '',
        telephone: contact ? contact.telephone : '',
        mobile: contact ? contact.mobile : '',
        fax: contact ? contact.fax : '',
        email: contact ? contact.email : '',
        decision_maker: contact ? contact.decision_maker : false,
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);

        try {
          await ContactFirebase.update({
            id: values.id,
            org_id: organisation.id,
            investor_id: values.investor_id,
            first_name: trimString(values.first_name),
            last_name: trimString(values.last_name),
            position: trimString(values.position),
            telephone: trimString(values.telephone),
            mobile: trimString(values.mobile),
            fax: trimString(values.fax),
            email: trimString(values.email),
            decision_maker: values.decision_maker,
          });
          setToast({
            message: `Updated ${values.first_name} ${values.last_name}`,
            type: 'info',
          });
          actions.setSubmitting(false);
          closeModal();
        } catch (error) {
          actions.setSubmitting(false);
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required('A first name is required to create a contact'),
        last_name: Yup.string().required('A last_name is required to create a contact'),
        position: Yup.string(),
        email: Yup.string().email('Invalid email address'),
        telephone: Yup.string().matches(telephoneRegex, 'Not a valid telephone number'),
        mobile: Yup.string().matches(telephoneRegex, 'Not a valid mobile number'),
        fax: Yup.string().matches(telephoneRegex, 'Not a valid fax number'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values, setFieldValue } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="first_name"
                  label="First Name"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="last_name"
                  label="Last name"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="position"
                  label="Position"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="telephone"
                  label="Telephone"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="mobile"
                  label="Mobile"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="fax"
                  label="Fax"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.decision_maker}
                      onChange={(event: object) => {
                        setFieldValue('decision_maker', values.decision_maker ? false : true);
                      }}
                      name="decision_maker"
                      color="primary"
                    />
                  }
                  label="Decision Maker (signatory)"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ContactEditForm;
