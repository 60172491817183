import colors from 'src/themes/colors';

const shadows = {
  purple: {
    1: `0 2px 15px 0 rgba(127, 40, 252, 0.14)`,
    2: `0 2px 45px 0 rgba(145,132,174,0.44)`,
    3: `0 2px 45px 0 rgba(145, 132, 174, 1)`,
    4: `0 0 25px 0 rgba(125,38,253,0.35)`,
  },
  black: {
    1: `0 2px 25px 0 rgba(145, 132, 174, 0.1)`,
    2: `0 2px 25px 0 rgba(145, 132, 174, 0.2)`,
    3: `0 2px 25px 0 rgba(145, 132, 174, 1)`,
  },
  red: {
    1: `0 2px 15px 0 ${colors.red[50]}`,
  },
};

export default shadows;
