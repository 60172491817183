import { Box, Button, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UserCard from 'src/components/Cards/User';
import UserInvitedCard from 'src/components/Cards/UserInvited';
import TabPanel from 'src/components/Tabs/TabPanel';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { firebaseDateToIso } from 'src/helpers/firebaseDateToIso';
import * as ROUTES from 'src/routes/routes';
import Fluit from 'src/types/Fluit';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Users: React.FC = () => {
  const firebase = React.useContext(FirebaseContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { members, organisation, invited_members, init } = state;
  const { admin } = init;
  let query = useQuery();
  const [invited, setInvited] = React.useState(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { limits } = organisation;

  const history = useHistory();

  const hit_user_limits = limits.members.count >= limits.members.limit;

  const tab = query.get('tab') ? query.get('tab') : 'users';

  const handleTabs = (event: React.ChangeEvent<{}>, newTab: string) => {
    history.push(`${ROUTES.USERS}/?tab=${newTab}`);
  };

  React.useEffect(() => {
    setInvited(_.values(invited_members).length);
  }, [invited_members]);

  React.useEffect(() => {
    if (!_.some(invited_members)) {
      history.push(`${ROUTES.USERS}/?tab=users`);
    }
  }, [invited_members, history]);

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('organisations')
      .doc(organisation.id)
      .onSnapshot(function(doc) {
        const org = doc.data() as Fluit.organisations.Organisation;
        dispatch({ type: 'ORGANISATIONS_UPDATE', payload: org });
        dispatch({ type: 'ORGANISATION_SET', payload: org });
      });
    return () => {
      unsubscribe();
    };
  }, [dispatch, firebase, organisation.id]);

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('organisations')
      .doc(organisation.id)
      .collection('members')
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          let document = change.doc.data() as Fluit.organisations.Member;
          document.created_at = firebaseDateToIso(document.created_at);
          document.updated_at = firebaseDateToIso(document.updated_at);

          if (change.type === 'added') {
            const user_exists = _.find(members, { id: document.id });
            if (!user_exists) {
              dispatch({ type: 'MEMBER_CREATE', payload: document });
            } else {
              dispatch({ type: 'MEMBER_UPDATE', payload: document });
            }
          }
          if (change.type === 'modified') {
            dispatch({ type: 'MEMBER_UPDATE', payload: document });
          }
          if (change.type === 'removed') {
            dispatch({ type: 'MEMBER_DELETE', payload: document });
          }
        });
      });

    return () => {
      unsubscribe();
    };
  }, [firebase, dispatch, organisation.id]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('organisations')
      .doc(organisation.id)
      .collection('invites')
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          let document = change.doc.data();
          document.created_at = firebaseDateToIso(document.created_at);
          document.updated_at = firebaseDateToIso(document.updated_at);
          if (change.type === 'added') {
            dispatch({ type: 'INVITED_CREATE', payload: document });
          }
          if (change.type === 'removed') {
            dispatch({ type: 'INVITED_REMOVE', payload: document });
          }
        });
      });

    return () => {
      unsubscribe();
    };
  }, [firebase, dispatch, organisation.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const addUser = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: hit_user_limits ? '' : `Invite to ${organisation.name}`,
        component: hit_user_limits ? 'UPGRADE_USERS' : 'USER_INVITE',
        centered: hit_user_limits,
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h1">Users</Typography>
          {mobile && admin ? (
            <Button variant="outlined" color="secondary" onClick={() => addUser()}>
              Add User
            </Button>
          ) : null}
        </Box>
      </Grid>

      {admin ? (
        <Grid item xs={12}>
          <Box display={!mobile ? 'flex' : ''} flexDirection="row" justifyContent="space-between">
            <Tabs
              value={tab}
              onChange={handleTabs}
              indicatorColor="primary"
              textColor="primary"
              variant={!mobile ? 'standard' : 'fullWidth'}
              centered={!mobile ? false : true}
            >
              <Tab value="users" label={`Active (${_.values(members).length})`} />
              <Tab value="invited" label={`Invited (${invited})`} disabled={!_.some(invited_members)} />
            </Tabs>
            {!mobile ? (
              <Button variant="outlined" color="secondary" onClick={() => addUser()}>
                Add User
              </Button>
            ) : null}
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <TabPanel value={tab} id="users">
          <Grid container spacing={4}>
            {_.map(members, (user: Fluit.organisations.Member) => {
              return (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={user.id}>
                  <UserCard user={user} role={user.role} />
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value={tab} id="invited">
          <Grid container spacing={4}>
            {_.map(invited_members, invitee => {
              return (
                <Grid item xs={12} key={invitee.email}>
                  <UserInvitedCard user={invitee} role="member" />
                </Grid>
              );
            })}
            {!_.some(invited_members) && (
              <Grid item xs={12}>
                <Typography variant="body1">No pending invites.</Typography>
              </Grid>
            )}
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default Users;
