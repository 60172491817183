import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { NotesFirebase } from 'src/services/notes';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const NoteDeleteForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const note = state.ui.modal.note as Fluit.notes.Note;

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const deleteNote = React.useCallback(async () => {
    setLoading(true);
    try {
      // if (note.parent_type === 'organisation') {
      //   await NotesFirebase.delete(note);
      // }
      // if (note.parent_type === 'investor') {
      //   await NotesFirebase.investors.delete(note);
      // }
      // if (note.parent_type === 'deal') {
      //   await NotesFirebase.deals.delete(note);
      // }
      // if (note.parent_type === 'contact') {
      //   await NotesFirebase.contacts.delete(note);
      // }
      await NotesFirebase.delete(note);
      setLoading(false);
      dispatch({ type: 'NOTE_DELETE', payload: note });
      setToast({
        message: `Note deleted`,
        type: 'info',
      });
      dispatch({
        type: 'MODAL',
        payload: {
          ...state.ui.modal,
          open: false,
        },
      });
    } catch (error) {
      setLoading(false);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [state.ui.modal, dispatch, setLoading, setToast, note]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">This will permantly delete the note from Fluit.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => deleteNote()}
          disabled={loading ? true : false}
        >
          Delete
        </Button>
        <Button
          variant="text"
          style={{ marginLeft: '16px' }}
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default NoteDeleteForm;
