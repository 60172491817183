import { Grid, Link, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles(() => ({
  legal: {
    '& h2': {
      fontSize: 30,
      marginBottom: 24,
    },
    '& h3': {
      fontSize: 24,
      marginBottom: 24,
    },
    '& p': {
      marginBottom: 16,
    },
  },
}));

const PrivacyPolicy: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.legal}>
      <Grid item xs={12}>
        <Typography variant="h1">Privacy Policy</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Fluit Tech Ltd ("We") are committed to protecting and respecting your privacy.
        </Typography>
        <Typography variant="body1">
          This policy (together with our{' '}
          <Link component={NavLink} to={ROUTES.ACCEPTABLE_USE}>
            terms of use
          </Link>{' '}
          and any other documents referred to on it) sets out the basis on which any personal data we collect from you,
          or that you provide to us, will be processed by us. Please read the following carefully to understand our
          views and practices regarding your personal data and how we will treat it. By visiting www.fluit.co you are
          accepting and consenting to the practices described in this policy.
        </Typography>
        <Typography variant="body1">
          For the purpose of the Data Protection Act 1998 (the Act), the data controller is Fluit Tech Ltd of 55
          Commodore House, 2 Admiralty Avenue, London, E16 2PY.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">INFORMATION WE MAY COLLECT FROM YOU</Typography>
        <Typography variant="body1">We may collect and process the following data about you:</Typography>
        <ul>
          <li>
            <b>Information you give us.</b> You may give us information about you by filling in forms on our site
            www.fluit.co (our site) or by corresponding with us by phone, e-mail or otherwise. This includes information
            you provide when you register to use our site, subscribe to our service, search for a product, place an
            order on our site, participate in discussion boards or other social media functions on our site, enter a
            competition, promotion or survey, request a brochure and when you report a problem with our site. The
            information you give us may include your name, address, e-mail address and phone number, financial and
            credit card information, personal description and photograph and any other information that are required for
            troubleshooting purposes.
          </li>
          <li>
            <b>Information we collect about you.</b> With regard to each of your visits to our site we may automatically
            collect the following information:
            <ul>
              <li>
                technical information, including the Internet protocol (IP) address used to connect your computer to the
                Internet, your login information, browser type and version, time zone setting, browser plug-in types and
                versions, operating system and platform;
              </li>
              <li>
                information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through
                and from our site (including date and time); products you viewed or searched for; page response times,
                download errors, length of visits to certain pages, page interaction information (such as scrolling,
                clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to
                call our customer service number.
              </li>
            </ul>
          </li>
          <li>
            <b>Information we receive from other sources.</b> We may receive information about you if you use any of the
            other websites we operate or the other services we provide. We are also working closely with third parties
            (including, for example, business partners, sub-contractors in technical, payment and delivery services,
            advertising networks, analytics providers, search information providers, credit reference agencies) and may
            receive information about you from them.
          </li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Cookies</Typography>
        <Typography variant="body1">
          Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with
          a good experience when you browse our website and also allows us to improve our site. For detailed information
          on the cookies we use and the purposes for which we use them see our{' '}
          <Link component={NavLink} to={ROUTES.COOKIE}>
            Cookie Policy
          </Link>
          .
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Uses made of the information</Typography>
        <Typography variant="body1">We use information held about you in the following ways:</Typography>
        <ul>
          <li>
            <b>Information you give to us.</b> We will use this information:
            <ul>
              <li>
                to carry out our obligations arising from any contracts entered into between you and us and to provide
                you with the information, products and services that you request from us;
              </li>
              <li>
                to provide you with information about other goods and services we offer that are similar to those that
                you have already purchased or enquired about;
              </li>
              <li>to notify you about changes to our service;</li>
              <li>
                to ensure that content from our site is presented in the most effective manner for you and for your
                computer.
              </li>
            </ul>
          </li>

          <li>
            <b>Information we collect about you.</b> We will use this information:
            <ul>
              <li>
                to administer our site and for internal operations, including troubleshooting, data analysis, testing,
                research, statistical and survey purposes;
              </li>
              <li>
                to improve our site to ensure that content is presented in the most effective manner for you and for
                your computer;
              </li>
              <li>to allow you to participate in interactive features of our service, when you choose to do so;</li>
              <li>as part of our efforts to keep our site safe and secure;</li>
              <li>
                to measure or understand the effectiveness of advertising we serve to you and others, and to deliver
                relevant advertising to you;
              </li>
              <li>
                to make suggestions and recommendations to you and other users of our site about goods or services that
                may interest you or them.
              </li>
            </ul>
          </li>

          <li>
            <b>Information we receive from other sources.</b> We may combine this information with information you give
            to us and information we collect about you. We may us this information and the combined information for the
            purposes set out above (depending on the types of information we receive).
          </li>
        </ul>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Disclosure of your information</Typography>
        <Typography variant="body1">
          We may share your personal information with any member of our group, which means our subsidiaries, our
          ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.
        </Typography>
        <Typography variant="body1">We may share your information with selected third parties including:</Typography>
        <ul>
          <li>
            Business partners, suppliers and sub-contractors for the performance of any contract we enter into with you.
          </li>
          <li>
            Advertisers and advertising networks that require the data to select and serve relevant adverts to you and
            others. We do not disclose information about identifiable individuals to our advertisers, but we may provide
            them with aggregate information about our users (for example, we may inform them that 500 men aged under 30
            have clicked on their advertisement on any given day). We may also use such aggregate information to help
            advertisers reach the kind of audience they want to target (for example, women in SW1). We may make use of
            the personal data we have collected from you to enable us to comply with our advertisers wishes by
            displaying their advertisement to that target audience.
          </li>
          <li>Analytics and search engine providers that assist us in the improvement and optimisation of our site.</li>
        </ul>

        <Typography variant="body1">We may disclose your personal information to third parties:</Typography>

        <ul>
          <li>
            In the event that we sell or buy any business or assets, in which case we may disclose your personal data to
            the prospective seller or buyer of such business or assets.
          </li>
          <li>
            If Fluit or substantially all of its assets are acquired by a third party, in which case personal data held
            by it about its customers will be one of the transferred assets.
          </li>
          <li>
            If we are under a duty to disclose or share your personal data in order to comply with any legal obligation,
            or in order to enforce or apply our{' '}
            <Link component={NavLink} to={ROUTES.TERMS}>
              terms of use
            </Link>{' '}
            [or{' '}
            <Link component={NavLink} to={ROUTES.TERMS}>
              terms and conditions of supply
            </Link>
            ] and other agreements; or to protect the rights, property, or safety of Fluit, our customers, or others.
            This includes exchanging information with other companies and organisations for the purposes of fraud
            protection and credit risk reduction.]
          </li>
        </ul>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Where we store your personal data</Typography>
        <Typography variant="body1">
          The data that we collect from you may be transferred to, and stored at, a destination outside the European
          Economic Area ("EEA"). It may also be processed by staff operating outside the EEA who work for us or for one
          of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your order, the
          processing of your payment details and the provision of support services. By submitting your personal data,
          you agree to this transfer, storing or processing. Fluit will take all steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this privacy policy.
        </Typography>
        <Typography variant="body1">
          All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted
          using SSL technology. Where we have given you (or where you have chosen) a password which enables you to
          access certain parts of our site, you are responsible for keeping this password confidential. We ask you not
          to share a password with anyone.
        </Typography>
        <Typography variant="body1">
          Unfortunately, the transmission of information via the internet is not completely secure. Although we will do
          our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site;
          any transmission is at your own risk. Once we have received your information, we will use strict procedures
          and security features to try to prevent unauthorised access.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Your rights</Typography>
        <Typography variant="body1">
          You have the right to ask us not to process your personal data for marketing purposes. We will usually inform
          you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose
          your information to any third party for such purposes. You can exercise your right to prevent such processing
          by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any
          time by contacting us at <Link href="mailto:info@fluit.co">info@fluit.co</Link>.
        </Typography>
        <Typography variant="body1">
          Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers
          and affiliates. If you follow a link to any of these websites, please note that these websites have their own
          privacy policies and that we do not accept any responsibility or liability for these policies. Please check
          these policies before you submit any personal data to these websites.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Access to information</Typography>
        <Typography variant="body1">
          The Act gives you the right to access information held about you. Your right of access can be exercised in
          accordance with the Act. Any access request may be subject to a fee of £10 to meet our costs in providing you
          with details of the information we hold about you.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Changes to our privacy policy</Typography>
        <Typography variant="body1">
          Any changes we may make to our privacy policy in the future will be posted on this page and, where
          appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our
          privacy policy.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Contact</Typography>
        <Typography variant="body1">
          Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to{' '}
          <Link href="mailto:info@fluit.co">info@fluit.co</Link>.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
