import React from 'react';
import { ReactComponent as FIActivity } from 'src/assets/icons/activity/activity.svg';
import { ReactComponent as FIActivityAdd } from 'src/assets/icons/activity/add.svg';
import { ReactComponent as FIMeeting } from 'src/assets/icons/activity/meeting.svg';
import { ReactComponent as FIMoney } from 'src/assets/icons/activity/money.svg';
import { ReactComponent as FIMove } from 'src/assets/icons/activity/move.svg';
import { ReactComponent as FIAdd } from 'src/assets/icons/add.svg';
import { ReactComponent as FIInfo } from 'src/assets/icons/alerts/info.svg';
import { ReactComponent as FIArrowDown } from 'src/assets/icons/arrow/down.svg';
import { ReactComponent as FIArrowUp } from 'src/assets/icons/arrow/up.svg';
import { ReactComponent as FIAttachment } from 'src/assets/icons/attachment.svg';
import { ReactComponent as FIAmericanExpress } from 'src/assets/icons/cards/American_Express.svg';
import { ReactComponent as FIDinersclub } from 'src/assets/icons/cards/Dinersclub.svg';
import { ReactComponent as FIDiscover } from 'src/assets/icons/cards/Discover.svg';
import { ReactComponent as FIJCB } from 'src/assets/icons/cards/JCB.svg';
import { ReactComponent as FIMastercard } from 'src/assets/icons/cards/Mastercard.svg';
import { ReactComponent as FIUnionPay } from 'src/assets/icons/cards/UnionPay.svg';
import { ReactComponent as FIUnknown } from 'src/assets/icons/cards/Unknown.svg';
import { ReactComponent as FIVisa } from 'src/assets/icons/cards/Visa.svg';
import { ReactComponent as FICaretDown } from 'src/assets/icons/caret/down.svg';
import { ReactComponent as FICaretLeft } from 'src/assets/icons/caret/left.svg';
import { ReactComponent as FICaretRight } from 'src/assets/icons/caret/right.svg';
import { ReactComponent as FICaretUp } from 'src/assets/icons/caret/up.svg';
import { ReactComponent as FICheck } from 'src/assets/icons/check.svg';
import { ReactComponent as FIClients } from 'src/assets/icons/clients.svg';
import { ReactComponent as FIClose } from 'src/assets/icons/close.svg';
import { ReactComponent as FICompany } from 'src/assets/icons/company.svg';
import { ReactComponent as FIEmail } from 'src/assets/icons/contact/email.svg';
import { ReactComponent as FIFax } from 'src/assets/icons/contact/fax.svg';
import { ReactComponent as FIMobile } from 'src/assets/icons/contact/mobile.svg';
import { ReactComponent as FITelephone } from 'src/assets/icons/contact/telephone.svg';
import { ReactComponent as FIWebsite } from 'src/assets/icons/contact/website.svg';
import { ReactComponent as FIDashboard } from 'src/assets/icons/dashboard.svg';
import { ReactComponent as FIDealNetwork } from 'src/assets/icons/deal-network.svg';
import { ReactComponent as FIDealsAdd } from 'src/assets/icons/deals/add.svg';
import { ReactComponent as FIDeals } from 'src/assets/icons/deals/deals.svg';
import { ReactComponent as FIDealsNone } from 'src/assets/icons/deals/none.svg';
import { ReactComponent as FIDealsDelete } from 'src/assets/icons/deals/remove.svg';
import { ReactComponent as FIDealsUpdate } from 'src/assets/icons/deals/update.svg';
import { ReactComponent as FIDocumentDelete } from 'src/assets/icons/document/delete.svg';
import { ReactComponent as FIDocument } from 'src/assets/icons/document/document.svg';
import { ReactComponent as FIDocumentDownload } from 'src/assets/icons/document/download.svg';
import { ReactComponent as FIDocumentEdit } from 'src/assets/icons/document/edit.svg';
import { ReactComponent as FIDocumentMove } from 'src/assets/icons/document/move.svg';
import { ReactComponent as FIDotsHorizontal } from 'src/assets/icons/dots-horizontal.svg';
import { ReactComponent as FIDots } from 'src/assets/icons/dots.svg';
import { ReactComponent as FIEyeHide } from 'src/assets/icons/eye-hide.svg';
import { ReactComponent as FIFolderDelete } from 'src/assets/icons/folder/delete.svg';
import { ReactComponent as FIFolder } from 'src/assets/icons/folder/folder.svg';
import { ReactComponent as FIFolderRename } from 'src/assets/icons/folder/rename.svg';
import { ReactComponent as FIH1 } from 'src/assets/icons/heading/h1.svg';
import { ReactComponent as FIH2 } from 'src/assets/icons/heading/h2.svg';
import { ReactComponent as FIH3 } from 'src/assets/icons/heading/h3.svg';
import { ReactComponent as FIInvestment } from 'src/assets/icons/investment.svg';
import { ReactComponent as FIInvestorsAdd } from 'src/assets/icons/investors/add.svg';
import { ReactComponent as FIInvestors } from 'src/assets/icons/investors/investors.svg';
import { ReactComponent as FIInvestorsNone } from 'src/assets/icons/investors/none.svg';
import { ReactComponent as FIInvestorsRemove } from 'src/assets/icons/investors/remove.svg';
import { ReactComponent as FILogout } from 'src/assets/icons/logout.svg';
import { ReactComponent as FIMenu } from 'src/assets/icons/menu.svg';
import { ReactComponent as FINotesAdd } from 'src/assets/icons/notes/add.svg';
import { ReactComponent as FINotes } from 'src/assets/icons/notes/notes.svg';
import { ReactComponent as FINotification } from 'src/assets/icons/notification.svg';
import { ReactComponent as FIPriceTag } from 'src/assets/icons/price-tag.svg';
import { ReactComponent as FIProcess } from 'src/assets/icons/process/process.svg';
import { ReactComponent as FIRename } from 'src/assets/icons/rename.svg';
import { ReactComponent as FISettings } from 'src/assets/icons/settings.svg';
import { ReactComponent as FIStarFilled } from 'src/assets/icons/star-filled.svg';
import { ReactComponent as FIStarOutline } from 'src/assets/icons/star-outline.svg';
import { ReactComponent as FITasksAdd } from 'src/assets/icons/tasks/add.svg';
import { ReactComponent as FITasks } from 'src/assets/icons/tasks/tasks.svg';
import { ReactComponent as FIUpload } from 'src/assets/icons/upload.svg';
import { ReactComponent as FIUsersAdd } from 'src/assets/icons/users/add.svg';
import { ReactComponent as FIUsersInvited } from 'src/assets/icons/users/invited.svg';
import { ReactComponent as FIUsersPermissions } from 'src/assets/icons/users/permissions.svg';
import { ReactComponent as FIUsers } from 'src/assets/icons/users/user.svg';
import Fluit from 'src/types/Fluit';

const DealsUpdate: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDealsUpdate {...props} />;
};
const DealsRemove: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDealsDelete {...props} />;
};

const CardAmex: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIAmericanExpress {...props} />;
};
const CardMastercard: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIMastercard {...props} />;
};
const CardDinersClub: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDinersclub {...props} />;
};
const CardJCB: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIJCB {...props} />;
};
const CardUnionPay: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIUnionPay {...props} />;
};
const CardDiscover: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDiscover {...props} />;
};
const CardVisa: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIVisa {...props} />;
};
const CardUnknown: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIUnknown {...props} />;
};

const StarOutline: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIStarOutline {...props} />;
};
const StarFilled: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIStarFilled {...props} />;
};
const Activity: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIActivity {...props} />;
};
const NotificationIcon: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FINotification {...props} />;
};
const Notes: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FINotes {...props} />;
};
const H1: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIH1 {...props} />;
};
const H2: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIH2 {...props} />;
};
const H3: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIH3 {...props} />;
};
const ArrowUp: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIArrowUp {...props} />;
};
const ArrowDown: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIArrowDown {...props} />;
};

const Add: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIAdd {...props} />;
};
const Attachment: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIAttachment {...props} />;
};
const Clients: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIClients {...props} />;
};
const Close: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIClose {...props} />;
};
const Company: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FICompany {...props} />;
};
const Dashboard: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDashboard {...props} />;
};
const DealNetwork: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDealNetwork {...props} />;
};
const Dots: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDots {...props} />;
};
const DotsHorizontal: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDotsHorizontal {...props} />;
};
const EyeHide: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIEyeHide {...props} />;
};
const Investment: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIInvestment {...props} />;
};
const Logout: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FILogout {...props} />;
};
const PriceTag: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIPriceTag {...props} />;
};
const Rename: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIRename {...props} />;
};
const Settings: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FISettings {...props} />;
};
const Upload: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIUpload {...props} />;
};
const Meeting: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIMeeting {...props} />;
};
const Money: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIMoney {...props} />;
};
const Move: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIMove {...props} />;
};
const CaretDown: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FICaretDown {...props} />;
};
const CaretLeft: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FICaretLeft {...props} />;
};
const CaretRight: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FICaretRight {...props} />;
};
const CaretUp: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FICaretUp {...props} />;
};
const DealsAdd: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDealsAdd {...props} />;
};
const Deals: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDeals {...props} />;
};
const DealsNone: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDealsNone {...props} />;
};
const DocumentDelete: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDocumentDelete {...props} />;
};
const Document: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDocument {...props} />;
};
const DocumentDownload: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDocumentDownload {...props} />;
};
const DocumentEdit: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDocumentEdit {...props} />;
};
const DocumentMove: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIDocumentMove {...props} />;
};
const Folder: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIFolder {...props} />;
};
const FolderRename: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIFolderRename {...props} />;
};
const FolderDelete: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIFolderDelete {...props} />;
};
const Investors: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIInvestors {...props} />;
};
const InvestorsAdd: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIInvestorsAdd {...props} />;
};
const InvestorsRemove: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIInvestorsRemove {...props} />;
};
const Users: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIUsers {...props} />;
};
const UsersAdd: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIUsersAdd {...props} />;
};
const UsersInvited: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIUsersInvited {...props} />;
};
const UsersPermissions: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIUsersPermissions {...props} />;
};
const NotesAdd: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FINotesAdd {...props} />;
};

const TasksAdd: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FITasksAdd {...props} />;
};

const ActivityAdd: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIActivityAdd {...props} />;
};

const Telephone: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FITelephone {...props} />;
};

const Fax: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIFax {...props} />;
};

const Mobile: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIMobile {...props} />;
};

const Email: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIEmail {...props} />;
};

const Website: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIWebsite {...props} />;
};

const Menu: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIMenu {...props} />;
};

const Check: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FICheck {...props} />;
};

const Process: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIProcess {...props} />;
};

const InvestorsNone: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIInvestorsNone {...props} />;
};

const Tasks: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FITasks {...props} />;
};

const Info: React.FC<Fluit.props.Icon> = (props: Fluit.props.Icon) => {
  return <FIInfo {...props} />;
};

export {
  DealsUpdate,
  DealsRemove,
  Activity,
  ActivityAdd,
  Add,
  ArrowDown,
  ArrowUp,
  Attachment,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Check,
  Clients,
  Close,
  Company,
  Dashboard,
  DealNetwork,
  Deals,
  DealsAdd,
  DealsNone,
  Document,
  DocumentDelete,
  DocumentDownload,
  DocumentEdit,
  DocumentMove,
  Dots,
  DotsHorizontal,
  Email,
  EyeHide,
  Fax,
  Folder,
  FolderDelete,
  FolderRename,
  H1,
  H2,
  H3,
  Info,
  Investment,
  Investors,
  InvestorsAdd,
  InvestorsNone,
  InvestorsRemove,
  Logout,
  Meeting,
  Menu,
  Mobile,
  Money,
  Move,
  Notes,
  NotesAdd,
  NotificationIcon,
  PriceTag,
  Process,
  Rename,
  Settings,
  Tasks,
  TasksAdd,
  Telephone,
  Upload,
  Users,
  UsersAdd,
  UsersInvited,
  UsersPermissions,
  Website,
  StarFilled,
  StarOutline,
  CardAmex,
  CardVisa,
  CardUnknown,
  CardUnionPay,
  CardJCB,
  CardMastercard,
  CardDiscover,
  CardDinersClub,
};
