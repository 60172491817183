import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const NotesReducer = (state: Fluit.notes.Note[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    /* LIST */
    case 'NOTES_LIST':
      const NOTES_LIST = action.payload;
      return NOTES_LIST;

    /* CREATE */
    case 'NOTE_CREATE':
      const NOTE_CREATE = _.concat(_.values(state), action.payload);
      return NOTE_CREATE;

    /* DELETE */
    case 'NOTE_DELETE':
      const NOTE_DELETE = _.filter(state, note => note.id !== action.payload.id);
      return NOTE_DELETE;

    /* UPDATE */
    case 'NOTE_UPDATE':
      const NOTE_UPDATE = _.concat(_.values(_.filter(state, note => note.id !== action.payload.id)), action.payload);
      return NOTE_UPDATE;

    /* SET */
    case 'NOTES_SET':
      const NOTES_SET = _.map(action.payload, note => {
        const exists = _.find(state, { id: note.id });
        if (exists) {
          return _.merge(exists, note);
        }
        return note;
      });
      return NOTES_SET;
    case 'NOTES_FLUSH':
      return [];

    default:
      return { ...state };
  }
};
