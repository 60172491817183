import { initalise } from 'src/constants/init';
import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const BillingReducer = (state: Fluit.billing.Billing, action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'BILLING_LIST':
      return action.payload;
    case 'BILLING_INVOICES_LIST':
      const INVOICES = state.invoices;
      const INVOICES_LIST = _.merge(INVOICES, action.payload);
      return { ...state, invoices: INVOICES_LIST };
    case 'BILLING_PAYMENT_METHOD_LIST':
      const PAYMENT_METHODS = state.payment_methods;
      const PAYMENT_METHODS_LIST = _.merge(PAYMENT_METHODS, action.payload);
      return { ...state, payment_methods: PAYMENT_METHODS_LIST };
    case 'BILLING_PAYMENT_METHOD_DETACH':
      const PAYMENT_METHODS_NOT_REMOVED = _.filter(state.payment_methods, method => method.id !== action.payload);
      return { ...state, payment_methods: PAYMENT_METHODS_NOT_REMOVED };
    case 'BILLING_CUSTOMER_GET':
      return { ...state, customer: action.payload };
    case 'BILLING_CUSTOMER_UPDATE':
      const CUSTOMER = state.customer;
      const CUSTOMER_UPDATE = _.merge(CUSTOMER, action.payload);
      return { ...state, customer: CUSTOMER_UPDATE };
    case 'BILLING_INVOICE_LATEST':
      return { ...state, latest_invoice: action.payload };
    /* FLUSH */
    case 'BILLING_FLUSH':
      return initalise.billing;
    default:
      return state;
  }
};
