import { Button, Grid, LinearProgress, MenuItem } from '@material-ui/core';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { OrganisationFirebase } from 'src/services/organisations';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const UserRoleForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;

  let user = _.find(state.members, user => user.id === state.ui.modal.user_id);
  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{ role: user ? user.role : 'member' }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await OrganisationFirebase.changeRole(organisation.id, String(state.ui.modal.user_id), values.role);

          dispatch({ type: 'MEMBER_UPDATE', payload: user });
          setToast({
            message: `Updated ${user?.first_name ? `${user?.first_name} ${user?.last_name}` : `${user?.email}`} to ${
              values.role
            }`,
            type: 'info',
          });
          closeModal();
        } catch (error) {
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        role: Yup.mixed<'member' | 'owner'>()
          .oneOf(['member', 'owner'])
          .required('Must assign user to a role'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  select
                  name="role"
                  label="Role"
                  type="text"
                  variant="outlined"
                  inputProps={{ name: 'role', id: 'role', label: 'Role' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="member">Member</MenuItem>
                  <MenuItem value="owner">Owner</MenuItem>
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default UserRoleForm;
