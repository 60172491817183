import { Button } from '@material-ui/core';
import React from 'react';
import { CurrentRefinementsProvided, RefinementValue } from 'react-instantsearch-core';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

interface Props {
  items: RefinementValue[];
  refine: (refinement: RefinementValue | RefinementValue[]) => void;
}

const AlgoliaClearRefinements: React.FC<CurrentRefinementsProvided> = ({ items, refine }) => {
  return (
    //@ts-ignore
    <Button onClick={() => refine(items)} color="secondary" disabled={!items.length}>
      Clear Filters
    </Button>
  );
};

export default connectCurrentRefinements(AlgoliaClearRefinements);
