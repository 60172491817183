import React from 'react';
import { initalise } from 'src/constants/init';
import Fluit from 'src/types/Fluit';

export const ProcessContext = React.createContext<Fluit.context.process.Context>({} as Fluit.context.process.Context);

export const ProcessProvider = ({ children }: Fluit.props.Children) => {
  const [process, setProcess] = React.useState<Fluit.context.process.State>(initalise.process);
  return <ProcessContext.Provider value={{ process, setProcess }}>{children}</ProcessContext.Provider>;
};
