import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { titleCase } from 'src/helpers/titleCase';
import { trimString } from 'src/helpers/trim';
import { ProcessesFirebase } from 'src/services/processes';
import Fluit from 'src/types/Fluit';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const StepCreateForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { process, setProcess } = React.useContext(ProcessContext);
  const { steps } = process;

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        let newStep = {
          id: uuidv4(),
          name: titleCase(trimString(values.name)),
          editable: true,
          investors: null,
        };
        let removeDecliend = _.filter(steps, step => step.name !== 'Declined');
        let declinedObject = _.find(steps, { name: 'Declined' }) as Fluit.process.Step;
        let newSteps = _.values(_.concat(removeDecliend, newStep, declinedObject));
        try {
          const updated_process = { ...process, steps: newSteps };
          const newProcess = await ProcessesFirebase.update(updated_process);
          setProcess(newProcess);
          actions.setSubmitting(false);
          setToast({
            message: `Step ${values.name} added to process`,
            type: 'success',
          });
          closeModal();
        } catch (error) {
          actions.setSubmitting(false);
          const err = error as Fluit.firestore.Error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('A name is required for this step'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="name"
                  label="Step Name"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default StepCreateForm;
