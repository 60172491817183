import Fluit from 'src/types/Fluit';
import { initalise } from 'src/constants/init';

export const OrganisationReducer = (state: Fluit.organisations.Organisation, action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'ORGANISATION_SET':
      return action.payload;
    case 'COUNT_UP_DEALS':
      if (state.limits.deals.count + 1 <= state.limits.deals.limit) {
        state.limits.deals.count++;
      }
      return state;
    case 'COUNT_DOWN_DEALS':
      state.limits.deals.count--;
      return state;
    case 'COUNT_UP_INVESTORS':
      if (state.limits.investors.count + 1 <= state.limits.investors.limit) {
        state.limits.investors.count++;
      }
      return state;
    case 'COUNT_DOWN_INVESTORS':
      state.limits.investors.count--;
      return state;
    case 'COUNT_UP_MEMBERS':
      if (state.limits.members.count + 1 <= state.limits.members.limit) {
        state.limits.members.count++;
      }
      return state;
    case 'COUNT_DOWN_MEMBERS':
      state.limits.members.count--;
      return state;
    case 'COUNT_UP_ACTIVITIES':
      if (state.limits.activities.count + 1 <= state.limits.activities.limit) {
        state.limits.activities.count++;
      }
      return state;
    case 'COUNT_DOWN_ACTIVITIES':
      state.limits.activities.count--;
      return state;
    case 'ORGANISATION_FLUSH':
      return initalise.application.organisation;
    default:
      return { ...state };
  }
};
