import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { initalise } from 'src/constants/init';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { InvestorFirebase } from 'src/services/investors';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const InvestorDeleteForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { contacts, termsheets, tasks, notes, assignments } = state;
  const { setInvestor } = React.useContext(InvestorContext);
  const classes = useStyles();
  const investor = _.find(state.investors, investor => investor.id === state.ui.modal.investor_id);
  const [loading, setLoading] = React.useState(false);

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  const removeInvestor = React.useCallback(async () => {
    setLoading(true);
    if (investor) {
      try {
        await InvestorFirebase.delete(investor, state.organisation.id);
        setLoading(false);
        dispatch({ type: 'INVESTOR_DELETE', payload: investor });
        dispatch({ type: 'COUNT_DOWN_INVESTORS' });
        const investor_contacts = _.filter(contacts, { investor_id: investor.id });
        const investor_termsheets = _.filter(termsheets, { investor_id: investor.id });
        const investor_tasks = _.filter(tasks, { parent_id: investor.id });
        const investor_notes = _.filter(notes, { parent_id: investor.id });
        const investor_assignments = _.filter(assignments, { investor_id: investor.id });
        _.map(investor_contacts, contact => dispatch({ type: 'CONTACT_DELETE', payload: contact }));
        _.map(investor_termsheets, termsheet => dispatch({ type: 'TERMSHEET_DELETE', payload: termsheet }));
        _.map(investor_tasks, task => dispatch({ type: 'TASK_DELETE', payload: task }));
        _.map(investor_notes, note => dispatch({ type: 'NOTE_DELETE', payload: note }));
        _.map(investor_assignments, assignment => dispatch({ type: 'ASSIGNMENT_DELETE', payload: assignment }));
        setInvestor(initalise.investor);
        dispatch({
          type: 'MODAL',
          payload: {
            ...state.ui.modal,
            open: false,
          },
        });
        setToast({
          message: `${investor.name} deleted`,
          type: 'info',
        });
      } catch (error) {
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
        setLoading(false);
      }
    }
  }, [
    state.ui.modal,
    dispatch,
    setLoading,
    setToast,
    investor,
    state.organisation.id,
    assignments,
    contacts,
    notes,
    setInvestor,
    tasks,
    termsheets,
  ]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">
          All deal data, contacts, notes, tasks and activity will be permantly deleted for {investor?.name}.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          style={{ marginRight: '16px' }}
          onClick={() => removeInvestor()}
          disabled={loading ? true : false}
        >
          Delete
        </Button>
        <Button variant="text" size="large" onClick={() => closeModal()} disabled={loading ? true : false}>
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default InvestorDeleteForm;
