import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const MembersReducer = (state: Fluit.organisations.Member[], action: Fluit.reducer.actions.Actions) => {
  // console.log(`%c•• ${action.type} ••`, 'color: #52D868');
  // console.log(action.payload);

  switch (action.type) {
    case 'MEMBERS_LIST':
      return _.orderBy(action.payload, ['is_onboarded', 'last_name', 'first_name'], ['desc', 'asc', 'asc']);
    case 'MEMBER_CREATE':
      const MEMBERS_CREATE = _.orderBy(
        _.concat(_.values(state), action.payload),
        ['last_name', 'first_name'],
        ['asc', 'asc']
      );
      return MEMBERS_CREATE;
    case 'MEMBER_UPDATE':
      // if (_.values(state).length === 1) {
      //   const newUser = { ...state[0], ...action.payload };
      //   return [newUser];
      // }
      let users = _.filter(state, user => user.id !== action.payload.id);
      let user = _.find(state, user => user.id === action.payload.id);
      let updates = { ...user, ...action.payload };
      return _.orderBy(_.concat(users, updates), ['is_onboarded', 'last_name', 'first_name'], ['desc', 'asc', 'asc']);
    case 'MEMBER_DELETE':
      let removedUser = _.filter(state, user => user.id !== action.payload.id);
      return _.orderBy(removedUser, ['is_onboarded', 'last_name', 'first_name'], ['desc', 'asc', 'asc']);
    case 'MEMBERS_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
