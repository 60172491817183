import * as React from 'react';
import { Switch } from 'react-router-dom';
import InvestorHandler from 'src/components/Investors/RouteHandler';
import Error404 from 'src/pages/404';
import AcceptableUsePolicy from 'src/pages/AcceptableUsePolicy';
import Activity from 'src/pages/Activity';
import Buy from 'src/pages/Checkout';
import CookiePolicy from 'src/pages/CookiePolicy';
import Deal from 'src/pages/Deal';
import Deals from 'src/pages/Deals';
import Investors from 'src/pages/Investors';
import Login from 'src/pages/Login';
import Note from 'src/pages/Note';
import NoteEdit from 'src/pages/NoteEdit';
import Notes from 'src/pages/Notes';
import OnBoard from 'src/pages/OnBoarding';
import Organisations from 'src/pages/Organisations';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import Profile from 'src/pages/Profile';
import Register from 'src/pages/Register';
import RequestReset from 'src/pages/RequestReset';
import Support from 'src/pages/Support';
import Tasks from 'src/pages/Tasks';
import TermsAndConditions from 'src/pages/TermsAndConditions';
import Users from 'src/pages/Users';
import ValidateEmail from 'src/pages/VerifyEmail';
import LayoutApp from 'src/pages/_layouts/App';
import LayoutOnboarding from 'src/pages/_layouts/Onboarding';
import LayoutPublic from 'src/pages/_layouts/PublicRoute';
import LayoutSite from 'src/pages/_layouts/Site';
import LayoutVerify from 'src/pages/_layouts/Verify';
import * as ROUTES from 'src/routes/routes';

export default function Routes() {
  return (
    <Switch>
      {/* ====   PRE AUTH    ==== */}
      <LayoutSite exact path={ROUTES.LOGIN}>
        <Login />
      </LayoutSite>
      <LayoutSite exact path={ROUTES.REGISTER}>
        <Register />
      </LayoutSite>
      <LayoutSite exact path={ROUTES.REQUEST_RESET}>
        <RequestReset />
      </LayoutSite>

      {/* ====   LEGALS    ==== */}
      <LayoutPublic exact path={ROUTES.TERMS}>
        <TermsAndConditions />
      </LayoutPublic>
      <LayoutPublic exact path={ROUTES.COOKIE}>
        <CookiePolicy />
      </LayoutPublic>
      <LayoutPublic exact path={ROUTES.PRIVACY}>
        <PrivacyPolicy />
      </LayoutPublic>
      <LayoutPublic exact path={ROUTES.ACCEPTABLE_USE}>
        <AcceptableUsePolicy />
      </LayoutPublic>

      {/* <Route exact path={ROUTES.CHANGE_PASSWORD}>
        <ChangePassword />
      </Route> */}

      {/* ====   ON BOARDING    ==== */}
      <LayoutVerify exact path={ROUTES.VERIFY_EMAIL}>
        <ValidateEmail />
      </LayoutVerify>
      <LayoutOnboarding exact path={ROUTES.ONBOARDING}>
        <OnBoard />
      </LayoutOnboarding>

      {/* ====   Account Management   ==== */}
      <LayoutApp exact path={ROUTES.USERS}>
        <Users />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.PROFILE}>
        <Profile />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.SUPPORT}>
        <Support />
      </LayoutApp>

      {/* ====   APP   ==== */}
      <LayoutApp exact path={ROUTES.DASHBOARD}>
        <Deals />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.INVESTORS}>
        <Investors />
      </LayoutApp>
      <LayoutApp path={ROUTES.INVESTOR}>
        <InvestorHandler />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.DEALS}>
        <Deals />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.DEAL}>
        <Deal />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.TASKS}>
        <Tasks />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.NOTES}>
        <Notes />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.NOTE}>
        <Note />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.NOTE_EDIT}>
        <NoteEdit />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.ACTIVITY}>
        <Activity />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.ORGANISATIONS}>
        <Organisations />
      </LayoutApp>
      <LayoutApp exact path={ROUTES.BUY}>
        <Buy />
      </LayoutApp>

      {/* ==== ERROR PAGES ==== */}
      <LayoutPublic exact path="">
        <Error404 />
      </LayoutPublic>
    </Switch>
  );
}
