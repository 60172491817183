import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Container,
  Grow,
  IconButton,
  Link,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import { initalise } from 'src/constants/init';
import { AlgoliaContext } from 'src/contexts/AlgoliaContext';
import { AppContext } from 'src/contexts/AppContext';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { DealContext } from 'src/contexts/DealContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { OrganisationFirebase } from 'src/services/organisations';
import colors from 'src/themes/colors';
import { NotificationIcon } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(3, 0),
    borderBottom: '1px solid #ECECEC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(0, 0, 0, 3),
    padding: theme.spacing(0, 1.5, 0, 0),
    background: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  upgrade: {
    height: 42,
    marginLeft: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(1),
    background: colors.purple[400],
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.5, 0, 0, 0.5),
    textTransform: 'uppercase',
  },
  svg: {
    width: 24,
    height: 24,
    '& > *': {
      fill: colors.grey[300],
    },
    color: colors.grey[300],
  },
}));

const HeaderApp: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { setSearch } = React.useContext(AlgoliaContext);
  const { setCheckout } = React.useContext(CheckoutContext);
  const { setDeal } = React.useContext(DealContext);
  const { setInvestor } = React.useContext(InvestorContext);
  const { setOnboard } = React.useContext(OnboardingContext);
  const { setProcess } = React.useContext(ProcessContext);
  const history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AppContext);
  const { user, invites, subscriptions, organisation } = state;
  const fbAuth = React.useContext(FirebaseContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState<JSX.Element>();
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = React.useState(false);

  const active_subscription = _.find(subscriptions, { status: 'active' });
  const overdue_subscription = _.find(subscriptions, { status: 'past_due' });

  const notificationRef = React.useRef<HTMLButtonElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      (notificationRef.current && notificationRef.current.contains(event.target as HTMLElement)) ||
      (buttonRef.current && buttonRef.current.contains(event.target as HTMLElement))
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const logout = React.useCallback(async () => {
    await fbAuth.signOut();
    setSearch({
      query: '',
      selected: [],
    });
    setCheckout(initalise.checkout);
    setDeal(initalise.deal);
    setInvestor(initalise.investor);
    setOnboard({
      user: initalise.user,
      organisation: initalise.organisation,
      step: 0,
      currency_step: false,
    });
    setProcess(initalise.process);
    dispatch({ type: 'ACTIVITIES_FLUSH' });
    dispatch({ type: 'API_FLUSH_INIT' });
    dispatch({ type: 'BILLING_FLUSH' });
    dispatch({ type: 'CONTACTS_FLUSH' });
    dispatch({ type: 'DEALS_FLUSH' });
    dispatch({ type: 'INVESTORS_FLUSH' });
    dispatch({ type: 'INVITE_FLUSH' });
    dispatch({ type: 'INVITED_FLUSH' });
    dispatch({ type: 'MEMBERS_FLUSH' });
    dispatch({ type: 'NOTES_FLUSH' });
    dispatch({ type: 'PROCESSES_FLUSH' });
    dispatch({ type: 'ORGANISATION_FLUSH' });
    dispatch({ type: 'ORGANISATIONS_FLUSH' });
    dispatch({ type: 'SUBSCRIPTIONS_FLUSH' });
    dispatch({ type: 'TASKS_FLUSH' });
    dispatch({ type: 'TERMSHEETS_FLUSH' });
    dispatch({ type: 'UI_FLUSH' });
    dispatch({ type: 'USER_FLUSH' });
  }, [fbAuth, dispatch, setSearch, setCheckout, setDeal, setInvestor, setOnboard, setProcess]);

  const handleProfile = React.useCallback(() => {
    history.replace(ROUTES.PROFILE);
    setOpen(false);
  }, [history]);

  const handleUpgrade = React.useCallback(() => {
    history.replace(ROUTES.BUY);
  }, [history]);

  const getInvites = React.useCallback(async () => {
    try {
      setLoading(true);
      const result = await OrganisationFirebase.getInvites();
      dispatch({ type: 'INVITES_LIST', payload: result });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const err: Fluit.firestore.Error = error;
      setToast({
        type: 'error',
        message: `${err.name} | ${err.message}`,
      });
    }
  }, [setToast, dispatch]);

  React.useEffect(() => {
    getInvites();
  }, [getInvites]);

  const handleInvites = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: 'Invites',
        component: 'ORGANISATION_INVITES',
        close: true,
      },
    });
    setOpen(false);
  }, [dispatch]);

  const helpModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: 'Help & Support',
        component: 'TOUR_HELP',
        close: true,
      },
    });
  };

  const handleContent = React.useCallback(
    (content: string) => {
      if (!loading) {
        switch (content) {
          case 'profile':
            return (
              <ClickAwayListener onClickAway={handleClose} disableReactTree>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={() => handleProfile()}>Profile</MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            );
          case 'notifications':
            return (
              <ClickAwayListener onClickAway={handleClose} disableReactTree>
                {_.some(invites) ? (
                  <List component="nav" aria-label="notifications" disablePadding>
                    {_.map(invites, invite => (
                      <ListItem button key={_.uniqueId()} onClick={() => handleInvites()}>
                        <Typography variant="body1">
                          You've been invited to join <strong>{invite.name}</strong> <br />
                          <Link color="secondary">View invitation</Link>
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Box p={2}>
                    <Typography variant="body2">No notifications</Typography>
                  </Box>
                )}
              </ClickAwayListener>
            );
          default:
            return undefined;
        }
      }
    },
    [loading, handleInvites, handleProfile, invites, logout, open]
  );

  const handleToggle = async (content: string, target: HTMLButtonElement, check: boolean) => {
    if (open && anchor === target) {
      setOpen(false);
      setContent(undefined);
    } else if (open && anchor !== target) {
      setAnchor(target);
      setContent(handleContent(content));
    } else {
      setAnchor(target);
      setContent(handleContent(content));
      setOpen(true);
    }
  };

  if (mobile) {
    return (
      <Container maxWidth={false}>
        <Box className={classes.header}>
          <Breadcrumb />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth={false}>
      <Box className={classes.header}>
        <Box flex={1}>
          <Breadcrumb />
        </Box>
        <IconButton onClick={() => helpModal()}>
          <HelpOutlineIcon className={classes.svg} />
        </IconButton>
        <IconButton
          ref={notificationRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={e => handleToggle('notifications', e.currentTarget, true)}
        >
          <Badge badgeContent={_.values(invites).length} color="error" invisible={_.values(invites).length === 0}>
            <NotificationIcon className={classes.svg} />
          </Badge>
        </IconButton>
        {!active_subscription && (
          <Button color="primary" variant="contained" className={classes.upgrade} onClick={() => handleUpgrade()}>
            Upgrade
          </Button>
        )}
        <Button
          ref={buttonRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={e => handleToggle('profile', e.currentTarget, false)}
          className={classes.button}
        >
          <Box className={classes.avatar}>
            {user.first_name.charAt(0)}
            {user.last_name.charAt(0)}
          </Box>

          <Typography variant="subtitle2">
            {user.first_name} {user.last_name}
          </Typography>
        </Button>
        <Popper open={open} anchorEl={anchor} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper elevation={4}>{content}</Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      {state.init.payment_failed ? (
        <Box my={3}>
          <Paper>
            <Alert severity="error">
              <strong>Account Disabled:</strong> A payment for {organisation.name} is overdue, contact the owner to
              complete payment and re-enable your account.
            </Alert>
          </Paper>
        </Box>
      ) : null}

      {overdue_subscription ? (
        <Box my={3}>
          <Paper>
            <Alert
              severity="error"
              action={
                <Button color="inherit" size="small" component={NavLink} to={`${ROUTES.ORGANISATIONS}?tab=billing`}>
                  Billing
                </Button>
              }
            >
              <strong>Payment Failed</strong> We had an issue collecting a recent payment, please ensure you have the
              necessary funds in your account and that your card details are accurate &amp; active.
            </Alert>
          </Paper>
        </Box>
      ) : null}
    </Container>
  );
};

export default HeaderApp;
