import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';

export const AssignmentsFirebase = {
  create: async (assignment: Fluit.assignments.Assignment) => {
    try {
      const resp = await API({
        endpoint: `organisations/${assignment.org_id}/assignments`,
        method: 'POST',
        payload: assignment,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  get: async (orgId: string, processId: string): Promise<Fluit.assignments.Assignment> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/assignments/${processId}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (assignment: Partial<Fluit.assignments.Assignment>) => {
    try {
      const resp = await API({
        endpoint: `organisations/${assignment.org_id}/assignments/${assignment.id}`,
        method: 'PATCH',
        payload: assignment,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (assignment: Fluit.assignments.Assignment) => {
    try {
      const resp = await API({
        endpoint: `organisations/${assignment.org_id}/assignments/${assignment.id}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (orgId: string): Promise<Fluit.investors.Contact[]> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/assignments`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
