import { createMuiTheme } from '@material-ui/core/styles';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';
require('typeface-montserrat');

const muiTheme = createMuiTheme();
const themeLight = (createMuiTheme as any)({
  palette: {
    common: {
      black: colors.black[900],
      white: colors.common.white,
    },
    background: {
      paper: colors.common.white,
      default: colors.common.background,
    },
    primary: {
      light: colors.purple[400],
      main: colors.purple[700],
      dark: colors.purple[900],
      contrastText: colors.common.white,
    },
    secondary: {
      light: colors.blue[100],
      main: colors.blue[400],
      dark: colors.blue[600],
      contrastText: colors.common.white,
    },
    error: {
      light: colors.red[200],
      main: colors.red[500],
      dark: colors.red[700],
      contrastText: colors.common.white,
    },
    grey: colors.grey,
    text: {
      primary: colors.black[900],
      secondary: colors.black[700],
      disabled: colors.black[400],
      hint: colors.black[500],
    },
  },
  typography: {
    fontFamily: [
      'montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
    h1: {
      fontWeight: 600,
      fontSize: '34px',
      lineHeight: '44px',
      color: colors.navy[900],
      [muiTheme.breakpoints.down('xs')]: {
        fontSize: '24px',
        lineHeight: '34px',
      },
    },
    h2: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '34px',
      color: colors.navy[900],
    },
    h3: {
      fontWeight: 500,
      fontSize: '30px',
      lineHeight: '40px',
      color: colors.navy[900],
    },
    h4: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '26px',
      color: colors.navy[900],
    },
    h5: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      color: colors.navy[900],
    },
    h6: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: colors.navy[900],
    },
    body1: {
      fontSize: '15px',
      lineHeight: '28px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '26px',
      fontWeight: 400,
    },
    overline: {
      lineHeight: 1.5,
      color: colors.grey[400],
    },
  },
  shadows: [
    'none',
    shadows.black[1],
    '0 2px 25px 0 rgba(145,132,174,0.05),0 2px 25px 0 rgba(145,132,174,0.05),0 2px 25px 0 rgba(145,132,174,0.05)',
    '0 2px 15px 0 rgba(127,40,252,0.14),0 2px 15px 0 rgba(127,40,252,0.14),0 2px 15px 0 rgba(127,40,252,0.14)',
    shadows.purple[3],
    '0px 6px 6px -1px rgba(0, 0, 0, 0.3),0px -1px 10px 0px rgba(0, 0, 0, 0.06),0px 1px 14px 0px rgba(0, 0, 0, 0.04)',
    '0px 6px 6px -1px rgba(0, 0, 0, 0.3),0px -2px 12px 0px rgba(0, 0, 0, 0.06),0px 1px 18px 0px rgba(0, 0, 0, 0.04)',
    '0px 7px 6px -2px rgba(0, 0, 0, 0.3),0px -1px 12px 1px rgba(0, 0, 0, 0.06),0px 2px 16px 1px rgba(0, 0, 0, 0.04)',
    '0px 10px 6px -3px rgba(0, 0, 0, 0.3),0px 0px 12px 1px rgba(0, 0, 0, 0.06),0px 3px 14px 2px rgba(0, 0, 0, 0.04)',
    '0px 10px 7px -3px rgba(0, 0, 0, 0.3),0px 1px 14px 1px rgba(0, 0, 0, 0.06),0px 3px 16px 2px rgba(0, 0, 0, 0.04)',
    '0px 11px 7px -3px rgba(0, 0, 0, 0.3),0px 2px 16px 1px rgba(0, 0, 0, 0.06),0px 4px 18px 3px rgba(0, 0, 0, 0.04)',
    '0px 11px 8px -4px rgba(0, 0, 0, 0.3),0px 3px 17px 1px rgba(0, 0, 0, 0.06),0px 4px 20px 3px rgba(0, 0, 0, 0.04)',
    '0px 13px 9px -4px rgba(0, 0, 0, 0.3),0px 4px 19px 2px rgba(0, 0, 0, 0.06),0px 5px 22px 4px rgba(0, 0, 0, 0.04)',
    '0px 13px 9px -4px rgba(0, 0, 0, 0.3),0px 5px 21px 2px rgba(0, 0, 0, 0.06),0px 5px 24px 4px rgba(0, 0, 0, 0.04)',
    '0px 13px 10px -4px rgba(0, 0, 0, 0.3),0px 6px 23px 2px rgba(0, 0, 0, 0.06),0px 5px 26px 4px rgba(0, 0, 0, 0.04)',
    '0px 15px 10px -5px rgba(0, 0, 0, 0.3),0px 7px 24px 2px rgba(0, 0, 0, 0.06),0px 6px 28px 5px rgba(0, 0, 0, 0.04)',
    '0px 15px 12px -5px rgba(0, 0, 0, 0.3),0px 8px 26px 2px rgba(0, 0, 0, 0.06),0px 6px 30px 5px rgba(0, 0, 0, 0.04)',
    '0px 15px 13px -5px rgba(0, 0, 0, 0.3),0px 9px 28px 2px rgba(0, 0, 0, 0.06),0px 6px 32px 5px rgba(0, 0, 0, 0.04)',
    '0px 17px 13px -5px rgba(0, 0, 0, 0.3),0px 10px 30px 2px rgba(0, 0, 0, 0.06),0px 7px 34px 6px rgba(0, 0, 0, 0.04)',
    '0px 17px 14px -6px rgba(0, 0, 0, 0.3),0px 11px 31px 2px rgba(0, 0, 0, 0.06),0px 7px 36px 6px rgba(0, 0, 0, 0.04)',
    '0px 19px 15px -6px rgba(0, 0, 0, 0.3),0px 12px 33px 3px rgba(0, 0, 0, 0.06),0px 8px 38px 7px rgba(0, 0, 0, 0.04)',
    '0px 19px 15px -6px rgba(0, 0, 0, 0.3),0px 13px 35px 3px rgba(0, 0, 0, 0.06),0px 8px 40px 7px rgba(0, 0, 0, 0.04)',
    '0px 19px 16px -6px rgba(0, 0, 0, 0.3),0px 14px 37px 3px rgba(0, 0, 0, 0.06),0px 8px 42px 7px rgba(0, 0, 0, 0.04)',
    '0px 20px 16px -7px rgba(0, 0, 0, 0.3),0px 15px 38px 3px rgba(0, 0, 0, 0.06),0px 9px 44px 8px rgba(0, 0, 0, 0.04)',
    '0px 20px 18px -7px rgba(0, 0, 0, 1),0px 16px 40px 3px rgba(0, 0, 0, 1),0px 9px 46px 8px rgba(0, 0, 0, 1)',
  ],
  overrides: {
    MuiCard: {
      root: {
        height: '100%',
      },
    },
    MuiCardContent: {
      root: {
        padding: '24px',
      },
    },
    MuiCardActionArea: {
      root: {
        border: `2px solid rgba(255,255,255,0)`,
        '&:hover': {
          border: `2px solid ${colors.purple.main}`,
          boxShadow: shadows.purple[1],
        },
      },
      focusHighlight: {
        opacity: 0,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.navy[900],
        fontWeight: 600,
        fontSize: 12,
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: colors.grey[50],
        boxShadow: shadows.black[1],
      },
      label: {
        fontWeight: 600,
        fontSize: 15,
        textTransform: 'capitalize',
      },
      outlined: {
        borderWidth: '1.5px !important',
      },
      sizeLarge: {
        height: '48px',
        padding: '8px 24px',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: colors.grey[50],
      },
      label: {
        fontWeight: 600,
        fontSize: 13,
      },
    },
    MuiSelect: {
      select: {
        background: colors.common.white,
        boxShadow: shadows.black[1],
      },
    },
    MuiMenuItem: {
      root: {
        background: colors.common.white,
      },
    },
    MuiAutocomplete: {
      root: {
        borderRadius: '4px',
        fontSize: 16,
      },
      option: {
        '&[aria-selected="true"]': {
          backgroundColor: colors.purple[1],
        },
        '&:active': {
          backgroundColor: colors.purple[1],
        },
        '&[data-focus="true"]': {
          '&:active, &[aria-selected="true"]': {
            backgroundColor: colors.purple[1],
          },
          backgroundColor: colors.grey[50],
        },
      },
    },

    MuiInputBase: {
      root: {
        fontSize: 16,
        background: colors.common.white,
        boxShadow: shadows.black[1],
      },
    },
    MuiFormHelperText: {
      contained: {
        marginTop: '8px',
        marginLeft: 0,
        marginRight: 0,
        fontWeight: 500,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(27,0,108,0.4)',
      },
    },
    MuiLink: {
      root: {
        fontWeight: 600,
      },
    },
    MuiTablePagination: {
      root: {
        background: colors.grey[1],
        border: 'none',
        borderRadius: `0px 0px 4px 4px`,
      },
      toolbar: {
        padding: '24px',
      },
      actions: {
        paddingRight: '24px',
      },
    },
    MuiTableSortLabel: {
      root: {
        fontSize: '13px',
        color: colors.grey[500],
      },
      active: {
        color: colors.blue[700],
      },
    },
    MuiTableCell: {
      head: {
        padding: '24px',
        borderBottom: `1px solid ${colors.grey[50]}`,
      },
      body: {
        padding: '24px',
        borderBottom: `1px solid ${colors.grey[50]}`,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: colors.purple[700],
        },
      },
    },

    MuiDialog: {
      root: {},
      paper: {
        borderRadius: '16px',
        background: colors.common.background,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '48px 48px 16px',
        [muiTheme.breakpoints.down('sm')]: {
          padding: '16px',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px 48px 48px',
        [muiTheme.breakpoints.down('sm')]: {
          padding: '16px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
        marginTop: '24px',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
        fontWeight: 500,
        '&.Mui-selected': {
          fontWeight: 600,
        },
      },
    },
    MuiAlert: {
      root: {
        fontWeight: 500,
        fontSize: '15px',
      },
      filledInfo: {
        backgroundColor: colors.navy[900],
      },
      icon: {
        padding: '9px 0px',
      },
    },
  },
});

export default themeLight;
