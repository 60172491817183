import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Investor from 'src/pages/Investor';
import InvestorSearch from 'src/pages/InvestorSearch';

const InvestorHandler: React.FC = () => {
  let is_search = useRouteMatch('/investors/search');

  if (is_search) {
    return <InvestorSearch />;
  }

  return <Investor />;
};

export default InvestorHandler;
