import { Avatar, Box, Chip, IconButton, Link, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { styles_UserCard } from 'src/styles/cards';
import { DotsHorizontal, Users } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

interface Props {
  user: Fluit.organisations.Member;
  role: string;
}

const UserCard: React.FC<Props> = ({ user, role }) => {
  const classes = styles_UserCard();
  const { state, dispatch } = React.useContext(AppContext);
  const { init } = state;
  const { admin } = init;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper style={{ height: '100%' }}>
      <Box
        p={4}
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
        className={clsx(classes.box, user.system.disabled && classes.disabled)}
        height="100%"
      >
        {!user.is_onboarded && !user.first_name ? <Users className={classes.inactive} /> : null}
        {user.first_name && user.last_name ? (
          <>
            <Avatar className={classes.avatar}>{`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}</Avatar>
            <Typography variant="h4">
              {user.first_name} {user.last_name}
            </Typography>
          </>
        ) : null}

        {user.position ? <Typography variant="caption">{user.position}</Typography> : null}

        <Box mt={2} alignItems="center" display="flex" flexDirection="column">
          {user.telephone ? (
            <Typography variant="body2">
              <Typography variant="caption">Tel: </Typography>
              <Link color="secondary" href={`tel:${user.telephone}`}>
                {user.telephone}
              </Link>
            </Typography>
          ) : null}

          {user.email ? (
            <Typography variant="body2">
              <Link color="secondary" href={`mailto:${user.email}`} target="_blank">
                {user.email}
              </Link>
            </Typography>
          ) : null}

          <Box>
            <Chip
              className={clsx(classes.chip, role === 'member' && classes.member, role === 'owner' && classes.owner)}
              label={role}
            />
            {!user.is_onboarded ? <Chip className={clsx(classes.chip, classes.pending)} label="Inactive" /> : null}

            {user.system.disabled ? <Chip className={clsx(classes.chip, classes.pending)} label="Disabled" /> : null}
          </Box>
        </Box>
        {admin && user.id !== state.user.id ? (
          <>
            <IconButton
              color="secondary"
              aria-controls="user-contextual-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.iconButton}
            >
              <DotsHorizontal className={classes.svg} />
            </IconButton>
            <Menu
              id="user-contextual-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              elevation={4}
            >
              <MenuItem
                disabled={user.system.disabled}
                onClick={() => {
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      open: true,
                      title:
                        user.email_verified && user.is_onboarded
                          ? `${user.first_name} ${user.last_name}'s Role`
                          : `${user.email}'s Role`,
                      component: 'USER_ROLE',
                      user_id: user.id,
                    },
                  });
                  setAnchorEl(null);
                }}
              >
                Edit Role
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch({
                    type: 'MODAL',
                    payload: {
                      open: true,
                      title:
                        user.email_verified && user.is_onboarded
                          ? `Remove ${user.first_name} ${user.last_name}`
                          : `Remove ${user.email}`,
                      component: 'USER_DELETE',
                      user_id: user.id,
                    },
                  });
                  setAnchorEl(null);
                }}
              >
                Remove
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </Box>
    </Paper>
  );
};

export default UserCard;
