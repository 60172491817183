import { Grid, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  legal: {
    '& h2': {
      fontSize: 30,
      marginBottom: 24,
    },
    '& h3': {
      fontSize: 24,
      marginBottom: 24,
    },
    '& p': {
      marginBottom: 16,
    },
  },
}));

const CookiePolicy: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.legal}>
      <Grid item xs={12}>
        <Typography variant="h1">Cookie Policy</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">INFORMATION ABOUT OUR USE OF COOKIES</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with
          a good experience when you browse our website and also allows us to improve our site. By continuing to browse
          the site, you are agreeing to our use of cookies.
        </Typography>
        <Typography variant="body1">
          A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
          computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.
        </Typography>
        <Typography variant="body1">We use the following cookies:</Typography>
        <ul>
          <li>
            <b>Strictly necessary cookies.</b> These are cookies that are required for the operation of our website.
            They include, for example, cookies that enable you to log into secure areas of our website, use a shopping
            cart or make use of e-billing services.
          </li>
          <li>
            <b>Analytical/performance cookies.</b> They allow us to recognise and count the number of visitors and to
            see how visitors move around our website when they are using it. This helps us to improve the way our
            website works, for example, by ensuring that users are finding what they are looking for easily.
          </li>
          <li>
            <b>Functionality cookies.</b> These are used to recognise you when you return to our website. This enables
            us to personalise our content for you, greet you by name and remember your preferences (for example, your
            choice of language or region).
          </li>
          <li>
            <b>Targeting cookies.</b> These cookies record your visit to our website, the pages you have visited and the
            links you have followed. We will use this information to make our website and the advertising displayed on
            it more relevant to your interests. We may also share this information with third parties for this purpose.
          </li>
        </ul>
        <Typography variant="body1">
          You can find more information about the individual cookies we use and the purposes for which we use them in
          the table below:
        </Typography>
        <Typography variant="body1">
          Please note that third parties (including, for example, advertising networks and providers of external
          services like web traffic analysis services) may also use cookies, over which we have no control. These
          cookies are likely to be analytical/performance cookies or targeting cookies.
        </Typography>
        <Typography variant="body1">
          You block cookies by activating the setting on your browser that allows you to refuse the setting of all or
          some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you
          may not be able to access all or parts of our site.
        </Typography>
        <Typography variant="body1">Except for essential cookies, all cookies will expire after 45 days.</Typography>
      </Grid>
    </Grid>
  );
};

export default CookiePolicy;
