const colors = {
  common: {
    white: '#ffffff',
    background: '#f5f6fa',
  },
  purple: {
    1: '#F6F1FE',
    50: '#f0e7fe',
    100: '#d8c4fd', // Light
    200: '#bd9cfd',
    300: '#a071fe',
    400: '#6c24fb',
    500: '#6B24FB', // Main
    600: '#5e1ff4',
    700: '#4a14ec', // Dark
    800: '#300ae7',
    900: '#0000e2',
    light: '#d8c4fd', // 100
    main: '#6c24fb', // 500
    dark: '#4a14ec', // 700
  },
  blue: {
    50: '#e7e8fd',
    100: '#c1c6f9', // Light
    200: '#95a1f4',
    300: '#637cf1',
    400: '#325eee', // Main
    500: '#0040e8',
    600: '#0037dd', // Dark
    700: '#002cd1',
    800: '#001fc6',
    900: '#0002ae',
  },
  red: {
    50: '#fde5eb',
    100: '#fbbdce',
    200: '#f993ad', // Light
    300: '#f6688d',
    400: '#f14a75',
    500: '#ee325e', // Main
    600: '#dd2d5b',
    700: '#c72857', // Dark
    800: '#b32354',
    900: '#8e1b4d',
    main: '#ee325e',
  },
  navy: {
    50: '#e9e3f1',
    100: '#c8badc',
    200: '#a48dc5',
    300: '#8260af',
    400: '#683f9f',
    500: '#4f1e8f',
    600: '#47198a',
    700: '#3b1282',
    800: '#2f0b7a',
    900: '#1b006c', // Main
  },
  grey: {
    1: '#F9F9F9',
    50: '#f0f0f0',
    100: '#d9d9d9',
    200: '#bec0c3',
    300: '#a0a8ae',
    400: '#8a949d', // Main
    500: '#74828d',
    600: '#67737c',
    700: '#565e65',
    800: '#474b50',
    900: '#343639',
  },
  black: {
    50: '#f5f5f5',
    100: '#e9e9e9',
    200: '#d9d9d9',
    300: '#c4c4c4',
    400: '#9d9d9d',
    500: '#9d9d9d',
    600: '#555555',
    700: '#434343',
    800: '#262626',
    900: '#000000', // Main
  },
  green: {
    50: '#e8faea',
    100: '#c8f1cb',
    200: '#a3e9aa', // Light
    300: '#78e086',
    400: '#52d868', // Main
    500: '#20d04a',
    600: '#0dbf40', // Dark
    700: '#00ac33',
    800: '#009b26',
    900: '#007a11',
  },
  gradients: {
    vertical: {
      purple: 'linear-gradient(180deg, #9D45FD 0%, #7019FC 28%, #5B47F5 100%)',
      grey: `linear-gradient(180deg, #F5F6FA 0%, #FFFFFF 100%)`,
    },
    horizontal: {
      purple: 'linear-gradient(90deg, #9D45FD 0%, #5B47F5 100%)',
    },
  },
};

export default colors;
