import { InputAdornment } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import FormikTextField from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import NumberInput from './NumberInput';

interface Props {
  name: string;
  id?: string;
  label: string;
  value?: string;
  currency?: string;
}

const TextfieldFormattedNumber: React.FC<Props> = ({ name, id, label, value, currency }) => {
  const { state } = React.useContext(AppContext);
  const val = value ? value : '';
  const symbol = currency ? currency : state.organisation.currency.symbol;

  return (
    <Field
      component={FormikTextField}
      fullWidth={true}
      name={name}
      id={id ? id : name}
      label={label}
      value={val}
      InputProps={{
        autoComplete: 'new-password',
        startAdornment: (
          <InputAdornment position="start" disableTypography={true}>
            {symbol}
          </InputAdornment>
        ),
        inputComponent: NumberInput as any,
      }}
      variant="outlined"
    />
  );
};

export default TextfieldFormattedNumber;
