import Fluit from 'src/types/Fluit';
import { API } from 'src/services/api';

export const ActivityFirebase = {
  create: async (activity: Partial<Fluit.activities.Activity>) => {
    try {
      const resp = await API({
        endpoint: `organisations/${activity.org_id}/activities/`,
        method: 'POST',
        payload: activity,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  get: async (orgId: string, activityId: string): Promise<Fluit.activities.Activity> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/activities/${activityId}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (activity: Partial<Fluit.activities.Activity>) => {
    try {
      const resp = await API({
        endpoint: `organisations/${activity.org_id}/activities/${activity.id}`,
        method: 'PATCH',
        payload: activity,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (orgId: string, activityId: string) => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/activities/${activityId}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (
    orgId: string,
    source: string,
    target_field?: string,
    target_id?: string,
    cursor?: string
  ): Promise<Fluit.activities.Activity[]> => {
    let base_url = `organisations/${orgId}/activities?source=${source}`;
    let target_url = `&target_field=${target_field ? target_field : ''}&target_id=${target_id ? target_id : ''}`;
    let cursor_url = `&cursor=${cursor ? cursor : ''}`;
    let url = base_url;

    if (target_field && target_id) url = `${base_url}${target_url}`;
    if (target_field && target_id && cursor) url = `${base_url}${target_url}${cursor_url}`;
    if (!target_field && !target_id && cursor) url = `${base_url}${cursor_url}`;

    // console.log(url);

    try {
      const resp = await API({
        endpoint: url,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
};
