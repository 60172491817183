import { Box, Grid, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoData from 'src/components/Empty';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { TaskFirebase } from 'src/services/tasks';
import colors from 'src/themes/colors';
import { Tasks } from 'src/themes/icons';
import GenericLoader from '../Loaders/GenericLoader';
import Task from './Task';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '20px',
    height: '20px',
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  contact: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    display: 'inline-flex',
    padding: theme.spacing(0.25, 0.5),
    margin: theme.spacing(0, -0.5),
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: colors.blue[50],
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  },
  paper: {
    pointerEvents: 'auto',
  },
}));

interface Props {
  createFunction?: () => void;
  parent?: string;
}

const TasksList: React.FC<Props> = ({ createFunction, parent }) => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, tasks } = state;

  /* Interface Controls for Showing Hiding Popovers */
  const [poperEl, setPoperEl] = React.useState<HTMLElement | null>(null);
  const [popEl, setPopEl] = React.useState<HTMLElement | null>(null);
  const [content, setContent] = React.useState<JSX.Element>();

  /* API State */
  const [fetched, setFetched] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);

  const [last, setLast] = React.useState<string | undefined>(undefined);
  const [more, setMore] = React.useState(false);
  const classes = useStyles();

  const parent_tasks = _.filter(tasks, { parent_id: parent });

  const listTasks = React.useCallback(async () => {
    if (fetching) {
      return undefined;
    }
    setFetching(true);
    try {
      const tasks = await TaskFirebase.list(organisation.id, last, parent);
      dispatch({ type: 'TASKS_LIST', payload: tasks });
      setMore(tasks.length === 10 ? true : false);
      setLast(_.last(tasks)?.id);
      setFetched(true);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      setToast({
        message: error.message,
        type: 'error',
      });
    }
  }, [organisation.id, setToast, dispatch, last, fetching, parent]);

  React.useEffect(() => {
    if (fetched) {
      return undefined;
    }

    listTasks();
  }, [fetched, listTasks]);

  // Interface Actions
  const handlePopoverClose = () => {
    setPoperEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, content: JSX.Element) => {
    setPoperEl(event.currentTarget);
    setPopEl(event.currentTarget);
    setContent(content);
  };

  const handlePopoverHover = () => {
    setPoperEl(popEl);
  };

  const handlePopoverExit = () => {
    setPopEl(null);
    setPoperEl(null);
  };

  // Crud API Options
  const TaskCreate = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `New Task`,
        component: 'TASK_CREATE',
      },
    });
  };

  const html_popover = (
    <Popover
      open={Boolean(poperEl)}
      anchorEl={poperEl}
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      id="mouse-over-popover"
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      onClose={handlePopoverClose}
      PaperProps={{ onMouseEnter: handlePopoverHover, onMouseLeave: handlePopoverExit, elevation: 5 }}
      disableRestoreFocus
    >
      {content ? content : null}
    </Popover>
  );

  if (!fetched) {
    return (
      <Grid item xs={12}>
        <GenericLoader variant="none" height="160px" />
      </Grid>
    );
  }

  if (_.some(parent ? parent_tasks : tasks)) {
    return (
      <>
        <InfiniteScroll
          dataLength={parent ? _.values(parent_tasks).length : _.values(tasks).length}
          next={listTasks}
          hasMore={more}
          loader={
            <Box mt={2}>
              <GenericLoader height="100%" variant="none" />
            </Box>
          }
          endMessage={
            <Box mt={2}>
              <Typography>All Tasks Loaded</Typography>
            </Box>
          }
          style={{ overflow: 'hidden' }}
        >
          <Grid container spacing={4}>
            {_.map(parent ? parent_tasks : tasks, task => (
              <Grid item xs={12} key={task.id}>
                <Task task={task} handlePopoverClose={handlePopoverClose} handlePopoverOpen={handlePopoverOpen} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>

        {html_popover}
      </>
    );
  }

  return (
    <NoData
      text="No tasks"
      icon={<Tasks />}
      label="Create a task"
      action={createFunction ? createFunction : TaskCreate}
    />
  );
};

export default TasksList;
