import { makeStyles, Theme, createStyles } from '@material-ui/core';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';

export const styles_checkoutStepper = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      background: 'none',
    },
  })
);

export const styles_checkoutCatalog = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300 + theme.spacing(3) * 2,
    },
    pricing: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    icon: {
      fill: colors.grey[50],
      '& g, & path': {
        fill: colors.grey[50],
      },
    },
    paper: {
      marginBottom: theme.spacing(4),
    },
    margin: {
      height: theme.spacing(3),
    },
    chip: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
      },
    },
    toggle: {
      width: '100%',
      display: 'flex',
      flexDirect: 'row',
    },
    toggleButton: {
      display: 'flex',
      flex: 1,
      textTransform: 'none',
      border: `2px solid ${colors.common.background}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: colors.common.background,
      },
      '&$toggleSelected': {
        border: `2px solid ${colors.purple.main}`,
        backgroundColor: colors.common.white,
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: colors.common.white,
        },
      },
    },
    toggleLabel: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    toggleSelected: {},
    check: {
      color: colors.purple.main,
    },
  })
);

export const styles_checkoutSummary = makeStyles((theme: Theme) =>
  createStyles({
    decimals: {
      fontSize: 12,
      fontWeight: 400,
      opacity: 0.6,
    },
  })
);

export const styles_checkoutPayment = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      padding: theme.spacing(3),
      background: colors.common.white,
      boxShadow: shadows.black[1],
      border: `1.5px solid ${colors.grey[100]}`,
      borderRadius: theme.spacing(0.5),
      '&:hover': {
        border: `1.5px solid ${colors.purple.main}`,
        boxShadow: shadows.purple[1],
      },
    },
    complete: {
      border: `1.5px solid ${colors.green[400]}`,
      boxShadow: shadows.black[1],
      '&:hover': {
        border: `1.5px solid ${colors.green[400]}`,
        boxShadow: shadows.black[1],
      },
    },
    empty: {},
    focused: {
      border: `1.5px solid ${colors.purple.main}`,
      boxShadow: shadows.purple[1],
      '&$complete': {
        border: `1.5px solid ${colors.green[400]}`,
        boxShadow: shadows.black[1],
      },
    },
    invalid: {
      border: `1.5px solid ${colors.red.main}`,
      boxShadow: shadows.red[1],
      '&:hover': {
        border: `1.5px solid ${colors.red.main}`,
        boxShadow: shadows.red[1],
      },
    },
  })
);
