import { Box, Grid, Link, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import _ from 'lodash';
import Lottie from 'lottie-react-web';
import React from 'react';
import { useHistory } from 'react-router-dom';
import animationData from 'src/assets/animations/help.json';
import { AppContext } from 'src/contexts/AppContext';
import { ToursContext } from 'src/contexts/TourContext';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.4,
  },
}));

const TourHelp: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { dispatchTours } = React.useContext(ToursContext);
  const history = useHistory();
  const has_investors = _.some(state.investors);
  const has_deals = _.some(state.deals);
  const deal_id = state.deals[0] ? state.deals[0].id : '';
  const investor_id = state.investors[0] ? state.investors[0].id : '';

  const classes = useStyles();

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
        close: false,
      },
    });
  };

  const handleLink = (route: string) => {
    closeModal();
    history.push(route);
  };

  const handleTour = (route: string, tour: string) => {
    closeModal();
    if (route !== 'none') {
      history.push(route);
    }
    if (tour === 'navigation') {
      dispatchTours({ type: 'TOURS_SET_TARGET', payload: 'navigation' });
      dispatchTours({ type: 'TOURS_SET_NAVIGATION', payload: true });
    }
    if (tour === 'deal') {
      dispatchTours({ type: 'TOURS_SET_TARGET', payload: 'deal' });
      dispatchTours({ type: 'TOURS_SET_DEAL', payload: true });
    }
    if (tour === 'investor') {
      dispatchTours({ type: 'TOURS_SET_TARGET', payload: 'investor' });
      dispatchTours({ type: 'TOURS_SET_INVESTOR', payload: true });
    }
    if (tour === 'investor_search') {
      dispatchTours({ type: 'TOURS_SET_TARGET', payload: 'investor_search' });
      dispatchTours({ type: 'TOURS_SET_INVESTOR_SEARCH', payload: true });
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="center" flexDirection="column" height="100%">
          <Typography variant="h4">Tutorials</Typography>
          <Box display="flex" flexDirection="column" mb={3}>
            <Link className={classes.link} onClick={() => handleTour('none', 'navigation')}>
              Navigation
            </Link>
            {!has_deals ? (
              <Tooltip title="Add a deal to unlock this tutorial">
                <Link className={classes.disabled}>Deal Management</Link>
              </Tooltip>
            ) : (
              <Link onClick={() => handleTour(`${ROUTES.DEALS}/${deal_id}`, 'deal')} className={classes.link}>
                Deal Management
              </Link>
            )}
            {!has_investors ? (
              <Tooltip title="Add an investor to unlock this tutorial">
                <Link className={classes.disabled}>Investor Management</Link>
              </Tooltip>
            ) : (
              <Link
                onClick={() => handleTour(`${ROUTES.INVESTORS}/${investor_id}`, 'investor')}
                className={classes.link}
              >
                Investor Management
              </Link>
            )}
            <Link onClick={() => handleTour(ROUTES.INVESTOR_SEARCH, 'investor_search')} className={classes.link}>
              Investor Search
            </Link>
          </Box>
          <Typography variant="h4">Support</Typography>
          <Box>
            <Link onClick={() => handleLink(ROUTES.SUPPORT)}>Contact Support</Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid meet',
            },
          }}
          height="100%"
          width="100%"
        />
      </Grid>
    </Grid>
  );
};

export default TourHelp;
