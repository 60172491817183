import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { AssignmentsFirebase } from 'src/services/assignments';
import { ProcessesFirebase } from 'src/services/processes';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const StepDeleteForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { process, setProcess } = React.useContext(ProcessContext);
  const { steps } = process;
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const firststepId = steps[0].id;
  const assignments = state.ui.modal.step_investors as Fluit.assignments.Assignment[];
  const hasInvestors = assignments.length;

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const removeStep = React.useCallback(async () => {
    setLoading(true);

    let deleteStep = _.find(steps, { id: String(state.ui.modal.step_id) });
    let newArray = _.filter(steps, step => step.id !== String(state.ui.modal.step_id));

    try {
      if (hasInvestors > 0) {
        _.map(assignments, assignment => {
          assignment.step_id = firststepId;
          (async () => {
            await AssignmentsFirebase.update(assignment);
          })();
        });
      }
      const updated_process = { ...process, steps: newArray };
      await ProcessesFirebase.update(updated_process);
      setProcess(updated_process);
      setLoading(false);
      setToast({
        message: `Step: ${deleteStep?.name} Deleted`,
        type: 'info',
      });
      closeModal();
    } catch (error) {
      setLoading(false);
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [
    closeModal,
    process,
    setProcess,
    steps,
    setToast,
    state.ui.modal.step_id,
    hasInvestors,
    assignments,
    firststepId,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1">
          This will remove the step from the process, All investors at this step will be moved to{' '}
          <strong>"Unassigned"</strong>.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => removeStep()}
          disabled={loading ? true : false}
        >
          Delete
        </Button>
        <Button
          variant="text"
          style={{ marginLeft: '16px' }}
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default StepDeleteForm;
