import { Box, Button, Grid, makeStyles, Paper, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import DealProgress from 'src/components/Deals/DealProgress';
import DealStep from 'src/components/Deals/DealStep';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { Document, Investors } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  circleIcon: {
    background: theme.palette.background.default,
    height: '48px',
    width: '48px',
    borderRadius: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 2, 0, 0),
    '& svg': {
      width: '24px',
      height: '24px',
      fill: colors.navy[900],
    },
    '& svg, & g, & path': {
      fill: colors.navy[900],
    },
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

interface Props {
  investorCount: number;
  termsheets: Fluit.deals.Termsheet[];
}

const DealOverview: React.FC<Props> = ({ investorCount, termsheets }) => {
  const { dispatch, state } = React.useContext(AppContext);
  const { deal } = React.useContext(DealContext);
  const { process } = React.useContext(ProcessContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const history = useHistory();

  const assignInvestor = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Add Investor to Deal`,
        component: 'DEAL_ASSIGN_INVESTORS',
      },
    });
  };

  const addInvestors = () => {
    history.push(ROUTES.INVESTOR_SEARCH);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Overview</Typography>
          </Box>
        </Box>
      </Grid>
      {deal.type === 'Fundraise' ? (
        <Grid item xs={12} md={8} lg={7}>
          <DealProgress termsheets={termsheets} />
        </Grid>
      ) : null}
      <Grid item xs={12} md={4} lg={5}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <Paper className={clsx(classes.paper)}>
            <Box className={classes.circleIcon}>
              <Investors />
            </Box>
            <Typography variant="h4">
              {investorCount === 1 ? `${investorCount} investor` : `${investorCount} investors`}
            </Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Box className={classes.circleIcon}>
              <Document />
            </Box>
            <Typography variant="h4">
              {termsheets !== null
                ? termsheets.length === 1
                  ? `${termsheets.length} termsheets`
                  : `${termsheets.length} termsheets`
                : '0 termsheets'}
            </Typography>
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={mobile ? 0 : 4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h2">Process</Typography>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => (_.some(state.investors) ? assignInvestor() : addInvestors())}
          >
            {_.some(state.investors) ? 'Assign' : 'Add'} Investors
          </Button>
        </Box>
      </Grid>
      {_.map(process.steps, step => (
        <Grid item xs={12} sm={6} lg={4} xl={3} key={step.id}>
          <DealStep step={step} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DealOverview;
