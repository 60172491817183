import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import EventDelete from 'src/components/Forms/Activity/Delete';
import EventEdit from 'src/components/Forms/Activity/Edit';
import EventLog from 'src/components/Forms/Activity/Log';
import InvestorsAssignForm from 'src/components/Forms/Assignment/Assign';
import ContactsAssignForm from 'src/components/Forms/Assignment/Contacts';
import InvestorReassignForm from 'src/components/Forms/Assignment/Reassign';
import InvestorsUnassignForm from 'src/components/Forms/Assignment/Unassign';
import AddPaymentMethod from 'src/components/Forms/Billing/AddCard';
import SubscriptionCancelForm from 'src/components/Forms/Billing/Cancel';
import EditBillingDetails from 'src/components/Forms/Billing/Details';
import BillingUnits from 'src/components/Forms/Billing/Units';
import ContactCreateForm from 'src/components/Forms/Contacts/Create';
import ContactDeleteForm from 'src/components/Forms/Contacts/Delete';
import ContactEditForm from 'src/components/Forms/Contacts/Edit';
import DealCreateForm from 'src/components/Forms/Deals/Create';
import DealDeleteForm from 'src/components/Forms/Deals/Delete';
import DealEditForm from 'src/components/Forms/Deals/Edit';
import InvestorCreateForm from 'src/components/Forms/Investors/Create';
import InvestorDeleteForm from 'src/components/Forms/Investors/Delete';
import InvestorDetailsForm from 'src/components/Forms/Investors/Details';
import InvestorLegalForm from 'src/components/Forms/Investors/Legal';
import InvestorSignatoryForm from 'src/components/Forms/Investors/Signatory';
import NoteDeleteForm from 'src/components/Forms/Notes/Delete';
import OnboardJoinOrganisation from 'src/components/Forms/Onboarding/Join';
import OrganisationCreate from 'src/components/Forms/Organisation/Create';
import OrganisationDeleteForm from 'src/components/Forms/Organisation/Delete';
import OrganisationEditForm from 'src/components/Forms/Organisation/Edit';
import OrganisationLeaveForm from 'src/components/Forms/Organisation/Leave';
import ProfileDeleteForm from 'src/components/Forms/Profile/Delete';
import StepCreateForm from 'src/components/Forms/Step/Create';
import StepDeleteForm from 'src/components/Forms/Step/Delete';
import StepEditForm from 'src/components/Forms/Step/Edit';
import TaskCreateForm from 'src/components/Forms/Tasks/Create';
import TaskEditForm from 'src/components/Forms/Tasks/Edit';
import TermsheetCreateForm from 'src/components/Forms/Termsheets/Create';
import TermsheetDeleteForm from 'src/components/Forms/Termsheets/Delete';
import TermsheetEditForm from 'src/components/Forms/Termsheets/Edit';
import UserDeleteForm from 'src/components/Forms/User/Delete';
import UserInviteForm from 'src/components/Forms/User/Invite';
import UserRevokeForm from 'src/components/Forms/User/Revoke';
import UserRoleForm from 'src/components/Forms/User/Role';
import UpgradeActivities from 'src/components/Upgrade/Activities';
import UpgradeDeals from 'src/components/Upgrade/Deals';
import UpgradeInvestors from 'src/components/Upgrade/Investors';
import UpgradeUsers from 'src/components/Upgrade/Users';
import { AppContext } from 'src/contexts/AppContext';
import TourHelp from '../Tour/Help';
import TourWelcome from '../Tour/Welcome';

const Modal: React.FC = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { state, dispatch } = React.useContext(AppContext);
  const { ui } = state;
  const { modal } = ui;

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...modal,
        open: false,
        close: false,
      },
    });
  };

  const handleComponent = React.useCallback(() => {
    switch (modal.component) {
      /* Contact Forms */
      case 'CONTACT_CREATE':
        return <ContactCreateForm />;
      case 'CONTACT_EDIT':
        return <ContactEditForm />;
      case 'CONTACT_DELETE':
        return <ContactDeleteForm />;

      /* User Forms */
      case 'USER_INVITE':
        return <UserInviteForm />;
      case 'USER_ROLE':
        return <UserRoleForm />;
      case 'USER_DELETE':
        return <UserDeleteForm />;
      case 'USER_REVOKE':
        return <UserRevokeForm />;

      /* Organisation Forms */
      case 'ORGANISATION_EDIT':
        return <OrganisationEditForm />;
      case 'ORGANISATION_DELETE':
        return <OrganisationDeleteForm />;
      case 'ORGANISATION_INVITES':
        return <OnboardJoinOrganisation />;
      case 'ORGANISATION_CREATE':
        return <OrganisationCreate />;
      case 'ORGANISATION_LEAVE':
        return <OrganisationLeaveForm />;

      /* Investor Forms */
      case 'INVESTOR_CREATE':
        return <InvestorCreateForm />;
      case 'INVESTOR_DELETE':
        return <InvestorDeleteForm />;
      case 'INVESTOR_EDIT':
        return <InvestorDetailsForm />;
      case 'INVESTOR_LEGAL':
        return <InvestorLegalForm />;
      case 'INVESTOR_SIGNATORY':
        return <InvestorSignatoryForm />;

      /* DEALS */
      case 'DEAL_CREATE':
        return <DealCreateForm />;
      case 'DEAL_EDIT':
        return <DealEditForm />;
      case 'DEAL_DELETE':
        return <DealDeleteForm />;

      /* DEAL ASSIGNMENT */
      case 'DEAL_ASSIGN_CONTACTS':
        return <ContactsAssignForm />;
      case 'DEAL_ASSIGN_INVESTORS':
        return <InvestorsAssignForm />;
      case 'DEAL_UNASSIGN_INVESTOR':
        return <InvestorsUnassignForm />;
      case 'DEAL_REASSIGN_INVESTOR':
        return <InvestorReassignForm />;

      /* TERMSHEETS */
      case 'DEAL_TERMSHEET_EDIT':
        return <TermsheetEditForm />;
      case 'DEAL_TERMSHEET_DELETE':
        return <TermsheetDeleteForm />;
      case 'DEAL_TERMSHEET_CREATE':
        return <TermsheetCreateForm />;

      /* PROCESS STEPS */
      case 'PROCESS_STEP_RENAME':
        return <StepEditForm />;
      case 'PROCESS_STEP_DELETE':
        return <StepDeleteForm />;
      case 'PROCESS_STEP_CREATE':
        return <StepCreateForm />;

      /* TASKS */
      case 'TASK_CREATE':
        return <TaskCreateForm />;
      case 'TASK_EDIT':
        return <TaskEditForm />;

      /* PROFILE */
      case 'PROFILE_DELETE':
        return <ProfileDeleteForm />;

      /* NOTES */
      case 'NOTE_DELETE':
        return <NoteDeleteForm />;

      /* ACTIVITIES */
      case 'EVENT_LOG':
        return <EventLog />;
      case 'EVENT_EDIT':
        return <EventEdit />;
      case 'EVENT_DELETE':
        return <EventDelete />;

      /* UPGRADE */
      case 'UPGRADE_USERS':
        return <UpgradeUsers />;
      case 'UPGRADE_DEALS':
        return <UpgradeDeals />;
      case 'UPGRADE_INVESTORS':
        return <UpgradeInvestors />;
      case 'UPGRADE_ACTIVITIES':
        return <UpgradeActivities />;
      case 'BILLING_DETAILS':
        return <EditBillingDetails />;
      case 'SUBSCRIPTION_CANCEL':
        return <SubscriptionCancelForm />;
      case 'ADD_PAYMENT_METHOD':
        return <AddPaymentMethod />;
      case 'BILLING_CYCLE':
        return <BillingUnits />;

      /* Tour */
      case 'TOUR_WELCOME':
        return <TourWelcome />;
      case 'TOUR_HELP':
        return <TourHelp />;

      default:
        return null;
    }
  }, [modal.component]);

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="dialog-edit"
      open={modal.open}
      fullWidth={true}
      maxWidth="sm"
      fullScreen={fullScreen}
      PaperProps={{ elevation: 5 }}
      disableBackdropClick={false}
      scroll="body"
    >
      {!modal.centered ? (
        <DialogTitle id="dialog-edit" disableTypography>
          <Typography variant="h2">{modal.title}</Typography>
          {modal.close ? (
            <IconButton style={{ position: 'absolute', top: 16, right: 16 }} onClick={() => closeModal()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      ) : (
        <Box pt={4} />
      )}
      <DialogContent>{handleComponent()}</DialogContent>
    </Dialog>
  );
};

export default Modal;
