import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Link,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { firebaseToDate } from 'src/helpers/firebaseDate';
import { isoToFlag } from 'src/helpers/isoToFlag';
import colors from 'src/themes/colors';
import { Email, Fax, InvestorsAdd, Telephone, Website } from 'src/themes/icons';

const useStyles = makeStyles((theme: Theme) => ({
  right: {
    '&::before': {
      flex: 0,
      padding: 0,
      display: 'none',
    },
  },
  content: {
    paddingRight: 0,
    paddingTop: 3,
    marginBottom: theme.spacing(4),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  svg: {
    width: 24,
    height: 24,
    fill: colors.grey[200],
    '& > *': {
      fill: colors.grey[200],
    },
  },
  inlineSvg: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& svg': {
      width: 14,
      height: 14,
      fill: colors.grey[200],
      marginRight: theme.spacing(1),
      '& > *': {
        fill: colors.grey[200],
      },
    },
  },
  overline: {
    lineHeight: 1,
    color: colors.grey[400],
    marginRight: theme.spacing(1),
  },
  date: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    display: 'block',
  },
  dot: {
    backgroundColor: colors.grey[100],
  },
  line: {
    backgroundColor: `${colors.grey[100]}50`,
  },
  meta: {
    fontSize: 12,
  },
  accordion: {
    '&::before': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummary: {
    padding: theme.spacing(2, 3),
  },
  accordionContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  rounded: {
    borderRadius: theme.spacing(1),
    '&:last-child': {
      borderRadius: theme.spacing(1),
    },
  },
  details: {
    padding: theme.spacing(0, 3, 3),
    display: 'block',
  },
  json: {
    background: colors.grey[1],
    border: `1px dotted ${colors.grey[50]}`,
    padding: theme.spacing(1),
    color: colors.grey[400],
    fontFamily: 'Lucida Console, Courier, monospace',
    wordBreak: 'break-all',
  },
  iconLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  svgInvestor: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    fill: theme.palette.grey[200],
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const ExampleSystemItem: React.FC = () => {
  const classes = useStyles();
  const { state } = React.useContext(AppContext);
  const { user } = state;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TimelineItem
      classes={{
        missingOppositeContent: classes.right,
      }}
    >
      <TimelineSeparator>
        <TimelineDot className={classes.dot} />
        <TimelineConnector className={classes.line} />
      </TimelineSeparator>

      <TimelineContent className={classes.content}>
        <Typography variant="overline" className={classes.date}>
          {firebaseToDate(firebase.firestore.Timestamp.now())}
        </Typography>

        <Accordion classes={{ root: classes.accordion, rounded: classes.rounded }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{ root: classes.accordionSummary, content: classes.accordionContent }}
          >
            <Box className={classes.titleRow}>
              <Box display="flex" flexDirection="row" alignItems="center" mb={mobile ? 1 : 0}>
                <Box width={48} display="flex" alignItems="center">
                  <InvestorsAdd className={classes.svg} />
                </Box>
                <Box mr={2}>
                  <Chip label="Example" />
                </Box>
              </Box>

              <Box flex={1}>
                <Box>
                  <Typography variant="body1" className={classes.capitalize}>
                    <Tooltip title="View Sequoia Capital">
                      <Link color="secondary">Sequoia Capital</Link>
                    </Tooltip>{' '}
                    added by {user.first_name} {user.last_name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Divider light={true} />
            <Box mb={2}></Box>
            <Typography variant="h4">Sequoia Capital</Typography>

            <Typography variant="body2" className={classes.iconLink}>
              <span style={{ marginRight: '4px' }}>{isoToFlag('us')}</span>
              <span>Menlo Park, 2800 Sand Hill Road, Suite 101, Menlo Park, CA 94025</span>
            </Typography>

            <Typography variant="body2">
              <Link color="secondary" href={`tel:+16508543927`} className={classes.iconLink}>
                <Telephone className={classes.svgInvestor} /> + 1 650 854 3927
              </Link>
            </Typography>

            <Typography variant="body2" className={classes.iconLink}>
              <Fax className={classes.svgInvestor} /> + 1 650 854 3928
            </Typography>

            <Typography variant="body2">
              <Link color="secondary" href={`mailto:info@sequoiacap.com`} target="_blank" className={classes.iconLink}>
                <Email className={classes.svgInvestor} /> info@sequoiacap.com
              </Link>
            </Typography>

            <Typography variant="body2">
              <Link color="secondary" href="https://www.sequoiacap.com/" target="_blank" className={classes.iconLink}>
                <Website className={classes.svgInvestor} /> https://www.sequoiacap.com/
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ExampleSystemItem;
