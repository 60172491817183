import { Button, Divider, Grid, LinearProgress, MenuItem, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AutocompleteContactsbyInvestor from 'src/components/Inputs/Autocomplete/ContactsbyInvestor';
import AutocompleteDeals from 'src/components/Inputs/Autocomplete/Deals';
import AutocompleteInvestorsByLetter from 'src/components/Inputs/Autocomplete/InvestorsbyLetter';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import SlateEditor from 'src/components/Notes/Editor';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import { NotesFirebase } from 'src/services/notes';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const NoteCreateForm: React.FC = () => {
  const history = useHistory();
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, user, ui } = state;

  return (
    <Formik
      initialValues={{
        id: '',
        org_id: organisation.id,

        title: '',
        contents: [],

        parent_type: ui.notes.parent_type ? ui.notes.parent_type : 'organisation',
        parent_id: ui.notes.parent_id ? ui.notes.parent_id : '',
        child_id: ui.notes.child_id ? ui.notes.child_id : '',

        created_by: user.id,
        creator_name: `${user.first_name} ${user.last_name}`,

        updated_by: '',
        updator_name: '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const note = {
          ...values,
          title: trimString(values.title),
          contents: JSON.stringify(values.contents),
        };

        try {
          const result = await NotesFirebase.create(note);
          dispatch({ type: 'NOTE_CREATE', payload: result });

          actions.setSubmitting(false);
          setToast({
            message: `Note Created`,
            type: 'success',
          });
          history.goBack();
        } catch (error) {
          actions.setSubmitting(false);
          const err = error as Fluit.firestore.Error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('A title is required for the note'),
        parent_id: Yup.string().when('parent_type', {
          is: 'organisation',
          then: Yup.string(),
          otherwise: Yup.string().required('You need to attach the note to a deal, investor or contact'),
        }),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values, setFieldValue, errors, touched, submitCount } = props;

        const handleType = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
          setFieldValue('parent_type', type);
          setFieldValue('parent_id', '');
        };

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  select
                  name="parent_type"
                  label="Attached to"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  value={values.parent_type}
                  onChange={handleType}
                  variant="outlined"
                >
                  <MenuItem value="organisation">
                    {organisation.name}{' '}
                    <small>
                      | <em>Organisation</em>
                    </small>
                  </MenuItem>
                  <MenuItem value="deal">Deal</MenuItem>
                  <MenuItem value="investor">Investor</MenuItem>
                  <MenuItem value="contact">Contact</MenuItem>
                </Field>
              </Grid>

              {values.parent_type === 'deal' && (
                <Grid item xs={12} sm={4} md={6} lg={4} xl={3}>
                  <AutocompleteDeals
                    setFieldValue={setFieldValue}
                    name="parent_id"
                    label="Attached Deal"
                    value={values.parent_id}
                    error={errors.parent_id}
                    touched={touched.parent_id}
                  />
                </Grid>
              )}

              {values.parent_type === 'investor' && (
                <Grid item xs={12} sm={4} md={6} lg={4} xl={3}>
                  <AutocompleteInvestorsByLetter
                    key={values.parent_type}
                    name="parent_id"
                    setFieldValue={setFieldValue}
                    label="Attached Investor"
                    error={errors.parent_id}
                    touched={touched.parent_id}
                    value={values.parent_id}
                  />
                </Grid>
              )}

              {values.parent_type === 'contact' ? (
                <Grid item xs={12} sm={4} md={6} lg={4} xl={3}>
                  <AutocompleteContactsbyInvestor
                    key={values.parent_type}
                    name="parent_id"
                    setFieldValue={setFieldValue}
                    label="Attached Contact"
                    error={errors.parent_id}
                    touched={touched.parent_id}
                    value={values.child_id}
                    childName="child_id"
                  />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Field component={FormikTextField} fullWidth={true} name="title" label="Title" variant="outlined" />
              </Grid>

              <Grid item xs={12}>
                <SlateEditor setFieldValue={setFieldValue} val={values.contents} />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Create
                </Button>
                <Button variant="text" size="large" style={{ marginLeft: '16px' }} onClick={() => history.goBack()}>
                  Cancel
                </Button>
              </Grid>
              {_.some(errors) && submitCount > 0 ? (
                <Grid item xs={12}>
                  <Paper>
                    <Alert severity="error">You need to fill in all fields before you can create a note.</Alert>
                  </Paper>
                </Grid>
              ) : null}
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default NoteCreateForm;
