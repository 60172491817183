import { makeStyles, Theme } from '@material-ui/core';
import colors from 'src/themes/colors';

export const styles_toggleView = makeStyles((theme: Theme) => ({
  buttonToggle: {
    '&$buttonSelected': {
      borderColor: colors.blue[400],
      backgroundColor: colors.common.white,
      color: colors.blue[400],
      '&:hover': {
        backgroundColor: colors.common.white,
      },
    },
    padding: theme.spacing(1, 1.5),
  },
  grouped: {
    background: 'none',
    border: `1px solid ${colors.grey[200]}`,
    textTransform: 'capitalize',
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  buttonSelected: {},
}));
