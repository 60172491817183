import { Box, Container, makeStyles, Theme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';
import Footer from 'src/components/Layout/Footer';
import HeaderSite from 'src/components/Layout/HeaderSite';
import Placeholder from 'src/components/Loaders/FluitPlaceHolder';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto 1',
  },
  container: {
    marginTop: '80px',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(12),
      alignItems: 'flex-start',
    },
  },
}));

const LayoutVerify: React.FC<RouteProps> = props => {
  const location = useLocation();
  const classes = useStyles();
  const { state } = React.useContext(AppContext);
  const { init, user, organisations } = state;
  const { from }: any = location.state || { from: { pathname: ROUTES.DEALS } };
  const { children } = props;

  if (init.initalized) {
    if (init.user === false) {
      return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location } }} />;
    }
    if (_.some(organisations) && user.email_verified && user.is_onboarded) {
      return <Redirect to={from} />;
    }
    if (user.email_verified && (!user.is_onboarded || !_.some(organisations))) {
      return <Redirect to={ROUTES.ONBOARDING} />;
    } else {
      return (
        <Box className={classes.box}>
          <HeaderSite />
          <Container maxWidth="md" className={classes.container}>
            {children as any}
          </Container>
          <Container maxWidth="md">
            <Footer md={true} />
          </Container>
        </Box>
      );
    }
  }

  if (init.initalized === false && init.user === false) {
    return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location } }} />;
  }
  return <Placeholder />;
};

export default LayoutVerify;
