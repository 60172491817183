import { Box, Button, Grid, LinearProgress, MenuItem, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import Lottie from 'lottie-react-web';
import React from 'react';
import animationData from 'src/assets/animations/help.json';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { OrganisationFirebase } from 'src/services/organisations';
import * as Yup from 'yup';

const Support: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state } = React.useContext(AppContext);
  const { user, organisation } = state;
  const [submitted, setSubmitted] = React.useState(false);

  const topics = [
    `I'm experiencing a technical issue`,
    'I have a question about using Fluit',
    'I have a billing question',
    'I want to suggest a feature',
    'I need help with something else',
  ];

  const initialValues = {
    topic: '',
    subject: '',
    description: '',
    user_id: user.id,
    user_name: `${user.first_name} ${user.last_name}`,
    user_email: user.email,
    organisation_name: organisation.name,
    organisation_id: organisation.id,
    navigator: window.navigator,
    screen: window.screen,
    localStorage: localStorage,
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h1">Support</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box hidden={submitted}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              try {
                OrganisationFirebase.getSupport(values);
                setToast({
                  message: 'Support Request Received',
                  type: 'success',
                });
                setSubmitted(true);
                actions.setSubmitting(false);
                actions.resetForm();
              } catch (err) {
                actions.setSubmitting(false);
                setToast({
                  message: err,
                  type: 'error',
                });
              }
            }}
            validationSchema={Yup.object().shape({
              topic: Yup.string().required('Select a topic to get help'),
              subject: Yup.string().required('Enter a subject so we can help you'),
              description: Yup.string().required('Enter details about your question or issue'),
            })}
          >
            {props => {
              const { isSubmitting, handleSubmit } = props;
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Box mb={2}>
                        <Field
                          fullWidth={true}
                          component={FormikTextField}
                          select
                          name="topic"
                          label="Topic"
                          type="text"
                          variant="outlined"
                          placeholder="Please Select"
                        >
                          {_.map(topics, topic => (
                            <MenuItem key={_.uniqueId()} value={topic}>
                              {topic}
                            </MenuItem>
                          ))}
                        </Field>
                      </Box>
                      <Box my={2}>
                        <Field
                          component={FormikTextField}
                          autoComplete="off"
                          name="subject"
                          label="Subject"
                          variant="outlined"
                          fullWidth={true}
                        />
                      </Box>
                      <Box my={2}>
                        <Field
                          component={FormikTextField}
                          autoComplete="off"
                          name="description"
                          label="Description"
                          variant="outlined"
                          InputProps={{
                            multiline: true,
                            rows: 4,
                          }}
                          fullWidth={true}
                        />
                      </Box>
                      {/* <Box mt={2}>
                      <FileUpload />
                    </Box> */}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box display="flex" alignItems="flex-end">
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid meet',
                            },
                          }}
                          height="100%"
                          width="300px"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                        Send to Support
                      </Button>
                    </Grid>
                    {isSubmitting && (
                      <Grid item xs={12}>
                        <LinearProgress variant="query" />
                      </Grid>
                    )}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </Box>

        <Box hidden={!submitted}>
          <Paper>
            <Alert severity="success">
              We've received your support request and will respond by email within 2 business days.
            </Alert>
          </Paper>
          <Box mt={3}>
            <Button variant="outlined" color="secondary" onClick={() => setSubmitted(false)}>
              Submit another ticket
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Support;
