import { Button, Divider, Grid, LinearProgress, MenuItem } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AutocompleteContactsbyInvestor from 'src/components/Inputs/Autocomplete/ContactsbyInvestor';
import AutocompleteDeals from 'src/components/Inputs/Autocomplete/Deals';
import AutocompleteInvestorsByLetter from 'src/components/Inputs/Autocomplete/InvestorsbyLetter';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import SlateEditor from 'src/components/Notes/Editor';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import * as ROUTES from 'src/routes/routes';
import { NotesFirebase } from 'src/services/notes';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

interface Props {
  note: Fluit.notes.Note;
}

const NoteEditForm: React.FC<Props> = ({ note }) => {
  const history = useHistory();
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation, user } = state;

  return (
    <Formik
      initialValues={{
        id: note.id,
        org_id: note.org_id,

        title: note.title,
        contents: JSON.parse(note.contents),

        parent_type: note.parent_type,
        parent_id: note.parent_id,
        child_id: note.child_id,

        created_by: note.created_by,
        creator_name: note.creator_name,

        updated_by: user.id,
        updator_name: `${user.first_name} ${user.last_name}`,
        created_at: note.created_at,
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);

        try {
          const note = {
            ...values,
            title: trimString(values.title),
            contents: JSON.stringify(values.contents),
          };
          const result = await NotesFirebase.update(note);
          actions.setSubmitting(false);
          setToast({
            message: `Note Created`,
            type: 'success',
          });
          dispatch({ type: 'NOTE_UPDATE', payload: result });
          history.push(`${ROUTES.NOTES}/${note.id}`);
        } catch (error) {
          actions.setSubmitting(false);
          const err = error as Fluit.firestore.Error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('A title is required for the note'),
        parent_id: Yup.string().when('parent_type', {
          is: 'organisation',
          then: Yup.string(),
          otherwise: Yup.string().required('You need to attach the note to a deal, investor or contact'),
        }),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values, setFieldValue, errors, touched } = props;

        const handleType = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
          setFieldValue('parent_type', type);
          setFieldValue('parent_id', '');
        };

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  select
                  name="parent_type"
                  label="Attached to"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  value={values.parent_type}
                  onChange={handleType}
                  variant="outlined"
                >
                  <MenuItem value="organisation">
                    {organisation.name}{' '}
                    <small>
                      | <em>Organisation</em>
                    </small>
                  </MenuItem>
                  <MenuItem value="deal">Deal</MenuItem>
                  <MenuItem value="investor">Investor</MenuItem>
                  <MenuItem value="contact">Contact</MenuItem>
                </Field>
              </Grid>

              {values.parent_type === 'deal' && (
                <Grid item xs={12} sm={4} md={6} lg={4} xl={3}>
                  <AutocompleteDeals
                    setFieldValue={setFieldValue}
                    name="parent_id"
                    label="Attached Deal"
                    value={values.parent_id}
                    error={errors.parent_id}
                    touched={touched.parent_id}
                  />
                </Grid>
              )}

              {values.parent_type === 'investor' && (
                <Grid item xs={12} sm={4} md={6} lg={4} xl={3}>
                  <AutocompleteInvestorsByLetter
                    key={values.parent_type}
                    name="parent_id"
                    setFieldValue={setFieldValue}
                    label="Attached Investor"
                    error={errors.parent_id}
                    touched={touched.parent_id}
                    value={values.parent_id}
                  />
                </Grid>
              )}

              {values.parent_type === 'contact' && (
                <Grid item xs={12} sm={4} md={6} lg={4} xl={3}>
                  <AutocompleteContactsbyInvestor
                    key={values.parent_type}
                    name="parent_id"
                    setFieldValue={setFieldValue}
                    label="Attached Contact"
                    error={errors.parent_id}
                    touched={touched.parent_id}
                    value={values.child_id}
                    childName="child_id"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Field component={FormikTextField} fullWidth={true} name="title" label="Title" variant="outlined" />
              </Grid>

              <Grid item xs={12}>
                <SlateEditor setFieldValue={setFieldValue} val={values.contents} />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button
                  variant="text"
                  size="large"
                  style={{ marginLeft: '16px' }}
                  onClick={() => history.push(`${ROUTES.NOTES}/${note.id}`)}
                >
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default NoteEditForm;
