import { Box, Button, Chip, Divider, Link, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import { formatDate } from 'src/helpers/formatDate';
import * as ROUTES from 'src/routes/routes';
import { BillingFirebase } from 'src/services/billing';
import { styles_subscription } from 'src/styles/billing';
import { Close, Meeting, UsersAdd } from 'src/themes/icons';
import Stripe from 'stripe';

const BillingSubscription: React.FC = () => {
  const classes = styles_subscription();
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { billing, subscriptions, organisation } = state;

  const [loading, setLoading] = React.useState(true);
  const [mapped, setMapped] = React.useState(false);

  const getInvoice = React.useCallback(async () => {
    try {
      const invoice = await BillingFirebase.upcomingInvoice({ customer: billing.stripe_customer_id });
      dispatch({ type: 'BILLING_INVOICE_LATEST', payload: invoice });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [billing.stripe_customer_id, dispatch]);

  React.useEffect(() => {
    if (mapped) {
      return undefined;
    }

    _.map(subscriptions, (subscription, index) => {
      if (subscription.status !== 'canceled') {
        getInvoice();
      }
      if (index + 1 === subscriptions.length) {
        setLoading(false);
        setMapped(true);
      }
    });
  }, [getInvoice, subscriptions, mapped]);

  /*
  ? Modal Handlers
  */

  const manageQuantityCycle = (
    subscriptionId: string,
    items: Stripe.subscriptionItems.ISubscriptionItem[],
    title: string
  ) => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: title,
        component: 'BILLING_CYCLE',
        subscriptionId: subscriptionId,
        subscriptionItems: items,
      },
    });
  };

  const cancelSubscription = (subscriptionId: string) => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: 'Cancel Subscription',
        component: 'SUBSCRIPTION_CANCEL',
        subscriptionId: subscriptionId,
      },
    });
  };

  const status = (subscription: Stripe.subscriptions.ISubscription) => {
    const status = subscription.status;
    const cancel = subscription.cancel_at_period_end;

    if (status === 'active' && !cancel) {
      return 'Active';
    }
    if (status === 'active' && cancel) {
      return `Cancels on ${formatDate(subscription.current_period_end, true)}`;
    }
    if (status === 'canceled') {
      return 'Cancelled';
    }
    return status;
  };

  const reActiviateSubscription = async (subscription: Stripe.subscriptions.ISubscription) => {
    try {
      const resp = await BillingFirebase.reActivateSubscription(subscription);
      dispatch({ type: 'SUBSCRIPTION_UPDATE', payload: resp });
    } catch (error) {
      setToast({
        message: error,
        type: 'error',
      });
    }
  };

  if (loading) {
    return (
      <Paper>
        <Box className={classes.box} justifyContent="center">
          <GenericLoader height="160px" variant="none" />
        </Box>
      </Paper>
    );
  }

  return (
    <>
      {_.map(_.orderBy(subscriptions, ['status'], ['asc']), (subscription, index) => {
        const items = subscription.items.data;
        const count = index + 1;
        const cancel = subscription.cancel_at_period_end;

        return (
          <Paper key={subscription.id} style={{ marginBottom: count < subscriptions.length ? 24 : 0 }}>
            <Box className={classes.box}>
              <Box className={classes.content}>
                <Chip
                  label={status(subscription)}
                  size="small"
                  color={subscription.status === 'active' && !cancel ? 'primary' : 'default'}
                  className={classes.chip}
                />
                <Typography>
                  {organisation.name} is using <strong>{organisation.limits.members.count}</strong> of{' '}
                  <strong>{subscription.items.data[0].quantity}</strong> available users and{' '}
                  <strong>{organisation.limits.deals.count}</strong> of{' '}
                  <strong>{subscription.items.data[1].quantity}</strong> available deals
                </Typography>

                {billing.latest_invoice && !cancel && subscription.status !== 'canceled' ? (
                  <Box py={2}>
                    <Divider light={true} />
                  </Box>
                ) : null}

                {billing.latest_invoice && !cancel && subscription.status !== 'canceled' ? (
                  <Typography>
                    Next payment of <strong>{formatCurrency(billing.latest_invoice.amount_due / 100)}</strong> due on{' '}
                    <strong>{formatDate(billing.latest_invoice.period_end, true)}</strong>
                    {billing.latest_invoice.invoice_pdf && (
                      <Link href={billing.latest_invoice.invoice_pdf} color="secondary">
                        Download Invoice
                      </Link>
                    )}
                  </Typography>
                ) : null}

                {cancel && (
                  <Typography>
                    Your plan is set to cancel on <strong>{formatDate(subscription.cancel_at, true)}</strong>
                  </Typography>
                )}

                {subscription.status === 'canceled' && (
                  <Typography>
                    Your plan ended on <strong>{formatDate(subscription.canceled_at, true)}</strong>
                  </Typography>
                )}
              </Box>
              {subscription.status !== 'canceled' && !cancel ? (
                <Box className={classes.actions}>
                  <Button
                    className={classes.button}
                    onClick={() => manageQuantityCycle(subscription.id, items, 'Add or Remove User Licenses')}
                    color="secondary"
                  >
                    <Box className={classes.button_icon_container}>
                      <UsersAdd className={classes.button_icon} />
                    </Box>{' '}
                    Add or Remove User Licenses
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => manageQuantityCycle(subscription.id, items, 'Change Billing Cycle')}
                    color="secondary"
                  >
                    <Box className={classes.button_icon_container}>
                      <Meeting className={classes.button_icon} />
                    </Box>{' '}
                    Change Billing Cycle
                  </Button>
                  <Button
                    className={clsx(classes.button_delete, classes.button)}
                    onClick={() => cancelSubscription(subscription.id)}
                    color="secondary"
                  >
                    <Box className={classes.button_icon_container}>
                      <Close className={classes.button_icon} />
                    </Box>{' '}
                    Cancel Subscription
                  </Button>
                </Box>
              ) : null}

              {subscription.status === 'active' && cancel && (
                <Box className={classes.actions}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => reActiviateSubscription(subscription)}
                    color="primary"
                  >
                    Activate Subscription
                  </Button>
                </Box>
              )}

              {subscription.status === 'canceled' && (
                <Box className={classes.actions}>
                  <Button className={classes.button} component={NavLink} to={ROUTES.BUY} color="secondary">
                    Purchase Again
                  </Button>
                </Box>
              )}
            </Box>
          </Paper>
        );
      })}
    </>
  );
};

export default BillingSubscription;
