import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import * as React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { ContactFirebase } from 'src/services/contacts';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const ContactDeleteForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { contacts } = state;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const contact = _.find(contacts, { id: String(state.ui.modal.contact_id) });

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const removeContact = React.useCallback(async () => {
    if (contact) {
      setLoading(true);
      try {
        await ContactFirebase.delete(contact);
        setLoading(false);
        setToast({
          message: `Deleted ${contact?.first_name} ${contact?.last_name}`,
          type: 'info',
        });
        closeModal();
      } catch (error) {
        setLoading(false);
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    }
  }, [setToast, setLoading, contact, closeModal]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">
          By removing {contact?.first_name} {contact?.last_name} you will remove their contact reference against all
          activity, tasks and notes.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => removeContact()}
          disabled={loading ? true : false}
        >
          Remove
        </Button>
        <Button
          variant="text"
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
          style={{ marginLeft: '16px' }}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default ContactDeleteForm;
