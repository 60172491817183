import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const InvitesReducer = (state: Fluit.organisations.Organisation[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'INVITES_LIST':
      return action.payload;
    case 'INVITE_ACCEPT':
      return _.filter(state, organisation => organisation.id !== action.payload.id);
    case 'INVITE_DECLINE':
      return _.filter(state, organisation => organisation.id !== action.payload.id);
    case 'INVITE_FLUSH':
      return [];
    default:
      return { ...state };
  }
};
