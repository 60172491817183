import { Box, createStyles, makeStyles, Paper, Theme, Tooltip } from '@material-ui/core';
import isHotkey from 'is-hotkey';
import isUrl from 'is-url';
import _ from 'lodash';
import React from 'react';
import { createEditor, Editor, Range, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { initialValue } from 'src/constants/editor';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';
import SlateToolbar from './Toolbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.8,

      '& h1': {
        fontWeight: 600,
        fontSize: 34,
        lineHeight: 2,
        color: colors.navy[900],
      },
      '& h2': {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: 2,
        color: colors.navy[900],
      },
      '& h3': {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: 2,
        color: colors.navy[900],
      },
      '& blockquote': {
        borderLeft: `4px solid ${colors.grey[100]}`,
        margin: theme.spacing(4, 0),
        padding: theme.spacing(2, 4, 2, 2),
        borderRadius: theme.spacing(0, 1, 1, 0),
        fontSize: 18,
        lineHeight: 1.5,
        color: colors.grey[600],
        background: colors.grey[1],
        fontWeight: 500,
      },
      '& ul, & ol': {
        margin: theme.spacing(4, 0),
      },
      '& a': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        fontWeight: 600,
      },
    },
  })
);

const LEAFKEYS: any = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const CONTROLS: any = {
  'mod+z': 'undo',
  'mod+y': 'redo',
  'shift+enter': 'shift+enter',
};

const TOGGLE_HEADINGS: any = {
  'mod+alt+0': 'paragraph',
  'mod+alt+1': 'heading-one',
  'mod+alt+2': 'heading-two',
  'mod+alt+3': 'heading-three',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

interface Props {
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  val: [] | never[];
  display?: boolean;
}

const SlateEditor: React.FC<Props> = ({ setFieldValue, val, display }) => {
  const [value, setValue] = React.useState(_.some(val) ? val : initialValue);
  const renderElement = React.useCallback(props => <Element {...props} />, []);
  const renderLeaf = React.useCallback(props => <Leaf {...props} />, []);
  const editor = React.useMemo(() => withHistory(withReact(withLinks(createEditor()))), []);
  const [eSelection, seteSelection] = React.useState<Range>();
  const classes = useStyles();

  const handleChange = (opts: any) => {
    setValue(opts);
    if (setFieldValue) {
      setFieldValue('contents', opts);
    }
    if (editor.selection) {
      seteSelection(editor.selection);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent | KeyboardEvent) => {
    for (const hotkey in LEAFKEYS) {
      if (isHotkey(hotkey, event as KeyboardEvent)) {
        event.preventDefault();
        const mark = LEAFKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
    for (const hotkey in CONTROLS) {
      if (isHotkey(hotkey, event as KeyboardEvent)) {
        event.preventDefault();
        if (hotkey === 'mod+z') {
          editor.undo();
        }
        if (hotkey === 'mod+y') {
          editor.redo();
        }
        if (hotkey === 'shift+enter') {
          editor.insertText('\n');
        }
      }
    }
    for (const hotkey in TOGGLE_HEADINGS) {
      if (isHotkey(hotkey, event as KeyboardEvent)) {
        event.preventDefault();
        const block = TOGGLE_HEADINGS[hotkey];
        toggleBlock(editor, block);
      }
    }
  };

  const insertLink = (editor: Editor, url: string, text: string) => {
    const link = {
      type: 'link',
      url,
      children: [{ text: text }],
    };
    Transforms.insertNodes(editor, link, { at: eSelection });
  };

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <Paper>
        <SlateToolbar
          editor={editor}
          activeBlock={isBlockActive}
          activeMark={isMarkActive}
          toggleBlock={toggleBlock}
          toggleMark={toggleMark}
          insertLink={insertLink}
        />
        <Box p={3}>
          <Editable
            onKeyDown={handleKeyDown}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            spellCheck
            className={classes.editor}
            readOnly={display ? true : false}
          />
        </Box>
      </Paper>
    </Slate>
  );
};

const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type as string),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const withLinks = (editor: Editor) => {
  const { insertText, isInline } = editor;
  const { insertData }: any = editor;
  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' });
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
};

const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const isBlockActive = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }: Fluit.notes.Element) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return (
        <Tooltip title={element.url}>
          <a {...attributes} href={element.url} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        </Tooltip>
      );
    case 'br':
      return <br {...attributes} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: Fluit.notes.Leaf) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default SlateEditor;
