import { Box, Button, Divider, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import numeral from 'numeral';
import React from 'react';
import BillingButton from 'src/components/Checkout/BillingButton';
import { AppContext } from 'src/contexts/AppContext';
import { CheckoutContext } from 'src/contexts/CheckoutContext';
import { styles_checkoutSummary } from 'src/styles/CheckoutStyles';
import Fluit from 'src/types/Fluit';

const CheckoutSummary: React.FC = () => {
  const { state } = React.useContext(AppContext);
  const { checkout, setCheckout } = React.useContext(CheckoutContext);
  const { quantity, interval, error, products, step, loading } = checkout;
  const [totals, setTotals] = React.useState<Fluit.checkout.Totals>({
    gross: 0,
    vat: 0,
    savings: 0,
    total: 0,
  });

  const classes = styles_checkoutSummary();

  let customer_country = checkout.customer.address.country;
  if (customer_country === '') {
    customer_country = state.organisation.location.iso;
  }
  const vat_rate = customer_country === 'gb' ? 0.2 : 0;

  React.useEffect(() => {
    const month =
      (quantity.deals - 1) * products.deals.fundraise.month.price +
      quantity.users * products.users.standard.month.price;
    const year =
      (quantity.deals - 1) * products.deals.fundraise.year.price + quantity.users * products.users.standard.year.price;
    if (interval === 'month') {
      setTotals({
        gross: month,
        vat: month * vat_rate,
        savings: 0,
        total: month * (vat_rate + 1),
      });
    } else if (interval === 'year') {
      setTotals({
        gross: year,
        vat: year * vat_rate,
        savings: month * 12 - year,
        total: year * (vat_rate + 1),
      });
    }
  }, [quantity, interval, products, vat_rate]);

  /* Step Handler */
  const setStep = React.useCallback(
    (newStep: number) => {
      let newLogic = checkout.logic;
      newLogic[step] = true;
      if (step === 0) {
        setCheckout({
          ...checkout,
          totals: totals,
          step: newStep,
          logic: newLogic,
        });
        return;
      }
    },
    [step, checkout, setCheckout, totals]
  );

  /* Display Handlers */
  const handleDisplay = (number: number) => {
    const floated = number.toFixed(2);
    const integer = floated.split('.')[0];
    const decimals = floated.split('.')[1];
    const formatted = numeral(integer).format('0,0');
    return (
      <>
        £{formatted}
        <span className={classes.decimals}>.{decimals}</span>
      </>
    );
  };

  /* Button Handler */

  return (
    <Paper>
      <Box p={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>Users</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" align="right">
              {quantity.users}
            </Typography>
          </Grid>
          {interval === 'year' && (
            <>
              <Grid item xs={6}>
                <Typography>Monthly Price Per User</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" align="right">
                  {handleDisplay(products.users.standard.year.price / 12)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography>
              {interval === 'year' && 'Yearly '}
              Price Per User
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" align="right">
              {interval === 'month'
                ? handleDisplay(products.users.standard.month.price)
                : handleDisplay(products.users.standard.year.price)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider light={true} />
          </Grid>

          <Grid item xs={6}>
            <Typography>Deals</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" align="right">
              {quantity.deals}
            </Typography>
          </Grid>
          {interval === 'year' && (
            <>
              <Grid item xs={6}>
                <Typography>Monthly Price Per Deal</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" align="right">
                  {quantity.deals > 1 ? handleDisplay(products.deals.fundraise.year.price / 12) : handleDisplay(0.0)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography>
              {interval === 'year' && 'Yearly '}
              Price Per Deal
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" align="right">
              {quantity.deals > 1 && interval === 'month' && handleDisplay(products.deals.fundraise.month.price)}
              {quantity.deals > 1 && interval === 'year' && handleDisplay(products.deals.fundraise.year.price)}
              {quantity.deals === 1 && handleDisplay(0.0)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider light={true} />
          </Grid>
          <Grid item xs={6}>
            <Typography>Gross</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" align="right">
              {handleDisplay(totals.gross)}
            </Typography>
          </Grid>
          {totals.vat > 0 && (
            <>
              <Grid item xs={6}>
                <Typography>VAT</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" align="right">
                  {handleDisplay(totals.vat)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider light={true} />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                <strong>Total</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h2" align="right">
              {handleDisplay(totals.total)}
            </Typography>
          </Grid>
          {interval === 'year' && (
            <>
              <Grid item xs={6}>
                <Typography>Yearly Savings</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" align="right">
                  {handleDisplay(totals.savings)}
                </Typography>
              </Grid>
            </>
          )}

          {step === 0 ? (
            <Grid item xs={12}>
              <Button color="primary" size="large" variant="contained" fullWidth onClick={() => setStep(step + 1)}>
                Continue to Billing Details
              </Button>
            </Grid>
          ) : null}

          {step === 1 ? <BillingButton /> : null}

          {step === 2 ? (
            <Grid item xs={12}>
              <Button color="primary" size="large" variant="contained" fullWidth type="submit" disabled={loading}>
                Make Payment
              </Button>
            </Grid>
          ) : null}

          {loading && (
            <Grid item xs={12}>
              <LinearProgress variant="query" />
            </Grid>
          )}

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error.message}</Alert>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default CheckoutSummary;
