import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';

const BillingButton: React.FC = () => {
  const { submitForm, isSubmitting, errors } = useFormikContext();
  const [submitted, setSubmitted] = React.useState(false);
  const handleStep = () => {
    setSubmitted(true);
    submitForm();
  };

  return (
    <>
      <Grid item xs={12}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          onClick={() => handleStep()}
          disabled={isSubmitting}
        >
          Continue to Review &amp; Pay
        </Button>
      </Grid>

      {isSubmitting && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}

      {_.values(errors).length > 0 && submitted ? (
        <Grid item xs={12}>
          <Alert severity="error">You need to fill out all required fields</Alert>
        </Grid>
      ) : null}
    </>
  );
};

export default BillingButton;
