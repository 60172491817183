import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import TermsheetCard from 'src/components/Cards/Termsheet';
import NoData from 'src/components/Empty';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import * as ROUTES from 'src/routes/routes';
import { DealsNone } from 'src/themes/icons';

const InvestorTabTermsheets: React.FC = () => {
  const { investor } = React.useContext(InvestorContext);
  const { state } = React.useContext(AppContext);
  const { deals, termsheets } = state;
  const investorTermsheets = _.filter(termsheets, { investor_id: investor.id });
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const navigateToDeals = () => {
    history.push(ROUTES.DEALS);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Termsheets</Typography>
          </Box>
        </Box>
      </Grid>

      {_.some(investorTermsheets) ? (
        _.map(investorTermsheets, termsheet => {
          let deal = _.find(deals, { id: termsheet.deal_id });
          return (
            <Grid item xs={12} key={termsheet.id}>
              <TermsheetCard termsheet={termsheet} deal={deal} showDeal={true} />
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12}>
          <NoData
            text={`No Termsheets for ${investor.name}`}
            icon={<DealsNone />}
            label="Manage Deals"
            action={navigateToDeals}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InvestorTabTermsheets;
