import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';

const Loading: React.FC = () => {
  const { state } = React.useContext(AppContext);

  return (
    <Backdrop style={{ zIndex: 99999 }} open={state.init.loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
