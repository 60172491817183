import { Button, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as React from 'react';
import AutocompleteCountries from 'src/components/Inputs/Autocomplete/Countries';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { telephoneRegex, urlRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import { InvestorFirebase } from 'src/services/investors';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const InvestorSignatoryForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { investor, setInvestor } = React.useContext(InvestorContext);

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        first_name: investor?.signatory.first_name ? investor?.signatory.first_name : '',
        last_name: investor?.signatory.last_name ? investor?.signatory.last_name : '',
        telephone: investor?.signatory.telephone ? investor?.signatory.telephone : '',
        fax: investor?.signatory.fax ? investor?.signatory.fax : '',
        email: investor?.signatory.email ? investor?.signatory.email : '',
        address: {
          line_1: investor?.signatory.address.line_1 ? investor?.signatory.address.line_1 : '',
          line_2: investor?.signatory.address.line_2 ? investor?.signatory.address.line_2 : '',
          town: investor?.signatory.address.town ? investor?.signatory.address.town : '',
          city: investor?.signatory.address.city ? investor?.signatory.address.city : '',
          postcode: investor?.signatory.address.postcode ? investor?.signatory.address.postcode : '',
          country: {
            name: investor?.signatory.address.country.name ? investor?.signatory.address.country.name : '',
            iso: investor?.signatory.address.country.iso ? investor?.signatory.address.country.iso : '',
            currency: investor?.signatory.address.country.currency ? investor?.signatory.address.country.currency : '',
          },
        },
        witness: investor?.signatory.witness ? investor?.signatory.witness : false,
        notes: investor?.signatory.notes ? investor?.signatory.notes : '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const i = {
          ...investor,
          signatory: {
            first_name: trimString(values.first_name),
            last_name: trimString(values.last_name),
            telephone: trimString(values.telephone),
            fax: trimString(values.fax),
            email: trimString(values.email),
            address: {
              line_1: trimString(values.address.line_1),
              line_2: trimString(values.address.line_2),
              town: trimString(values.address.town),
              city: trimString(values.address.city),
              postcode: trimString(values.address.postcode),
              country: {
                name: values.address.country.name,
                iso: values.address.country.iso,
                currency: values.address.country.currency,
              },
            },
            witness: values.witness,
            notes: trimString(values.notes),
          },
        };
        try {
          await InvestorFirebase.update(i);
          setInvestor(i);
          setToast({
            message: `Updated ${i.name}'s legal details`,
            type: 'info',
          });
          actions.setSubmitting(false);
          closeModal();
        } catch (error) {
          const err = error as Fluit.firestore.Error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string(),
        last_name: Yup.string(),
        email: Yup.string().email('Invalid email address'),
        telephone: Yup.string().matches(telephoneRegex, 'Not a valid telephone number'),
        fax: Yup.string().matches(telephoneRegex, 'Not a valid fax number'),
        website: Yup.string().matches(urlRegex, 'Not a valid website address'),
        address: Yup.object().shape({
          country: Yup.object().shape({
            name: Yup.string(),
            iso: Yup.string(),
            currency: Yup.string(),
          }),
        }),
        witness: Yup.boolean(),
        notes: Yup.string(),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, values, setFieldValue, errors, touched } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="first_name"
                  label="First Name"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="last_name"
                  label="Last name"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="telephone"
                  label="Telephone"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="fax"
                  label="Fax"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteCountries
                  setFieldValue={setFieldValue}
                  name="address.country"
                  label="Country"
                  error={errors.address?.country?.iso}
                  touched={touched.address?.country?.iso}
                  value={values.address.country}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.line_1"
                  label="Address Line 1"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.line_2"
                  label="Address Line 2"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.town"
                  label="Town"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.city"
                  label="City"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="address.postcode"
                  label="Postcode"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Witness required?</Typography>
                <RadioGroup
                  aria-label="witness"
                  value={values.witness}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                    setFieldValue('witness', (event.target as HTMLInputElement).value === 'true' ? true : false);
                  }}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="notes"
                  label="Notes"
                  multiline={true}
                  rows={6}
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default InvestorSignatoryForm;
