import { Box, Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as VerifyAsset } from 'src/assets/images/verify_email.svg';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { UserFirebase } from 'src/services/users';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '100%',
    height: 'auto',
  },
  buttons: {
    '& button:last-child': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      '& button': {
        display: 'block',
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      '& button:last-child': {
        marginLeft: 0,
      },
    },
  },
}));

const ValidateEmail: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const firebase = React.useContext(FirebaseContext);
  const { setToast } = React.useContext(ToastContext);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const resendEmail = async () => {
    try {
      await firebase.sendEmailVerification();
      setToast({
        message: `Sent email verification to ${state.user.email}`,
        type: 'success',
      });
    } catch (error) {
      setToast({
        message: error.message,
        type: 'error',
      });
    }
  };

  const checkVerification = React.useCallback(() => {
    setLoading(true);
    const cu = firebase.auth().currentUser;
    cu?.reload().then(() => {
      const u = firebase.auth().currentUser;
      if (u && u.emailVerified) {
        let user = state.user;
        (async () => {
          await UserFirebase.update(user);
          setLoading(false);
          user.email_verified = u.emailVerified;
          dispatch({ type: 'USER_SET', payload: user });
          dispatch({ type: 'USER_REGISTERING', payload: false });
        })();
      } else if (u && !u.emailVerified) {
        setLoading(false);
        setToast({
          message: `${u.email} hasn't been verified.`,
          type: 'error',
        });
      }
    });
  }, [firebase, setToast, state.user, dispatch]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={8} md={6}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
          <Box mb={4}>
            <Typography variant="h1">Verify your email</Typography>
          </Box>
          <Box mb={4}>
            <Typography variant="body1">
              We've sent an email to <strong>{state.user.email}</strong>, click the link to validate your account.
            </Typography>
          </Box>
          <Box className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => checkVerification()}
              disabled={loading}
            >
              I've verified my email
            </Button>
            <Button color="secondary" onClick={resendEmail} size="large">
              Resend email
            </Button>
          </Box>
          {loading ? (
            <Box mt={4}>
              <LinearProgress variant="query" />
            </Box>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={6} style={{ textAlign: 'right' }}>
        <VerifyAsset className={classes.svg} />
      </Grid>
    </Grid>
  );
};

export default ValidateEmail;
