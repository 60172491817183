import { Box, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ReactComponent as FluitLogo } from 'src/assets/logo/fluit-light-shape.svg';
import NavItem from 'src/components/Layout/NavItem';
import { desktopLinks, mobileLinks } from 'src/constants/navigation';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import AppTour from '../Tour/Tour';
import MobileDrawer from './MobileDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'sticky',
    top: 0,
    height: '100vh',
    overflow: 'auto',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  sidebar: {
    width: '80px',
    height: '100%',
    background: colors.gradients.vertical.purple,
    zIndex: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(4, 0),
    '&: a': {
      display: 'flex',
      width: '100%',
      height: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  drawer: {
    width: '240px',
    borderRadius: '0px 28px 0px 0px',
    padding: theme.spacing(3),
    background: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    margin: theme.spacing(0, 2, 0, 0),
    height: '100%',
    transition: 'width 0.5s, border-radius 0.5s, padding 0.5s',
    zIndex: 1,
    overflow: 'hidden',
  },
  mobileWrapper: {
    borderRadius: theme.spacing(2, 2, 0, 0),
    background: colors.gradients.horizontal.purple,
    boxShadow: theme.shadows[3],
    width: '100%',
    paddingBottom: theme.spacing(1),
    position: 'sticky',
    left: 0,
  },
  mobileNav: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileLogo: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(0),
    '& svg': {
      width: '20px',
      height: 'auto',
      opacity: 0.4,
    },
  },
}));

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const { state } = React.useContext(AppContext);
  const { init } = state;
  const { payment_failed } = init;

  let deal_path = useRouteMatch('/deals/:deal_id');
  let investor_path = useRouteMatch('/investors/:investor_id');
  if (xs) {
    return (
      <Box className={classes.mobileWrapper}>
        <Box className={classes.mobileNav}>
          <Box className={classes.mobileLogo}>
            <NavLink to={payment_failed ? ROUTES.ORGANISATIONS : ROUTES.DEALS} style={{ display: 'flex' }}>
              <FluitLogo />
            </NavLink>
          </Box>
          {_.map(mobileLinks, link => {
            return <NavItem key={link.title} link={link} />;
          })}
          <MobileDrawer />
        </Box>
      </Box>
    );
  }

  if (sm) {
    return (
      <Box className={classes.mobileWrapper}>
        <Box className={classes.mobileNav}>
          <Box className={classes.mobileLogo}>
            <NavLink to={payment_failed ? ROUTES.ORGANISATIONS : ROUTES.DEALS} style={{ display: 'flex' }}>
              <FluitLogo />
            </NavLink>
          </Box>
          {_.map(desktopLinks, link => {
            return <NavItem key={link.title} link={link} />;
          })}
          <MobileDrawer />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.sidebar}>
        <Box className={classes.logo}>
          <NavLink to={payment_failed ? ROUTES.ORGANISATIONS : ROUTES.DEALS}>
            <FluitLogo />
          </NavLink>
        </Box>
        {!deal_path && !investor_path ? <AppTour /> : null}
        <Box className={classes.nav}>
          <Box>
            {_.map(_.filter(desktopLinks, { position: 'top' }), link => {
              return <NavItem key={link.title} link={link} />;
            })}
          </Box>
          <Box>
            {_.map(_.filter(desktopLinks, { position: 'bottom' }), link => {
              return <NavItem key={link.title} link={link} />;
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
