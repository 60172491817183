import FirebaseClient from 'src/services/firebaseAuth';
import { API } from './api';
import Fluit from 'src/types/Fluit';
const fb = FirebaseClient;

export const OrganisationFirebase = {
  create: async (organisation: Partial<Fluit.organisations.Organisation>) => {
    try {
      const resp = await API({
        endpoint: 'organisations',
        method: 'POST',
        payload: organisation,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  get: async (id: string): Promise<Fluit.organisations.Organisation> => {
    try {
      const resp = await API({
        endpoint: `organisations/${id}`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  list: async (): Promise<Fluit.organisations.Organisation[]> => {
    try {
      const resp = await API({
        endpoint: `organisations`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  update: async (organisation: Partial<Fluit.organisations.Organisation>) => {
    try {
      const resp = await API({
        endpoint: `organisations/${organisation.id}`,
        method: 'PATCH',
        payload: organisation,
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  delete: async (organisation: Fluit.organisations.Organisation) => {
    try {
      const resp = await API({
        endpoint: `organisations/${organisation.id}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },

  // * MEMBERS CALLS
  listMembers: async (orgId: string): Promise<Fluit.organisations.Member[]> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/members`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },

  // * INVITE MANAGEMENT (ORG SIDE)
  listInvites: async (orgId: string): Promise<Fluit.organisations.Invitee[]> => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/invites`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  invite: async (organisation: Fluit.organisations.Organisation, email: string, user: Fluit.users.User) => {
    const org = fb
      .firestore()
      .collection('organisations')
      .doc(organisation.id);
    try {
      const resp = await API({
        endpoint: `organisations/${organisation.id}/invites`,
        method: 'POST',
        payload: { email: email },
      });
      await org.collection('mail').add({
        to: email,
        from: 'Fluit <noreply@fluit.co>',
        template: {
          name: 'invited_to_organisation',
          data: {
            organisation_name: organisation.name,
            inviter_firstname: user.first_name,
            inviter_last_name: user.last_name,
          },
        },
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  revokeInvite: async (organisation: Fluit.organisations.Organisation, email: string) => {
    try {
      const resp = await API({
        endpoint: `organisations/${organisation.id}/invites`,
        method: 'DELETE',
        payload: { email: email },
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },

  // * INVITE MANAGEMENT (USER SIDE)
  getInvites: async (): Promise<Fluit.organisations.Organisation[]> => {
    try {
      const resp = await API({
        endpoint: `invites`,
        method: 'GET',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  acceptInvite: async (orgId: string, user: Fluit.users.User) => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/members`,
        method: 'POST',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },

  // * Organisation Member Management
  changeRole: async (orgId: string, userId: string, role: string) => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/members/${userId}`,
        method: 'POST',
        payload: { role: role },
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },
  removeMember: async (orgId: string, userId: string) => {
    try {
      const resp = await API({
        endpoint: `organisations/${orgId}/members/${userId}`,
        method: 'DELETE',
      });
      return resp;
    } catch (err) {
      throw err;
    }
  },

  // * Misc API Calls

  getSupport: async (support: Fluit.support.Support) => {
    const org = fb
      .firestore()
      .collection('organisations')
      .doc(support.organisation_id);
    try {
      await org.collection('mail').add({
        to: 'support@fluit.co',
        message: {
          subject: `${support.topic} : ${support.user_name} from ${support.organisation_name}`,
          text: JSON.stringify(support),
          html: JSON.stringify(support),
        },
      });
    } catch (err) {
      throw err;
    }
  },
};
