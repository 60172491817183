import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import ContactCard from 'src/components/Cards/Contact';
import NoData from 'src/components/Empty';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { InvestorsNone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const InvestorTabContacts: React.FC = () => {
  const firebase = React.useContext(FirebaseContext);
  const { investor } = React.useContext(InvestorContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { contacts, organisation } = state;
  const [loading, setLoading] = React.useState(true);
  const investorContacts = _.filter(contacts, { investor_id: investor.id });
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const addContact = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Add a contact`,
        component: 'CONTACT_CREATE',
        investor_id: investor.id,
      },
    });
  };

  React.useEffect(() => {
    setLoading(true);
    const unsubscribe = firebase
      .firestore()
      .collection('organisations')
      .doc(organisation.id)
      .collection('contacts')
      .where('investor_id', '==', investor.id)
      .where('system.disabled', '==', false)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change, index) {
          const contact = change.doc.data() as Fluit.investors.Contact;
          if (change.type === 'added') {
            const contact_exists = _.find(contacts, { id: contact.id });
            if (!contact_exists) {
              dispatch({ type: 'CONTACT_CREATE', payload: contact });
            } else {
              dispatch({ type: 'CONTACT_UPDATE', payload: contact });
            }
          }
          if (change.type === 'modified') {
            dispatch({ type: 'CONTACT_UPDATE', payload: contact });
          }
          if (change.type === 'removed') {
            dispatch({ type: 'CONTACT_DELETE', payload: contact });
          }
        });
        setLoading(false);
      });

    return () => {
      unsubscribe();
    };
  }, [firebase, dispatch, organisation.id, investor.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Contacts</Typography>
            {_.some(investorContacts) ? (
              <Button color="secondary" variant="outlined" onClick={() => addContact()}>
                New Contact
              </Button>
            ) : null}
          </Box>
        </Box>
      </Grid>

      {loading ? (
        <Grid item xs={12}>
          <GenericLoader variant="none" height="160px" />{' '}
        </Grid>
      ) : (
        _.map(_.orderBy(investorContacts, ['last_name', '[first_name]'], ['asc', 'asc']), contact => {
          return (
            <Grid item xs={12} key={contact.id}>
              <ContactCard data={contact} />
            </Grid>
          );
        })
      )}

      {!_.some(investorContacts) && !loading ? (
        <Grid item xs={12}>
          <NoData
            text={`No contacts for ${investor.name}`}
            icon={<InvestorsNone />}
            label="Add Contact"
            action={addContact}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default InvestorTabContacts;
