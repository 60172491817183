import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';

const TourWelcome: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { user } = state;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
        close: false,
      },
    });
  };

  const helpModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: 'Help & Support',
        component: 'TOUR_HELP',
        close: true,
      },
    });
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Player
          autoplay
          loop
          src="https://assets4.lottiefiles.com/packages/lf20_mVx1pv.json"
          style={{ height: '250px', width: '250px' }}
        ></Player>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h1" align="center">
          Welcome {user.first_name}
        </Typography>
        <Typography variant="subtitle1" align="center">
          You're all setup and ready to get going.
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        {!mobile && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginRight: 16 }}
            onClick={() => helpModal()}
          >
            Tutorial
          </Button>
        )}
        <Button variant="outlined" color="secondary" size="large" onClick={() => closeModal()}>
          {mobile ? 'Continue' : 'Close'}
        </Button>
      </Box>
    </Box>
  );
};

export default TourWelcome;
