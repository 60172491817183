import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  LinearProgress,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CurrencyDisplay from 'src/components/Displays/Currency';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { calculateRaise } from 'src/helpers/calculateRaise';
import { postMoney } from 'src/helpers/postMoney';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { Document, Investors, Process, Tasks } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    border: `2px solid ${theme.palette.common.white}`,
    '&:hover': {
      border: `2px solid ${colors.purple.main}`,
      boxShadow: theme.shadows[3],
    },
  },
  cardContent: {
    flexGrow: 1,
    '& > div': {
      marginBottom: theme.spacing(1),
    },
  },
  cardActions: {
    paddingRight: theme.spacing(3),
  },
  cardActionArea: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    '&:hover': {
      borderColor: theme.palette.common.white,
      background: theme.palette.common.white,
      boxShadow: 'none',
    },
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
  focusHighlight: {},
  percentage: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
  },
  progressOuter: {
    background: theme.palette.background.default,
    height: '16px',
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  progress: {
    height: '16px',
    borderRadius: theme.spacing(2),
    background: colors.gradients.horizontal.purple,
    minWidth: '16px',
    maxWidth: '100%',
    transition: `0.5s ease-in width`,
  },
  svg: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > div': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  attributeContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(1),
    },
  },
  attribute: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  disabled: {
    pointerEvents: 'none',
    '& > *': {
      opacity: 0.4,
    },
    '& $linearprogress': {
      opacity: 1,
    },
  },
  linearprogress: {},
}));

interface Props {
  deal: Fluit.deals.Deal;
}

const DealCard: React.FC<Props> = ({ deal }) => {
  const { id, name, type, details, system } = deal;
  const { setDeal } = React.useContext(DealContext);
  const { state } = React.useContext(AppContext);
  const { assignments, processes } = state;
  const [sum, setSum] = React.useState(10000);
  const [calc, setCalc] = React.useState(0);
  const [termsheets, setTermsheets] = React.useState<Fluit.deals.Termsheet[]>([]);

  const process = _.find(processes, { deal_id: deal.id });

  const tasks = _.filter(state.tasks, { parent_id: deal.id, completed: false });

  const classes = useStyles();
  const history = useHistory();
  let investorCount = 0;
  if (process) {
    _.map(process.steps, step => {
      const assigned = _.filter(assignments, { step_id: step.id });
      investorCount = investorCount + (assigned ? assigned.length : 0);
    });
  }

  React.useEffect(() => {
    setTermsheets(_.filter(state.termsheets, { deal_id: id }));
  }, [state.termsheets, id]);

  React.useEffect(() => {
    let val = Number(_.sum(_.map(termsheets, 'amount')));
    setSum(val);
    setCalc(Number(numeral(calculateRaise(details?.amount!, val!)).format('0')));
  }, [termsheets, details]);

  const handleClick = () => {
    setDeal(deal);
    history.push(`${ROUTES.DEALS}/${id}`);
  };

  return (
    <Card className={clsx(system?.delete && classes.disabled, classes.card)}>
      <CardActionArea
        classes={{
          root: classes.cardActionArea,
          focusHighlight: classes.focusHighlight,
        }}
        onClick={() => handleClick()}
        disabled={system?.delete}
      >
        <CardContent className={classes.cardContent}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Chip color={type === 'Fundraise' ? 'primary' : 'secondary'} label={type} />
            {type === 'Fundraise' && details ? (
              <Chip
                color="default"
                variant="outlined"
                label={
                  <CurrencyDisplay
                    name={details.currency.name}
                    code={details.currency.code}
                    symbol={details.currency.symbol}
                    alpha_2={details.currency.alpha_2}
                  />
                }
              />
            ) : null}
          </Box>

          <Box>
            <Typography variant="h3">{name}</Typography>
            {details ? <Typography variant="caption">{details.round}</Typography> : null}
          </Box>

          <Box className={classes.attributeContainer}>
            <Box className={classes.attribute}>
              <Investors className={classes.svg} />
              <Typography variant="body1">
                {investorCount === 1 ? `${investorCount} investor` : `${investorCount} investors`}
              </Typography>
            </Box>

            <Box className={classes.attribute}>
              <Document className={classes.svg} />
              <Typography variant="body1">
                {termsheets !== null
                  ? termsheets.length === 1
                    ? `${termsheets.length} termhseet`
                    : `${termsheets.length} termsheets`
                  : '0 termsheets'}
              </Typography>
            </Box>

            <Box className={classes.attribute}>
              <Tasks className={classes.svg} />
              <Typography variant="body1">
                {tasks.length === 1 ? '1 task to do' : `${tasks.length} tasks to do`}
              </Typography>
            </Box>

            <Box className={classes.attribute}>
              <Process className={classes.svg} />
              <Typography variant="body1">
                {process ? `${process.steps.length} steps in process` : '0 steps in process'}
              </Typography>
            </Box>
          </Box>

          {details && type === 'Fundraise' ? (
            <Box className={classes.details}>
              <Box flex="auto">
                <Typography variant="caption">Allocation</Typography>
                <Typography variant="h4">
                  {details.currency.symbol} {numeral(details.amount).format('0,0')}
                </Typography>
              </Box>

              {details.equity ? (
                <Box flex="auto" mr={2}>
                  <Typography variant="caption">Equity</Typography>
                  <Typography variant="h4">{numeral(details.equity).format('0')}%</Typography>
                </Box>
              ) : null}

              <Box flex="auto">
                <Typography variant="caption">Implied Valuation</Typography>
                <Typography variant="h4">
                  {details.currency.symbol}
                  {postMoney(details.amount, details.equity)}{' '}
                </Typography>
              </Box>

              <Box flex="auto">
                <Typography variant="caption">Termsheet Values</Typography>
                <Typography variant="h4">
                  {details.currency.symbol} {numeral(sum).format('0,0')}
                  <Chip
                    className={classes.percentage}
                    variant="outlined"
                    size="small"
                    color="default"
                    label={`${calc}%`}
                  />
                </Typography>
              </Box>
            </Box>
          ) : null}

          {details && type === 'Fundraise' ? (
            <Box className={classes.progressOuter}>
              <Tooltip
                title={`${details.currency.symbol} ${numeral(sum).format('0,0')} of ${
                  details.currency.symbol
                } ${numeral(details.amount).format('0,0')} Raised`}
              >
                <Box style={{ width: `${calc}%` }} className={classes.progress} />
              </Tooltip>
            </Box>
          ) : null}
        </CardContent>
      </CardActionArea>

      {system?.delete ? <LinearProgress variant="query" className={classes.linearprogress} /> : null}
    </Card>
  );
};

export default DealCard;
