import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field } from 'formik';
import matchSorter from 'match-sorter';
import React from 'react';
import { data_currencies } from 'src/constants/currencies';
import { isoToFlag } from 'src/helpers/isoToFlag';
import FluitStyles from 'src/themes/Styles';
import Fluit from 'src/types/Fluit';

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  name: string;
  id?: string;
  touched: boolean | undefined;
  error: string | undefined;
  label: string;
  value: Fluit.Currency;
}

const AutocompleteCurrencies: React.FC<Props> = ({ setFieldValue, name, id, touched, error, label, value }) => {
  const classes = FluitStyles.AutoComplete.country();

  const val = value.code !== '' ? value : null;

  const filterOptions = (options: Fluit.Currency[], { inputValue }: { inputValue: string }) =>
    matchSorter(options, inputValue, { keys: ['code', 'name', 'alpha_2'] });

  return (
    <Field
      name={name}
      id={id ? id : name}
      component={Autocomplete}
      options={data_currencies}
      getOptionLabel={(option: Fluit.Currency) => option.name}
      disableClearable={true}
      filterOptions={filterOptions}
      autoSelect={true}
      value={val}
      onChange={(event: object, value: Fluit.Currency) => {
        setFieldValue(name, value);
      }}
      getOptionSelected={(option: Fluit.Currency, value: Fluit.Currency) => option.code === value.code}
      renderOption={(option: Fluit.Currency) => (
        <div className={classes.option}>
          <span className={classes.flag}>{isoToFlag(option.alpha_2)}</span>
          <span className={classes.label}>{option.name}</span>
          <span className={classes.symbol}>{option.symbol}</span>
          <span className={classes.alpha_2}>{option.alpha_2}</span>
        </div>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

export default AutocompleteCurrencies;
