import { makeStyles, Theme } from '@material-ui/core';
import colors from 'src/themes/colors';

export const styles_OrganisationCard = makeStyles((theme: Theme) => ({
  svg: {
    width: '100%',
    maxWidth: 300,
    height: 'auto',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const styles_UserCard = makeStyles((theme: Theme) => ({
  svg: {
    width: 20,
    height: 20,
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  disabled: {
    opacity: 0.6,
  },
  box: {
    position: 'relative',
  },
  iconButton: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
  avatar: {
    width: 60,
    height: 60,
    background: colors.grey[50],
    fontSize: 18,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: colors.grey[500],
    margin: theme.spacing(0, 0, 2),
  },
  chip: {
    textTransform: 'capitalize',
    margin: theme.spacing(2, 0, 0),
    color: theme.palette.common.white,
  },
  owner: { background: colors.purple[500] },
  member: { background: colors.purple[300] },
  pending: { background: colors.grey[300], marginLeft: theme.spacing(1) },
  inactive: {
    width: 80,
    height: 80,
    fill: theme.palette.grey[100],
    '& g, & path': {
      fill: theme.palette.grey[100],
    },
  },
}));

export const styles_ContactCard = makeStyles((theme: Theme) => ({
  svg: {
    width: 20,
    height: 20,
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  avatar: {
    width: 20,
    height: 20,
    background: colors.grey[50],
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: colors.grey[500],
  },
  box: {},
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    flex: 'fit-content',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${colors.grey[50]}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        marginRight: 0,
        marginBottom: theme.spacing(2),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    '&:empty': {
      display: 'none',
      visilibity: 'hidden',
    },
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

export const styles_InvestorCard = makeStyles((theme: Theme) => ({
  svg: {
    width: 20,
    height: 20,
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  card: {
    border: `2px solid ${theme.palette.common.white}`,
    display: 'flex',
    '&:hover': {
      border: `2px solid ${colors.purple.main}`,
      boxShadow: theme.shadows[3],
    },
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    paddingRight: `${theme.spacing(3)} !important`,
  },
  cardActionArea: {
    display: 'flex',
    '&:hover': {
      borderColor: `${theme.palette.common.white} !important`,
      background: `${theme.palette.common.white} !important`,
      boxShadow: 'none !important',
    },
    '&:hover $focusHighlight': {
      opacity: `0 !important`,
    },
  },
  focusHighlight: {},
  activity: {
    padding: theme.spacing(1.5, 0, 0),
    margin: theme.spacing(2, 0, 0),
    borderTop: `1px dashed ${colors.grey[50]}`,
  },
  icon: {
    width: 10,
    height: 10,
    marginRight: theme.spacing(1),
    fill: theme.palette.grey[500],
    '& g, & path': {
      fill: theme.palette.grey[500],
    },
  },
  disabled: {
    pointerEvents: 'none',
    '& > *': {
      opacity: 0.4,
    },
    '& $linearprogress': {
      opacity: 1,
    },
  },
  linearprogress: {},
}));
