import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import NoData from 'src/components/Empty';
import { data_countries } from 'src/constants/countries';
import { data_currencies } from 'src/constants/currencies';
import { AlgoliaContext } from 'src/contexts/AlgoliaContext';
import { AppContext } from 'src/contexts/AppContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import { isoToFlag } from 'src/helpers/isoToFlag';
import colors from 'src/themes/colors';
import { InvestorsNone } from 'src/themes/icons';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';
import AlgoliaHighlight from './AlgoliaHighlight';

interface Props {
  hits: Fluit.investors.Firm[];
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      '&:hover $uncheck $icon': {
        color: colors.purple.main,
      },
      '&:hover $check $icon': {
        color: colors.common.white,
      },
    },
  },
  checked: {
    boxShadow: shadows.purple[3],
  },
  icon: {
    width: 24,
    height: 24,
    color: colors.grey[100],
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  disabled: {
    opacity: 0.6,
    '&:hover $icon': {
      color: `${colors.grey[100]} !important`,
    },
  },
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      '&:hover': {
        border: `2px solid rgba(255,255,255,0) !important`,
      },
    },
  },
  uncheck: {
    padding: theme.spacing(3),
    background: colors.common.background,
    display: 'flex',
    alignItems: 'center',
    transition: `0.3s background ease-in-out`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 3),
    },
  },
  check: {
    background: colors.purple.main,
    '& $icon': {
      color: colors.common.white,
    },
  },
  attributeContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  attributes: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > div': {
      marginRight: theme.spacing(3),
    },
  },
}));

const AlgoliaHit: React.FC<Props> = ({ hits }) => {
  const classes = useStyles();
  const { search, setSearch } = React.useContext(AlgoliaContext);
  const { selected } = search;
  const { state, dispatch } = React.useContext(AppContext);
  const { organisation } = state;
  const { limits } = organisation;
  const available = limits.investors.limit - limits.investors.count;
  const remaining = available - selected.length;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClick = (obj: Fluit.investors.Firm) => {
    const in_array = _.find(selected, hit => hit.objectID === obj.objectID);
    if (in_array) {
      return setSearch({
        selected: _.filter(selected, hit => hit.objectID !== obj.objectID),
        query: search.query,
      });
    }
    return setSearch({
      selected: _.concat(selected, obj),
      query: search.query,
    });
  };

  const createInvestor = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: 'Add a new investor',
        component: 'INVESTOR_CREATE',
        investor_name: search.query,
      },
    });
  };

  return (
    <Box>
      {_.map(hits, hit => {
        const in_array = _.find(selected, item => item.objectID === hit.objectID);
        const country: Fluit.address.Country | undefined = _.find(data_countries, item => item.name === hit.location);
        const currency: Fluit.Currency | undefined = _.find(data_currencies, item => item.code === hit.currency);
        return (
          <Card
            key={hit.objectID}
            data-tour="investor_search_results"
            className={clsx(
              classes.card,
              remaining === 0 && !in_array && classes.disabled,
              in_array && classes.checked
            )}
          >
            <CardActionArea
              onClick={() => handleClick(hit)}
              disabled={remaining === 0 && !in_array}
              classes={{
                root: classes.actionArea,
              }}
            >
              <CardContent
                classes={{
                  root: classes.cardContent,
                }}
              >
                <Box className={clsx(classes.uncheck, in_array && classes.check)}>
                  <CheckIcon className={classes.icon} />
                </Box>

                <Box flex={1} className={classes.attributeContainer}>
                  <Box mb={1} flex={1}>
                    <Typography variant="h4">
                      <AlgoliaHighlight attribute="name" hit={hit} />
                    </Typography>
                    <Typography>
                      {country && isoToFlag(country.iso)}
                      {country && ' '}
                      <AlgoliaHighlight attribute="location" hit={hit} />
                    </Typography>
                  </Box>

                  {mobile && (
                    <Box>
                      <Box>
                        <Typography>
                          <Typography variant="overline">Regions Covered</Typography>
                        </Typography>
                        <Typography>
                          {_.map(
                            hit.regions,
                            (region, index) => `${region}${index + 1 === hit.regions?.length ? '' : ', '}`
                          )}
                          {!hit.regions && '-'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <Typography variant="overline">Stages</Typography>
                        </Typography>
                        <Typography>
                          {_.map(
                            hit.stages,
                            (stage, index) => `${stage}${index + 1 === hit.stages?.length ? '' : ', '}`
                          )}
                          {!hit.stages && '-'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <Typography variant="overline">Sectors</Typography>
                        </Typography>
                        <Typography>
                          {_.map(
                            hit.sectors,
                            (sector, index) => `${sector}${index + 1 === hit.sectors?.length ? '' : ', '}`
                          )}
                          {!hit.sectors && '-'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <Typography variant="overline">Investment Size</Typography>
                        </Typography>
                        <Typography>
                          {hit.investment_min && hit.investment_max ? (
                            <>
                              {`${formatCurrency(hit.investment_min, currency?.symbol, true)} - ${formatCurrency(
                                hit.investment_max,
                                currency?.symbol,
                                true
                              )}`}
                            </>
                          ) : (
                            ''
                          )}
                          {!hit.investment_min && !hit.investment_max ? '-' : ''}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {!mobile && (
                    <Box className={classes.attributes}>
                      <Box>
                        <Typography>
                          <Typography variant="overline">Regions Covered</Typography>
                        </Typography>

                        <Typography>
                          <Typography variant="overline">Stages</Typography>
                        </Typography>

                        <Typography>
                          <Typography variant="overline">Sectors</Typography>
                        </Typography>

                        <Typography>
                          <Typography variant="overline">Investment Size</Typography>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          {_.map(
                            hit.regions,
                            (region, index) => `${region}${index + 1 === hit.regions?.length ? '' : ', '}`
                          )}
                          {!_.some(hit.regions) && '-'}
                        </Typography>
                        <Typography>
                          {_.map(
                            hit.stages,
                            (stage, index) => `${stage}${index + 1 === hit.stages?.length ? '' : ', '}`
                          )}
                          {!_.some(hit.stages) && '-'}
                        </Typography>
                        <Typography>
                          {_.map(
                            hit.sectors,
                            (sector, index) => `${sector}${index + 1 === hit.sectors?.length ? '' : ', '}`
                          )}
                          {!_.some(hit.sectors) && '-'}
                        </Typography>
                        <Typography>
                          {hit.investment_min && hit.investment_max ? (
                            <>
                              {`${formatCurrency(hit.investment_min, currency?.symbol, true)} - ${formatCurrency(
                                hit.investment_max,
                                currency?.symbol,
                                true
                              )}`}
                            </>
                          ) : (
                            ''
                          )}
                          {!hit.investment_min && !hit.investment_max ? '-' : ''}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}

      {hits.length === 0 && (
        <Box mb={4}>
          <NoData icon={<InvestorsNone />} text="No investors found" action={createInvestor} label="Create Investor" />
        </Box>
      )}
    </Box>
  );
};

//@ts-ignore
export default connectHits(AlgoliaHit);
