import { Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import ProfileDetailsForm from 'src/components/Forms/Profile/Details';
import ProfileEmailForm from 'src/components/Forms/Profile/Email';
import ProfilePasswordForm from 'src/components/Forms/Profile/Password';
import { AppContext } from 'src/contexts/AppContext';
import colors from 'src/themes/colors';

const Profile: React.FC = () => {
  const { dispatch } = React.useContext(AppContext);

  const deleteAccount = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Delete your Account`,
        component: 'PROFILE_DELETE',
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h1">Profile</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">Your details</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ProfileDetailsForm />
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Typography variant="h2">Account email</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ProfileEmailForm />
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Typography variant="h2">Account password</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ProfilePasswordForm />
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Typography variant="h2">Delete Account</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Button
          variant="contained"
          size="large"
          style={{ background: colors.red.main, color: colors.common.white }}
          onClick={() => deleteAccount()}
        >
          Delete Account
        </Button>
      </Grid>
    </Grid>
  );
};

export default Profile;
