import React from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { AppContext } from 'src/contexts/AppContext';
import { ToursContext } from 'src/contexts/TourContext';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';

const AppTour: React.FC = () => {
  const { dispatch } = React.useContext(AppContext);
  const { tours, dispatchTours } = React.useContext(ToursContext);
  const [steps, setSteps] = React.useState<Step[]>([]);
  const [start, setStart] = React.useState(false);

  const navigationSteps = React.useCallback(() => {
    const data: Step[] = [
      {
        target: '[data-tour=app_navigation_deals]',
        title: 'Deals',
        content: 'Create and manage deals here.',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=app_navigation_investors]',
        title: 'Investors',
        content: 'Find new investors or manage existing investors here',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=app_navigation_activity]',
        title: 'Activities & Logs',
        content:
          'Log activities like Investor communications or read updates on events that have occured on the system',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=app_navigation_tasks]',
        title: 'Tasks',
        content: 'Create tasks and assign them to colleageus.',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=app_navigation_notes]',
        title: 'Notes',
        content: 'Create notes and attach them to investors or deals.',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=app_navigation_users]',
        title: 'Users',
        content: 'Add users and manage their permissions here.',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=app_navigation_organisations]',
        title: 'Organisations & Billing',
        content: 'Manage your organisations, including billing and invoices.',
        disableBeacon: true,
        placement: 'right',
      },
    ];
    return data;
  }, []);

  const dealSteps = React.useCallback(() => {
    const data: Step[] = [
      {
        target: '[data-tour=deal_overview]',
        content: 'This tab provides you an overview of your raise, investors, termsheets and process.',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=deal_investors]',
        content: 'Add, remove & move investors in your deal and their retrospective contacts',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=deal_termsheets]',
        content: `Add, remove and view termsheets you've received from investors for this deal`,
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=deal_activity]',
        content: `Log activities for this deal, if you have a premium account you can see intimate details like when investors moved between stages in your process.`,
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=deal_tasks]',
        content: 'Manage and create tasks for this deal',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=deal_notes]',
        content: 'Manage and create notes for this deal',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=deal_details]',
        content: 'Change the status of this deal or manage the amount, equity and currency of your raise',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=deal_process]',
        content:
          'View the process and investors in the process, you can also edit the process by renaming or adding / removing steps.',
        disableBeacon: true,
        placement: 'right',
      },
    ];
    return data;
  }, []);

  const investorSearchSteps = React.useCallback(() => {
    const data: Step[] = [
      {
        target: '[data-tour=investor_search_field]',
        content: 'Enter the investors name here',
        disableBeacon: true,
      },
      {
        target: '[data-tour=investor_search_results]',
        content: `Your results will be displayed here, click the investor to select it.  If the investor isn't found, a create button will be availalbe in the results`,
        disableBeacon: true,
      },
      {
        target: '[data-tour=investor_search_selected]',
        content: `Investors you select will be available here, you can remove them or click Add Investors to add them to your account.`,
        disableBeacon: true,
      },
      {
        target: '[data-tour=investor_search_filters]',
        content: `You can filter your results, before or after your initial search.`,
        disableBeacon: true,
      },
    ];
    return data;
  }, []);

  const investorSteps = React.useCallback(() => {
    const data: Step[] = [
      {
        target: '[data-tour=investor_contacts]',
        content:
          'Add your contacts at the investment firm here, these will be used against the deal, activity logs, tasks and notes.',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=investor_deals]',
        content: `The deals your investors are active in will be displayed here.`,
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=investor_termsheets]',
        content: `Termsheets you receive from investors can be added and viewed here.`,
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=investor_tasks]',
        content: `View tasks assigned to this investor or create new tasks here.`,
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=investor_notes]',
        content: `All notes you assign to this investor will be available here, you can also create new notes.`,
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=investor_activity]',
        content: `Logged activities will be available here, and if you have a premium account you can see all system logs of actions peformed with / to this investor.`,
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=investor_details]',
        content: `View and update this investors details.`,
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-tour=investor_legals]',
        content: `You can store the investors legal details here, this will be helpful when dealing with lawyers.`,
        disableBeacon: true,
        placement: 'right',
      },
    ];
    return data;
  }, []);

  React.useEffect(() => {
    if (tours.tourTarget === 'deal' && tours.deal) {
      setSteps(dealSteps());
      setStart(true);
      return undefined;
    }
    if (tours.tourTarget === 'investor' && tours.investor) {
      setSteps(investorSteps());
      setStart(true);
      return undefined;
    }
    if (tours.tourTarget === 'investor_search' && tours.investor_search) {
      setSteps(investorSearchSteps());
      setStart(true);
      return undefined;
    }
    if (tours.tourTarget === 'navigation' && tours.navigation) {
      setSteps(navigationSteps());
      setStart(true);
      return undefined;
    }
    setSteps([]);
    setStart(false);
    return undefined;
  }, [tours, dealSteps, investorSearchSteps, navigationSteps, investorSteps]);

  const handleJoyride = React.useCallback(
    (data: CallBackProps) => {
      const { status } = data;

      if (status === 'skipped' || status === 'finished') {
        if (tours.firstLoad) {
          dispatch({
            type: 'MODAL',
            payload: {
              open: true,
              title: 'Help & Support',
              component: 'TOUR_HELP',
              close: true,
            },
          });
        }
        dispatchTours({ type: 'TOURS_SET_TARGET', payload: 'none' });
      }
    },
    [tours, dispatchTours, dispatch]
  );

  const fontFamily = [
    'montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',');

  return (
    <Joyride
      steps={steps}
      run={start}
      continuous={true}
      showProgress
      callback={handleJoyride}
      showSkipButton
      locale={{
        skip: 'Close',
      }}
      styles={{
        options: {
          arrowColor: colors.navy[900],
          backgroundColor: colors.navy[900],
          beaconSize: 36,
          primaryColor: '#f04',
          spotlightShadow: 'green',
          textColor: colors.common.white,
          width: undefined,
          zIndex: 100,
        },
        tooltip: {
          padding: 24,
          borderRadius: 8,
        },
        overlay: {},
        spotlight: {
          borderRadius: 8,
          boxShadow: shadows.black[1],
        },
        buttonNext: {
          backgroundColor: colors.purple[500],
          borderRadius: 4,
          color: colors.common.white,
          padding: 16,
          fontWeight: 600,
          fontFamily: fontFamily,
        },
        buttonBack: {
          backgroundColor: colors.navy[900],
          borderRadius: 4,
          color: colors.common.white,
          padding: 16,
          marginRight: 16,
          fontWeight: 600,
          fontFamily: fontFamily,
        },
        buttonSkip: {
          backgroundColor: colors.red[500],
          borderRadius: 4,
          color: colors.common.white,
          padding: 16,
          fontWeight: 600,
          fontFamily: fontFamily,
        },
        tooltipContent: {
          fontSize: 16,
          fontWeight: 500,
          textAlign: 'left',
          padding: 0,
          marginBottom: 24,
        },
        tooltipTitle: {
          fontSize: 18,
          textAlign: 'left',
        },
        buttonClose: {
          display: 'none',
        },
      }}
    />
  );
};

export default AppTour;
