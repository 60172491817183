import { Box, Button, Checkbox, CircularProgress, makeStyles, Paper, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    '& .Mui-error input': {
      boxShadow: 'none !important',
    },
    '& .Mui-error p': {
      background: 'none',
    },
  },
  header: {
    padding: theme.spacing(1, 3),
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
  },
  menu: {
    '& > ul': {
      paddingTop: 0,
    },
  },
}));

type ArrayType = {
  id: string;
  name: string;
};

interface Props {
  data: ArrayType[];
  setFieldValue: Function;
  noOptionsText: string;
  label: string;
  name: string;
  loading: boolean;
  open: boolean;
  onOpen: (event: React.ChangeEvent<{}>) => void;
  onClose: (event: React.ChangeEvent<{}>) => void;
}

const AutocompleteInvestorMultiSelect: React.FC<Props> = ({
  setFieldValue,
  noOptionsText,
  label,
  name,
  data,
  loading,
  open,
  onOpen,
  onClose,
}) => {
  const [array, setArray] = React.useState<ArrayType[]>(data);
  const [value, setValue] = React.useState<ArrayType[] | undefined>([]);
  const classes = useStyles();

  const selectAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setFieldValue(name, data);
    setValue(array);
  };

  React.useEffect(() => {
    setArray(data);
  }, [data, setArray]);

  const deselectAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setFieldValue(name, data);
    setValue([]);
  };

  const customMenu = ({ children }: any, { ...other }) => (
    <Paper {...other} className={classes.menu}>
      <Box className={classes.header}>
        <Button color="secondary" onMouseDown={e => selectAll(e)}>
          Select All
        </Button>
        <Button color="secondary" onMouseDown={e => deselectAll(e)} style={{ marginLeft: 8 }}>
          Clear All
        </Button>
      </Box>
      {children}
    </Paper>
  );

  return (
    <Autocomplete
      options={array}
      value={value}
      multiple
      disableCloseOnSelect={true}
      openOnFocus={true}
      selectOnFocus={false}
      className={classes.autocomplete}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      autoComplete={true}
      limitTags={6}
      loading={loading}
      onOpen={onOpen}
      onClose={onClose}
      open={open}
      noOptionsText={noOptionsText}
      onChange={(e: object, v: ArrayType[] | null) => {
        if (v !== null) {
          setFieldValue(name, v);
          setValue(v);
        }
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </React.Fragment>
      )}
      PaperComponent={customMenu}
      renderInput={params => (
        <Field
          {...params}
          component={FormikTextField}
          fullWidth={true}
          label={label}
          name={name}
          inputProps={{
            ...params.inputProps,
            autoComplete: _.uniqueId('new-password off'),
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          variant="outlined"
        />
      )}
    />
  );
};

export default AutocompleteInvestorMultiSelect;
