import { Button, Grid, LinearProgress, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import * as React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { OrganisationFirebase } from 'src/services/organisations';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const OrganisationDeleteForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { organisations } = state;
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState('');
  const classes = useStyles();

  let find_organisation = _.find(organisations, {
    id: String(state.ui.modal.org_id),
  }) as Fluit.organisations.Organisation;
  const organisation = find_organisation ? find_organisation : state.organisation;

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const deleteOrg = React.useCallback(async () => {
    setLoading(true);
    try {
      await OrganisationFirebase.delete(organisation);
      setToast({
        message: `${organisation.name} Deleted`,
        type: 'info',
      });
      dispatch({ type: 'ORGANISATION_DELETE', payload: organisation });
      dispatch({ type: 'APP_RELOAD', payload: true });
    } catch (err) {
      setLoading(false);
      setToast({
        message: err,
        type: 'error',
      });
    }
  }, [setLoading, setToast, organisation, dispatch]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">Permanently delete {organisation.name} from Fluit?</Typography>
        <ul>
          <li>You and all other users will no longer have access to {organisation.name}</li>
          <li>Delete all deals associated to {organisation.name}</li>
          <li>Delete all investors associated to {organisation.name}</li>
          <li>Delete all notes, tasks, and activity logs</li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">To permantly delete type {organisation.name} in the field below</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          name="organisation_name"
          onChange={event => {
            setConfirm(event.target.value);
          }}
          error={confirm === organisation.name ? true : false}
          helperText={confirm === organisation.name ? 'Pressing delete will permantly delete your organisation' : ''}
          disabled={loading ? true : false}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          style={{ marginRight: '16px' }}
          onClick={() => deleteOrg()}
          disabled={loading || confirm !== organisation.name ? true : false}
        >
          Delete
        </Button>
        <Button variant="text" size="large" onClick={() => closeModal()}>
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default OrganisationDeleteForm;
