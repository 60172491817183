import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import NoteList from 'src/components/Notes/NoteList';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { NotesFirebase } from 'src/services/notes';
import Fluit from 'src/types/Fluit';

const InvestorTabNotes: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const { setToast } = React.useContext(ToastContext);
  const { investor } = React.useContext(InvestorContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { notes, organisation } = state;
  const [loading, setLoading] = React.useState(false);

  const listNotes = React.useCallback(async () => {
    try {
      setLoading(true);
      const result = await NotesFirebase.list(organisation.id);
      dispatch({ type: 'NOTES_SET', payload: result });
      setLoading(false);
    } catch (error) {
      const err = error as Fluit.firestore.Error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
    }
  }, [organisation.id, dispatch, setToast]);

  React.useEffect(() => {
    listNotes();
  }, [listNotes]);

  const NoteCreate = () => {
    dispatch({
      type: 'NOTE_ATTACH_TO',
      payload: {
        parent_type: 'investor',
        parent_id: investor.id,
        child_id: '',
      },
    });
    history.push(ROUTES.NOTE_CREATE);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Notes</Typography>
            <Box>
              <Button style={{ marginLeft: 16 }} variant="outlined" color="secondary" onClick={() => NoteCreate()}>
                New {!mobile && 'Note'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      {loading ? (
        <Grid item xs={12}>
          <GenericLoader height="100%" variant="none" />
        </Grid>
      ) : null}

      {!loading ? <NoteList notes={_.filter(notes, { parent_id: investor.id })} createNote={NoteCreate} /> : null}
    </Grid>
  );
};

export default InvestorTabNotes;
