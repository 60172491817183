import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import TasksList from 'src/components/Tasks/List';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';

const DealTasks: React.FC = () => {
  const { deal } = React.useContext(DealContext);
  const { dispatch } = React.useContext(AppContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const TaskCreate = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `New Task for ${deal.name}`,
        component: 'TASK_CREATE',
        parent_id: deal.id,
        parent_type: 'deal',
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
            <Typography variant="h2">Tasks</Typography>
            <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="space-between">
              <Button style={{ marginLeft: 16 }} variant="outlined" color="secondary" onClick={() => TaskCreate()}>
                New {!mobile && 'Task'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TasksList createFunction={TaskCreate} parent={deal.id} />
      </Grid>
    </Grid>
  );
};

export default DealTasks;
