import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory, useLocation, useParams, withRouter } from 'react-router';
import DealSnapshop from 'src/components/Deals/DealSnapshop';
import DealSwitch from 'src/components/Inputs/Autocomplete/DealSwitch';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import DealTabActivity from 'src/components/Tabs/Deals/Activity';
import DealDetails from 'src/components/Tabs/Deals/Details';
import DealInvestors from 'src/components/Tabs/Deals/Investors';
import DealNavigation from 'src/components/Tabs/Deals/Navigation';
import DealTabNotes from 'src/components/Tabs/Deals/Notes';
import DealOverview from 'src/components/Tabs/Deals/Overview';
import DealProcess from 'src/components/Tabs/Deals/Process';
import DealTasks from 'src/components/Tabs/Deals/Tasks';
import DealTermsheets from 'src/components/Tabs/Deals/Termsheets';
import TabPanel from 'src/components/Tabs/TabPanel';
import AppTour from 'src/components/Tour/Tour';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { firebaseDateToIso } from 'src/helpers/firebaseDateToIso';
import * as ROUTES from 'src/routes/routes';
import { ProcessesFirebase } from 'src/services/processes';
import Fluit from 'src/types/Fluit';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Deal: React.FC = () => {
  let history = useHistory();
  let { deal_id } = useParams();
  let query = useQuery();
  const { state, dispatch } = React.useContext(AppContext);
  const { setToast } = React.useContext(ToastContext);
  const { organisation, deals, assignments } = state;
  const { deal, setDeal } = React.useContext(DealContext);
  const [loading, setLoading] = React.useState(true);
  const [termsheets, setTermsheets] = React.useState<Fluit.deals.Termsheet[]>([]);
  const { process, setProcess } = React.useContext(ProcessContext);
  const [fetchedProcess, setFetchedProcess] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const firebase = React.useContext(FirebaseContext);

  const tab = query.get('tab') ? query.get('tab') : 'overview';

  React.useEffect(() => {
    if (deal.id === deal_id) {
      return;
    }

    const found = _.find(deals, { id: deal_id });

    if (!found) {
      history.push(ROUTES.DEALS);
      return;
    }

    return setDeal(found);
  }, [deals, deal_id, setDeal, history, deal.id]);

  React.useEffect(() => {
    if (fetchedProcess || deal.id !== deal_id) {
      return undefined;
    }

    (async () => {
      try {
        const result: Fluit.process.Process = await ProcessesFirebase.get(organisation.id, deal.id);
        dispatch({ type: 'PROCESS_SET', payload: result });
        setFetchedProcess(true);
        setProcess(result);
      } catch (error) {
        const err: Fluit.firestore.Error = error;
        setToast({
          message: `${err.name} | ${err.message}`,
          type: 'error',
        });
      }
    })();
  }, [deal.id, setProcess, dispatch, setToast, organisation.id, deal_id, fetchedProcess]);

  React.useEffect(() => {
    if (process.id === '' || organisation.id === '') {
      return;
    }
    const unsubscribe = firebase
      .firestore()
      .collection('organisations')
      .doc(organisation.id)
      .collection('assignments')
      .where('process_id', '==', process.id)
      .where('system.disabled', '==', false)
      .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
          const assignment = change.doc.data() as Fluit.assignments.Assignment;
          assignment.assigned_at = firebaseDateToIso(assignment.assigned_at);
          assignment.updated_at = firebaseDateToIso(assignment.updated_at);
          if (change.type === 'added' || change.type === 'modified') {
            dispatch({ type: 'ASSIGNMENT_SET', payload: assignment });
          }
          if (change.type === 'removed') {
            dispatch({ type: 'ASSIGNMENT_DELETE', payload: assignment });
          }
        });
        setLoading(false);
      });

    return () => {
      unsubscribe();
    };
  }, [firebase, dispatch, organisation.id, process.id]);

  let investorCount = 0;
  _.map(process.steps, step => {
    const assigned = _.filter(assignments, { step_id: step.id });
    investorCount = investorCount + (assigned ? assigned.length : 0);
  });

  React.useEffect(() => {
    setTermsheets(_.filter(state.termsheets, { deal_id: deal.id }));
  }, [state.termsheets, deal.id]);

  const handleTabs = (event: React.ChangeEvent<{}>, newTab: string) => {
    history.push(`${ROUTES.DEALS}/${deal_id}?tab=${newTab}`);
  };

  const pannels = (
    <>
      <TabPanel value={tab} id="overview">
        <DealOverview investorCount={investorCount} termsheets={termsheets} />
      </TabPanel>
      <TabPanel value={tab} id="investors">
        <DealInvestors investorCount={investorCount} />
      </TabPanel>
      <TabPanel value={tab} id="termsheets">
        <DealTermsheets investorCount={investorCount} />
      </TabPanel>
      <TabPanel value={tab} id="activity">
        <DealTabActivity />
      </TabPanel>
      <TabPanel value={tab} id="tasks">
        <DealTasks />
      </TabPanel>
      <TabPanel value={tab} id="details">
        <DealDetails />
      </TabPanel>
      <TabPanel value={tab} id="process">
        <DealProcess />
      </TabPanel>
      <TabPanel value={tab} id="notes">
        <DealTabNotes />
      </TabPanel>
    </>
  );

  if (loading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ height: 200 }}>
          <GenericLoader height="100%" variant="none" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      <AppTour />
      <Grid item xs={12} md={4} lg={3} xl={2}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DealSwitch setFetchedProcess={setFetchedProcess} />
          </Grid>
          <Grid item xs={12}>
            <DealNavigation
              investorCount={investorCount}
              termsheetCount={termsheets.length}
              tab={tab}
              handleTabs={handleTabs}
            />
          </Grid>
          {!mobile ? (
            <Grid item xs={12}>
              <DealSnapshop />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={9} xl={10}>
        {pannels}
      </Grid>
    </Grid>
  );
};

export default withRouter(Deal);
