import _ from 'lodash';
import Fluit from 'src/types/Fluit';

export const TasksReducer = (state: Fluit.tasks.Task[], action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'TASKS_LIST':
      if (_.some(state)) {
        var data = _.map(state, item => {
          const inPayload = _.find(action.payload, { id: item.id });
          if (inPayload) {
            return inPayload;
          }
          return item;
        });

        var dif = _.differenceWith(action.payload, data, _.isEqual);
        return _.orderBy(_.concat(data, dif), ['date', 'details'], ['asc', 'asc']);
      }
      return action.payload;

    case 'TASK_CREATE':
      const TASK_CREATE = _.concat(_.values(state), action.payload);
      return TASK_CREATE;

    /* DELETE */
    case 'TASK_DELETE':
      const TASK_DELETE = _.filter(state, task => task.id !== action.payload.id);
      return TASK_DELETE;

    /* UPDATE */
    case 'TASK_UPDATE':
      return _.map(state, task => {
        if (task.id === action.payload.id) {
          return action.payload;
        }
        return task;
      });

    case 'TASK_TOGGLE':
      return _.map(state, task => {
        if (task.id === action.payload.id) {
          task.completed = task.completed ? false : true;
        }
        return task;
      });

    case 'TASKS_FLUSH':
      return [];

    default:
      return { ...state };
  }
};
