import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { isoToFlag } from 'src/helpers/isoToFlag';
import * as ROUTES from 'src/routes/routes';
import { styles_InvestorCard } from 'src/styles/cards';
import { Deals, Dots, Investors, Money, Tasks } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

interface Props {
  data: Fluit.investors.Investor;
}

const InvestorCard: React.FC<Props> = ({ data }) => {
  const { state, dispatch } = React.useContext(AppContext);
  const { tasks, deals, assignments, processes } = state;
  const { setInvestor } = React.useContext(InvestorContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const investorContacts = _.filter(state.contacts, { investor_id: data.id });
  const investorTasks = _.filter(tasks, { parent_id: data.id, completed: false });
  const contactAllTasks = _.filter(tasks, function(task) {
    _.map(investorContacts, contact => contact.id === task.parent_id);
  });
  const contactTasks = _.filter(contactAllTasks, { completed: false });
  const task_count = investorTasks.length + contactTasks.length;

  const assignedTo = _.filter(assignments, { investor_id: data.id });
  const inProcesses = _.map(assignedTo, assignment => _.find(processes, { id: assignment.process_id }));
  const filterProcess = _.filter(inProcesses, _.size) as Fluit.process.Process[];
  const investorDeals = _.map(filterProcess, process => _.find(deals, { id: process?.deal_id }));

  const termsheets_count = _.filter(state.termsheets, { investor_id: data.id }).length;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeInvestor = () => {
    setAnchorEl(null);
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Permantly delete ${data.name}?`,
        component: 'INVESTOR_DELETE',
        investor_id: data.id,
      },
    });
  };
  const classes = styles_InvestorCard();
  const history = useHistory();

  const handleRoute = () => {
    setInvestor(data);
    history.push(`${ROUTES.INVESTORS}/${data.id}`);
  };

  return (
    <Card className={clsx(data.system?.delete && classes.disabled, classes.card)}>
      <Box display="flex" flexDirection="row" style={{ width: '100%' }}>
        <CardActionArea
          classes={{
            root: classes.cardActionArea,
            focusHighlight: classes.focusHighlight,
          }}
          onClick={() => handleRoute()}
          disabled={data.system?.delete}
        >
          <CardContent className={classes.cardContent}>
            <Typography variant="h4">{data.name}</Typography>

            {data.address.country && (
              <Typography variant="body2">
                <span style={{ marginRight: '4px' }}>{isoToFlag(data.address.country.iso)}</span>
                {data.address.city ? `${data.address.city},` : ''} {data.address.country.name}
              </Typography>
            )}

            <Typography variant="body2">
              <Investors className={classes.icon} /> {investorContacts === null ? '0' : investorContacts.length}{' '}
              {investorContacts === null
                ? 'Contacts'
                : investorContacts.length === 0 || investorContacts.length > 1
                ? 'Contacts'
                : 'Contact'}
            </Typography>

            <Typography variant="body2">
              <Deals className={classes.icon} /> {investorDeals === null ? '0' : investorDeals.length}{' '}
              {investorDeals === null
                ? 'Deals'
                : investorDeals.length === 0 || investorDeals.length > 1
                ? 'Deals'
                : 'Deal'}
            </Typography>

            <Typography variant="body2">
              <Money className={classes.icon} /> {termsheets_count !== null && `${termsheets_count} Termsheets`}
            </Typography>

            <Typography variant="body2">
              <Tasks className={classes.icon} /> {task_count === null ? '0' : task_count}{' '}
              {task_count === null ? 'Tasks to do' : task_count === 0 || task_count > 1 ? 'Tasks to do' : 'Task to do'}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions className={classes.cardActions}>
          <IconButton
            color="secondary"
            aria-controls="investor-contextual-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Dots className={classes.svg} />
          </IconButton>
          <Menu
            id="investor-contextual-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={4}
          >
            <MenuItem component={NavLink} to={`investors/${data.id}`}>
              View
            </MenuItem>
            <MenuItem onClick={() => removeInvestor()}>Delete</MenuItem>
          </Menu>
        </CardActions>
      </Box>
      {data.system?.delete ? <LinearProgress variant="query" className={classes.linearprogress} /> : null}
    </Card>
  );
};

export default InvestorCard;
