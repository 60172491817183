import 'firebase/firestore';
import FirebaseClient from 'src/services/firebaseAuth';
import Fluit from 'src/types/Fluit';
const fb = FirebaseClient;

export const API = async ({ method, payload, endpoint, test }: Fluit.services.API) => {
  const live_url = process.env.REACT_APP_FLUIT_API_URL;
  const test_url = 'https://9038f8a067bb.eu.ngrok.io/v1/';
  const fb_user = fb.auth().currentUser;
  const token = fb_user && (await fb_user.getIdToken());
  // const ngrok = true;
  const ngrok = test;
  try {
    let opts: RequestInit = {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      opts.body = JSON.stringify(payload);
    }
    const resp = await fetch(`${ngrok ? test_url : live_url}${endpoint}`, opts);
    if (resp.status === 402) {
      throw new Error('Payment Error');
    }
    if (resp.status > 200) {
      const msg: string = await resp.text();
      throw new Error(msg);
    }
    const result = await resp.json();
    return result;
  } catch (err) {
    throw err;
  }
};
