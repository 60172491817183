import { Grid, makeStyles, Paper, Tab, Tabs, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import * as React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    '& button': {
      maxWidth: '100%',
      padding: theme.spacing(1.5, 3),
      '& span': {
        alignItems: 'flex-start',
      },
      '&:hover': {
        backgroundColor: colors.purple[1],
      },
    },
  },
  mobileHotfix: {
    width: '100vw',
  },
  paper: {
    background: 'none',
    boxShadow: 'none',
  },
}));

interface Props {
  handleTabs: (event: React.ChangeEvent<{}>, value: any) => void;
  tab: string | null;
}

const InvestorTabs: React.FC<Props> = ({ handleTabs, tab }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { investor } = React.useContext(InvestorContext);
  const { state } = React.useContext(AppContext);
  const { tasks, contacts, deals, assignments, processes } = state;

  const investorContacts = _.filter(contacts, { investor_id: investor.id });
  const assignedTo = _.filter(assignments, { investor_id: investor.id });
  const inProcesses = _.map(assignedTo, assignment => _.find(processes, { id: assignment.process_id }));
  const filterProcess = _.filter(inProcesses, _.size) as Fluit.process.Process[];
  const investorDeals = _.map(filterProcess, process => _.find(deals, { id: process?.deal_id }));
  const investorTasks = _.filter(tasks, { parent_id: investor.id, completed: false });
  const contactAllTasks = _.filter(tasks, function(task) {
    _.map(investorContacts, contact => contact.id === task.parent_id);
  });
  const contactTasks = _.filter(contactAllTasks, { completed: false });

  const termsheets_count = _.filter(state.termsheets, { investor_id: investor.id }).length;

  return (
    <Grid item xs={12} className={clsx(mobile && classes.mobileHotfix)}>
      <Paper className={clsx(mobile && classes.paper)}>
        <Tabs
          value={tab}
          orientation={mobile ? 'horizontal' : 'vertical'}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
          className={clsx(!mobile && classes.tabs)}
          aria-label="scrollable"
        >
          <Tab
            value="contacts"
            label={`Contacts (${investorContacts === null ? '0' : investorContacts.length})`}
            data-tour="investor_contacts"
          />
          <Tab value="deals" label={`Deals (${investorDeals.length})`} data-tour="investor_deals" />
          <Tab value="termsheets" label={`Termsheets (${termsheets_count})`} data-tour="investor_termsheets" />
          <Tab
            value="tasks"
            label={`Tasks (${investorTasks.length + contactTasks.length})`}
            data-tour="investor_tasks"
          />
          <Tab value="notes" label="Notes" data-tour="investor_notes" />
          <Tab value="activity" label="Activity" data-tour="investor_activity" />
          <Tab value="details" label="Details" data-tour="investor_details" />
          <Tab value="legal" label="Legal" data-tour="investor_legals" />
        </Tabs>
      </Paper>
    </Grid>
  );
};

export default InvestorTabs;
