import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InvestorDragDrop from 'src/components/Process/InvestorDragDrop';
import ProcessDragDrop from 'src/components/Process/ProcessDragDrop';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import * as ROUTES from 'src/routes/routes';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DealProcess: React.FC = () => {
  const { dispatch } = React.useContext(AppContext);
  const { deal } = React.useContext(DealContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  let history = useHistory();
  let query = useQuery();

  const handlePage = (page: string) => {
    history.push(`${ROUTES.DEALS}/${deal.id}?tab=process&sub=${page}`);
  };

  const page = query.get('sub') ? query.get('sub') : 'main';

  const assignInvestor = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Add Investor to Deal`,
        component: 'DEAL_ASSIGN_INVESTORS',
      },
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box hidden={page !== 'main'}>
            <Box display="flex" flexDirection={!mobile ? 'row' : 'column'} justifyContent="space-between">
              <Typography variant="h2">Process</Typography>
              <Box mt={mobile ? 2 : 0}>
                <Button
                  color="secondary"
                  style={{ marginRight: 16 }}
                  variant="contained"
                  onClick={() => assignInvestor()}
                >
                  Add Investors
                </Button>
                <Button color="secondary" variant="outlined" onClick={() => handlePage('edit')}>
                  Edit Process
                </Button>
              </Box>
            </Box>
          </Box>
          <Box hidden={page !== 'edit'}>
            <Box display="flex" flexDirection={!mobile ? 'row' : 'column'} justifyContent="space-between">
              <Typography variant="h2">Edit Process</Typography>
              <Box mt={mobile ? 2 : 0}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    dispatch({
                      type: 'MODAL',
                      payload: {
                        open: true,
                        title: `New Step`,
                        component: 'PROCESS_STEP_CREATE',
                      },
                    })
                  }
                >
                  Add Step
                </Button>
                <Button
                  style={{ marginLeft: '16px' }}
                  color="secondary"
                  variant="outlined"
                  onClick={() => handlePage('main')}
                >
                  Back
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>

      {page === 'main' && (
        <Grid item xs={12}>
          <InvestorDragDrop />
        </Grid>
      )}

      {page === 'edit' && (
        <Grid item xs={12}>
          <ProcessDragDrop />
        </Grid>
      )}
    </Grid>
  );
};

export default DealProcess;
