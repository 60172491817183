import { Box, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'src/contexts/AppContext';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { isoToFlag } from 'src/helpers/isoToFlag';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import shadows from 'src/themes/shadows';

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    boxShadow: 'none',
    height: '64px',
    '& input': {
      fontSize: 16,
      fontWeight: 600,
      color: colors.navy[900],
    },
    '& fieldset': {
      border: `2px solid ${colors.common.white}`,
      boxShadow: 'none',
    },
    '& > div, & > div > div': {
      height: '64px',
    },
  },
  svg: {
    width: '14px',
    height: '14px',
    marginRight: theme.spacing(1),
    fill: theme.palette.grey[200],
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  inlineIcon: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  paper: {
    boxShadow: shadows.black[3],
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  setLoading: (value: React.SetStateAction<boolean>) => void;
}

const InvestorSwitch: React.FC<Props> = ({ setLoading }) => {
  const { state } = React.useContext(AppContext);
  const { investors } = state;
  let investorsArray = _.values(investors);
  const { investor, setInvestor } = React.useContext(InvestorContext);
  const classes = useStyles();
  const history = useHistory();
  let query = useQuery();

  const handleChange = (event: object, values: any) => {
    if (values) {
      setInvestor(values);
      setLoading(true);
      history.push(`${ROUTES.INVESTORS}/${values.id}?tab=${query.get('tab') ? query.get('tab') : 'contacts'}`);
    }
  };

  return (
    <Box>
      <Autocomplete
        id="investor-switch"
        options={investorsArray}
        getOptionLabel={option => option.name}
        fullWidth
        className={classes.autocomplete}
        onChange={handleChange}
        value={investor}
        getOptionSelected={(option, value) => option.id === value.id}
        classes={{
          paper: classes.paper,
        }}
        renderOption={option => {
          return (
            <Box py={1}>
              <Typography variant="h4">{option.name}</Typography>
              {option.address.country && (
                <Typography variant="body2">
                  {isoToFlag(option.address.country.iso)} {option.address.country.name}
                </Typography>
              )}
            </Box>
          );
        }}
        renderInput={params => <TextField {...params} variant="outlined" label="Investor" />}
      />
    </Box>
  );
};

export default InvestorSwitch;
