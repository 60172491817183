import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import { AssignmentsFirebase } from 'src/services/assignments';
import { ContactFirebase } from 'src/services/contacts';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${colors.grey[100]}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));

const ContactsAssignForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const [dataLoad, setDataLoad] = React.useState(true);
  const [checked, setChecked] = React.useState(['']);
  const investor = _.find(state.investors, investor => investor.id === state.ui.modal.id);
  let history = useHistory();
  const classes = useStyles();
  const { contacts, organisation, assignments } = state;
  const investorContacts = _.filter(contacts, { investor_id: investor?.id });
  const assignment_id = String(state.ui.modal.assignment_id);

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  React.useEffect(() => {
    if (checked[0] === '') {
      setChecked([]);
    }
  }, [checked, setChecked]);

  React.useEffect(() => {
    const processInvestor = _.find(assignments, { id: assignment_id });
    if (processInvestor) {
      if (processInvestor.contacts) {
        setChecked(processInvestor.contacts);
      }
    }
  }, [assignments, assignment_id, setChecked]);

  React.useEffect(() => {
    let active = true;

    if (!dataLoad) {
      return undefined;
    }

    (async () => {
      try {
        setDataLoad(true);
        const api_contacts = await ContactFirebase.list(organisation.id);
        if (active) {
          const cleaned_contacts = _.filter(contacts, contact => contact.investor_id !== String(state.ui.modal.id));
          dispatch({ type: 'CONTACTS_LIST', payload: _.concat(cleaned_contacts, api_contacts) });
          setDataLoad(false);
        }
      } catch (error) {
        setDataLoad(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [dataLoad, dispatch, organisation.id, state.ui.modal.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const assignInvestor = React.useCallback(async () => {
    setLoading(true);

    try {
      const current_assignment = _.find(assignments, { id: assignment_id });
      const contact_names = _.map(checked, id => {
        const contact = _.find(contacts, { id: id });
        return `${contact?.first_name} ${contact?.last_name}`;
      });
      const assignment = {
        ...current_assignment,
        contacts: checked,
        metadata: {
          activity_contacts: contact_names,
          activity_event_action: 'assignment_added_contacts',
        },
      };
      await AssignmentsFirebase.update(assignment);

      setToast({
        message: `Updated contacts for ${state.ui.modal.name}`,
        type: 'success',
      });
      setLoading(false);
      closeModal();
    } catch (error) {
      const err: Fluit.firestore.Error = error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
      setLoading(false);
    }
  }, [checked, closeModal, setToast, state.ui.modal, assignment_id, assignments, contacts]);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleRedirect = () => {
    history.push(`${ROUTES.INVESTORS}/${investor?.id}`);
    closeModal();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <List className={classes.list}>
          {dataLoad ? (
            <GenericLoader height="100%" variant="none" />
          ) : !_.some(investorContacts) ? (
            <Typography variant="body1">
              This investor doesn't have any contacts, you can add some from the{' '}
              <Link onClick={() => handleRedirect()}>investor's page</Link>.
            </Typography>
          ) : (
            _.map(investorContacts, contact => (
              <ListItem
                key={contact.id}
                role={undefined}
                dense
                button
                onClick={handleToggle(contact.id)}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    edge="start"
                    checked={checked.indexOf(contact.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <Box id={contact.id}>
                  <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                    {contact.first_name} {contact.last_name}{' '}
                    {contact.decision_maker && (
                      <Chip size="small" label="Decision Maker" color="primary" style={{ marginLeft: 8 }} />
                    )}
                  </Typography>
                  <Typography variant="body1">{contact.position}</Typography>
                </Box>
              </ListItem>
            ))
          )}
        </List>
      </Grid>
      {_.some(investorContacts) ? (
        <>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={loading}
              onClick={() => assignInvestor()}
            >
              Update
            </Button>
            <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
              Cancel
            </Button>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress variant="query" />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Button variant="text" size="large" onClick={() => closeModal()}>
            Close
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ContactsAssignForm;
