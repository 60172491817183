import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import colors from 'src/themes/colors';
import Fluit from 'src/types/Fluit';
import InvestorDraggable from './InvestorDraggable';

const useStyles = makeStyles((theme: Theme) => ({
  dragOver: {},
  startDroppable: {},
  dragStart: {},
  droppable: {
    background: 'inherit',
    transition: 'background-color 0.5s ease-in-out',
    borderRadius: theme.spacing(0.5),
    minHeight: '80px',
    '&$dragStart': {
      border: `2px dashed ${colors.grey[100]}`,
    },
    '&$dragOver': {
      background: colors.purple[50],
      borderColor: colors.purple[100],
    },
    '&$startDroppable': {
      background: colors.grey[50],
      borderColor: colors.grey[100],
    },
  },
}));

interface Props {
  step: Fluit.process.Step;
  investors: Fluit.investors.Investor[];
  startId: string;
  stepIndex: number;
  dropState: Fluit.assignments.Assignment[];
}
const InvestorDroppable: React.FC<Props> = ({ step, investors, startId, stepIndex, dropState }) => {
  const classes = useStyles();
  const assigned = _.filter(dropState, { step_id: step.id });
  return (
    <Grid item xs={12}>
      <Box mb={2}>
        <Typography variant="h4">
          {step.name} <Typography variant="caption">({investors.length})</Typography>
        </Typography>
      </Box>
      <Droppable droppableId={step.id} direction="horizontal">
        {(provided, snapshot) => (
          <Grid
            container
            spacing={4}
            innerRef={provided.innerRef}
            className={clsx(
              snapshot.isDraggingOver && classes.dragOver,
              startId === step.id && classes.startDroppable,
              startId && classes.dragStart,
              classes.droppable
            )}
            {...provided.droppableProps}
          >
            {_.map(assigned, (investor, index) => {
              const foundInvestor = _.find(investors, { id: investor.investor_id });
              if (foundInvestor) {
                return (
                  <InvestorDraggable
                    key={investor.id}
                    stepInv={investor}
                    investor={foundInvestor}
                    index={index}
                    stepIndex={stepIndex}
                    assignmentId={investor.id}
                  />
                );
              }
            })}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </Grid>
  );
};

export default InvestorDroppable;
