import { Button, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { AssignmentsFirebase } from 'src/services/assignments';
import shadows from 'src/themes/shadows';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.error.dark,
      boxShadow: shadows.red[1],
    },
  },
}));

const InvestorsUnassignForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { assignments } = state;
  const { setToast } = React.useContext(ToastContext);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const unassign = React.useCallback(async () => {
    setLoading(true);

    const assignment_id = String(state.ui.modal.id);
    const assignment = _.find(assignments, { id: assignment_id });

    try {
      await AssignmentsFirebase.delete(assignment as Fluit.assignments.Assignment);
      setToast({
        message: `Removed Investor from deal`,
        type: 'info',
      });
      closeModal();
    } catch (error) {
      const err: Fluit.firestore.Error = error;
      setToast({
        message: `${err.name} | ${err.message}`,
        type: 'error',
      });
      setLoading(false);
    }
  }, [state.ui.modal, setLoading, setToast, closeModal, assignments]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="body1">This will remove the investor from the deal.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.delete}
          size="large"
          onClick={() => unassign()}
          disabled={loading ? true : false}
        >
          Remove
        </Button>
        <Button
          variant="text"
          style={{ marginLeft: '16px' }}
          size="large"
          onClick={() => closeModal()}
          disabled={loading ? true : false}
        >
          Cancel
        </Button>
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <LinearProgress variant="query" />
        </Grid>
      )}
    </Grid>
  );
};

export default InvestorsUnassignForm;
