import { format, parseISO, fromUnixTime } from 'date-fns';

export const formatDateandTime = (date: number | string | undefined | null, unix?: boolean) => {
  unix = unix ? true : false;
  if (date === null) {
    return '-';
  }
  if (date && !unix) {
    return format(parseISO(date.toString()), 'iii, do MMM yyyy, p');
  }
  if (date && unix) {
    const unix_date = fromUnixTime(Number(date));
    const d = new Date(unix_date).toISOString();
    return format(parseISO(d), 'iii, do MMM yyyy, p');
  }
  return '';
};
