import { Button, Grid, LinearProgress, MenuItem, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { ProcessContext } from 'src/contexts/ProcessContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { AssignmentsFirebase } from 'src/services/assignments';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const InvestorReassignForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { process } = React.useContext(ProcessContext);
  const { steps } = process;

  const investor_id = String(state.ui.modal.id);
  const investor_name = String(state.ui.modal.name);
  const step_index = Number(state.ui.modal.stage);
  const assignment_id = String(state.ui.modal.assignment_id);
  const assignment = _.find(state.assignments, { id: assignment_id });

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  let stepInvestor = React.useRef<Fluit.assignments.Assignment | null>(null);
  let stepContacts = React.useRef<string[] | null>(null);

  React.useEffect(() => {
    const all_investors = _.flattenDeep(_.map(steps, 'investors'));
    const current_investor = _.find(all_investors, { id: investor_id });
    if (current_investor) {
      stepInvestor.current = current_investor;
      if (current_investor.contacts) {
        stepContacts.current = current_investor.contacts;
      }
    }
  }, [investor_id, steps]);

  return (
    <Formik
      initialValues={{
        investor: investor_id,
        step_index: step_index,
      }}
      validationSchema={Yup.object().shape({
        step_index: Yup.number().required(`A stage is required to move this investor`),
      })}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);

        try {
          const newAssignment = {
            ...assignment,
            step_id: steps[values.step_index].id,
            metadata: {
              activity_previous_step_id: steps[step_index].id,
              activity_previous_step_name: steps[step_index].name,
              activity_event_action: 'assignment_moved',
            },
          };
          await AssignmentsFirebase.update(newAssignment);
          setToast({
            message: `${investor_name} moved from ${steps[step_index].name} to ${steps[values.step_index].name}`,
            type: 'info',
          });
          actions.setSubmitting(false);
          closeModal();
        } catch (error) {
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
          actions.setSubmitting(false);
        }
      }}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="caption">Current Stage</Typography>
                <Typography variant="body1">{steps[step_index].name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  select
                  name="step_index"
                  label="New Process Stage"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                >
                  {steps.map((step, index) => (
                    <MenuItem key={index} value={index}>
                      {index + 1} - {step.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Move
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default InvestorReassignForm;
