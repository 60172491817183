import { Grid, makeStyles, Menu, MenuItem, Popover, Theme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import NoteCard from 'src/components/Cards/Note';
import NoData from 'src/components/Empty';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';
import colors from 'src/themes/colors';
import { Notes } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '20px',
    height: '20px',
    fill: theme.palette.grey[400],
    '& g, & path': {
      fill: theme.palette.grey[400],
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  contact: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    display: 'inline-flex',
    padding: theme.spacing(0.25, 0.5),
    margin: theme.spacing(0, -0.5),
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: colors.blue[50],
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  },
  paper: {
    pointerEvents: 'auto',
  },
}));

interface Props {
  notes: Fluit.notes.Note[];
  createNote: (value: React.SetStateAction<string>) => void;
}

const NoteList: React.FC<Props> = ({ notes, createNote }) => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = React.useContext(AppContext);

  const [note, setNote] = React.useState<Fluit.notes.Note>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [poperEl, setPoperEl] = React.useState<HTMLElement | null>(null);
  const [popEl, setPopEl] = React.useState<HTMLElement | null>(null);
  const [content, setContent] = React.useState<JSX.Element>();

  // Interface Actions
  const handlePopoverClose = () => {
    setPoperEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, content: JSX.Element) => {
    setPoperEl(event.currentTarget);
    setPopEl(event.currentTarget);
    setContent(content);
  };

  const handlePopoverHover = () => {
    setPoperEl(popEl);
  };

  const handlePopoverExit = () => {
    setPopEl(null);
    setPoperEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, note_id: string) => {
    setAnchorEl(event.currentTarget);
    setNote(_.find(notes, { id: note_id }));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Delete Modal
  const NoteDelete = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Delete Note?`,
        component: 'NOTE_DELETE',
        note: note as Fluit.notes.Note,
      },
    });
  };

  return (
    <>
      {!_.some(notes) ? (
        <Grid item xs={12}>
          <NoData text={`No notes on the system.`} icon={<Notes />} label="Create a note" action={createNote} />
        </Grid>
      ) : (
        <>
          {_.map(notes, note => (
            <Grid item xs={12} key={note.id}>
              <NoteCard
                note={note}
                handlePopoverClose={handlePopoverClose}
                handlePopoverOpen={handlePopoverOpen}
                handleMenu={handleMenuOpen}
              />
            </Grid>
          ))}

          <Popover
            open={Boolean(poperEl)}
            anchorEl={poperEl}
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            id="mouse-over-popover"
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            PaperProps={{ onMouseEnter: handlePopoverHover, onMouseLeave: handlePopoverExit, elevation: 5 }}
            disableRestoreFocus
          >
            {content ? content : null}
          </Popover>
          <Menu
            id="note-contextual-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            elevation={4}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                history.push(`${ROUTES.NOTES}/${note?.id}/edit`);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                NoteDelete();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default NoteList;
