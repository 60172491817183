import { Box, Chip, Link, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { data_currencies } from 'src/constants/currencies';
import { InvestorContext } from 'src/contexts/InvestorContext';
import { formatCurrency } from 'src/helpers/formatCurrency';
import { isoToFlag } from 'src/helpers/isoToFlag';
import { Email, Fax, Telephone, Website } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    fill: theme.palette.grey[200],
    '& g, & path': {
      fill: theme.palette.grey[200],
    },
  },
  iconLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(2, -0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const InvestorDetailsDisplay: React.FC = () => {
  const classes = useStyles();
  const { investor } = React.useContext(InvestorContext);

  const currency: Fluit.Currency | undefined = _.find(
    data_currencies,
    item => item.code === investor.address.country.currency
  );

  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h4">{investor.name}</Typography>

        {investor.address ? (
          <Typography variant="body2" className={classes.iconLink}>
            <span style={{ marginRight: '4px' }}>{isoToFlag(investor.address.country.iso)}</span>
            <span>
              {investor.address.city ? `${investor.address.city},` : ''} {investor.address.country.name}
            </span>
          </Typography>
        ) : null}

        {investor.telephone ? (
          <Typography variant="body2">
            <Link color="secondary" href={`tel:${investor.telephone}`} className={classes.iconLink}>
              <Telephone className={classes.svg} /> {investor.telephone}
            </Link>
          </Typography>
        ) : null}

        {investor.fax ? (
          <Typography variant="body2" className={classes.iconLink}>
            <Fax className={classes.svg} /> {investor.fax}
          </Typography>
        ) : null}

        {investor.email ? (
          <Typography variant="body2">
            <Link color="secondary" href={`mailto:${investor.email}`} target="_blank" className={classes.iconLink}>
              <Email className={classes.svg} /> {investor.email}
            </Link>
          </Typography>
        ) : null}

        {investor.website ? (
          <Typography variant="body2">
            <Link color="secondary" href={`http://${investor.website}`} target="_blank" className={classes.iconLink}>
              <Website className={classes.svg} /> {investor.website}
            </Link>
          </Typography>
        ) : null}

        {_.some(investor.regions) ? (
          <Box my={1}>
            <Typography variant="body2">
              <Typography variant="caption">Regions: </Typography>
              {_.map(investor.regions, region => (
                <Chip
                  style={{ marginRight: 4 }}
                  key={`${region}${_.uniqueId()}`}
                  component="span"
                  size="small"
                  label={region}
                />
              ))}
            </Typography>
          </Box>
        ) : null}

        {_.some(investor.stages) ? (
          <Box mb={1}>
            <Typography variant="body2">
              <Typography variant="caption">Stages: </Typography>
              {_.map(investor.stages, stage => (
                <Chip
                  style={{ marginRight: 4 }}
                  key={`${stage}${_.uniqueId()}`}
                  component="span"
                  size="small"
                  label={stage}
                />
              ))}
            </Typography>
          </Box>
        ) : null}

        {_.some(investor.sectors) ? (
          <Box mb={1}>
            <Typography variant="body2">
              <Typography variant="caption">Sectors: </Typography>

              {_.map(investor.sectors, sector => (
                <Chip
                  style={{ marginRight: 4 }}
                  key={`${sector}${_.uniqueId()}`}
                  component="span"
                  size="small"
                  label={sector}
                />
              ))}
            </Typography>
          </Box>
        ) : null}

        {investor.investment_min && investor.investment_max ? (
          <Box>
            <Typography variant="body2">
              <Typography variant="caption">Investment Size:</Typography>
            </Typography>
            <Typography variant="body1">
              {`${formatCurrency(investor.investment_min, currency?.symbol, true)} - ${formatCurrency(
                investor.investment_max,
                currency?.symbol,
                true
              )}`}
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Paper>
  );
};

export default InvestorDetailsDisplay;
