import { Button, Grid } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import CreditCard from 'src/components/Billing/CreditCard';
import GenericLoader from 'src/components/Loaders/GenericLoader';
import { AppContext } from 'src/contexts/AppContext';
import { BillingFirebase } from 'src/services/billing';

const BillingPaymentMethods: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { billing } = state;
  const { payment_methods, customer } = billing;
  const [loading, setLoading] = React.useState(true);

  const getPaymentMethods = React.useCallback(async () => {
    const result = await BillingFirebase.listPaymentMethods(billing.stripe_customer_id);
    dispatch({ type: 'BILLING_PAYMENT_METHOD_LIST', payload: result });
    setLoading(false);
  }, [billing.stripe_customer_id, dispatch]);

  React.useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const addPaymentMethod = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: 'New Payment Method',
        component: 'ADD_PAYMENT_METHOD',
      },
    });
  };

  if (loading) {
    return <GenericLoader height="160px" variant="none" />;
  }

  return (
    <Grid container spacing={4}>
      {_.map(payment_methods, method => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={method.id}>
          <CreditCard
            brand={method.card.brand}
            last4={method.card.last4}
            exp_month={method.card.exp_month}
            exp_year={method.card.exp_year}
            id={method.id}
            main={method.id === customer?.invoice_settings?.default_payment_method}
          />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button variant="outlined" color="secondary" onClick={() => addPaymentMethod()}>
          + Add payment method
        </Button>
      </Grid>
    </Grid>
  );
};

export default BillingPaymentMethods;
