import { Box, Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import AutocompleteCurrencies from 'src/components/Inputs/Autocomplete/Currencies';
import SelectFundingRounds from 'src/components/Inputs/Selects/Rounds';
import TextfieldFormattedNumber from 'src/components/Inputs/Textfields/FormattedNumber';
import { data_currencies } from 'src/constants/currencies';
import { initalise } from 'src/constants/init';
import { OnboardingContext } from 'src/contexts/OnboardContext';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const OnboardFunding: React.FC = () => {
  /* Context */
  const { onboard, setOnboard } = React.useContext(OnboardingContext);

  const prevPage = () => {
    setOnboard({
      ...onboard,
      step: onboard.step - 1,
    });
  };

  const find_currency = _.find(data_currencies, { code: onboard.organisation.location.currency });

  const cur = onboard.currency_step
    ? onboard.organisation.currency
    : find_currency
    ? find_currency
    : initalise.currency;

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h1">Funding Details</Typography>
      </Box>
      <Formik
        initialValues={{
          current_round: onboard.organisation.current_round
            ? onboard.organisation.current_round
            : ('Seed' as Fluit.Rounds),
          currency: cur,
          total_funding: onboard.organisation.total_funding ? String(onboard.organisation.total_funding) : '0',
        }}
        onSubmit={(values, actions) => {
          setOnboard({
            ...onboard,
            organisation: {
              ...onboard.organisation,
              current_round: values.current_round as Fluit.Rounds,
              currency: values.currency,
              total_funding: parseInt(values.total_funding, 10),
            },
            step: onboard.step + 1,
            currency_step: true,
          });
        }}
        validationSchema={Yup.object().shape({
          currency: Yup.object().shape({
            label: Yup.string(),
            code: Yup.string(),
            symbol: Yup.string(),
            alpha_2: Yup.string().required(`Select which currency you're raising in or trading in`),
          }),
          total_funding: Yup.number()
            .required('Total Funding is required')
            .min(0, `Can't be below 0`),
        })}
      >
        {props => {
          const { isSubmitting, handleSubmit, touched, errors, values, setFieldValue } = props;
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SelectFundingRounds
                    name="current_round"
                    label="Current Funding Round"
                    setFieldValue={setFieldValue}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteCurrencies
                    setFieldValue={setFieldValue}
                    name="currency"
                    label="Funding or Opperating Currency"
                    error={errors.currency?.alpha_2}
                    touched={touched.currency?.alpha_2}
                    value={values.currency}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextfieldFormattedNumber
                    name="total_funding"
                    label="Total Funding"
                    value={values.total_funding}
                    currency={values.currency?.symbol}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    style={{ marginRight: '16px' }}
                  >
                    Next
                  </Button>
                  <Button color="default" size="large" onClick={() => prevPage()}>
                    Back
                  </Button>
                </Grid>
                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress variant="query" />
                  </Grid>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OnboardFunding;
