import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { connectPoweredBy } from 'react-instantsearch-dom';
import AlgoliaLogo from 'src/assets/logo/logo-algolia.png';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: 80,
    height: 'auto',
    [theme.breakpoints.only('xs')]: {
      margin: '0 auto',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    opacity: 0.6,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&:hover': {
      opacity: 1,
    },
    transition: `0.2s opacity linear`,
    [theme.breakpoints.only('xs')]: {
      display: 'inline-flex',
      textAlign: 'center',
    },
  },
}));

interface Props {
  url: string;
}

const AlgoliaPoweredBy = ({ url }: Props) => {
  const classes = useStyles();
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={classes.link}>
      <Typography variant="caption">Search by:</Typography>
      <img src={AlgoliaLogo} alt="Algolia Logo" className={classes.img} />
    </a>
  );
};

export default connectPoweredBy(AlgoliaPoweredBy);
