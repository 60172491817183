export const initialValue = [
  {
    type: 'paragraph',
    children: [
      {
        text: 'Paragraph with the contents of your note. You can also add ',
      },
      {
        type: 'link',
        url: 'https://www.fluit.co',
        children: [
          {
            text: 'links',
          },
        ],
      },
      {
        text: ' in your documents.',
      },
    ],
  },
  {
    type: 'block-quote',
    children: [
      {
        text: 'Creativity is just connecting things. \n',
      },
      {
        text: '- Steve Jobs',
        bold: true,
      },
    ],
  },
  {
    type: 'heading-two',
    children: [
      {
        text: 'Sub Title',
      },
    ],
  },
  {
    type: 'bulleted-list',
    children: [
      {
        type: 'list-item',
        children: [
          {
            text: 'You can',
          },
        ],
      },
      {
        type: 'list-item',
        children: [
          {
            text: 'add bulleted lists',
          },
        ],
      },
    ],
  },
  {
    type: 'numbered-list',
    children: [
      {
        type: 'list-item',
        children: [
          {
            text: 'You can',
          },
        ],
      },
      {
        type: 'list-item',
        children: [
          {
            text: 'also add numbered lists',
          },
          {
            type: 'link',
            url: 'https://www.google.com',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            text: '',
          },
          {
            type: 'link',
            url: 'https://www.google.com',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            text: '',
          },
          {
            type: 'link',
            url: 'https://www.google.com',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            text: '',
          },
          {
            type: 'link',
            url: 'https://www.google.com',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            text: '',
          },
        ],
      },
    ],
  },
  {
    type: 'heading-two',
    children: [
      {
        text: 'Keyboard Shortcuts',
      },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text: 'Click the (?) in the top right to see all shortcuts.',
      },
    ],
  },
];
