import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import NoData from 'src/components/Empty';
import InvestorTable from 'src/components/Investors/InvestorTable';
import MobileInvestor from 'src/components/Investors/MobileInvestor';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import * as ROUTES from 'src/routes/routes';
import { InvestorsNone } from 'src/themes/icons';
import Fluit from 'src/types/Fluit';

interface Props {
  investorCount: number;
}

const DealInvestors: React.FC<Props> = ({ investorCount }) => {
  const { state, dispatch } = React.useContext(AppContext);
  const [termsheets, setTermsheets] = React.useState<Fluit.deals.Termsheet[]>([]);
  const { deal } = React.useContext(DealContext);
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    setTermsheets(_.filter(state.termsheets, { deal_id: deal.id }));
  }, [state.termsheets, deal.id]);

  const assignInvestor = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        open: true,
        title: `Add Investor to Deal`,
        component: 'DEAL_ASSIGN_INVESTORS',
      },
    });
  };

  const addInvestors = () => {
    history.push(ROUTES.INVESTOR_SEARCH);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          style={{ height: !mobile ? '64px' : 'auto' }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">Investors</Typography>
            {investorCount > 0 ? (
              <Button color="secondary" variant="outlined" onClick={() => assignInvestor()}>
                Assign Investors
              </Button>
            ) : null}
          </Box>
        </Box>
      </Grid>
      {investorCount > 0 ? (
        <Grid item xs={12}>
          {!mobile ? <InvestorTable termsheets={termsheets} /> : <MobileInvestor />}
        </Grid>
      ) : _.some(state.investors) ? (
        <Grid item xs={12}>
          <NoData
            text={`No investors currently in this deal`}
            icon={<InvestorsNone />}
            label="Add Investors"
            action={assignInvestor}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <NoData
            text={`You haven't added any investors in your organisation`}
            icon={<InvestorsNone />}
            label="Add Investors"
            action={addInvestors}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DealInvestors;
