import { Button, Divider, Grid, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import AutocompleteCountries from 'src/components/Inputs/Autocomplete/Countries';
import AutocompleteCurrencies from 'src/components/Inputs/Autocomplete/Currencies';
import AutocompleteIndustries from 'src/components/Inputs/Autocomplete/Industries';
import SelectFundingRounds from 'src/components/Inputs/Selects/Rounds';
import TextfieldFormattedNumber from 'src/components/Inputs/Textfields/FormattedNumber';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { telephoneRegex, urlRegex } from 'src/constants/regex';
import { AppContext } from 'src/contexts/AppContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { OrganisationFirebase } from 'src/services/organisations';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const OrganisationEditForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const organisation = _.find(state.organisations, org => org.id === state.ui.modal.org_id);

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        id: organisation?.id ? organisation?.id : '',
        name: organisation?.name ? organisation?.name : '',
        telephone: organisation?.telephone ? organisation?.telephone : '',
        email: organisation?.email ? organisation?.email : '',
        website: organisation?.website ? organisation?.website : '',
        industry: organisation?.industry ? organisation?.industry : '',
        currency: {
          name: organisation?.currency.name ? organisation?.currency.name : '',
          alpha_2: organisation?.currency.alpha_2 ? organisation?.currency.alpha_2 : '',
          code: organisation?.currency.code ? organisation?.currency.code : '',
          symbol: organisation?.currency.symbol ? organisation?.currency.symbol : '',
        },
        current_round: organisation?.current_round ? organisation?.current_round : ('Seed' as Fluit.Rounds),
        total_funding: organisation?.total_funding ? organisation?.total_funding : 0,
        year_founded: organisation?.year_founded ? organisation?.year_founded : 2020,
        employees: organisation?.employees ? organisation?.employees : 1,
        location: {
          name: organisation?.location.name ? organisation?.location.name : '',
          iso: organisation?.location.iso ? organisation?.location.iso : '',
          currency: organisation?.location.currency ? organisation?.location.currency : '',
        },
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          values.total_funding = parseInt(String(values.total_funding), 10);
          const organisation = await OrganisationFirebase.update(values);
          dispatch({ type: 'ORGANISATION_UPDATE', payload: organisation });
          dispatch({ type: 'ORGANISATION_SET', payload: organisation });
          setToast({
            message: `Updated Organisation`,
            type: 'info',
          });
          actions.setSubmitting(false);
          closeModal();
        } catch (err) {
          actions.setSubmitting(false);
          setToast({
            message: err,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Organisation name is required'),
        telephone: Yup.string()
          .required('Organisation telephone number is required')
          .matches(telephoneRegex, 'Not a valid telephone number'),
        email: Yup.string()
          .email(`This is not a valid email address`)
          .required('Organisation primary email is required'),
        website: Yup.string().matches(urlRegex, 'Not a valid website address'),
        location: Yup.object().shape({
          country: Yup.string(),
          iso: Yup.string().required(`Select your company location from the list`),
        }),
        industry: Yup.string().required('Select your industry type from the list'),
        current_round: Yup.string().required('Select your current funding round'),
        employees: Yup.number()
          .required('Total Number of Employees is required')
          .min(1, 'Must be more than 1'),
        year_founded: Yup.number()
          .required('Total Number of Employees is required')
          .min(1500, 'Must be more than 1500')
          .max(new Date().getFullYear(), `Can't be after this year`),
        currency: Yup.object().shape({
          label: Yup.string(),
          code: Yup.string(),
          symbol: Yup.string(),
          alpha_2: Yup.string().required(`Select which currency you're raising in or trading in`),
        }),
        total_funding: Yup.number()
          .required('Total Funding is required')
          .min(0, `Can't be below 0`),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, errors, touched, setFieldValue, values } = props;

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="name"
                  label="Organisation Name"
                  autoComplete="company name"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <AutocompleteCountries
                  setFieldValue={setFieldValue}
                  name="location"
                  label="Location"
                  error={errors.location?.iso}
                  touched={touched.location?.iso}
                  value={values.location}
                />
              </Grid>

              <Grid item xs={12}>
                <AutocompleteIndustries
                  setFieldValue={setFieldValue}
                  name="industry"
                  label="Industry"
                  error={errors.industry}
                  touched={touched.industry}
                  value={values.industry}
                />
              </Grid>

              <Grid item xs={12} md={7}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  type="number"
                  name="year_founded"
                  label="Year Founded"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  type="number"
                  name="employees"
                  label="Number of Employees"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="telephone"
                  label="Telephone number"
                  autoComplete="telephone"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="email"
                  label="Email"
                  autoComplete="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="website"
                  label="Website"
                  autoComplete="website"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>

              <Grid item xs={12}>
                <SelectFundingRounds name="current_round" label="Current Funding Round" setFieldValue={setFieldValue} />
              </Grid>

              <Grid item xs={12}>
                <AutocompleteCurrencies
                  setFieldValue={setFieldValue}
                  name="currency"
                  label="Funding or Opperating Currency"
                  error={errors.currency?.alpha_2}
                  touched={touched.currency?.alpha_2}
                  value={values.currency}
                />
              </Grid>

              <Grid item xs={12}>
                <TextfieldFormattedNumber
                  name="total_funding"
                  label="Total Funding"
                  value={String(values.total_funding)}
                  currency={values.currency?.symbol}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  style={{ marginRight: '16px' }}
                >
                  Update
                </Button>
                <Button color="default" size="large" onClick={() => closeModal()}>
                  Close
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default OrganisationEditForm;
