import { Box, Button, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Seats } from 'src/assets/images/seats.svg';
import { AppContext } from 'src/contexts/AppContext';
import * as ROUTES from 'src/routes/routes';

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: '50%',
    height: 'auto',
    maxHeight: 180,
  },
  button: {
    margin: theme.spacing(0, 0, 0, 2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  },
  centered: {
    textAlign: 'center',
  },
}));

const UpgradeUsers: React.FC = () => {
  const { dispatch, state } = React.useContext(AppContext);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const seats = state.organisation.limits.members.limit;
  const history = useHistory();

  const closeModal = React.useCallback(() => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  }, [dispatch, state.ui.modal]);

  const handleRoute = () => {
    closeModal();
    history.push(ROUTES.BUY);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.centered}>
        <Box display="flex" justifyContent="center">
          <Seats className={classes.svg} />
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.centered}>
        <Box mb={2}>
          <Typography variant="h2">Out of seats</Typography>
        </Box>
        <Typography variant="body1">
          You currently using {seats === 1 ? 'your 1 seat' : `all of your ${seats} seats`}
        </Typography>
        <Typography variant="body1">Purchase additional seats to add continue adding users.</Typography>
      </Grid>
      <Grid item xs={12} className={classes.centered}>
        <Button color="primary" variant="contained" size="large" fullWidth={mobile} onClick={() => handleRoute()}>
          Purchase Seats
        </Button>
        <Button variant="outlined" size="large" className={classes.button} onClick={closeModal} fullWidth={mobile}>
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export default UpgradeUsers;
