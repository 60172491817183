import Fluit from 'src/types/Fluit';
import { initalise } from 'src/constants/init';

export const UiReducer = (state: Fluit.props.UI, action: Fluit.reducer.actions.Actions) => {
  switch (action.type) {
    case 'MODAL': {
      return {
        ...state,
        modal: action.payload,
      };
    }
    case 'NOTE_ATTACH_TO': {
      return {
        ...state,
        notes: action.payload,
      };
    }
    case 'UI_FLUSH':
      return initalise.application.ui;
    default:
      return { ...state };
  }
};
