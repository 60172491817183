import React from 'react';
import Fluit from 'src/types/Fluit';

export const ToastContext = React.createContext<Fluit.context.toast.Context>({} as Fluit.context.toast.Context);

export const ToastProvider = ({ children }: Fluit.props.Children) => {
  const [toast, setToast] = React.useState<Fluit.context.toast.State>({
    message: '',
    type: undefined,
  });
  return <ToastContext.Provider value={{ toast, setToast }}>{children}</ToastContext.Provider>;
};
