import { makeStyles, Theme } from '@material-ui/core';

/* App Styles */
const app = makeStyles((theme: Theme) => ({
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 0),
      justifyContent: 'space-between',
      width: '100%',
    },
  },
}));

interface Props {
  height: number;
  width: number;
  color?: string;
}

/* SVG Variants */
const icon = makeStyles<Theme, Props>(theme => ({
  svg: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    color: ({ color }) => color,
    fill: ({ color }) => color,
  },
}));

export const Styles = {
  app: app,
  icon: icon,
};
