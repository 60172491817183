import { Button, Grid, InputAdornment, LinearProgress } from '@material-ui/core';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import AutocompleteFilteredInvestorsByLetter from 'src/components/Inputs/Autocomplete/FilteredInvestorsbyLetter';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import NumberInput from 'src/components/Inputs/Textfields/NumberInput';
import { AppContext } from 'src/contexts/AppContext';
import { DealContext } from 'src/contexts/DealContext';
import { ToastContext } from 'src/contexts/ToastContext';
import { trimString } from 'src/helpers/trim';
import { TermsheetFirebase } from 'src/services/termsheets';
import Fluit from 'src/types/Fluit';
import * as Yup from 'yup';

const TermsheetEditForm: React.FC = () => {
  const { setToast } = React.useContext(ToastContext);
  const { state, dispatch } = React.useContext(AppContext);
  const { deal } = React.useContext(DealContext);
  const { termsheets } = state;

  const termsheet = _.find(termsheets, { id: String(state.ui.modal.id) });

  const closeModal = () => {
    dispatch({
      type: 'MODAL',
      payload: {
        ...state.ui.modal,
        open: false,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        id: termsheet ? termsheet.id : '',
        org_id: termsheet ? termsheet.org_id : '',
        deal_id: termsheet ? termsheet.deal_id : '',
        investor_id: termsheet ? termsheet.investor_id : '',
        amount: termsheet ? termsheet.amount : 0,
        valuation: termsheet ? termsheet.valuation : 0,
        url: termsheet ? termsheet.url : '',
        created_at: termsheet?.created_at,
        currency_iso: termsheet ? termsheet.currency_iso : deal.details.currency.code,
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const termsheet: Fluit.deals.Termsheet = {
          ...values,
          investor_id: values.investor_id,
          amount: Number(values.amount),
          url: trimString(values.url),
          valuation: Number(values.valuation),
        };

        try {
          const result = await TermsheetFirebase.update(termsheet);
          dispatch({ type: 'TERMSHEET_UPDATE', payload: result });
          setToast({
            message: `Termsheet Updated`,
            type: 'info',
          });
          actions.setSubmitting(false);
          closeModal();
        } catch (error) {
          const err: Fluit.firestore.Error = error;
          setToast({
            message: `${err.name} | ${err.message}`,
            type: 'error',
          });
          actions.setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        investor_id: Yup.string().required('An investor is required to add the termsheet'),
        amount: Yup.number().required('The amount of the termsheet is required'),
        valuation: Yup.number()
          .moreThan(Yup.ref('amount'), 'Valuation should be larger than the termsheet amount')
          .required('The Pre-Money valuation of the termsheet is required'),
        url: Yup.string().url('This is not a valid link'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit, setFieldValue, values, errors, touched } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AutocompleteFilteredInvestorsByLetter
                  setFieldValue={setFieldValue}
                  label="Investor"
                  name="investor_id"
                  touched={touched.investor_id}
                  error={errors.investor_id}
                  value={values.investor_id}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="amount"
                  label="Amount"
                  value={values.amount}
                  onChange={(e: object, value: any | null) => {
                    if (value !== null) {
                      setFieldValue('amount', value);
                    }
                  }}
                  InputProps={{
                    autoComplete: 'new-password',
                    inputComponent: NumberInput as any,
                    startAdornment: (
                      <InputAdornment position="start" disableTypography={true}>
                        {deal.details ? (deal.details.currency ? deal.details.currency?.symbol : '') : ''}
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="valuation"
                  label="Pre-Money Valuation"
                  value={values.amount}
                  onChange={(e: object, value: any | null) => {
                    if (value !== null) {
                      setFieldValue('details.amount', parseInt(value, 10));
                    }
                  }}
                  InputProps={{
                    autoComplete: 'new-password',
                    startAdornment: (
                      <InputAdornment position="start" disableTypography={true}>
                        {deal.details ? (deal.details.currency ? deal.details.currency?.symbol : '') : ''}
                      </InputAdornment>
                    ),
                    inputComponent: NumberInput as any,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="url"
                  label="Termsheet Link"
                  type="url"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" size="large" color="primary" disabled={isSubmitting} type="submit">
                  Update
                </Button>
                <Button variant="text" size="large" onClick={() => closeModal()} style={{ marginLeft: '16px' }}>
                  Cancel
                </Button>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default TermsheetEditForm;
