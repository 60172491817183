import { Box, Container, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import Footer from 'src/components/Layout/Footer';
import HeaderSite from 'src/components/Layout/HeaderSite';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto 1',
  },
  container: {
    marginTop: 120,
    marginBottom: 90,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(12),
      alignItems: 'flex-start',
    },
  },
}));

const LayoutPublic: React.FC<RouteProps> = props => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Box className={classes.box}>
      <HeaderSite />
      <Container maxWidth="md" className={classes.container}>
        {children as any}
      </Container>
      <Container maxWidth="md">
        <Footer md={true} />
      </Container>
    </Box>
  );
};

export default LayoutPublic;
