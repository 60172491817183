import { Button, Grid, LinearProgress, Link, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Field, Formik } from 'formik';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as GoogleIcon } from 'src/assets/images/google.svg';
import { default as FormikTextField } from 'src/components/Inputs/Textfields/FormikTextField';
import { AppContext } from 'src/contexts/AppContext';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import { ToastContext } from 'src/contexts/ToastContext';
import * as ROUTES from 'src/routes/routes';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  google: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
    fill: theme.palette.primary.main,
    '& > *': {
      fill: theme.palette.primary.main,
    },
  },
}));

const LoginForm: React.FC = () => {
  const classes = useStyles();
  const { setToast } = React.useContext(ToastContext);
  const fbAuth = React.useContext(FirebaseContext);
  const { dispatch } = React.useContext(AppContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [popup, setPopup] = React.useState(false);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            if (result.additionalUserInfo?.isNewUser) {
              dispatch({ type: 'USER_REGISTERING', payload: true });
            }
          })
          .catch(e => {
            if (e.code === 'auth/popup-blocked') {
              setPopup(true);
              return undefined;
            }
            setToast({
              message: e.message,
              type: 'error',
            });
          });
      });
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await fbAuth.signIn(values.email, values.password);
        } catch (error) {
          dispatch({ type: 'API_LOADING', payload: false });
          setToast({
            message: error.message,
            type: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required to login'),
        password: Yup.string().required('Password is required to login'),
      })}
    >
      {props => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  component={FormikTextField}
                  fullWidth={true}
                  name="email"
                  label="Email"
                  type="email"
                  autoComplete="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth={true}
                  component={FormikTextField}
                  name="password"
                  label="Password"
                  type="password"
                  inputProps={{
                    autoComplete: 'current-password',
                  }}
                  variant="outlined"
                />
              </Grid>

              {popup && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    The Google Signin popup has been blocked by your browser, click the button to try again.
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth={mobile}
                  type="submit"
                  style={{ marginRight: mobile ? 0 : '16px', marginBottom: mobile ? 16 : 0 }}
                >
                  Login
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  disabled={isSubmitting}
                  color="primary"
                  fullWidth={mobile}
                  onClick={signInWithGoogle}
                >
                  <GoogleIcon className={classes.google} /> Login with Google
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Link color="secondary" component={NavLink} to={ROUTES.REQUEST_RESET}>
                  Forgot Password
                </Link>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress variant="query" />
                </Grid>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
